import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import EnglandIcon from '../../../assets/images/england-icon.png'
import GermenIcon from '../../../assets/images/germany-icon.png'
import google from "../../../assets/images/google.webp";
import play_store from "../../../assets/images/app.webp";
import logo_light from "../../../assets/images/logo-light.webp";
import Language from "../Language/Language";

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

function Header() {

  return(
      <Navbar bg="navbar-blue" expand="lg" variant="dark">
          <Container>
              <Navbar.Brand href="/"><img src={logo_light} alt="logo" height="25" /></Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav icon-red" ></Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="justify-content-end flex-grow-1 pe-3">
                      <Nav.Link href="/features">Features</Nav.Link>
                      <Nav.Link href="/preise">Preise</Nav.Link>
                      <Nav.Link href="/support">Support</Nav.Link>
                      <Nav.Link href="/ueberuns">Über uns</Nav.Link>
                      <Nav.Link href="https://play.google.com/store/apps/details?id=com.to_app" target="_blank"><img src={google} /></Nav.Link>
                      <Nav.Link href="https://apps.apple.com/ch/app/team-organizer/id6445862867" target="_blank"><img src={play_store} /> </Nav.Link>
                      </Nav>
                  <Language />
              </Navbar.Collapse>
          </Container>
      </Navbar>

  )
}

export default Header