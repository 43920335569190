import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import TeamInfo from "./TeamInfo";
import Functions from "./Functions";
import AnswerOptions from "./AnswerOptions";
import Tasks from "./Tasks";
import Member from "./Member";
import EventTypes from "./EventTypes";
import Finish from "./Finish";
import Sidebar from "../GeneralComponents/Sidebar";
import Footer from '../GeneralComponents/Footer'
import { withTranslation } from 'react-i18next';

class AddTeam extends Component {

  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      activeTab: 0,
      visible: false,
      Fullvisible: false,
      user: JSON.parse(localStorage.getItem("userData")),
      formValue: {},
      teamId: "",
      team_name: ""
    };
    this.child = React.createRef();
  }

  HandleSideBar = () => {
    this.setState({
      visible: !this.state.visible,

    });
  };
  HandleSideBarRes = () => {
    this.setState({
      Fullvisible: !this.state.Fullvisible,
    });
  };

  handleActiveTab(index) {
    // console.log(this.state.activeTab, ' - ', index);
    if (this.state.activeTab === 1 && index === 2) {
      if (this.state.teamId) {
       // this.child.current.getFunctions();
      }
    }

    this.setState({
      activeTab: index
    });
  }

  onLogoutHandler = () => {
    localStorage.clear();
    this.setState({
      navigate: true,
    });
  };

  teamInfo(item) {
    console.log("TTTTTTT", item)
    if (item) {
      this.setState({
        teamId: item.team_id,
        team_name: item.Team_name
      })
      console.log(this.state.team_name)
    }
  }

  componentDidMount() {
    this.teamInfo()

  }
  // getTeamInfo(){
  //     let id = '';
  //     if(this.props.match.params.id != 'undefined')
  //     {
  //       id = this.props.match.params.id
  //     }
  //     axios
  //       .get(APIURL+"team_info/" + id)
  //       .then((response) => {
  //           console.log("team_info ==== ",response.data)
  //           // this.setState({
  //           //     func_List:response.data
  //           // })
  //           // console.log("list",this.state.func_List)
  //       });
  // }

  onClick = () => {
   // this.child.current.getFunctions();
  };

  render() {
    const { t } = this.props;
    console.log("Edit team", this.props)
    // console.log("Edit TEam ",this.props.location.pathname)
    const { visible } = this.state
    const { navigate } = this.state;
    if (navigate || !this.state.user) {
      return <Redirect to="/login" push={true} />;
    }
    return (
      <div id="wrapper">
        <Sidebar
          data={{
            // teamInfo: this.teamInfo.bind(this),
            teamId: this.props.match.params.id,
            team_name: this.state.team_name
          }} />
        <div className={visible ? "content-page content-margin" : "content-page"}>
          <div className="content">
            {/* <!-- Start Content--> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box page-title-box-alt">
                    <h4 className="page-title">{this.state.team_name}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div id="rootwizard">
                    <ul className="nav nav-pills nav-justified form-wizard-header mb-3">
                      <li className="nav-item" data-target-form="#teaminfoform">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(0)}
                          className={this.state.activeTab === 0 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">1</span></span> <span className="d-none d-sm-inline">Team Infos</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#functionform">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(1)}
                          className={this.state.activeTab === 1 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">2</span></span> <span className="d-none d-sm-inline">Functions</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#mitgliderfrom">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(2)}
                          className={this.state.activeTab === 2 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">3</span></span> <span className="d-none d-sm-inline">Members</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#Tasksform">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(3)}
                          className={this.state.activeTab === 3 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">4</span></span> <span className="d-none d-sm-inline">Tasks</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#antwortform">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(4)}
                          className={this.state.activeTab === 4 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">5</span></span> <span className="d-none d-sm-inline">Answer options</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#eventtypenform">
                        <Link
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(5)}
                          className={this.state.activeTab === 5 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">6</span></span> <span className="d-none d-sm-inline">Event types</span>
                        </Link>
                      </li>
                      <li className="nav-item" data-target-form="#finsih">
                        <Link

                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(6)}
                          className={this.state.activeTab === 6 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">7</span></span> <span className="d-none d-sm-inline">Finish</span>
                        </Link>
                      </li>
                    </ul>
                    {/* Team Informatin */}
                    <div className="tab-content mb-0 b-0">
                      <div
                        className={this.state.activeTab === 0 ? ' tab-pane fade active show' : 'tab-pane fade'} id="first">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "14%" }}></div>
                        </div>
                        <TeamInfo
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamInfo: this.teamInfo.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId
                          }} />
                      </div>

                      <div className={this.state.activeTab === 1 ? ' tab-pane fade active show' : 'tab-pane fade'} id="second">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "28%" }}></div>
                        </div>
                        <Functions
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId,
                            teamInfo: this.teamInfo.bind(this)
                          }} />
                      </div>

                      <div className={this.state.activeTab === 2 ? ' tab-pane fade active show' : 'tab-pane fade'} id="third">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "42.5%" }}></div>
                        </div>
                        <Member
                          ref={this.child}
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId,
                            teamInfo: this.teamInfo.bind(this)
                          }} />
                      </div>

                      <div className={this.state.activeTab === 3 ? ' tab-pane fade active show' : 'tab-pane fade'} id="fourth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "57%" }}></div>
                        </div>
                        <Tasks
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId,
                            teamInfo: this.teamInfo.bind(this)
                          }} />

                      </div>

                      <div className={this.state.activeTab === 4 ? ' tab-pane fade active show' : 'tab-pane fade'} id="fivth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "71%" }}></div>
                        </div>
                        <AnswerOptions
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId,
                            teamInfo: this.teamInfo.bind(this)
                          }} />

                      </div>

                      <div className={this.state.activeTab === 5 ? ' tab-pane fade active show' : 'tab-pane fade'} id="sixth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "86%" }}></div>
                        </div>
                        <EventTypes
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.props.match.params.id,
                            // teamId:this.state.teamId,
                            teamInfo: this.teamInfo.bind(this)
                          }} />
                      </div>
                      <div className={this.state.activeTab === 6 ? ' tab-pane fade active show' : 'tab-pane fade'} id="seventh">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "100%" }}></div>
                        </div>
                        <Finish />
                        <ul className="pager wizard mb-0 list-inline">
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <!-- container-fluid --> */}
          </div>
        </div>
                    
        <Footer />

        {/*<footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <Link to="#"><i className="fe-facebook"></i></Link>
                        <Link to="#"><i className="fe-instagram"></i></Link>
                        TeamOrganizer GMbH
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-end footer-links d-none d-sm-block">
                            <Link to="/">imprint</Link>
                            <Link to="/">privacy</Link>
                            <Link to="/">design by stoz</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>*/}
        <div className="rightbar-overlay"></div>
      </div>
    );
  }
}
export default withTranslation()(AddTeam);