import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import dateFormat from 'dateformat';
import { withTranslation } from 'react-i18next';
import card from "../../../assets/images/card.webp"


class Index extends Component {
    constructor() {
        super()
        this.state = {
         navigate: false,
            scsMsg:[]
           

        };
    }


    componentDidMount() {
       
    }

 
    render() {
        const { t } = this.props;
        
        return (
            <div>
               <header>
                    <nav className="navbar navbar-expand-md navbar-blue fixed-top">
                        <div className="container">
                            <a className="navbar-brand" href="#">
                                <img src={logo_light} alt="logo" height="25" />
                            </a>
                        </div>
                    </nav>
                </header>

                <div className="errorpage">
                    <div>
                        <img src={card} alt="card" />
                        <h2>Payment Failed</h2>
                        <p>Your transaction has failed due to some technical error.<br />Please try again.</p>
                    </div>

                    <Link to="/home" className="btn btn-warning text-white">Go Home</Link>
                </div>
              
            </div>
        );
    }
}
export default withTranslation()(Index);