import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import EnglandIcon from '../../assets/images/england-icon.png'
import GermenIcon from '../../assets/images/germany-icon.png'

function Language() {

  useEffect(() => {
      if(JSON.parse(localStorage.getItem("language"))!=null)
        {
           let lang=JSON.parse(localStorage.getItem("language"));
           i18n.changeLanguage(lang);
        }
  }, []);
  const { i18n } = useTranslation();

  function changeLanguage(e) {
    i18n.changeLanguage(e);
    localStorage.setItem("language",JSON.stringify(e));
  }

  
  return(
    <div className="language-box">
     <button className="tooltip_1"><span>English</span><img alt="English" title="English" onClick={()=>changeLanguage('en')} src={EnglandIcon} /></button>
      <button className="tooltip_1"><span>German</span><img alt="German" title="German" onClick={()=>changeLanguage('ge')} src={GermenIcon} /></button>

    </div>
  )
}

export default Language