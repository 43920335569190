import React, { Component } from 'react'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import axios from "axios";
import { APIURL } from '../constants/Common';
import { Link } from 'react-router-dom';
import '../../assets/css/Custom.css'
import avatar from "../../assets/images/avatar.png"
import { Spinner, Button  } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class TeamList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AllTeamList: [],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),

            teamId: "",
            selectedTeam: "",
            Loader:false,
            OnTeamclickmodal:false,
            team_Notification:[]       
        };
    }

    componentDidMount() {

        this.getTeamList()
    }

    getNotification = () => {
        
        const formData = new FormData();
        
        formData.append('team_id', this.props.data.ActiveId);
         let user_id=0;
        if(this.state.switchUser!=null)
        {
           user_id=this.state.switchUser.user_id;
        }
         if(this.state.user!=null)
        {
           user_id=this.state.user.user_id
        }

         formData.append('user_id', user_id);
       
        var token = this.state.token
     

        axios
            .post(APIURL + "notification/get-team", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    team_Notification: response.data.data
              })
               
            });
    }


    getTeamList() {
        this.setState({
            Loader:true
        })
        let user_id=this.state.user.user_id;
        if(this.state.switchUser!=null)
        {
           user_id=this.state.switchUser.user_id;
        }
        var token = this.state.token;
        axios
            .get(APIURL + "my_teams/" + user_id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        AllTeamList: response.data.data,
                        teamId: response.data.data,
                        Loader:false
                     },()=>{this.getNotification()})
                }
                else {
                    this.setState({
                        Loader:false
                    })
                }
            });
    }

    sub_Pop_up(){
        this.setState({
            OnTeamclickmodal:true
        })
    }
    
    refreshPage(e) {
window.location.href=e
      //  this.sub_Pop_up();
      //  console.log("Team information Admin",e)
     //   this.props.data.SetTeamId({team_info:e});
       // setTimeout(()=>{
            //window.location.reload();
      //  }, 100);
    }

    render(props) {
        const { t } = this.props;
        return (
            <>
            {
                this.state.Loader ?
                <div className="loader">
                    <Spinner style={{ width: '3rem', height: '3rem' }} />
                </div>
                :
                ""
            }
            {
                this.state.AllTeamList.length > 0 ?
                <div className="team-list">    
                   
                    {this.state.AllTeamList.map((item, idx) => (
                        <a  key={item.team_name}
                         onClick={(e) => this.refreshPage("/dashboard/" + item.team_id)}
                        //  to={"/dashboard/" + item.team_id}
                          
                           // onClick={(e) => this.refreshPage(item)}
                            className={parseInt(this.props.data.ActiveId) === item.team_id ? "waves-effect btn-text waves-flex  active" : "waves-effect  btn-text waves-flex "}

                        >
                            <div className="d-flex align-items-center">
                            {
                                item.icon_path ?
                                    <div>
                                        <LazyLoadImage
                                            alt={'avatar'}
                                            height={36}
                                            src={item.icon_path} // use normal <img> attributes as props
                                            width={36}
                                            effect="blur"
                                            className="bg-profile-pic cursor-pointer avatar-sm rounded-circle"
                                        />
                                    </div>
                                :
                                    <div>
                                        <LazyLoadImage
                                            alt={'avatar'}
                                            height={36}
                                            src={avatar} // use normal <img> attributes as props
                                            width={36}
                                            effect="blur"
                                            className="bg-profile-pic cursor-pointer avatar-sm rounded-circle"
                                        />
                                    </div>
                            }
                            {
                                <span className={this.props.data.visible ? "hidetxt ml-2 mr-3" : "ml-2 mr-3"}> {item.team_name}</span> 
                            }
                            {this.state.team_Notification.length>0 && this.state.team_Notification.filter((s, sidx) => item.team_id  == s.team_id ).map((n, sidx) => (
                                <div
                                    className="rounded-pill bg-danger badge-absolute"
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        lineHeight: 'inherit',
                                        color: '#fff',
                                        textAlign: 'center',
                                        whiteSpace: 'normal',
                                        verticalAlign: 'baseline',
                                        padding: '.35em .65em',
                                        height: '17px',
                                        minWidth: '17px',
                                    }}
                                >
                                    {n.notification}
                                </div>
                            ))}

                            </div>
                        </a>                                    
                    ))}
                </div>
                :
                <div className="menu-title" >{t("No teams available")}</div>
            }
            </>
        )
    }
}
export default withTranslation()(TeamList);