import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import Error from '../../assets/images/error-1.svg'
import no_img from '../../assets/images/no_data_found.svg'



export default class Home extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData"))
        };

    }

    componentDidMount() {
    }

    HandleSideBar = () => {
        this.setState({
            visible: !this.state.visible,
        },  );
        
        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
    };
    HandleSideBarRes = () => {
        this.setState({
            Fullvisible: !this.state.Fullvisible,
        });
        if (this.state.Fullvisible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.Fullvisible) {
            document.body.classList.add('sidebar-disable');
        }
    };

    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    render() {

        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                <div className={visible ? "content-page1 content-margin" : "content-page1"}>
                    <div className="content"></div>
                    <div className="container-fluid mt-5 error_page">
                        <div className="row align-items-center h-100">
                            <div className="col-sm-12">
                                <div className="error_txt">
                                    <img src={no_img} className="error_img" />
                                   
                                    <p>The page you requested cannot be found.</p>
                                    <Link to=""><i className="fa-caret-right fas"></i> Return to Homepage</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}