import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import '../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'
import XLSX from 'xlsx'
import { ExportSheet } from 'react-xlsx-sheet'
import Footer from '../GeneralComponents/Footer';
import { withTranslation } from 'react-i18next';
import { changeSort, sortUI } from "../../utils/sortUI";

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

class TeamMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Member_Team_List: [],
            teamId: "",
            email: "",
            isOwner: "",
            setMember: "",
            function: "",
            func_List: [],
            msg: "",
            errMsg: {},
            scsMsg: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),
            Is_admin: [],
            function_name: [],
            teamAttrs: [],
            team_name: "",
            Loader: false,
            modal: false,
            table: "",
            ActiveTeam: 0,
            isPermission: 1,
            no_record: false,
            sort: {},
            search: "",
        }
        this.onChangeSort = this.onChangeSort.bind(this);
    }

    getTeamAttributes(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_info/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("atrrr", response.data)
                if (response.data.status === 200) {

                    this.setState({ teamAttrs: response.data.data.team_attributes });
                }
            })
    }

    getTeamList(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_list/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({
                        Member_Team_List: response.data.data,
                        no_record: true
                    });
                }
                this.setState({
                    no_record: true
                })
            })
            .catch(error =>
                this.setState({
                    no_record: true
                })
            );
    }

    componentDidMount() {
        this.setState({
            table: document.querySelector('#table-to-xls'),
        });
        if (this.props.match.params.id > 0) {
            this.getTeamList(this.props.match.params.id);
            this.getTeamAttributes(this.props.match.params.id);
            this.getTeamDetails();
            this.getExpirationPlanMsg()
        }
    }

    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        let user_id = this.state.user.user_id;

        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })

            });

    }


    getExpirationPlanMsg = () => {
        console.log(this.state.user.user_id)
        const formData = new FormData();
        let user_id = this.state.user.user_id;
        if (this.state.switchUser != null) {
            user_id = this.state.switchUser.user_id;
        }
        formData.append('user_id', user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }
                /*else if(response.data.member.length==1)
                {
                    if(response.data.member[0].is_admin==0 && response.data.member[0].is_teamleader==0)
                    {
                         isPermission=0;
                    }
                }*/

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission
                    // ActiveTeam:1
                })
            });
    }

    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    onChangeSort(key) {
        let sortTem = {};
        sortTem[`${key}`] = changeSort(this.state.sort[`${key}`]);
        this.setState({
            sort: sortTem,
        })

        let order_by = `${key}.${changeSort(this.state.sort[`${key}`])}`;

        var token = this.state.token
        axios
            .get(APIURL + "team_list/" + this.props.match.params.id + `?order_by=${order_by}&search=${this.state.search}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({
                        Member_Team_List: response.data.data,
                        no_record: true
                    });
                }
                this.setState({
                    no_record: true
                })
            })
            .catch(error =>
                this.setState({
                    no_record: true
                })
            );
    }

    onChangeSearch = (e) => {
        this.setState({ search: e.target.value });
    };

    onSearch = () => {
        var token = this.state.token
        let order_by = "";

        if (Object.keys(this.state.sort)[0]) {
            order_by = `${Object.keys(this.state.sort)[0]}.${changeSort(Object.values(this.state.sort)[0])}`;
        }
        axios
            .get(APIURL + "team_list/" + this.props.match.params.id + `?order_by=${order_by}&search=${this.state.search}`, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data.length > 0) {
                    this.setState({
                        Member_Team_List: response.data.data,
                        no_record: true
                    });
                }
                this.setState({
                    no_record: true
                })
            })
            .catch(error =>
                this.setState({
                    no_record: true
                })
            );
    }

    render() {
        const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
        const { visible } = this.state
        const { navigate } = this.state;

        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "team_list",
                        visible: this.props.location.visible
                    }} />
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                <h6 className="mt-3">Upgrade Package.</h6>
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}> Cancel </Button>
                            {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
                            </div>
                    </ModalFooter>
                </Modal>

                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{this.state.team_name} | {t('Team list')} </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <Input
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="filter"
                                                        placeholder={t("Search")}
                                                        value={this.state.search}
                                                        onChange={this.onChangeSearch}
                                                    />
                                                </div>
                                                <div className="col-md-4 mt-2">
                                                    <Button
                                                        className="btn btn-primary"
                                                        color="success"
                                                        onClick={this.onSearch}
                                                        disabled={this.state.isLoading}
                                                    >
                                                        {t("Search")}
                                                        {this.state.isLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </Button>
                                                </div>
                                            </div>

                                            <div className="table-responsive table-wrapper table-wrapper1">
                                                <Table id="table-to-xls" hover className="table-centered mt-3 tableFixHead">
                                                    <thead className="bg-light">
                                                        <tr>
                                                            <th className="fw-medium" style={{minWidth:"140px"}}>{t('Function')}{sortUI(this.state.sort.name, "name", this.onChangeSort)}</th>
                                                            <th className="fw-medium " style={{minWidth:"140px"}}>{t('First name')}{sortUI(this.state.sort.first_name, "first_name", this.onChangeSort)}</th>
                                                            <th className="fw-medium " style={{minWidth:"140px"}}>{t('Last Name')}{sortUI(this.state.sort.last_name, "last_name", this.onChangeSort)}</th>
                                                            <th className="fw-medium" style={{minWidth:"180px"}}>{t('Address')}{sortUI(this.state.sort.address, "address", this.onChangeSort)}</th>
                                                            <th className="fw-medium" style={{minWidth:"140px"}}>{t('ZIP')}{sortUI(this.state.sort.postcode, "postcode", this.onChangeSort)}</th>
                                                            <th className="fw-medium" style={{minWidth:"140px"}}>{t('Place')}{sortUI(this.state.sort.place, "place", this.onChangeSort)}</th>
                                                            <th className="fw-medium" style={{minWidth:"200px"}}>{t('E-Mail')}{sortUI(this.state.sort.email, "email", this.onChangeSort)}</th>
                                                            <th className="fw-medium" style={{minWidth:"110px"}}>{t('Phone')}{sortUI(this.state.sort.phone, "phone", this.onChangeSort)}</th>
                                                            {this.state.teamAttrs.length > 0 && this.state.teamAttrs.map((item, idx) => (

                                                                <th className="fw-medium ">{item.name}</th>

                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {this.state.Member_Team_List.length > 0 && this.state.Member_Team_List.map((item, idx) => (
                                                            <React.Fragment>
                                                                {item.member_list.map((sItem, sIdx) => (
                                                                    <tr className="">

                                                                        <td className="fw-medium">{sIdx == 0 ? item.name : ""}</td>
                                                                        <td >{sItem.first_name}</td>
                                                                        <td >{sItem.last_name}</td>
                                                                        <td >{sItem.address}</td>
                                                                        <td >{sItem.postcode}</td>
                                                                        <td >{sItem.place}</td>
                                                                        <td >{sItem.email}</td>
                                                                        <td >{sItem.phone}</td>
                                                                        {sItem.attributes.length > 0 && sItem.attributes.map((itr, ir_idx) => (

                                                                            <td>{itr.value}</td>

                                                                        ))}

                                                                    </tr>
                                                                ))}
                                                            </React.Fragment>

                                                        ))}
                                                        {this.state.no_record == true && this.state.Member_Team_List.length === 0 &&
                                                            <tr>
                                                                <td colSpan="8" className="text-center">

                                                                    {t("No main Member Added")}

                                                                </td>
                                                            </tr>
                                                        }

                                                    </tbody>
                                                </Table>
                                            </div>
                                            {this.state.no_record == true && this.state.Member_Team_List.length > 0 &&
                            <ExportSheet
                                dataType="Table-Node-Element"
                                fileName={`table`}
                                tableElement={this.state.table}
                                xlsx={XLSX}
                            >
                                <button className="download-table-xls-button btn btn-success fw-bolder mt-3">{t("Download Excel")}</button>
                            </ExportSheet>
                        }
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Modal size="md" isOpen={this.state.modal} toggle={this.toggle}>
                                <ModalHeader>Add Member Function</ModalHeader>
                                <ModalBody>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Member E-Mail</Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter member E-Mail"
                                                    id="useremail"
                                                    name="email"
                                                    required=""
                                                    value={this.state.email}
                                                    onChange={this.onChangehandler}
                                                />
                                                <span className="text-danger">{this.state.errMsg.member_email}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Function</Label>
                                                <Select
                                                    aria-label=".form-select-lg example"
                                                    required="required"
                                                    placeholder="Select Function"
                                                    options={this.state.func_List}
                                                    value={this.state.function_name}
                                                    onChange={this.SelectFunctions}
                                                    styles={colourStyles}
                                                />
                                                <span className="text-danger">{this.state.errMsg.function_id}</span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="form-group">
                                                <Label>Is-Team Admin</Label>
                                                <Select
                                                    placeholder="Select Is-Team Admin"
                                                    value={this.state.Is_admin}
                                                    onChange={this.handleChange}
                                                    options={options}
                                                    styles={colourStyles}
                                                />
                                                <span className="text-danger">{this.state.errMsg.Is_Admin}</span>
                                            </div>
                                        </div>
                                    </div>

                                </ModalBody>

                                <ModalFooter>
                                    <Button className="btn btn-primary mr-3" onClick={() => this.handleAddMembers()}> <i className="fas fa-save mr-1"> </i>Save </Button>

                                    <button className="btn btn-default" onClick={() => this.toggle()}> Cancel </button>
                                </ModalFooter>
                            </Modal>
                        </div>

                        {/* <!-- container-fluid --> */}
                    </div>
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <div className="rightbar-overlay"></div>
            </div>
        );
    }
}
export default withTranslation()(TeamMembers);