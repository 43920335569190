import React, { Component } from 'react'
import AOS from 'aos';
import axios from "axios";
import Select from 'react-select'
import { APIURL } from '../../constants/Common';
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/icons.css";
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import bg_auth from "../../../assets/images/bg-auth-dark.webp"
import '../../../assets/css/Custom.css'
import logoDark from '../../../assets/images/logo-dark.svg';
import queryString from 'query-string'
import { Redirect } from "react-router-dom";
import Language from "../Language/Language";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Trans, withTranslation } from 'react-i18next';

import { Form, Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

class Home extends Component {
    userData;
    constructor() {
        super();
        this.state = {
            signupData: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                redirect: false,
                email: "",
                password: "",
                isLoading: "",
                tnc: "",
                license_type_id: "",
                package_id: "",
            },
            msg: "",
            scsMsg: "",
            startDate: '',
            days: [],
            months: [],
            years: [],
            countries: [],
            countryCode: 0,
            day: 0,
            month: 0,
            year: 0,
            showPassword: false,
            Package_List: [],
            amount: '',
            number_of_teams: '',
            modal: false,
            isLoading: false,
            packageId: '',
            license_type_id: '',
            email: "",
            password: "",
            redirect: false,
            errMsgEmail: "",
            errMsgPwd: "",
            errMsg: "",
            errMsgSignUp: "",

            unverified: false,
            checked: false,
            active: false,
            ScsRegisterModal:false
        }

    }

    handleAddClass() {
        document.body.classList.add('front-end')
    }

    componentDidMount() {
        this.handleAddClass()

    }
    render() {
        const { t } = this.props;

        return (
            <>
                <div >

                    <Header />

                    <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                        <strong></strong> {this.state.scsMsg} {this.state.msg}
                        <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                    </div>
                    <section className="hero">
                        <div className="overlay"></div>
                        <div className="cover text-center" style={{ backgroundImage: `url(${bg_auth})` }} >
                        </div>
                    </section>

                    <section id="maincolumn">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <h1 className="maintitle">Datenschutzerklärung</h1>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="services-section">
                        <div className="container">
                            <div className="row"><div className="mar">
                                <p><b>Teamorganizer GmbH</b>, Barzloostrasse 2, CH-8330 Pfäffikon ZH, ist Betreiberin der Webseite www.teamorganizer.ch und der App „Teamorganizer“ und somit verantwortlich für die Erhebung, Verarbeitung und Nutzung Ihrer persönlichen Daten und die Vereinbarkeit der Datenbearbeitung mit dem geltenden Recht.</p>
                                <p>Ihr Vertrauen ist uns wichtig. Wir nehmen das Thema Datenschutz ernst und achten auf entspre-chende Sicherheit. Selbstverständlich beachten wir die gesetzlichen Bestimmungen des Bundesgesetzes über den Datenschutz (DSG), der Verordnung zum Bundesgesetz über den Datenschutz (VDSG), des Fernmeldegesetzes (FMG) und, soweit anwendbar, andere datenschutzrechtliche Bestimmungen, insbesondere die Datenschutzgrundverordnung der Europäischen Union (DSGVO).</p>
                                <p>Damit Sie wissen, welche personenbezogenen Daten wir von Ihnen erheben und für welche Zwecke wir sie verwenden, nehmen Sie bitte die nachstehenden Informationen zur Kenntnis.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">1) Grundsätze der Datenverarbeitung auf www.teamorganizer.ch und auf der App </h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Die auf der Webseite www.teamorganizer.ch erhobenen personenbezogenen Daten</p>
                                <p>a) werden auf rechtmässige Weise, nach Treu und Glauben und in einer für die betroffene Person nachvollziehbaren Weise verarbeitet.</p>
                                <p>b) werden einzig für die Durchführung und Abwicklung der auf der Webseite angebotenen Dienstleistungen und Angebote verwendet. Sie werden unter keinen Umständen für andere Zwecke weiterverarbeitet.</p>
                                <p>c) welche im Hinblick auf die Zwecke ihrer Verarbeitung unrichtig sind, werden unverzüglich gelöscht oder berichtigt.</p>
                                <p>d) Werden nur so lange gespeichert, wie es für die Zwecke, für die sie verarbeitet werden, erforderlich ist.</p>
                                <p>e) werden auf eine Weise verarbeitet, die eine angemessene Sicherheit gewährleistet, einschliesslich Schutz vor unbefugter oder unrechtmässiger Verarbeitung und vor unabsichtlichem Verlust, unbeabsichtigte Zerstörung oder unbeabsichtigte Schädigung.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">2) Rechtmässigkeit der Datenverarbeitung</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Indem der Nutzer die Webseite www.teamorganizer.ch einsieht, gibt er seine Einwilligung zur Verarbeitung der ihn betreffenden personenbezogenen Daten, zumal damit auch ein überwiegendes Interesse des Nutzers angenommen werden kann.</p>
                                <p>Der Nutzer hat das Recht, seine Einwilligung jederzeit zu widerrufen (per E-Mail auf info@teamorganizer.ch). Durch den Widerruf der Einwilligung wird die Rechtmässigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">3) Umfang und Zweck der Erhebung, Verarbeitung und Nutzung personenbezogener Daten</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>a) beim Besuch von www.teamorganizer.ch und auf der App</p>
                                <p>Beim Besuch von www.teamorganizer.ch speichern unsere Server temporär jeden Zugriff in einer Protokolldatei. Folgende Daten werden dabei ohne Ihr Zutun erfasst und bis zur automatisierten Löschung nach spätestens zwölf Monaten von uns gespeichert:</p>
                                <ul>
                                    <li>die IP-Adresse des anfragenden Rechners,</li>
                                    <li>das Datum und die Uhrzeit des Zugriffs,</li>
                                    <li>der Name und die URL der abgerufenen Datei,</li>
                                    <li>die Webseite, von der aus der Zugriff erfolgte,</li>
                                    <li>das Betriebssystem Ihres Rechners und der von Ihnen verwendete Browser,</li>
                                    <li>das Land von welchem sie zugegriffen haben und die Spracheinstellungen bei Ihrem Browser und</li>
                                    <li>der Name Ihres Internet-Access-Providers.</li>
                                </ul>
                                <p>Die Erhebung und Verarbeitung dieser Daten erfolgt zu dem Zweck, die Nutzung unserer Webseite zu ermöglichen (Verbindungsaufbau), die Systemsicherheit und -stabilität zu gewährleisten und die Optimierung unseres Internetangebots zu ermöglichen sowie zu internen statistischen Zwecken. Die IP-Adresse wird insbesondere dazu verwendet, um das Aufenthaltsland des Webseite-Besuchers zu erfassen und eine darauf abgestimmte Voreinstellung der Sprache der Webseite vorzunehmen. Ferner wird die IP-Adresse bei Angriffen auf die Netzinfrastruktur von www.teamorganizer.ch und zu statistischen Zwecken ausgewertet.</p>
                                <p>Darüber hinaus verwenden wir beim Besuch unserer Webseite sogenannte Pixel und Cookies zur Anzeige von personalisierter Werbung sowie zur Nutzung von Webanalyse-Diensten.</p>
                                <p>b) bei der Nutzung unseres Kontaktformulars</p>
                                <p>Sie haben die Möglichkeit, ein Kontaktformular zu verwenden, um mit uns in Kontakt zu treten. Die Eingabe folgender personenbezogenen Daten hat zu erfolgen:</p>
                                <ul>
                                    <li>Anrede,</li>
                                    <li>Vor- und Nachname sowie</li>
                                    <li>E-Mail-Adresse.</li>
                                </ul>
                                <p>Wenn diese Informationen nicht zur Verfügung gestellt werden, kann dies die Erbringung unserer Dienstleistungen behindern.</p>
                                <p>Wir verwenden diese Daten nur, um Ihre Kontaktanfrage bestmöglich und personalisiert beantworten zu können. Sie können dieser Datenverarbeitung jederzeit widersprechen und verlangen, dass wir Ihre Daten löschen (per E-Mail auf info@teamorganizer.ch).</p>
                                <p>c) bei der Anmeldung für unseren Newsletter</p>
                                <p>Sie haben auf unserer Webseite die Möglichkeit, unseren Newsletter zu abonnieren. Hierfür ist eine Registrierung erforderlich. Im Rahmen der Registrierung sind folgende Daten abzugeben:</p>
                                <ul>
                                    <li>Anrede,</li>
                                    <li>Vor- und Nachname sowie</li>
                                    <li>E-Mail-Adresse.</li>
                                </ul>
                                <p>Die obigen Daten sind für die Datenverarbeitung notwendig. Diese Daten bearbeiten wir ausschliesslich, um die Ihnen zugesendeten Informationen und Angebote zu personalisieren und besser auf Ihre Interessen auszurichten.</p>
                                <p>Mit der Registrierung erteilen Sie uns Ihre Einwilligung zur Bearbeitung der angegebenen Daten für den regelmässigen Versand des Newsletters an die von Ihnen angegebene Adresse und für die statistische Auswertung des Nutzungsverhaltens und die Optimierung des Newsletters. Diese Einwilligung stellt im Sinne von Art. 6 Abs. 1 lit. a DSGVO unsere Rechtsgrundlage für die Verarbeitung Ihrer E-Mail-Adresse dar. Wir sind berechtigt, Dritte mit der technischen Abwicklung von Werbemassnahmen zu beauftragen und sind berechtigt, Ihre Daten zu diesem Zweck weiterzugeben.</p>
                                <p>Am Ende jedes Newsletters findet sich ein Link, über den Sie den Newsletter jederzeit abbestellen können. Im Rahmen der Abmeldung können Sie uns freiwillig den Grund für die Abmeldung mitteilen. Nach der Abbestellung werden Ihre personenbezogenen Daten gelöscht. Eine Weiterbearbeitung erfolgt lediglich in anonymisierter Form zur Optimierung unseres Newsletters.</p>
                                <p>Der Versand der Newsletter erfolgt mittels des Versanddienstleisters „MailChimp“, einer Newsletterversandplattform des US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000, Atlanta, GA 30308, USA. Die Datenschutzbestimmungen des Versanddienstleisters können Sie hier einsehen: https://mailchimp.com/legal/privacy/. The Rocket Science Group LLC d/b/a MailChimp ist unter dem Privacy-Shield-Abkommen zertifiziert und bietet hierdurch eine Garantie, das europäisches Datenschutzniveau einzuhalten (https://www.privacyshield.gov/participant?id=a2zt0000000TO6hAAG&status=Active). Der Versanddienstleister wird auf Grundlage unserer berechtigten Interessen gemäss Art. 6 Abs. 1 lit. f DSGVO und eines Auftragsverarbeitungsvertrages gemäss Art. 28 Abs. 3 S. 1 DSGVO eingesetzt.</p>
                                <p>Der Versanddienstleister kann die Daten der Empfänger in pseudonymer Form, d.h. ohne Zuordnung zu einem Nutzer, zur Optimierung oder Verbesserung der eigenen Services nutzen, z.B. zur technischen Optimierung des Versandes und der Darstellung der Newsletter oder für statistische Zwecke verwenden. Der Versanddienstleister nutzt die Daten unserer Newsletterempfänger jedoch nicht, um diese selbst anzuschreiben oder um die Daten an Dritte weiterzugeben.</p>
                                <p>Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter gespeichert und nach der Abbestellung des Newsletters sowohl von unseren Servern als auch von den Servern des entsprechenden Newsletter-Dienstleisters gelöscht. Daten, die zu anderen Zwecken bei uns gespeichert wurden (z.B. E-Mail-Adressen für den Mitgliederbereich) bleiben hiervon unberührt.</p>
                                <p>Für den Versand unseres Newsletters nutzen wir E-Mail-Marketing-Dienstleistungen von Dritten. Unser Newsletter kann deshalb einen sog. Web Beacon (Zählpixel) oder ähnliche technische Mittel enthalten. Bei einem Web-Beacons handelt es sich um eine 1x1 Pixel grosse, nicht sichtbare Grafik, die mit der Benutzer-ID des jeweiligen Newsletter-Abonnenten im Zusammenhang steht.</p>
                                <p>Der Rückgriff auf entsprechende Dienstleistungen ermöglicht die Auswertung, ob die E-Mails mit unserem Newsletter geöffnet wurden. Darüber hinaus kann damit auch das Klick-verhalten der Newsletter-Empfänger erfasst und ausgewertet werden. Wir nutzen diese Daten zu statistischen Zwecken und zur Optimierung des Newsletters in Bezug auf Inhalt und Struktur. Dies ermöglicht uns, die Informationen und Angebote in unserem Newsletter besser auf die individuellen Interessen des jeweiligen Empfängers auszurichten. Der Zählpixel wird gelöscht, wenn Sie den Newsletter löschen.</p>
                                <p>Um Tracking-Pixel in unserem Newsletter zu unterbinden, stellen Sie bitte Ihr Mailprogramm so ein, dass in Nachrichten kein HTML angezeigt wird.</p>
                                <p>d) Registrierung und Kundenkonto</p>
                                <p>Sie haben auf unserer Webseite die Möglichkeit, sich zu registrieren und damit ein Kundenkonto zu eröffnen. Dazu sind folgende Daten abzugeben:</p>
                                <ul>
                                    <li>Anrede,</li>
                                    <li>Vor- und Nachname,</li>
                                    <li>Adresse (Strasse, Hausnummer, Postleitzahl, Ort),</li>
                                    <li>Geburtsdatum,</li>
                                    <li>Telefonnummer und</li>
                                    <li>E-Mail-Adresse.</li>
                                </ul>
                                <p>Die obigen Daten sind für die Datenverarbeitung notwendig. Diese Daten bearbeiten wir ausschliesslich, um die Ihnen zugesendeten Informationen und Angebote zu personalisieren und besser auf Ihre Interessen auszurichten.</p>
                                <p>Mit der Registrierung erteilen Sie uns Ihre Einwilligung zur Bearbeitung der angegebenen Daten für den regelmässigen Versand von Werbeaktionen (online oder print) an die von Ihnen angegebene Adresse und für die statistische Auswertung des Nutzungsverhaltens. Diese Einwilligung stellt im Sinne von Art. 6 Abs. 1 lit. a DSGVO unsere Rechtsgrundlage für die Verarbeitung Ihrer Angaben dar. Wir sind berechtigt, Dritte mit der technischen Abwicklung von Werbemassnahmen zu beauftragen und sind berechtigt, Ihre Daten zu diesem Zweck weiterzugeben.</p>
                                <p>Sie können jederzeit Ihre Einwilligung zur Verarbeitung Ihrer Daten widerrufen (auf info@teamorganizer.ch). Nach erfolgtem Widerruf werden Ihre personenbezogenen Daten gelöscht.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">4) Nutzung Ihrer Daten zu Werbezwecken</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>a) Im Allgemeinen</p>
                                <p>Teamorganizer GmbH kann personenbezogene Daten der Nutzer von www.teamorganizer.ch verwenden, um ihre Dienstleistungen zu bewerben (Online- und/oder Printwerbung etc.). Es ist Teamorganizer GmbH erlaubt, zu diesem Zweck personenbezogene Daten an Dritte weiterzugeben. Der Nutzer erklärt sich ausdrücklich mit dieser Verwendung seiner Daten einverstanden.</p>
                                <p>b) Re-Targeting</p>
                                <p>Wir setzen auf der Webseite Re-Targeting-Technologien ein. Dabei wird Ihr Nutzerverhalten auf unserer Webseite analysiert, um Ihnen anschliessend auch auf Partnerwebseiten individuell auf Sie zugeschnittene Werbung anbieten zu können. Ihr Nutzerverhalten wird dabei pseudonym erfasst. Die meisten Re-Targeting Technologien arbeiten mit Cookies.</p>
                                <p>c) Google Ads Remarketing und/oder Doubleclick by Google</p>
                                <p>Diese Webseite benutzt Google Ads Remarketing und/oder Doubleclick by Google, Dienste der Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA („Google“), zur Schaltung von Anzeigen, die auf der Nutzung zuvor besuchter Webseiten basieren. Google verwendet hierzu das sogenannte DoubleClick-Cookie, das eine Wiedererkennung Ihres Browsers beim Besuch anderer Webseiten ermöglicht. Die durch das Cookie erzeugten Informationen über den Besuch dieser Webseiten (einschliesslich Ihrer IP-Adresse) werden an einen Server von Google in den USA übertragen und dort gespeichert (zu Transfers von Personendaten in die USA finden Sie weiter unten in Ziff. 12 zusätzliche Informationen).</p>
                                <p>Google wird diese Informationen benutzen, um Ihre Nutzung der Webseite im Hinblick auf die zu schaltenden Anzeigen auszuwerten, um Reports über die Webseitenaktivitäten und Anzeigen für die Webseitenbetreiber zusammenzustellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen zu erbringen. Auch wird Google diese Informationen gegebenenfalls an Dritte übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte diese Daten im Auftrag von Google verarbeiten. Google wird jedoch in keinem Fall Ihre IP-Adresse mit anderen Daten von Google in Verbindung bringen.</p>
                                <p>Zur Verwaltung der Dienste zur nutzungsbasierten Werbung verwenden wir ausserdem den Google Tag Manager. Das Tool Tag Manager selbst ist eine cookielose Domain und erfasst keine personenbezogenen Daten. Das Tool sorgt vielmehr für die Auslösung anderer Tags, die ihrerseits unter Umständen Daten erfassen. Wenn Sie auf Domain- oder Cookie-Ebene eine Deaktivierung vorgenommen haben, bleibt diese für alle Tracking-Tags bestehen, die mit dem Google Tag Manager implementiert sind.</p>
                                <p>Sie können das Re-Targeting jederzeit verhindern, indem Sie in der Menuleiste Ihres Webbrowsers die betreffenden Cookies abweisen bzw. ausschalten. Zudem können Sie über die Webseite der Digital Advertising Alliance unter optout.aboutads.info ein OptOut für die genannten weiteren Werbe und Re-Targeting-Tools beantragen.</p>
                                <p>d) Nutzung von Google Adsense</p>
                                <p>Diese Webseite benutzt Google AdSense, einen Dienst zum Einbinden von Werbeanzeigen der Google Inc. ("Google"). Google AdSense verwendet sog. "Cookies", Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite ermöglicht. Google AdSense verwendet auch so genannte Web Beacons (unsichtbare Grafiken). Durch diese Web Beacons können Informationen wie der Besucherverkehr auf diesen Seiten ausgewertet werden.</p>
                                <p>Die durch Cookies und Web Beacons erzeugten Informationen über die Benutzung dieser Webseite (einschließlich Ihrer IP-Adresse) und Auslieferung von Werbeformaten werden an einen Server von Google in den USA übertragen und dort gespeichert. Diese Informationen können von Google an Vertragspartner von Google weitergegeben werden. Google wird Ihre IP-Adresse jedoch nicht mit anderen von Ihnen gespeicherten Daten zusammenführen.</p>
                                <p>Sie können die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Webseite voll umfänglich nutzen können. Durch die Nutzung dieser Webseite erklären Sie sich mit der Bearbeitung der über Sie erhobenen Daten durch Google in der zuvor beschriebenen Art und Weise und zu dem zuvor benannten Zweck einverstanden.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">5) Einbindung von Diensten und Inhalten Dritter</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Es kann vorkommen, dass innerhalb unseres Onlineangebotes Inhalte oder Dienste von Dritt-Anbietern, wie zum Beispiel Stadtpläne oder Schriftarten von anderen Webseiten eingebunden werden. Die Einbindung von Inhalten der Dritt-Anbieter setzt immer voraus, dass die Dritt-Anbieter die IP-Adresse der Nutzer wahrnehmen. Ohne die IP-Adresse können die Inhalte nicht an den Browser der Nutzer gesendet werden. Die IP-Adresse ist damit für die Darstellung dieser Inhalte erforderlich. Des Weiteren können die Anbieter der Dritt-Inhalte eigene Cookies setzen und die Daten der Nutzer für eigene Zwecke verarbeiten. Dabei können aus den verarbeiteten Daten Nutzungsprofile der Nutzer erstellt werden. Wir werden diese Inhalte möglichst datensparsam und datenvermeidend einsetzen sowie im Hinblick auf die Datensicherheit zuverlässige Dritt-Anbieter wählen.</p>
                                <p>Die nachfolgende Darstellung bietet eine Übersicht von Dritt-Anbietern sowie ihrer Inhalte, nebst Links zu deren Datenschutzerklärungen, welche weitere Hinweise zur Verarbeitung von Daten und, z.T. bereits hier genannt, Widerspruchsmöglichkeiten (sog. Opt-Out) enthalten:</p>
                                <p>Externe Schriftarten von Google, Inc., https://www.google.com/fonts („Google Fonts“). Die Einbindung der Google Fonts erfolgt durch einen Serveraufruf bei Google (in der Regel in den USA). Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out: https://www.google.com/settings/ads/.</p>
                                <p>Externe Schriftarten von Adobe, 4-6 Riverwalk, Citywest Business Campus, Dublin 24, Republic of Ireland. Datenschutzerklärung: https://www.adobe.com/ch_de/privacy/policies/adobe-fonts.html.</p>
                                <p>Schriftarten von fonts.com, ein Angebot von Monotype Imaging Inc. zur Visualisierung von Schriftarten. Datenschutzerklärung: https://www.monotype.com/legal/privacy-policy</p>
                                <p>Landkarten des Dienstes „Google Maps“ des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Durch den Besuch auf der Webseite erhält Google die Information, dass Sie die entsprechende Unterseite unserer Webseite aufgerufen haben. Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Webseite. Eine solche Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Webseite zu informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich zur Ausübung dessen an Google richten müssen. Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch Google erhalten Sie neben weiteren Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre unter https://www.google.com/policies/privacy/, Opt-Out: https://www.google.com/settings/ads/.</p>
                                <p>Landkarten des Dienstes „mapbox“ des Dritt-Anbieters mapbox, San Francisco, California, USA. Datenschutzerklärung: https://www.mapbox.com/legal/privacy/.</p>
                                <p>Videos der Plattform „YouTube“ des Dritt-Anbieters Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Datenschutzerklärung: https://www.google.com/policies/privacy/, Opt-Out: https://www.google.com/settings/ads/.</p>
                                <p>Videos der Plattform „Vimeo“ des Dritt-Anbieters Vimeo, InterActiveCorp, New York. Datenschutz-Erklärung: https://vimeo.com/privacy.</p>
                                <p>Diese Webseite verwendet den Dienst reCAPTCHA der Google Inc. (1600 Amphitheatre Park-way, Mountain View, CA 94043, USA; „Google“). Die Abfrage dient dem Zweck der Unterscheidung, ob die Eingabe durch einen Menschen oder durch automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage schliesst den Versand der IP-Adresse und ggf. weiterer von Google für den Dienst reCAPTCHA benötigter Daten an Google ein. Zu diesem Zweck wird Ihre Eingabe an Google übermittelt und dort weiterverwendet. Im Auftrag des Betreibers dieser Webseite wird Google diese Informationen benutzen, um Ihre Nutzung dieses Dienstes auszuwerten. Die im Rahmen von reCaptcha von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Ihre Daten werden dabei gegebenenfalls auch in die USA übermittelt. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. a DSGVO mit Ihrer Einwilligung. Sie können Ihre Einwilligung jederzeit widerrufen, ohne dass die Rechtmässigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird. Nähere Informationen zu Google reCAPTCHA sowie die dazugehörige Datenschutzerklärung finden Sie unter: https://www.google.com/privacy/ads/.</p>
                                <p>Bei dieser Webseite wird der Google Tag Manager eingesetzt. Dies ist eine Lösung, mit der wir sogenannte Webseite-Tags über eine Oberfläche verwalten können und so z.B. Google Analytics sowie andere Google-Marketing-Dienste in unser Onlineangebot einbinden können. Der Tag Manager selbst, welcher die Tags implementiert, verarbeitet keine personenbezogenen Daten der Nutzer. Im Hinblick auf die Verarbeitung der personenbezogenen Daten der Nutzer wird auf die folgenden Angaben zu den Google-Diensten verwiesen.</p>
                                <p>Nutzungsrichtlinien: https://www.google.com/intl/de/tagmanager/use-policy.html.</p>
                                <p>Cloud-Dienste von Google Cloud, Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Datenschutzerklärung: https://policies.google.com/privacy?hl=de.</p>
                                <p>Instagram Feed von Instagram; Datenschutzerklärung von Instragram: https://help.instagram.com/519522125107875.</p>
                                <p>Auf dieser Webseite verwenden wir OpenStreetMap, ein freies Projekt, das frei nutzbare Geodaten sammelt, strukturiert und für die Nutzung durch jedermann in einer Datenbank vorhält. Diese Daten stehen unter einer freien Lizenz, der Open Database Licence. Die Daten-schutzerklärung von OpenStreetMap finden Sie unter: https://wiki.osmfoundation.org/wiki/Privacy_Policy.</p>
                                <p>Im Rahmen der Erfüllung von Verträgen setzen wir einen oder mehrere Zahlungsdienstleister auf Grundlage der schweizerischen Datenschutzverordnung sowie und soweit nötig, des Art. 6 Abs. 1 lit. b. EU-DSGVO ein. Im Übrigen setzen wir externe Zahlungsdienstleister auf Grundlage unserer berechtigten Interessen gem. schweizerischer Datenschutzverordnung sowie und soweit nötig, gem. Art. 6 Abs. 1 lit. f. EU-DSGVO ein, um unseren Nutzern effektive und sichere Zahlungsmöglichkeit zu bieten. Zu den durch die Zahlungsdienstleister verarbeiteten Daten gehören Bestandsdaten, wie z.B. der Name und die Adresse, Bankdaten, wie u.a. Kontonummern oder Kreditkartennummern, Passwörter, TANs und Prüfsummen sowie die Vertrags-, Summen und empfängerbezogenen Angaben. Die Angaben sind erforderlich, um die Transaktionen durchzuführen. Die eingegebenen Daten werden jedoch nur durch die Zahlungsdienstleister verarbeitet und bei diesen gespeichert. Wir als Betreiber erhalten keinerlei Informationen zu (Bank-) Konto oder Kreditkarte, sondern lediglich Informationen zur Bestätigung (Annahme) oder Ablehnung der Zahlung. Unter Umständen werden die Daten seitens der Zahlungsdienstleister an Wirtschaftsauskunfteien übermittelt. Diese Übermittlung bezweckt die Identitäts- und Bonitätsprüfung. Hierzu verweisen wir auf die AGB und Datenschutzhinweise der Zahlungsdienstleister. Für die Zahlungsgeschäfte gelten die Geschäftsbedingungen und die Datenschutzhinweise der jeweiligen Zahlungsdienstleister, welche innerhalb der jeweiligen Webseite, bzw. Transaktionsapplikationen abrufbar sind. Wir verweisen auf diese ebenfalls zwecks weiterer Informationen und Geltendmachung von Widerrufs-, Auskunfts- und anderen Betroffenenrechten.</p>
                                <p>Zahlungsdienstleistungen von PAYREXX AG, Burgstrasse 18, 3600 Thun, Schweiz. Datenschutzerklärung: https://www.payrexx.ch/site/assets/files/2592/datenschutzerklaerung.pdf. Der Nutzer erklärt sich damit einverstanden, dass seine eingegebenen Daten zum Zwecke der Zahlungsverarbeitung an PAYREXX AG weitergeleitet werden.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">6) Weitergabe der Daten an Dritte</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Wir geben Ihre personenbezogenen Daten nur weiter, wenn Sie ausdrücklich eingewilligt haben, hierfür eine gesetzliche Verpflichtung besteht oder dies zur Durchsetzung unserer Rechte, insbesondere zur Durchsetzung von Ansprüchen aus dem Verhältnis zwischen Ihnen und uns, erforderlich ist.</p>
                                <p>Darüber hinaus geben wir Ihre Daten an Dritte weiter, soweit dies bei der Nutzung der Webseite für die Bereitstellung der von Ihnen gewünschten Dienstleistungen sowie der Analyse Ihres Nutzerverhaltens erforderlich ist. Soweit dies für die in Satz 1 genannten Zwecke erforderlich ist, kann die Weitergabe auch ins Ausland erfolgen. Sofern die Webseite Links zu Webseiten Dritter enthält, haben wir nach dem Anklicken dieser Links keinen Einfluss mehr auf die Erhebung, Verarbeitung, Speicherung oder Nutzung personenbezogener Daten durch den Dritten und übernehmen wir dafür keine Verantwortung.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">7) Übermittlung personenbezogener Daten ins Ausland </h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Wir sind berechtigt, Ihre persönlichen Daten auch an dritte Unternehmen (beauftragte Dienstleister) im Ausland zu übertragen, sofern dies für die in dieser Datenschutzerklärung beschriebenen Datenbearbeitungen erforderlich ist. Diese sind im gleichen Umfang wie wir selbst zum Datenschutz verpflichtet. Wenn das Datenschutzniveau in einem Land nicht dem schweizerischen bzw. dem europäischen entspricht, stellen wir vertraglich sicher, dass der Schutz Ihrer personenbezogenen Daten demjenigen in der Schweiz bzw. in der EU jederzeit entspricht.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">8) Datensicherheit</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmassnahmen, um Ihre bei uns gespeicherten persönlichen Daten gegen Manipulation, teilweisen oder vollständigen Verlust und gegen unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmassnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.</p>
                                <p>Sie sollten Ihre Zahlungsinformationen stets vertraulich behandeln und das Browserfenster schliessen, wenn Sie die Kommunikation mit uns beendet haben, insbesondere, wenn Sie den Computer gemeinsam mit anderen nutzen.</p>
                                <p>Auch den unternehmensinternen Datenschutz nehmen wir sehr ernst. Unsere Mitarbeitenden und die von uns beauftragten Dienstleistungsunternehmen sind von uns zur Verschwiegenheit und zur Einhaltung der datenschutzrechtlichen Bestimmungen verpflichtet worden.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">9) Cookies</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Cookies helfen unter vielen Aspekten, Ihren Besuch auf unserer Webseite einfacher, angenehmer und sinnvoller zu gestalten. Cookies sind Informationsdateien, die Ihr Webbrowser automatisch auf der Festplatte Ihres Computers speichert, wenn Sie unsere Webseite besuchen. Cookies beschädigen weder die Festplatte Ihres Rechners noch werden von diesen Cookies persönliche Daten der Anwender an uns übermittelt.</p>
                                <p>Wir setzen Cookies beispielsweise ein, um die Ihnen angezeigten Informationen, Angebote und Werbung besser auf Ihre individuellen Interessen auszurichten. Die Verwendung führt nicht dazu, dass wir neue personenbezogene Daten über Sie als Onlinebesucher erhalten. Die meisten Internet-Browser akzeptieren Cookies automatisch. Sie können Ihren Browser jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder stets ein Hinweis erscheint, wenn Sie ein neues Cookie erhalten.</p>
                                <p>Die Deaktivierung von Cookies kann dazu führen, dass Sie nicht alle Funktionen unserer Webseite nutzen können.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">10) Sicherheit bei der Datenübertragung mit SSL</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Aus Gründen der Sicherheit und zum Schutz der Übertragung vertraulicher Inhalte, nutzt diese Webseite eine SSL-/TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von "http://" auf "https://" wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.</p>
                                <p>Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">11) Tracking-Tools</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Auf unserer Webseite verwenden wir sogenannte Tracking-Tools. Mit diesen Tracking-Tools wird Ihr Surfverhalten auf unserer Webseite beobachtet. Diese Beobachtung erfolgt zum Zwecke der bedarfsgerechten Gestaltung und fortlaufenden Optimierung unserer Webseite. In diesem Zusammenhang werden pseudonymisierte Nutzungsprofile erstellt und kleine Textdateien, die auf Ihrem Computer gespeichert sind („Cookies“), verwendet.</p>
                                <p>a) Google Analytics</p>
                                <p>Diese Webseite benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Webseite durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen über Ihre Benutzung der Webseite werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt. Wir haben zudem auf dieser Webseite Google Analytics um den Code „anonymizeIP“ erweitert. Dies garantiert die Maskierung Ihrer IP-Adresse, sodass alle Daten anonym erhoben werden. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.</p>
                                <p>Im Auftrag des Betreibers dieser Webseite wird Google diese Information benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die Webseiten-Aktivitäten zusammenzustellen und um weitere mit der Webseiten-Nutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall möglicher-weise nicht sämtliche Funktionen dieser Webseite vollumfänglich werden nutzen können.</p>
                                <p>Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link verfügbare Browser-Plugin herunterladen und installieren: http://tools.google.com/dlpage/gaoptout?hl=de. Es wird ein Opt-Out-Cookie gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Webseite verhindert. Der Opt-Out-Cookie gilt nur in diesem Browser und nur für unsere Webseite und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das Opt-Out-Cookie erneut setzen. [Anm. Hinweise zur Einbindung des Opt-Out-Cookie finden Sie unter: https://developers.google.com/analytics/devguides/collection/gajs/?hl=de#disable.</p>
                                <p>Wir nutzen Google Analytics weiterhin dazu, Daten aus Double-Click-Cookies und auch Ads zu statistischen Zwecken auszuwerten. Sollten Sie dies nicht wünschen, können Sie dies über den Anzeigenvorgaben-Manager (http://www.google.com/settings/ads/onweb/?hl=de) deaktivieren.</p>
                                <p>Wir verwenden Google Analytics einschliesslich der Funktionen von Universal Analytics oder Google Analytics 4 (GA4). Dies erlaubt es uns, die Aktivitäten auf unseren Seiten geräteübergreifend zu analysieren (z.B. bei Zugriffen mittels Laptop und später über ein Tab-let). Dies wird durch die pseudonyme Zuweisung einer User-ID zu einem Nutzer ermöglicht. Eine solche Zuweisung erfolgt etwa, wenn Sie sich für ein Kundenkonto registrieren bzw. sich bei Ihrem Kundenkonto anmelden. Es werden jedoch keine personenbezogenen Daten an Google weitergeleitet. Auch wenn mit Universal Analytics oder Google Analytics 4 zusätzliche Funktionen zu Google Analytics hinzukommen, bedeutet dies nicht, dass damit eine Einschränkung von Massnahmen zum Datenschutz wie IP-Masking oder das Browser-Add-on verbunden ist.</p>
                                <p>b) Google Ads</p>
                                <p>Wir nutzen das Online-Werbeprogramm „Google Ads“ und im Rahmen von Google Ads das Conversion-Tracking. Das Google Conversion Tracking ist ein Analysedienst der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Wenn Sie auf eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem Rechner abgelegt. Diese Cookies verlieren nach 30 Tagen ihre Gültigkeit, enthalten keine personenbezogenen Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte Internetseiten unserer Webseite besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google Ads-Kunde erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Webseiten von Ads-Kunden nachverfolgt werden können.</p>
                                <p>Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dazu, Conversion-Statistiken für Ads-Kunden zu erstellen, die sich für Conversion-Tracking entschieden haben. Hierbei erfahren die Kunden die Gesamtanzahl der Nutzer, die auf ihre Anzeige geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite weitergeleitet wurden. Sie erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich identifizieren lassen.</p>
                                <p>Wenn Sie nicht am Tracking teilnehmen möchten, können Sie dieser Nutzung widersprechen, indem Sie die Installation der Cookies durch eine entsprechende Einstellung Ihrer Browser Software verhindern (Deaktivierungsmöglichkeit). Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen. Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter: http://www.google.com/policies/technologies/ads/  und http://www.google.de/policies/privacy/.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">12) Social Media Plug-Ins </h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Auf der Webseite kommen die nachfolgend beschriebenen Social Plugins zum Einsatz. Die Plugins sind auf unserer Webseite standardmässig deaktiviert und senden daher keine Daten. Durch einen Klick auf den entsprechenden Social Media-Button können Sie die Plugins aktivieren.</p>
                                <p>Wenn diese Plugins aktiviert sind, baut Ihr Browser mit den Servern des jeweiligen sozialen Netzwerks eine direkte Verbindung auf, sobald Sie eine unserer Webseiten aufrufen. Der Inhalt des Plugins wird vom sozialen Netzwerk direkt an Ihren Browser übermittelt und von diesem in die Webseite eingebunden. Die Plugins lassen sich mit einem Klick wieder deaktivieren.</p>
                                <p>Weitere Informationen finden Sie in den jeweiligen Datenschutzerklärungen.</p>
                                <p>a) Plugins von Facebook</p>
                                <p>Auf dieser Webseite kommen Social Plugins von Facebook zum Einsatz, um unseren Webauftritt persönlicher zu machen. Hierfür nutzen wir den „LIKE“ oder "TEILEN"-Button. Es handelt sich dabei um ein Angebot des US-amerikanischen Unternehmens Meta, 1 Hacker Way, Menio Park, CA-94025, USA.</p>
                                <p>Durch die Einbindung der Plugins erhält Facebook die Information, dass Ihr Browser die entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder gerade nicht bei Facebook eingeloggt sind. Diese Information (einschliesslich Ihrer IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA übermittelt und dort gespeichert.</p>
                                <p>Sind Sie bei Facebook eingeloggt, kann Facebook den Besuch unserer Webseite Ihrem Facebook-Konto direkt zuordnen. Wenn Sie mit den Plugins interagieren, zum Beispiel den „LIKE“ oder "TEILEN"-Button betätigen, wird die entsprechende Information ebenfalls direkt an einen Server von Facebook übermittelt und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und Ihren Facebook-Freunden angezeigt.</p>
                                <p>Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und bedarfsgerechten Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook Nutzungs-, Interessen- und Beziehungsprofile erstellt, z.B. um Ihre Nutzung unserer Webseite im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten, andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Webseite zu informieren und um weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.</p>
                                <p>Wenn Sie nicht möchten, dass Facebook die über unseren Webauftritt gesammelten Daten Ihrem Facebook-Konto zuordnet, müssen Sie sich vor Ihrem Besuch unserer Webseite bei Facebook ausloggen.</p>
                                <p>Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenschutzhinweisen von Facebook unter: https://www.facebook.com/policy.php</p>
                                <p>b) Plugins von Instagram</p>
                                <p>Auf unseren Seiten sind Funktionen des Dienstes Instagram eingebunden. Diese Funktionen werden angeboten durch die Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025, USA integriert. Wenn Sie in Ihrem Instagram-Account eingeloggt sind können Sie durch Anklicken des Instagram-Buttons die Inhalte unserer Seiten mit Ihrem Instagram-Profil verlinken. Dadurch kann Instagram den Besuch unserer Seiten Ihrem Benutzerkonto zuordnen. Wir weisen darauf hin, dass wir als Anbieter der Seiten keine Kenntnis vom Inhalt der übermittelten Daten sowie deren Nutzung durch Instagram erhalten.</p>
                                <p>Weitere Informationen hierzu finden Sie in der Datenschutzerklärung von Insta-gram: http://instagram.com/about/legal/privacy/</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">13) Hinweis zu Datenübermittlungen in die USA</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Aus Gründen der Vollständigkeit weisen wir für Nutzer mit Wohnsitz oder Sitz in der Schweiz darauf hin, dass in den USA Überwachungsmassnahmen von US-Behörden bestehen, die generell die Speicherung aller personenbezogenen Daten sämtlicher Personen, deren Daten aus der Schweiz in die USA übermittelt wurden, ermöglicht. Dies geschieht ohne Differenzierung, Einschränkung oder Ausnahme anhand des verfolgten Ziels und ohne ein objektives Kriterium, das es ermöglicht, den Zugang der US-Behörden zu den Daten und deren spätere Nutzung auf ganz bestimmte, strikt begrenzte Zwecke zu beschränken, die den sowohl mit dem Zugang zu diesen Daten als auch mit deren Nutzung verbundenen Eingriff zu rechtfertigen vermögen. Ausserdem weisen wir darauf hin, dass in den USA für die betroffenen Personen aus der Schweiz keine Rechtsbehelfe vorliegen, die es ihnen erlauben, Zugang zu den sie betreffenden Daten zu erhalten und deren Berichtigung oder Löschung zu erwirken, bzw. kein wirksamer gerichtlicher Rechtsschutz gegen generelle Zugriffsrechte von US-Behörden vorliegt. Wir weisen den Betroffenen explizit auf diese Rechts- und Sachlage hin, um eine entsprechend informierte Entscheidung zur Einwilligung in die Verwendung seiner Daten zu treffen.</p>
                                <p>Nutzer mit Wohnsitz in einem Mitgliedstaat der EU weisen wir darauf hin, dass die USA aus Sicht der Europäischen Union nicht über ein ausreichendes Datenschutzniveau verfügt.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">14) Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der Verarbeitung; Recht auf Datenübertragbarkeit</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Sie haben das Recht, über die personenbezogenen Daten, die von uns über Sie gespeichert werden, auf Antrag unentgeltlich Auskunft zu erhalten. Zusätzlich haben Sie das Recht auf Berichtigung unrichtiger Daten und das Recht auf Löschung Ihrer personenbezogenen Daten, soweit dem keine gesetzliche Aufbewahrungspflicht oder ein Erlaubnistatbestand, der uns die Verarbeitung der Daten gestattet, entgegensteht. Sie haben gemäss den Artikeln 18 und 21 DSGVO zudem das Recht, eine Einschränkung der Datenverarbeitung zu verlangen sowie der Datenverarbeitung zu widersprechen.</p>
                                <p>Sie haben zudem das Recht, diejenigen Daten, die Sie uns übergeben haben, wieder von uns herauszuverlangen. Auf Anfrage geben wir die Daten auch an einen Dritten Ihrer Wahl weiter. Sie haben das Recht, die Daten in einem gängigen Dateiformat zu erhalten.</p>
                                <p>Sie können uns für die vorgenannten Zwecke über die E-Mail-Adresse info@teamorganizer.ch erreichen. Für die Bearbeitung Ihrer Gesuche können wir, nach eigenem Ermessen, einen Identitätsnachweis verlangen.</p>
                                <p>Sie können uns auch mitteilen, was mit Ihren Daten nach Ihrem Tod geschehen soll, indem Sie uns entsprechende Anweisungen geben.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">15) Allgemeiner Haftungsausschluss</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Alle Angaben unseres Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, unser</p>
                                <p>Informationsangebot aktuell, inhaltlich richtig und vollständig anzubieten. Trotzdem kann das Auftreten von Fehlern nicht völlig ausgeschlossen werden, womit wir keine Garantie für Vollständigkeit, Richtigkeit und Aktualität von Informationen auch journalistischredaktioneller Art übernehmen können. Haftungsansprüche aus Schäden materieller oder ideeller Art, die durch die Nutzung der angebotenen Informationen verursacht wurden, sind ausgeschlossen, sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt.</p>
                                <p>Wir können nach eigenem Ermessen und ohne Ankündigung Texte verändern oder löschen und sind nicht verpflichtet, Inhalte dieser Webseite zu aktualisieren. Die Benutzung bzw. der Zugang zu dieser Webseite geschieht auf eigene Gefahr des Besuchers oder der Besucherin. Wir, unsere Auftraggeber oder Partner sind nicht verantwortlich für Schäden, wie direkte, indirekte, zufällige, vorab konkret zu bestimmende oder Folgeschäden, die angeblich durch den Besuch dieser Webseite entstanden sind und übernehmen hierfür folglich keine Haftung.</p>
                                <p>Wir übernehmen ebenfalls keine Verantwortung und Haftung für die Inhalte und die Verfügbarkeit von Webseite Dritter, die über externe Links dieser Webseite erreichbar sind. Für den Inhalt der verlinkten Seiten sind ausschliesslich deren Betreiber verantwortlich. Wir distanzieren uns ausdrücklich von allen Inhalten Dritter, die möglicherweise straf oder haftungsrechtlich relevant sind oder gegen die guten Sitten verstossen.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">16) Aufbewahrung von Daten</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Wir speichern personenbezogene Daten nur so lange, wie es erforderlich ist, um die oben genannten Tracking-, Werbe- und Analysedienste im Rahmen unseres berechtigten Interesses zu verwenden, um in dem oben genannten Umfang Dienstleistungen auszuführen, die Sie gewünscht oder zu denen Sie Ihre Einwilligung erteilt haben sowie um unseren gesetzlichen Verpflichtungen nachzukommen.</p>
                                <p>Vertragsdaten werden von uns länger aufbewahrt, da dies durch gesetzliche Aufbewahrungspflichten vorgeschrieben ist. Aufbewahrungspflichten, die uns zur Aufbewahrung von Daten verpflichten, ergeben sich aus Vorschriften der Rechnungslegung und aus steuerrechtlichen Vorschriften. Gemäss diesen Vorschriften sind geschäftliche Kommunikation, geschlossene Verträge und Buchungsbelege bis zu 10 Jahren bzw. in Bezug auf Nutzer mit Wohnsitz in Frankreich bis zu 5 Jahren aufzubewahren. Soweit wir diese Daten nicht mehr zur Durchführung der Dienstleistungen für Sie benötigen, werden die Daten gesperrt. Dies bedeutet, dass die Daten dann nur noch für Zwecke der Rechnungslegung und für Steuerzwecke verwendet werden dürfen.</p>
                            </div></div>

                            <div className="row"><div className="heading-section"><h3 className="text-center">17) Recht auf Beschwerde bei einer Datenschutzaufsichtsbehörde</h3></div></div>
                            <div className="row"><div className="mar">
                                <p>Falls Sie Wohnsitz in einem EU Staat haben, steht Ihnen das Recht zu, sich jederzeit bei der</p>
                                <p>zuständigen Datenschutzaufsichtsbehörde am Ort Ihres Wohnsitzes zu beschweren.</p>
                                <p>Falls Sie Wohnsitz in der Schweiz haben, steht Ihnen das Recht zu, sich jederzeit beim Eidgenössischen Datenschutz- und Öffentlichkeitsbeauftragten (EDÖB), Feldeggweg 1, CH-3003 Bern, zu beschweren.</p>
                            </div></div>

                            <div className="row"><div className="mar">
                                <p>Pfäffikon ZH, 25. September 2022</p>
                            </div></div>
                        </div>
                    </section>

                    <Footer />

                </div>
            </>
        )
    }}

export default withTranslation()(Home);
