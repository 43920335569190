import React, { Component } from "react";
import "./App.css";
import LogonPage from "./components/LogonPage";
import RegistrationPage from "./components/RegistrationPage";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AddTeam from "./components/AddTeam/AddTeam";
import Profile from "./components/ManageProfile/Profile";
import Editteam from './components/AddTeam/EditTeam'
import Setting from "./components/Setting/Setting";
import EventAdd from "./components/Administration/Event/Add";
import EventEdit from "./components/Administration/Event/Edit";
import EventFuture from "./components/Administration/Event/Future";
import EventPast from "./components/Administration/Event/Past";
import Members from "./components/Administration/Member/index";
import EventArchive from "./components/Administration/Event/Archive";
import Dashboard from "./components/Dashboard/Index";
import ErrorPage from "./components/GeneralComponents/ErrorPage";
import Permission from "./components/GeneralComponents/Permission";

import MemberTeamList from "./components/MemberTeamList/TeamList"
import MemberTeamInvitation from "./components/MemberTeamList/TeamInvitation"
// import News from "./components/News/Index";
import News from "./components/Administration/News/Index";
import TeamNews from "./components/News/TeamNews"
import Document from "./components/Administration/Document/Index";
import DocumentType from "./components/Administration/Document/Type";
import TeamMailing from "./components/Administration/TeamMailing/Index";
import Statistics from "./components/Administration/Stastics/Member/Index"
import StatisticsEventType from "./components/Administration/Stastics/EventType/Index"
import StatisticsTask from "./components/Administration/Stastics/Task/Index"
import DocumentList from "./components/Documents/Index"
import Registration from './components/Regi-deregistration/Registration';
import AdminTeam from "./components/Admin/Team/Index";
import UserList from "./components/Admin/User/Index";
import InactiveUserList from "./components/Admin/User/Inactive";
import Packages from "./components/Admin/Packages/Index";
import Payment from "./components/Admin/Payment_transaction/Index";
import ChangePassword from "./components/Admin/Settings/ChangePassword";
import Frontpage from './components/Front/Home/Index'
import UpgradePackage from './components/UpgradePackage/index'
import TeamRequest from './components/TeamRequest/index'
import Test from "./components/GeneralComponents/Test"
import Thankyou from "./components/Front/Thankyou/Index"
import Paymentfailed from "./components/Front/Paymentfailed/Index"
import Rechtliches from "./components/Front/Rechtliches/Index"
import Policies from "./components/Front/Policies/Index"
import Impressum from "./components/Front/Impressum/Index"
import Support from "./components/Front/Support/Index"
import Preise from "./components/Front/Preise/Index"
import Features from "./components/Front/Features/Index"
import UeberUns from "./components/Front/UeberUns/Index"

export default class App extends Component {
  render() {
    const login = localStorage.getItem("isLoggedIn");
    return (
      <div>
        {login ? (
            <Router>
             <Switch>
               <Route exact path="/" component={Frontpage}></Route>
               <Route exact path="/login" component={Frontpage}></Route>
               <Route exact path="/register" component={RegistrationPage}></Route>
               <Route exact path="/logon" component={LogonPage}></Route>
               <Route exact path="/rechtliches" component={Rechtliches}></Route>
               <Route exact path="/policies" component={Policies}></Route>
               <Route exact path="/impressum" component={Impressum}></Route>
               <Route exact path="/support" component={Support}></Route>
               <Route exact path="/preise" component={Preise}></Route>
               <Route exact path="/features" component={Features}></Route>
               <Route exact path="/ueberuns" component={UeberUns}></Route>

              <Route exact path="/forgot_password" component={ForgotPassword}></Route>
              <Route exact path="/reset_password/:token" component={ResetPassword}></Route>
              <Route exact path="/error" component={ErrorPage}></Route>
              <Route exact path="/home" component={Home}></Route>
              <Route exact path="/addteam" component={AddTeam}></Route> 
              <Route exact path="/editteam/:id?" component={Editteam}></Route> 
              <Route exact path="/profile/:id?" component={Profile}></Route> 
              <Route exact path="/team_setting/:id" component={Setting}></Route>
              <Route exact path="/eventAdd/:id" component={EventAdd}></Route> 
              <Route exact path="/event/:id" component={EventFuture}></Route>
              <Route exact path="/eventPast/:id" component={EventPast}></Route>
              <Route exact path="/member/:id?" component={Members}></Route>
              <Route exact path="/eventArchive/:id" component={EventArchive}></Route>
              <Route exact path="/editEvent/:id/:team_id" component={EventEdit}></Route>
              <Route exact path="/dashboard/:id" component={Dashboard}></Route>
              <Route exact path="/teamlist/:id" component={MemberTeamList}></Route>
              <Route exact path="/team_news/:id" component={TeamNews}></Route>
              <Route exact path="/invitaion" component={MemberTeamInvitation}></Route>
              <Route exact path="/news/:id" component={News}></Route>
              <Route exact path="/documents/:id" component={Document}></Route>
              <Route exact path="/document_types/:id" component={DocumentType}></Route>
              <Route exact path="/team-mailings/:id" component={TeamMailing}></Route> 
              <Route exact path="/statistics/:id" component={Statistics}></Route>
              <Route exact path="/statistics/event-type/:id" component={StatisticsEventType}></Route>
              <Route exact path="/statistics/task/:id" component={StatisticsTask}></Route>              
              <Route exact path="/document-list/:id" component={DocumentList}></Route>
              <Route exact path="/regi-deregistration/:id" component={Registration}></Route>
              <Route exact path="/admin/team" component={AdminTeam}></Route>
              <Route exact path="/admin/user" component={UserList}></Route>
              <Route exact path="/admin/inactive_user" component={InactiveUserList}></Route>
              <Route exact path="/admin/package" component={Packages}></Route>
              <Route exact path="/upgrade_package" component={UpgradePackage}></Route>
              <Route exact path="/team_request" component={TeamRequest}></Route>
              <Route exact path="/admin/transaction" component={Payment}></Route>
              <Route exact path="/admin/change_password" component={ChangePassword}></Route>
              <Route exact path="/permission" component={Permission}></Route>
              <Route exact path="/test" component={Test}></Route>
              <Route exact path="/thank-you" component={Thankyou}></Route>
               <Route exact path="/payment-failed" component={Paymentfailed}></Route>

               <Route path="*" exact={true}   component={ErrorPage}></Route>
            </Switch>
          </Router>        
        ) : (
          <Router>
             <Switch>

                <Route exact path="/" component={Frontpage}></Route>
               <Route exact path="/login" component={Frontpage}></Route>
               <Route exact path="/register" component={RegistrationPage}></Route>
               <Route exact path="/logon" component={LogonPage}></Route>
               <Route exact path="/payment-failed" component={Paymentfailed}></Route>
               <Route exact path="/rechtliches" component={Rechtliches}></Route>
               <Route exact path="/policies" component={Policies}></Route>
               <Route exact path="/impressum" component={Impressum}></Route>
               <Route exact path="/support" component={Support}></Route>
               <Route exact path="/preise" component={Preise}></Route>
               <Route exact path="/features" component={Features}></Route>
               <Route exact path="/ueberUns" component={UeberUns}></Route>

                <Route exact path="/forgot_password" component={ForgotPassword}></Route>
                <Route exact path="/reset_password/:token" component={ResetPassword}></Route>
                <Route exact path="/error" component={ErrorPage}></Route>
                <Route exact path="/home" component={Home}></Route>
                <Route exact path="/addteam" component={AddTeam}></Route> 
                <Route exact path="/editteam/:id?" component={Editteam}></Route> 
                <Route exact path="/profile" component={Profile}></Route>  
                <Route exact path="/team_setting/:id?" component={Setting}></Route> 
                <Route exact path="/eventAdd/:id" component={EventAdd}></Route> 
                <Route exact path="/event/:id" component={EventFuture}></Route>
                <Route exact path="/eventPast/:id" component={EventPast}></Route>
                <Route  path="/member/:id?" component={Members}></Route>
                <Route exact path="/eventArchive/:id" component={EventArchive}></Route>
                <Route exact path="/editEvent/:id/:team_id" component={EventEdit}></Route>
                <Route exact path="/dashboard/:id" component={Dashboard}></Route>
                <Route exact path="/teamlist/:id" component={MemberTeamList}></Route>
                <Route exact path="/invitaion" component={MemberTeamInvitation}></Route>
                <Route exact path="/news/:id" component={News}></Route>
                <Route exact path="/team_news/:id" component={TeamNews}></Route>
                <Route exact path="/documents/:id" component={Document}></Route>
                <Route exact path="/document_types/:id" component={DocumentType}></Route>
                <Route exact path="/team-mailings/:id" component={TeamMailing}></Route> 
                <Route exact path="/document-list/:id" component={DocumentList}></Route>
                <Route exact path="/admin/team" component={AdminTeam}></Route>
                <Route exact path="/admin/user" component={UserList}></Route>
                <Route exact path="/admin/inactive_user" component={InactiveUserList}></Route>
                <Route exact path="/admin/transaction" component={Payment}></Route>
                <Route exact path="/admin/change_password" component={ChangePassword}></Route>
                <Route exact path="/regi-deregistration/:id" component={Registration}></Route> 
                <Route exact path="/admin/package" component={Packages}></Route>
                <Route exact path="/upgrade_package" component={UpgradePackage}></Route>
                <Route exact path="/team_request" component={TeamRequest}></Route>
                <Route exact path="/permission" component={Permission}></Route>
                <Route exact path="/test" component={Test}></Route>
                <Route exact path="/thank-you" component={Thankyou}></Route>
                <Route exact path="/payment-failed" component={Paymentfailed}></Route>

                <Route path=""  component={ErrorPage}></Route>
             </Switch>

          </Router>
        )}
      </div>
    );
  }
}
