import React, { Component } from 'react'
import { Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL, APIDOMAIN } from '../../constants/Common';
import { Redirect, Link } from "react-router-dom";
import '@szhsin/react-menu/dist/index.css';
import Sidebar from "../../GeneralComponents/Sidebar";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import moment from 'moment';
import { withTranslation } from 'react-i18next';

const guestOption = [
    {
        label: "No",
        value: "0",
    },
    {
        label: "Yes",
        value: "1",
    }
];


class Add extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamInfo: {
                team_name: "",
                logo: "",
                owner: "",
                teamtype: "",
                redirect: false,
                isLoading: "",
            },
            docmodal: false,
            teamId: this.props.match.params.id,
            redirectToEvent: false,
            msg: "",
            errMsg: {},
            scsMsg: "",
            errMsgAttr: '',
            EventTypes: [],
            EventTypesList: [],
            AnsOpt_List: [],
            AnsSelected: [],
            DefultAnsSelected: [],
            setAttr: '',
            image: '',
            selected_type: [],
            selected_function: [{ label: "No", value: "0" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            teamAttrs: [],
            event_type_id: [],
            name: "",
            info: "",
            place: "",
            func_List: [],
            Task_list: [],
            doc_list: [],
            time_gather: "",
            time_from: "",
            time_to: "",
            date: "",
            date_to: "",
            registration_deadline: "",
            registration_deadline_time: "",
            infomail_uncertain: false,
            infomail_uncertain_days: '',
            infomail_unregistered: false,
            infomail_unregistered_days: '',
            infomail_teamleader_answerupdates: false,
            isSeries: false,
            series_end_date: "",
            seriesweekArr: [{ name: "Mon", status: false }, { name: "Tue", status: false }, { name: "Wed", status: false }, { name: "Thu", status: false }, { name: "Fri", status: false }, { name: "Sat", status: false }, { name: "Sun", status: false }],
            seriesweek: "",
            team_name: "",
            docname: "",
            docfile: "",
            error: [],
            errorRequireFiled: "",
            ActiveTeam: 0,
            isPermission: 1
        };
        this.toggle = this.toggle.bind(this);

    }

    componentDidMount() {
        if (this.state.teamId != "undefined") {
            this.getExpirationPlanMsg()
        }
        this.getEventTypes()
        this.getFunctions()
        this.getTasks()
        this.getAnswerOption()

    }

    handle_Event_Name(e) {
        this.setState({
            name: e
        })
    }
    handle_Event_Info(e) {
        this.setState({
            info: e
        })
    }
    handle_Event_place(e) {
        this.setState({
            place: e
        })
    }

    urlToOpen(lnk) {
        window.open(lnk, '_blank');
    }


    handleEndDate() {
        let default_duration_days = 0;
        let registration_deadline = "";
        let date = "";
        let month = 0;
        let day = 0;
        const selectedEtype = this.state.EventTypesList.filter((s, sidx) => this.state.event_type_id == s.event_type_id);

        if (selectedEtype.length && this.state.date != "") {
            default_duration_days = selectedEtype[0].default_deadline_days;
            date = new Date(this.state.date);
            date.setDate(date.getDate() - default_duration_days);
            month = date.getMonth() + 1; day = date.getDate();
            if (month < 10) {
                month = "0" + month;
            }
            if (day < 10) {
                day = "0" + day;
            }
            registration_deadline = date.getFullYear() + "-" + month + "-" + day;
            this.setState({
                registration_deadline: registration_deadline
            })
        }
    }

    handleEndTime() {

        let default_duration_hours = 0;
        let splitHours = 0;
        let splitHoursNew = 0; let splitHm = 0; let minitue = 0;
        const selectedEtype = this.state.EventTypesList.filter((s, sidx) => this.state.event_type_id == s.event_type_id);
        if (selectedEtype.length && this.state.time_from != "" && selectedEtype[0].default_duration_hours != "00:00:00") {
            default_duration_hours = selectedEtype[0].default_duration_hours;
            splitHours = this.state.time_from.split(":")
            splitHm = default_duration_hours.split(":")

            splitHoursNew = parseInt(splitHours[0]) + parseInt(splitHm[0]);
            minitue = parseInt(splitHours[1]) + parseInt(splitHm[1]);
            if (minitue > 59) {
                splitHoursNew = parseInt(splitHoursNew) + 1;
                minitue = parseInt(minitue) - 60;

            }
            if (minitue < 10) {
                minitue = "0" + minitue;
            }

            if (splitHoursNew < 10) {
                splitHours = "0" + splitHoursNew + ":" + minitue;
            }
            else {
                splitHours = splitHoursNew + ":" + minitue;
            }
            this.setState({
                time_to: splitHours
            })

        }
        else {
            this.setState({
                time_to: splitHours
            })
        }

        this.handleGratherTime();
    }

    handle_Event_Date(e) {
        if (!this.date) {
            this.setState({
                date_to: e
            })
        }
        this.setState({
            date: e
        }, () => {
            this.handleEndDate();
            this.handleCheckStartAndEndDate();
        });
    }

    handle_Event_Date_To(e) {
        this.setState({
            date_to: e,
        }, this.handleCheckStartAndEndDate);
    }

    handleCheckStartAndEndDate() {
        if (moment(this.state.date_to).isBefore(moment(this.state.date))) {
            this.setState({
                errMsg: {
                    date_to: this.props.t("The end date is earlier than the start date"),
                }
            });
            return;
        } else {
            this.setState({
                errMsg: {}
            });
        }

        if (moment(this.state.date_to + " " + this.state.time_to).isBefore(moment(this.state.date + " " + this.state.time_from))) {
            this.setState({
                errMsg: {
                    time_to: this.props.t("The end time is earlier than the start time"),
                }
            });
        } else {
            this.setState({
                errMsg: {}
            });
        }
    }

    handle_series_end_date(e) {
        this.setState({
            series_end_date: e
        }, () => {
            // this.handleSeriesEndDate();
        });
    }

    handle_Event_Gather_time(e) {
        this.setState({
            time_gather: e
        })
    }
    handle_Event_Zeit_time(e) {

        this.setState({
            time_from: e,
            registration_deadline_time: e
        }, () => {
            this.handleEndTime();
            this.handleCheckStartAndEndDate();
            //this.registration_deadline_time()
        });
    }

    handleGratherTime() {



        if (this.state.event_type_id != "") {
            let gatherTime = "00:00";
            const selectedEtype = this.state.EventTypesList.filter((s, sidx) => this.state.event_type_id == s.event_type_id);
            if (this.state.time_from != "") {
                let default_duration_hours = selectedEtype[0].default_gather_time;
                let splitHours = this.state.time_from.split(":")
                let splitHm = default_duration_hours.split(":")

                let splitHoursNew = parseInt(splitHours[0]) - parseInt(splitHm[0]);
                let minitue = parseInt(splitHours[1]) - parseInt(splitHm[1]);

                if (minitue < 0) {
                    splitHoursNew = parseInt(splitHoursNew) - 1;
                    minitue = minitue + 60;

                }
                if (minitue < 10) {
                    minitue = "0" + minitue;
                }

                if (splitHoursNew < 10) {
                    splitHours = "0" + splitHoursNew + ":" + minitue;
                }
                else {
                    splitHours = splitHoursNew + ":" + minitue;
                }
                this.setState({
                    time_gather: splitHours
                })


            }
        }

    }
    handle_Event_Zeit_End_time(e) {
        this.setState({
            time_to: e
        }, this.handleCheckStartAndEndDate)
    }
    handle_Register_Deadline(e) {
        this.setState({
            registration_deadline: e
        })
    }

    registration_deadline_time(e) {
        this.setState({
            registration_deadline_time: e
        })
    }

    getEventTypes() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "eventtype/get_active_event_types", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let EventTypes = response.data.data;
                const arryEvent = [];
                for (var c = 0; c < EventTypes.length; c++) {
                    arryEvent.push({ value: EventTypes[c].event_type_id, label: EventTypes[c].name })
                }
                this.setState({
                    EventTypes: arryEvent,
                    EventTypesList: response.data.data
                })
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    getFunctions() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "event/event_functions", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({
                    func_List: response.data.data
                })
                if (response.data.team != null) {
                    this.setState({
                        team_name: response.data.team.team_name
                    })
                }
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    getAnswerOption() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "answer/get_active_answers", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                this.setState({
                    AnsOpt_List: response.data.data
                })


            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    getTasks(id) {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "event/get_tasks", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ Task_list: response.data.data });
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }


    EventSelect = (e) => {
        if (e) {
            this.setState({
                event_type_id: e.target.value
            }, () => { this.handleEndDate(); this.handleEndTime(); this.EditDefaultTask(); })
            if (e.target.value) {
                const selectedEtype = this.state.EventTypesList.filter((s, sidx) => e.target.value == s.event_type_id);
                //let default_gather_time=selectedEtype[0].default_gather_time.split(":")
                // default_gather_time=default_gather_time[0]+":"+default_gather_time[1];
                this.setState({
                    // time_gather: selectedEtype[0].Event_type ==="Standard event" ? this.state.time_gather  : selectedEtype[0].default_gather_time === "00:00:00" ? "" : selectedEtype[0].default_gather_time,
                    //  time_gather:  selectedEtype[0].default_gather_time === "00:00:00" ? "" : default_gather_time,

                    infomail_uncertain: selectedEtype[0].default_infomail_uncertain === 1 ? true : false,
                    infomail_uncertain_days: selectedEtype[0].default_infomail_uncertain_days,
                    infomail_unregistered: selectedEtype[0].default_infomail_unregistered === 1 ? true : false,
                    infomail_unregistered_days: selectedEtype[0].default_infomail_unregistered_days,
                });

            }
        }
        else {
            this.setState({ errMsg: { Eventtype: 'Please select function.' } });
        }
    };


    EventAnsSelect(e, index) {
        let is_default = "No";
        if (e.target.checked === true) {
            is_default = "Yes";
        }

        let AnsOpt_List = this.state.AnsOpt_List;
        AnsOpt_List[index].is_default = is_default;

        this.setState({ AnsOpt_List: AnsOpt_List });
    };


    getUnique(arr, index) {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) == i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }



    handleGuestChange = (e, index, name) => {
        const func_List = this.state.func_List
        if (name === "checked") {
            func_List[index][name] = e.target.checked;
        }
        else {
            func_List[index][name] = e.target.value;
        }

        /*
        else if(name=="guest_allowed")
        {
           
            func_List[index][name]=e.target.value;
            
           
        }
        */

        this.setState({ func_List: func_List });
    };

    handleInfomail1 = (e) => {
        this.setState({ infomail_uncertain: e.target.checked });
    };

    handleInfomail2 = (e) => {
        this.setState({ infomail_unregistered: e.target.checked });
    };

    handleInfomail3 = (e) => {
        this.setState({ infomail_teamleader_answerupdates: e.target.checked });
    };
    handle_Infomain_day1(e) {
        this.setState({
            infomail_uncertain_days: e
        })
    }
    handle_Infomain_day2(e) {
        this.setState({
            infomail_unregistered_days: e
        })
    }
    handleSeries(e) {
        this.setState({
            isSeries: e.target.checked
        })
    }

    handleSeriesTotalWeek(e) {
        this.setState({
            seriesweek: e
        })

    }

    handleSeriesWeek = (e, index) => {
        const seriesweekArr = this.state.seriesweekArr;
        seriesweekArr[index]['status'] = e.target.checked;
        this.setState({
            seriesweekArr: seriesweekArr
        })

    }

    handleTasks = (e, index, name) => {
        const Task_list = this.state.Task_list
        if (name === "checked") {
            Task_list[index][name] = e.target.checked;
        }
        else {
            Task_list[index][name] = e.target.value;
        }

        this.setState({ Task_list: Task_list });
    };

    handleDocumentName(e) {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        this.setState({
            docname: e
        })
    }
    handleDocumentFile(e) {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        this.setState({
            docfile: e
        })
    }
    handleAddDocument = (e) => {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('docname', this.state.docname);
        formData.append('docfile', this.state.docfile);
        var token = this.state.token

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }

        axios
            .post(APIURL + "lib/create", formData, config)
            .then(result => {
                // const doc_list=[...this.state.doc_list,...result.data.data]

                this.setState({
                    errMsg: "",
                    docname: "",
                    docfile: "",
                    doc_list: [...this.state.doc_list, ...result.data.data],
                    isLoading: false
                })
                this.toggle();

            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }

            }
            );
    }
    toggle(e) {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        this.setState({
            docmodal: !this.state.docmodal,
            errMsg: ""
        });
    }
    handleDocumentDelete(item, index) {
        this.setState({ isLoading: true });

        const formData = new FormData();
        formData.append('id', item);

        var token = this.state.token

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }


        /* fetch repos with axios */
        axios
            .post(APIURL + "lib/delete", formData, config)
            .then(result => {

                const newRep = [...this.state.doc_list];
                newRep.splice(index, 1);

                this.setState({
                    doc_list: newRep,
                    isLoading: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    // EventSelect = (e) => {
    //     const { teamInfo } = this.state;
    //     teamInfo['teamtype'] = e.value;
    //     this.setState({ teamInfo, selected_type: [{ value: e.value, label: e.label }] });
    // };

    onSubmit = (e) => {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        formData.append('name', this.state.name);
        formData.append('info', this.state.info);
        formData.append('place', this.state.place);
        formData.append('event_type_id', this.state.event_type_id);
        formData.append('date', this.state.date);
        formData.append('date_to', this.state.date_to);
        formData.append('time_from', this.state.time_from);
        formData.append('time_to', this.state.time_to);
        formData.append('time_gather', this.state.time_gather);
        formData.append('registration_deadline', this.state.registration_deadline);
        formData.append('registration_deadline_time', this.state.registration_deadline_time);
        formData.append('infomail_uncertain', this.state.infomail_uncertain);
        formData.append('infomail_unregistered', this.state.infomail_unregistered);
        formData.append('infomail_teamleader_answerupdates', this.state.infomail_teamleader_answerupdates);
        formData.append('infomail_uncertain_days', this.state.infomail_uncertain_days);
        formData.append('infomail_unregistered_days', this.state.infomail_unregistered_days);
        formData.append('answerType', JSON.stringify(this.state.AnsOpt_List));
        formData.append('isSeries', this.state.isSeries);
        formData.append('series_end_date', this.state.series_end_date);
        formData.append('seriesweek', this.state.seriesweek);
        formData.append('seriesweekArr', JSON.stringify(this.state.seriesweekArr));
        formData.append('tasks', JSON.stringify(this.state.Task_list));
        formData.append('functions', JSON.stringify(this.state.func_List));

        formData.append('docs', JSON.stringify(this.state.doc_list));


        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "event/create", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {

                this.setState({
                    redirectToEvent: true
                })

            })
            .catch(error => {
                if (error.response && error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        errorRequireFiled: "Please fill in all mandatory fields"
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }

            }
            );
    }

    EditDefaultTask() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        formData.append('event_type_id', this.state.event_type_id);
        var token = this.state.token
        axios
            .post(APIURL + "eventtype/edit-default-task", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let Task_list = this.state.Task_list;
                Task_list.map((tasks, index) => {
                    if (Task_list[index].max_registrations === "") {
                        Task_list[index].checked = false;
                    }
                    response.data.data.map((type, idx) => {

                        if (tasks.task_id === type.value) {
                            Task_list[index].checked = true;
                        }
                    });
                });

                this.setState({
                    Task_list: Task_list,
                    // TaskSelected:response.data.data
                })

            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    getExpirationPlanMsg = () => {
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan === "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length === 0) {
                    isPermission = 0;
                }
                else if (response.data.member.length === 1) {
                    if (response.data.member[0].is_admin === 0 && response.data.member[0].is_teamleader === 0) {
                        isPermission = 0;
                    }
                }

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission

                })
            });
    }


    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    Sub_CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    render() {
        const { t } = this.props;
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }

        if (this.state.redirectToEvent) {
            return <Redirect to={"/event/" + this.state.teamId} />;
        }
        return (
            <React.Fragment>
                <div id="wrapper">
                    <Sidebar
                        data={{
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "events",
                            visible:this.props.location.visible
                        }} />

                    <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                        <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                        <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>

                                {
                                    this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                    <h6 className="mt-3">Upgrade Package.</h6>
                                }
                                {
                                    this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                    <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                                }
                                {
                                    this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                    <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                                }
                                {
                                    this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                    <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                                }
                                {
                                    this.state.day >= 30 && this.state.day < 61 &&
                                    <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                                }

                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-between w-100 text-center">
                                <Button className="btn btn-danger" onClick={() => this.Sub_CloseModal()}>Cancel </Button>
                                {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
                            </div>
                        </ModalFooter>
                    </Modal>
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt justify-content-start">
                                            <h4 className="page-title">{this.state.team_name} | {t("Add event")}</h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">

                                        <div className="p-2">
                                            <form className="form-horizontal">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 mt-2">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4">
                                                                        <ul className="list-group">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light">
                                                                                {t("DETAILS")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <label className="col-md-2 col-form-label" htmlFor="example-date">{t("Event type")}<big className="text-danger">*</big></label>
                                                                    <div className="col-md-2 ">
                                                                        <select className="form-select" autoFocus={true} value={this.state.event_type_id} onChange={(e) => this.EventSelect(e)} >
                                                                            <option value="">{t("Select")}</option>
                                                                            {this.state.EventTypes.map((option) => (
                                                                                <option value={option.value} >{option.label}</option>
                                                                            ))}
                                                                        </select>

                                                                        <div className="text-danger">{t(this.state.errMsg.event_type_id)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="simpleinput">{t("Event name")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-md-4">
                                                                        <input
                                                                            type="text"
                                                                            id="simpleinput"
                                                                            className="form-control"
                                                                            placeholder={t("Enter event name")}
                                                                            value={this.state.name}
                                                                            onChange={(e) => this.handle_Event_Name(e.target.value)}
                                                                            data-maxzpsw="0" />
                                                                        <div className="text-danger">{t(this.state.errMsg.name)}</div>
                                                                    </div>

                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="example-email">{t("Event information")}</label>
                                                                    <div className="col-md-10">
                                                                        <textarea
                                                                            className="form-control"
                                                                            id="example-textarea"
                                                                            rows="3"
                                                                            placeholder={t("Enter event information")}
                                                                            value={this.state.info}
                                                                            onChange={(e) => this.handle_Event_Info(e.target.value)}
                                                                        ></textarea>
                                                                        <div className="text-danger">{t(this.state.errMsg.info)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-md-2 col-form-label" htmlFor="simpleinput">{t("Event location")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-md-10">
                                                                        <input
                                                                            type="text"
                                                                            id="simpleinput"
                                                                            className="form-control"
                                                                            data-maxzpsw="0"
                                                                            placeholder={t("Enter event location")}
                                                                            value={this.state.place}
                                                                            onChange={(e) => this.handle_Event_place(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.place)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("DATE / TIMES")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-date">{t("Date")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3">
                                                                        <input
                                                                            className="form-control"
                                                                            type="date"
                                                                            name="date"
                                                                            id="example-date"
                                                                            value={this.state.date}
                                                                            onChange={(e) => this.handle_Event_Date(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.date)}</div>
                                                                    </div>
                                                                </div>

                                                                <div className="mb-2 row">
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-time">{t("Time of event start")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3">
                                                                        <input className="form-control"
                                                                            type="time"
                                                                            id="example-time"
                                                                            name="time"
                                                                            value={this.state.time_from}
                                                                            onChange={(e) => this.handle_Event_Zeit_time(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.time_from)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-date">{t("Date end")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3">
                                                                        <input
                                                                            className="form-control"
                                                                            type="date"
                                                                            name="date"
                                                                            id="example-date"
                                                                            value={this.state.date_to}
                                                                            onChange={(e) => this.handle_Event_Date_To(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.date_to)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-time">{t("Time of event end")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3">
                                                                        <input
                                                                            className="form-control"
                                                                            type="time"
                                                                            id="example-time"
                                                                            name="time"
                                                                            value={this.state.time_to}
                                                                            onChange={(e) => this.handle_Event_Zeit_End_time(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.time_to)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-time">{t("Meeting time")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3">
                                                                        <input
                                                                            className="form-control"
                                                                            type="time"
                                                                            id="example-time"
                                                                            name="time"
                                                                            value={this.state.time_gather}
                                                                            onChange={(e) => this.handle_Event_Gather_time(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.time_gather)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="mb-2 row">
                                                                    <label className="col-sm-2 col-form-label" htmlFor="example-date">{t("Register deadline")}<big className="text-danger ">*</big></label>
                                                                    <div className="col-sm-3 col-6">
                                                                        <input
                                                                            className="form-control"
                                                                            type="date"
                                                                            name="date"
                                                                            id="example-date"
                                                                            value={this.state.registration_deadline}
                                                                            onChange={(e) => this.handle_Register_Deadline(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.registration_deadline)}</div>
                                                                    </div>
                                                                    <div className="col-sm-3 col-6">
                                                                        <input
                                                                            className="form-control"
                                                                            type="time"
                                                                            id="example-time"
                                                                            name="time"
                                                                            value={this.state.registration_deadline_time}
                                                                            onChange={(e) => this.registration_deadline_time(e.target.value)}
                                                                        />
                                                                        <div className="text-danger">{t(this.state.errMsg.registration_deadline_time)}</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("FUNCTIONS")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="table-responsive">
                                                                            <Table hover className="table table-hover  table-centered nowrap">
                                                                                <thead >
                                                                                    <tr>
                                                                                        <th style={{ width: "30px" }}></th>
                                                                                        <th>{t("Functions")}</th>
                                                                                        <th>{t("Max. number of registrations")}</th>
                                                                                        <th style={{ width: "120px" }}>{t("Guests allowed")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.func_List.length > 0 &&
                                                                                        this.state.func_List.map((item, idx) => (
                                                                                            <tr key={item.function_id}>
                                                                                                <td>
                                                                                                    <Input className="form-check-input" type="checkbox" value={item.checked} onChange={(e) => this.handleGuestChange(e, idx, 'checked')} id="flexCheckDefault" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label className="form-check-label" htmlFor="flexCheckDefault"> {item.name}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <Input
                                                                                                        type="number"
                                                                                                        id="example-time"
                                                                                                        className="form-control col-4 "
                                                                                                        min="0"
                                                                                                        placeholder={t("Max. number of participants")}
                                                                                                        onChange={(e) => this.handleGuestChange(e, idx, 'max_registrations')}
                                                                                                        value={item.max_registrations}
                                                                                                    />
                                                                                                </td>

                                                                                                <td>
                                                                                                    <select value={item.guest_allowed} className="form-select" onChange={(e) => this.handleGuestChange(e, idx, 'guest_allowed')}>
                                                                                                        {guestOption.map((option) => (
                                                                                                            <option value={option.value}>{option.label}</option>
                                                                                                        ))}
                                                                                                    </select>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("ANSWER OPTIONS")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="table-responsive">
                                                                            <Table hover className="table table-hover  table-centered nowrap">
                                                                                <thead >
                                                                                    <tr>
                                                                                        <th ></th>
                                                                                        <th>{t("Answer")}</th>
                                                                                        <th>{t("Status")}</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.AnsOpt_List.length > 0 &&
                                                                                        this.state.AnsOpt_List.map((answer, idx) => (
                                                                                            <tr key={answer.answer_id}>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input className="form-check-input" type="checkbox" checked={answer.is_default === "Yes"} onChange={(e) => this.EventAnsSelect(e, idx)} value="" id="flexCheckDefault" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label className="form-check-label " htmlFor="flexCheckDefault">{answer.answer_name}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    {answer.state === 1 &&
                                                                                                        <div>Registered</div>
                                                                                                    }
                                                                                                    {answer.state === 2 &&
                                                                                                        <div>Unregistered</div>
                                                                                                    }
                                                                                                    {answer.state === 3 &&
                                                                                                        <div> Maybe</div>
                                                                                                    }
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                </tbody>
                                                                            </Table>
                                                                            <div className="text-danger">{t(this.state.errMsg.answerType)}</div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("TASKS")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    {/* <label className="col-md-12 col-form-label" htmlFor="example-date">Tasks</label> */}
                                                                    <div className="col-md-6">
                                                                        <div className="table-responsive">
                                                                            <Table hover className="table table-hover  table-centered nowrap">
                                                                                <thead >
                                                                                    <tr>
                                                                                        <th ></th>
                                                                                        <th>{t("Task")}</th>
                                                                                        <th>{t("Max. number of registrations")}</th>

                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {this.state.Task_list.length > 0 &&
                                                                                        this.state.Task_list.map((item, idx) => (
                                                                                            <tr key={item.task_id}>
                                                                                                <td style={{ width: "30px" }}>
                                                                                                    <input className="form-check-input" type="checkbox" checked={item.checked === true || item.checked === 'true'} onChange={(e) => this.handleTasks(e, idx, 'checked')} value="" id="flexCheckDefault" />
                                                                                                </td>
                                                                                                <td>
                                                                                                    <label className="form-check-label " htmlFor="flexCheckDefault">{item.task_name}</label>
                                                                                                </td>
                                                                                                <td>
                                                                                                    <input type="number" min="0" id="example-time" className="form-control" onChange={(e) => this.handleTasks(e, idx, 'max_registrations')} value={item.max_registrations} placeholder={t("Max number of people for this task")} />
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-md-12">
                                                                        <div className="col-12 document_list_area mb-4 " >
                                                                            <ul className="list-group ">
                                                                                <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                    {t("MAIL INFORMATION")}
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="d-flex">
                                                                            <div style={{ width: "30px" }}>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input mt-1" onChange={(e) => this.handleInfomail1(e)} type="checkbox"
                                                                                        checked={this.state.infomail_uncertain}
                                                                                        id="flexCheckDefault" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                        {t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")}<br />
                                                                                        <input type="number" min="0" size="1" className="mr-1 width-50 form-control" value={this.state.infomail_uncertain_days} onChange={(e) => this.handle_Infomain_day1(e.target.value)} />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="d-flex">
                                                                            <div style={{ width: "30px" }}>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input mt-3" onChange={(e) => this.handleInfomail2(e)}
                                                                                        checked={this.state.infomail_unregistered} type="checkbox" value="" id="flexCheckChecked1" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                        {t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}<br />
                                                                                        <input type="number" min="0" className="mr-1 width-50 form-control" value={this.state.infomail_unregistered_days} onChange={(e) => this.handle_Infomain_day2(e.target.value)} size="1" />
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12">
                                                                        <div className="d-flex">
                                                                            <div style={{ width: "30px" }}>
                                                                                <div className="form-check">
                                                                                    <input className="form-check-input" onChange={(e) => this.handleInfomail3(e)}
                                                                                        type="checkbox" value="" id="flexCheckChecked2" />
                                                                                </div>
                                                                            </div>
                                                                            <div>
                                                                                <div className="form-check">
                                                                                    <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                        {t("Send information e-mail and push notification to team administrators when a de-/registration is made or changed")}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row" >
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("EVENT SERIES")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    {/* <label className="col-md-2 col-form-label" htmlFor="example-date">Series event</label> */}
                                                                    <div className="col-md-10">
                                                                        <div className="form-check form-switch">
                                                                            <input className="form-check-input" onChange={(e) => this.handleSeries(e)}
                                                                                type="checkbox" id="flexSwitchCheckDefault" />
                                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">{t("It is an event series")}</label>
                                                                        </div>

                                                                        <div style={{ display: this.state.isSeries === false ? 'none' : 'block' }}>
                                                                            <div className="row">
                                                                                <div className="col-sm-2">{t("Repeat the event every")} </div>
                                                                                <div className="col-sm-2">
                                                                                    <input type="number" onChange={(e) => this.handleSeriesTotalWeek(e.target.value)} id="simpleinput" className="form-control" value={this.state.seriesweek} />
                                                                                    <div className="text-danger">{t(this.state.errMsg.seriesweek)}</div>
                                                                                </div>
                                                                                <div className="col-sm-1 p-2">
                                                                                    {t("Weeks")}
                                                                                </div>
                                                                            </div>
                                                                            <div className="row mt-3">
                                                                                <div className="col-sm-2 mt-2">{t("End date")} </div>
                                                                                <div className="col-sm-3">
                                                                                    <input
                                                                                        className="form-control"
                                                                                        type="date"
                                                                                        name="date"
                                                                                        id="example-date"
                                                                                        value={this.state.series_end_date}
                                                                                        onChange={(e) => this.handle_series_end_date(e.target.value)}
                                                                                    />
                                                                                    <div className="text-danger">{t(this.state.errMsg.series_end_date)}</div>
                                                                                </div>
                                                                                <div className="col-sm-10 offset-sm-2">
                                                                                    <div className="d-flex d-flexwrap mt-3">
                                                                                        <div className="form-check mr-1 ">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 0)} type="checkbox" value="" id="flexCheckDefault" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                                                                {t("Mon")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check mr-1">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 1)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Tue")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check mr-1">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 2)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Wed")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check mr-1">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 3)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Thu")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check mr-1">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 4)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Fri")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check mr-1">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 5)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Sat")}
                                                                                            </label>
                                                                                        </div>
                                                                                        <div className="form-check">
                                                                                            <input className="form-check-input" onChange={(e) => this.handleSeriesWeek(e, 6)} type="checkbox" value="" id="flexCheckChecked" />
                                                                                            <label className="form-check-label" htmlFor="flexCheckChecked">
                                                                                                {t("Sun")}
                                                                                            </label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="card p-2">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-12 ">
                                                                <div className="mb-2 row">
                                                                    <div className="col-12 document_list_area mb-4 " >
                                                                        <ul className="list-group ">
                                                                            <li className="list-group-item fw-medium mt-2 bg-light ">
                                                                                {t("DOCUMENTS")}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-md-2">
                                                                        <div className="table-responsive">
                                                                            <Table hover className="table table-hover  table-centered nowrap">
                                                                                <tbody className="font-14">
                                                                                    {this.state.doc_list.length > 0 ?
                                                                                        this.state.doc_list.map((item, idx) => (
                                                                                            <tr key={item.file}>
                                                                                                <td>
                                                                                                    <button type="button" onClick={() => this.urlToOpen(`${APIDOMAIN}uploads/${item.file}`)}  className="btn btn-link"><span className='text-white'>{item.name}</span></button>

                                                                                                </td>
                                                                                                <td style={{ width: "50px", textAlign: "right" }}><i className="far fa-trash-alt" onClick={() => this.handleDocumentDelete(item.lib_id, idx)}></i> </td>
                                                                                            </tr>
                                                                                        )) : ""}
                                                                                </tbody>
                                                                            </Table>
                                                                        </div>
                                                                        <div className="row">
                                                                            <div className="col-md-12" >
                                                                                <button style={{ width: "140px" }} type="button" onClick={() => this.toggle()} className="btn btn-success">{t("Add document")}</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Link to={"/event/" + this.state.teamId} className="btn btn-warning mx-3">{t("Back")}</Link>&nbsp; 
                                                <button type="button" disabled={this.state.isLoading} onClick={() => this.onSubmit()} className="btn btn-success">{t("Create event")}</button>
                                                
                                                <div className="mt-2">
                                                    <div className="text-danger"> {t(this.state.errorRequireFiled)}</div>
                                                </div>
                                            </form>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal size="md" isOpen={this.state.docmodal} >
                    <ModalHeader toggle={this.toggle}>{t("Document")}</ModalHeader>
                    <ModalBody>
                        <div className="col-md-12 mb-3">
                            <input type="text" onChange={(e) => this.handleDocumentName(e.target.value)} className="form-control" id="useremail" placeholder={t("Enter document name")} />
                            <div className="text-danger ">{t(this.state.errMsg.docname)}</div>
                        </div>
                        <div className="col-md-12">
                            <input type="file" onChange={(e) => this.handleDocumentFile(e.target.files[0])} className="form-control" id="example-fileinput" />
                            <span className="small">File size upto 2MB is allowed</span>
                            <div className="text-danger">{t(this.state.errMsg.docfile)}</div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                            <button className="btn btn-success fw-bolder" onClick={() => this.handleAddDocument()}>{t("Save")} </button>
                        </div>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Add);