    import React, { Component } from "react";
    import axios from "axios";
    import { APIURL } from '../../constants/Common';
    import '../../../assets/css/style.css'
    import '../../../assets/css/bootstrap.min.css'
    import '../../../assets/css/icons.css'
    import '../../../assets/css/Custom.css'
    import { Redirect, Link } from "react-router-dom";
    import Sidebar from "../../Admin/GeneralComponents/Sidebar";
    import {  Input, Spinner, Label, Button } from "reactstrap";
    import Footer from '../../GeneralComponents/Footer'


    export default class Package extends Component {
        constructor(props) {
            super(props);
            this.state = {
                Package_List: [],
                teamId: "",
                description: "",
                name: "",
                function: "",
                msg: "",
                errMsg: {},
                scsMsg: "",
                user: JSON.parse(localStorage.getItem("userData")),
                token: JSON.parse(localStorage.getItem("token")),
                Is_admin: [],
                function_name: [],
                team_name: "",
                package_id: "",
                Loader: false,
                isLoading: false,
                modal: false,
                addModal: false,
                teamAttrs: [
                    { 
                        id: '', 
                        month: '', 
                        price:'', 
                        number:'',
                        defaultValue:'Yes'
                    }
                ],
                removePkgModal:false,
                pkgId:"",
                index:"",
                password: "",
                old_password: "",
                c_password: "",
                showPassword: false,
                showOldPassword: false,
                showCPassword: false,
            }
        }
       
        componentDidMount() {
            // 
        }

        onChangehandler = (e) => {
            let name = e.target.name;
            let value = e.target.value;
            let data = {};
            data[name] = value;
            this.setState(data);
        };

        opClick = () => {
            // alert(this.state.showPassword)
            this.setState({ showOldPassword: this.state.showOldPassword ? false : true });
        };

        handleClick = () => {
            // alert(this.state.showPassword)
            this.setState({ showPassword: this.state.showPassword ? false : true });
        };

        cpClick = () => {
            // alert(this.state.showPassword)
            this.setState({ showCPassword: this.state.showCPassword ? false : true });
        };

        onSignInHandler = () => {
            this.setState({
                Loader: true
            });
            const formData = new FormData();
            formData.append('email', this.state.user.email);
            formData.append('old_password', this.state.old_password);
            formData.append('password', this.state.password);
            formData.append('c_password', this.state.c_password);

            var app_url = APIURL + "admin/change_password";
            var token = this.state.token
            axios
                .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {
                    this.setState({
                        old_password: '',
                        password: '',
                        c_password: '',
                        Loader: false,
                        errMsg: {},
                        error: {}
                    });
                })
                .catch((error) => {
                    if (error.response.data.message === "validation_error") {
                        this.setState({
                            errMsg: error.response.data.errors,
                            Loader: false
                        })
                    }
                    else {
                        this.setState({
                            error,
                            Loader: false
                        })
                    }
                });
          };

        render() {
            const { visible } = this.state
            const { navigate } = this.state;
            console.log(this.state.teamAttrs, 'errorrrr', this.state.errMsg)
            if (navigate || !this.state.user) {
                return <Redirect to="/login" push={true} />;
            }
            else if (this.state.user.user_type!=='Admin') {
                return <Redirect to="/login" push={true} />;
            }
            return (
                <div id="wrapper">
                    {
                        this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                    }
                    <Sidebar
                    data={{
                        ActivePage:"password",
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        
                    }} />

                    <div className={visible ? "content-page content-margin" : "content-page"}>
                        <div className="content">
                            {/* <!-- Start Content--> */}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">Super Admin | Change Password </h4>
                                        </div>
                                            { this.state.errMsg.message &&
                                            <span className="text-danger">{this.state.error.message}</span>
                                        }

                                    </div>
                                </div>
                                <div className="card p-2">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-6 ">
                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Current Password</Label>
                                                    <div className="input-group input-group-merge">
                                                      <Input
                                                        className="form-control"
                                                        required=""
                                                        type={this.state.showOldPassword ? "text" : "password"}
                                                        name="old_password"
                                                        placeholder="Current Password"
                                                        value={this.state.old_password}
                                                        onChange={this.onChangehandler}
                                                      />
                                                      <div className={`${this.state.showOldPassword ? "show-password" : ""}`} data-password={this.state.showOldPassword}>
                                                        <Button className="input-group-text">
                                                          <span className="password-eye" onClick={this.opClick}></span>
                                                        </Button>
                                                      </div>
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.old_password}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">New Password</Label>
                                                    <div className="input-group input-group-merge">
                                                      <Input
                                                        className="form-control"
                                                        required=""
                                                        type={this.state.showPassword ? "text" : "password"}
                                                        name="password"
                                                        placeholder="New Password"
                                                        value={this.state.password}
                                                        onChange={this.onChangehandler}
                                                      />
                                                      <div className={`${this.state.showPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                                                        <Button className="input-group-text">
                                                          <span className="password-eye" onClick={this.handleClick}></span>
                                                        </Button>
                                                      </div>
                                                    </div>
                                                    <span className="text-danger">{this.state.errMsg.password}</span>
                                                </div>
                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Confirm Password</Label>
                                                    <div className="input-group input-group-merge">
                                                        <Input
                                                          className="form-control"
                                                          type={this.state.showCPassword ? "text" : "password"}
                                                          name="c_password"
                                                          placeholder="Confirm password"
                                                          value={this.state.c_password}
                                                          onChange={this.onChangehandler}
                                                        />
                                                        <div className={`${this.state.showCPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                                                          <Button className="input-group-text">
                                                            <span className="password-eye" onClick={this.cpClick}></span>
                                                          </Button>
                                                        </div>
                                                      </div>
                                                      <span className="text-danger">{this.state.errMsg.c_password}</span>
                                                </div>
                                                <div className="col-md-3 d-grid text-center">
                                                    <Button
                                                        className="btn btn-primary"
                                                        onClick={this.onSignInHandler}
                                                    >
                                                        Submit
                                                    </Button>
                                               </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                    

                    <Footer />

                    {/*<footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Link to="#"><i className="fe-facebook"></i></Link>
                                    <Link to="#"><i className="fe-instagram"></i></Link>
                                    TeamOrganizer GMbH
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end footer-links d-none d-sm-block">
                                        <Link to="/">imprint</Link>
                                        <Link to="/">privacy</Link>
                                        <Link to="/">design by stoz</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>*/}
                    <div className="rightbar-overlay"></div>
                </div>
            );
        }
    }