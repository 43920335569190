import React from "react";
import { Input, Button, Modal, ModalBody, Table, ModalFooter, ModalHeader, Label } from 'reactstrap';
import { APIDOMAIN } from '../constants/Common';
import { Link } from "react-router-dom";
import dateFormat from 'dateformat';
import { Trans, withTranslation } from 'react-i18next';

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            eventDetails: this.props.data.eventDetails
        };
    }

    CloseEventModal() {

        this.props.data.CloseEventModal();
    }
    componentWillReceiveProps(props) {

        this.setState({
            eventmodal: props.data.eventmodal,
            eventDetails: props.data.eventDetails
        })

    }
    urlToOpen(lnk) {
        window.open(lnk, '_blank');
    }



    render() {
        const { t } = this.props;

        return (
            <Modal size="md" isOpen={this.props.data.eventmodal}   >
                <ModalHeader toggle={() => this.CloseEventModal()} > {this.state.eventDetails != "" ? dateFormat((this.state.eventDetails.date).split(' ')[0], "dd.mm.yyyy") : ""}: Event</ModalHeader>
                <ModalBody className="m-0 px-0 pt-0" style={{ backgroundColor: "#0c2541" }}>
                    <div className="form-group">
                        {this.state.eventDetails != "" ?
                            <React.Fragment>
                                <div className="card bg-blue mb-2" >
                                    <div className="card-body">
                                        <div className="d-flex"><i className="mdi mdi-text-subject mr-1"></i><strong>{t("Event")} </strong></div>
                                        <div>{this.state.eventDetails.name}</div>
                                    </div>
                                </div>

                                <div className="card bg-blue mb-2">
                                    <div className="card-body">
                                        <div className="d-flex "> <i className="ri-calendar-2-line mr-1"></i><strong> {t("Date")} </strong></div>
                                        <div >{dateFormat((this.state.eventDetails.date).split(' ')[0], "dd.mm.yyyy")} </div>
                                    </div>
                                </div>
                                <div className="card bg-blue mb-2">
                                    <div className="card-body">
                                        <div className="d-flex"><i className="ri-time-fill mr-1"></i><strong>{t("Time")} </strong></div>
                                        <div> {t("Meeting time")}: {((this.state.eventDetails.time_gather).split(':')[0]).concat(":" + (this.state.eventDetails.time_gather).split(':')[1])}</div>
                                        <div> {t("Start")}: {((this.state.eventDetails.time_from).split(':')[0]).concat(":" + (this.state.eventDetails.time_from).split(':')[1])}</div>
                                        <div> {t("End")}: {((this.state.eventDetails.time_to).split(':')[0]).concat(":" + (this.state.eventDetails.time_to).split(':')[1])}</div>
                                    </div>
                                </div>
                                <div className="card bg-blue mb-2">
                                    <div className="card-body">
                                        <div className="d-flex "><i className="mdi mdi-google-maps mr-1"></i><strong>{t("Place")} </strong></div>
                                        <div>{this.state.eventDetails.place}</div>
                                    </div>
                                </div>

                                <div className="card bg-blue mb-2">
                                    <div className="card-body">
                                        <div className="d-flex"><i className="ri-information-fill mr-1"></i><strong>{t("Event information")} </strong></div>
                                        <div>{this.state.eventDetails.info}</div>
                                    </div>
                                </div>

                                <div className="card bg-blue mb-2">
                                    <div className="card-body">
                                        <div className="d-flex"><i className="ri-file-fill mr-1"></i><strong>{t("Document")} </strong></div>
                                        {this.state.eventDetails.event_doc.map((lib, indx) =>
                                            <div>
                                                <button type="button" onClick={() => this.urlToOpen(`${APIDOMAIN}uploads/${lib.lib.file}`)} className="btn btn-link">{lib.lib.name}</button>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </React.Fragment>
                            :
                            ""
                        }

                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between w-100">
                        <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseEventModal()}>{t("Cancel")} </Button>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }
}
export default withTranslation()(App);