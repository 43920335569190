import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import EnglandIcon from '../../../assets/images/england-icon.png'
import GermenIcon from '../../../assets/images/germany-icon.png'
import google from "../../../assets/images/google.webp";
import play_store from "../../../assets/images/app.webp";

function Footer() {

  return(
      <footer>
          <div id="footer">
              <div className="container">
                  <div className="row">
                      <div className="col-6 icons">
                          <a href="#"><i className="ri-facebook-fill"></i></a>
                          <a href="#"><i className="ri-instagram-line"></i> </a>
                          <a href="#"><i className="ri-linkedin-fill"></i></a>
                      </div>
                      <div className="col-6">
                          <div className="text-right">
                              <a href="/impressum">Impressum</a> | <a href="/policies">Datenschutz</a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </footer>
  )
}

export default Footer