export const sortUI = (sort, key, onClick) => {
  let result = "";

  switch(sort) {
    case "ASC": {
      result = <i onClick={() => onClick(key)} className="fas fa-sort-down" style={{marginLeft: "4px", cursor: "pointer"}}></i>;
      break;
    }
    case "DESC": {
      result = <i onClick={() => onClick(key)} className="fas fa-sort-up" style={{marginLeft: "4px", cursor: "pointer"}}></i>;
      break;
    }
    default: {
      result = <i onClick={() => onClick(key)} className="fas fa-sort" style={{marginLeft: "4px", cursor: "pointer"}}></i>;
      break;
    }
  }

  return result;
}

export const changeSort = (value) => {
  let result = "";

  switch(value) {
    case "ASC": {
      result = "DESC";
      break;
    }
    case "DESC":
    default: {
      result = "ASC";
      break;
    }
  }

  return result;
}
