import React, { Component } from 'react'
import '../../assets/css/Custom.css'
import Switch from "react-switch";
import {
    Card,
    Button,
    CardTitle,
    CardText,
    Row,
    Col,
    CardBody,
    Input,
    Label,
    Modal, ModalHeader, ModalBody, ModalFooter, Spinner, FormGroup
} from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import Select from 'react-select'
import { APIURL } from '../constants/Common';
import avatar from "../../assets/images/avatar.png"
import axios from "axios";
import { withTranslation } from 'react-i18next';

const colourStyles = {
   
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {

            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

 class TeamInfos extends Component {
    constructor() {
        super()
        this.state = {
            teamInfo: {
                team_name: "",
                logo: "",
                owner: "",
                teamtype: "",
                redirect: false,
                isLoading: "",
            },
            Logo: "",
            msg: "",
            errMsg: {},
            scsMsg: "",
            errMsgAttr: '',
            teamTypes: [],
            setAttr: '',
            image: '',
            teamId: '',
            selected_type: [{ value: 1, label: "Ice-Hockey" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            modal: false,
            fade: true,
            redirect: false,
            team_admins: [],
            OwnerModal: false,
            ChangePicModal: false,
            user_id: "",
            Admin: "",
            checked: false,
            Loader:false,
            PreviewImage:false,
            owner_user_id:"",
             typeBothData:[]
        };
        this.toggle = this.toggle.bind(this);
        this.ChangePicModal = this.ChangePicModal.bind(this);
        this.Owner_modal_toggle = this.Owner_modal_toggle.bind(this);
    }

    Preview() {
        this.setState({
            PreviewImage: !this.state.PreviewImage
        });
    }

    onChangehandler = (e, key) => {
        const { teamInfo } = this.state;
        teamInfo[e.target.name] = e.target.value;
        this.setState({ teamInfo });
    };

    handleChangeLogo = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }

    typeSelect = (e) => {
       
        const { teamInfo } = this.state;
        teamInfo['teamtype'] = e.value;
        this.setState({ teamInfo, selected_type: [{ value: e.value, label: e.label }] });
    };

    getTeamInfo(id) {
        var token = this.state.token
        axios
            .get(APIURL + "team_info/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                   
                    const { teamInfo } = this.state;
                    teamInfo['team_name'] = response.data.data.team_name;
                    teamInfo['teamtype'] = response.data.data.team_type_id;
                    this.setState({ teamInfo });
                    this.props.data.teamInfo({ Team_name: response.data.data.team_name });
                    this.setState({
                        Admin: response.data.data.owner_name,
                        owner_user_id:response.data.data.owner_user_id,
                        selected_type: [{
                            'value': response.data.data.team_type_id,
                            'label': response.data.data.team_type_name,
                        }]
                    },()=>{this.setTeamTypes()});
                    this.setState({ team_admins: response.data.data.team_admins })
                    this.setState({ Logo: response.data.data.icon_path })
                }
            });
    }
    getTeamTypes() {
        var token = this.state.token
        axios
            .get(APIURL + "get_team_types",{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 'success') {
                     this.setState({
                        typeBothData: response.data.team_types
                    },()=>{this.setTeamTypes()})
                }
            });
    }

    compareBy(key) {
        return function (a, b) {
          if (""+a[key]<(""+b[key])) return -1;
          if (""+a[key]>(""+b[key])) return 1;
          return 0;
    };}

    setTeamTypes(){

         let team_types = this.state.typeBothData;
            let result=[]; let selectType=[{value: 5, label:"Ohne Teamart"}];
           
            if(this.props.i18n.language=="ge")
            { 
                team_types.sort(this.compareBy("name"));
              
            }
            else
            {
                team_types.sort(this.compareBy("name_en"));
            }

            for (var c = 0; c < team_types.length; c++) {
               if(this.props.i18n.language=="ge")
               { 
                   result.push({ value: team_types[c].team_type_id, label: team_types[c].name })
                   if(this.state.selected_type[0].value==team_types[c].team_type_id)
                   {
                      selectType=[{value: team_types[c].team_type_id, label:team_types[c].name}];
                   }
               }
               else
               {
                  if(team_types[c].name_en!=null)
                  {
                     result.push({ value: team_types[c].team_type_id, label: team_types[c].name_en })
                       if(this.state.selected_type[0].value==team_types[c].team_type_id)
                       {
                          selectType=[{value: team_types[c].team_type_id, label:team_types[c].name_en}];
                       }
                  }
                 
               }
                
            }
            

             this.setState({
                teamTypes: result,
                selected_type:selectType
            })
    }

    onDeleteClick() {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        var token = this.state.token
        axios.get(APIURL + "delete_team/" + this.props.data.teamId,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        redirect: true,
                    })
                }
            });
    }

    toggle() {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            modal: !this.state.modal
        });
    }


    ChangePicModal = () => {

        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            active:1,
            errMsg:"",
            ChangePicModal: !this.state.ChangePicModal
        });
    }


    Submit_Owner_Change() {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        if(!this.state.user_id){
            this.setState({
                Loader:false,
                OwnerModal:false
            })
        }
        else {
            this.setState({
                Loader: true,
            })
        }
        if(this.state.user_id==this.state.user.user_id)
        {
            this.setState({
                Loader:false,
                OwnerModal:false
            })
            return false;
        }

        
        const formData = new FormData();
        formData.append('request_to_id', this.state.user_id);
        formData.append('owner_user_id', this.state.user.user_id);
        var app_url = APIURL + "change_team_owner/" + this.props.data.teamId;
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                
                this.getTeamInfo(this.props.data.teamId)
               
                    this.setState({
                        teamId: response.data.data,
                        msg: response.data.message,
                        errMsg: '',
                        OwnerModal: false,
                        Loader: false,
                        isLoading: false,
                    })

            })
            .catch(error =>{
                if(error.response.data.message=="validation_error")
                {
                     this.setState({
                         errMsg:error.response.data.errors,
                         Loader: false
                     })
                }
                else
                {
                     this.setState({
                       error,
                       Loader: false
                     })
                }
                 
             }
           );   
            
    };

    handleRemoveMember = (idx, member_id) => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            // Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "delete_member/" + member_id, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                this.setState({
                    // Loader: false,
                    team_admins: this.state.team_admins.filter((s, sidx) => idx != sidx)
                });
            })
            .catch(error =>
                this.setState({
                    // Loader: false
                })
            );
    }

    Owner_modal_toggle() {
          if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            OwnerModal: !this.state.OwnerModal
        });
    }
   
    iconDataSubmit = (e) => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('icon', this.state.image);
        formData.append('team_id', this.props.data.teamId);

        var app_url = APIURL + "change_logo";
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

                    this.setState({
                        msg: response.data.message,
                        Logo:response.data.data,
                        errMsg: '',
                        Loader: false ,
                        ChangePicModal:false,
                        image:""
                    });
                    
                
            })
            .catch(error =>{
                if(error.response.data.message=="validation_error")
                {
                     this.setState({
                         errMsg:error.response.data.errors,
                         Loader: false
                     })
                }
                else
                {
                     this.setState({
                       error,
                       Loader: false
                     })
                }
                 
             }
           );   
    };
    
    TeamDataSubmit = (e) => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_type_id', this.state.selected_type[0].value);
        formData.append('team_name', this.state.teamInfo.team_name);
      

        var app_url = APIURL + "edit_team/" + this.props.data.teamId;
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
              
                    this.setState({
                        teamId: response.data.data,
                        scsMsg: response.data.message,
                        msg: '',
                        errMsg: '',
                        Loader: false ,
                        ChangePicModal:false,
                        isLoading: false 
                    });
                    this.getTeamInfo(this.props.data.teamId);
             
            })
            .catch(error =>{
                if(error.response.data.message=="validation_error")
                {
                     this.setState({
                         errMsg:error.response.data.errors,
                         Loader: false
                     })
                }
                else
                {
                     this.setState({
                       error,
                       Loader: false
                     })
                }
                 
             }
           );   
    };

    componentDidMount() {
        this.getTeamTypes();
        if (this.props.data.teamId > 0) {
            this.getTeamInfo(this.props.data.teamId);
        }
    }

    componentWillReceiveProps() {
         this.setTeamTypes();
    }

    HandleOwner(e) {
        this.setState({
            user_id: e
        })
    }

    onAlertClose = () => {
        this.setState({
            msg: "",
            scsMsg: "",
        });
    };
    
    render() {
        const { t } = this.props;
        if (this.state.redirect) {
            return <Redirect to="/home" />;
        }
        const { user } = this.state
        return (
            <React.Fragment>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Row>
                    <Col sm="6">
                        <Card>
                            <CardBody>
                                <div className="row align-items-center">
                                    <div className="col-sm-6">
                                        <div className="font-13">({t("Only png, jpg, jpeg format and size upto 2 MB is allowed")})</div>
                                        <div className="text-start mt-3">
                                            <div>
                                                <Button onClick={() => this.ChangePicModal()} className="btn btn-warning mb-3">
                                                    {t("Change picture")}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                        <div onClick={()=> this.Preview()} className="rounded-circle avatar-xxl m-auto bg-profile-pic cursor-pointer" style={{ backgroundImage: `url(${this.state.Logo})` }}>
                                        </div>
                                    </div>
                                    <Modal size="md" isOpen={this.state.PreviewImage} toggle={() => this.Preview()}>
                                    <ModalHeader className="header-less" toggle={() => this.Preview()}></ModalHeader>
                                            <img width="500" height="auto" src={this.state.Logo} />
                                    </Modal>
                                </div>
                            </CardBody>
                        </Card>
                        <Card body>
                            <CardTitle><h6>{t("Owner")}</h6></CardTitle>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="owner_name rounded p-2">
                                        <i className="fas fa-users mx-2"></i>
                                        <span><span>{this.state.Admin}</span></span>
                                    </div>
                                </div>
                                <div className="col-sm-6 text-right mtm-3">
                                {this.state.user.user_id === this.state.owner_user_id ?<Button onClick={() => this.Owner_modal_toggle()} className="btn btn-warning" >{t("Change Owner")}</Button > :"" }
                                    
                                </div>
                            </div>
                        </Card>
                        <Card body >
                            <CardTitle><h6>{t("Administrators")}</h6></CardTitle>
                            <div className="row">
                            {this.state.team_admins.length > 0 ?
                                this.state.team_admins.map((item, idx) => (                                    
                                        <div key={item.full_name} className="col-sm-6 my-1">
                                            <div className="owner_name rounded p-2">
                                                <i className="fas fa-users mx-2"></i>
                                                <span><span>{item.full_name}</span></span>
                                            </div>
                                        </div>
                                )) :
                                <span></span>
                            }
                            </div>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card body>
                            <h4 className="header-title">{/*<i className="mdi mdi-account-circle me-1"></i> */}{t("Team data")}</h4>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="team_name">{t("Team name")}</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Enter team name")}
                                            id="team_name"
                                            name="team_name"
                                            required=""
                                            data-maxzpsw="0"
                                            value={this.state.teamInfo.team_name}
                                            onChange={this.onChangehandler}
                                        />
                                          <span className="text-danger">{t(this.state.errMsg.team_name)}</span>
                                    </div>

                                </div>
                                <div className="col-sm-12">
                                    <div className="mb-3">
                                        <Label className="form-label" htmlFor="team_type">{t("Team type")}</Label>
                                        <Select
                                            aria-label=".form-select-lg example"
                                            required=""
                                            placeholder={t("Select")}
                                            className="form-select-control"
                                            options={this.state.teamTypes}
                                            value={this.state.selected_type}
                                            onChange={this.typeSelect}
                                            styles={colourStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end mtm-3">
                                <Button onClick={() => this.TeamDataSubmit()} color="success">{t("Save")} </Button>
                            </div>
                        </Card>
                        {this.state.user.user_id === this.state.owner_user_id ?
                        <Card body>
                            <CardTitle><h6>{t("Delete Team")}:</h6></CardTitle>
                            <CardText>{t("I would like to delete the team completely (only by team owner)")}</CardText>
                            <div>
                                {/* <Button color="danger" disabled={this.state.user.user_id !== this.state.owner_user_id} onClick={this.toggle}>{t("Delete Team")}</Button> */}
                                <Button color="danger"  onClick={this.toggle}>{t("Delete Team")}</Button>
                            
                            </div>
                        </Card>
                        :""}
                    </Col>
                </Row>


                <Modal size="md" isOpen={this.state.OwnerModal}  >
                    <ModalHeader toggle={() => this.Owner_modal_toggle()}>{t("Change Owner")}</ModalHeader>
                    <ModalBody>
                        <div style={{ color: "#fff", }}>
                            {this.state.team_admins.length > 0 ?
                                this.state.team_admins.map((item, idx) => (
                                    <div key={item.full_name} className="d-flex align-items-center border-secondary border-bottom py-2 ">
                                        <Input type="radio" name="radio1" className=""  defaultChecked={item.is_teamleader ? true : false} onChange={() => this.HandleOwner(item.user_id)} />
                                        <div className="ml-2 mr-1">
                                              {/* <img className="avatar-sm rounded-circle" src={item.avatar_picture ?  } />  */}
                                             {
                                                item.avatar_picture ?
                                                    <img className="avatar-sm rounded-circle" src={item.avatar_picture} />
                                                    :
                                                    <img className="avatar-sm rounded-circle" src={avatar} />
                                             }
                                        </div>
                                        <div className="ml-2 mr-1"><strong>{item.full_name}</strong></div>
                                    </div>
                                )) :
                                <span></span>
                            }
                             <span className="text-danger">{this.state.errMsg.request_to_id}</span>
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 ">
                            <Button className="btn btn-danger" onClick={this.Owner_modal_toggle}>{t("Cancel")}</Button>
                            <Button className="btn btn-success" width="50px" onClick={() => this.Submit_Owner_Change()}>{t("Confirm")} </Button>{' '}
                           </div>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-dialog" style={{ width: "300px", borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.modal} >
                    <ModalBody color="primary" style={{ height: "250px", borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <i style={{ fontSize: "50px" }} className="fas fa-exclamation-triangle mt-4"></i>
                            <p className="mt-3">{t("Please Confirm To Delete Team")} </p>
                            <p><h5>{this.state.teamInfo.team_name}</h5></p>
                            <p className="mt-4">
                                <Button className="mx-2" width="50px" color="success" onClick={() => this.onDeleteClick()}>{t("Confirm")} </Button>{' '}
                                <Button color="default" onClick={this.toggle}>{t("Cancel")}</Button></p>
                        </div>
                    </ModalBody>
                </Modal>

                <Modal size="md" isOpen={this.state.ChangePicModal} >
                    <ModalHeader toggle={this.ChangePicModal}>Change Picture</ModalHeader>
                    <ModalBody>
                        <div className="col-sm-12 mt-3">
                            <div className="mb-3">
                                <Input
                                    type="file"
                                    onChange={this.handleChangeLogo}
                                    className="form-control"
                                    id="fileinput"
                                />
                                 <span className="text-danger">{this.state.errMsg.icon}</span>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                           <button className="btn btn-danger" onClick={() => this.ChangePicModal()}> Cancel </button>
                           <Button className="btn btn-success" onClick={() => this.iconDataSubmit()}> <i className="fas fa-save mr-1"> </i>Save </Button>
                             </div>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-dialog" style={{ width: "300px" }} isOpen={!!this.state.scsMsg}>
                    <div className="row" style={{ backgroundColor: "#1abc9c", padding: "10px", borderRadius: "4px" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group text-center w-100">
                                    {this.state.scsMsg}
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100">
                            <Button className="btn btn-light" onClick={() => this.setState({ scsMsg: "" })}> {t("Ok")} </Button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(TeamInfos);
