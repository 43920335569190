import React, { Component } from "react";
import { Redirect, Link } from "react-router-dom";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import dateFormat from 'dateformat';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string'


class Index extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            scsMsg:[],
            suMesage:false,
            nonVarifyMesage:false
        };
    }


    componentDidMount() {
         this.paymentCheck();
    }


    paymentCheck() {
        const value = queryString.parse(this.props.location.search);
        const suid = value.suid;
        if(suid)
        {
            const formData = new FormData();
            formData.append('suid', suid)
            axios.post(APIURL + "payment-check", formData)
                .then((response) => {
                    if(response.data.activation_status==0)
                    {
                         this.setState({
                            nonVarifyMesage:true
                       })
                       
                    }
                  
                    this.setState({
                            scsMsg: response.data.message,
                            suMesage:true
                    })
                    var self=this;
                    setTimeout(function(){
                       self.setState({
                            scsMsg: ""
                       })
                    },2000)
                });
        }
       
    }


 
    render() {
        const { t } = this.props;
        
        return (
            <div>
               <header>
                    <nav className="navbar navbar-expand-md navbar-blue fixed-top">
                        <div className="container">
                            <a className="navbar-brand" href="#">
                                <img src={logo_light} alt="logo" height="25" />
                            </a>
                        </div>
                    </nav>
                </header>
                <div className="thankspage">
                    <div>
                        <h1>Thank You !</h1>
                        <p>
                        {this.state.suMesage==true &&
                        "Thank you for purchasing the subscription plan."
                        }
                        <br />
                        {this.state.nonVarifyMesage==true &&
                            "You will receive a confirmation email to verify your profile. Please check your email."
                        }
                        </p>
                    </div>

                    <Link to="/home" className="btn btn-info text-white">Go to the Dashboard</Link>
                </div>
            </div>
        );
    }
}
export default withTranslation()(Index);