import React, { Component } from "react";
import { Button, Form, Label, Input } from "reactstrap";
import axios from "axios";
import Select from 'react-select'
import {APIURL} from './constants/Common';
import { Redirect } from "react-router-dom";
// import "./signup.css";
// import { Link } from "react-router-dom";
import logoDark from '../assets/images/logo-dark.svg';
import queryString from 'query-string'

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/icons.css";
import '../assets/css/Custom.css'

import ErrorPage from "./GeneralComponents/ErrorPage";
import { Trans, withTranslation } from 'react-i18next';

const colourStyles = {

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#B8BCBD" : null,
      color: "grey",

    };
  }
};

class LogonPage extends Component {
  userData;
  constructor(props) {
    super(props);
    this.state = {
      msg: "",
      scsMsg: "",
      startDate: '',
      days: [],
      months: [],
      years: [],
      countries: [],
      countryCode: 0,
      day: 0,
      month: 0,
      year: 0,
      Package_List: [],
      amount: '',
      number_of_teams: '',
      email: "",
      password: "",
      isLoading: false,
      redirect: false,
      errMsgEmail: "",
      errMsgPwd: "",
      errMsg: "",
      errMsgSignUp: "",

      unverified: false,
      showPassword: false,
      modal: false,
      checked: false,
      active: false,
      ScsRegisterModal:false,
      packageId:"",
      license_type_id:"",

      siteid:"",
    };

    this.handleCheck = this.handleCheck.bind(this);
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
  };

  handleCheck(e) {
    // console.log(e.target.checked, this.state)
    this.setState({
      active: e.target.checked,
    })
  }

  handleClick = () => {
    // alert(this.state.showPassword)
    this.setState({ showPassword: this.state.showPassword ? false : true });
  };



  componentDidMount(){
    this.onLogoutHandler()
    this.getParams();
    this.getParamsForAccess();
  }


  resendActivationMail = () => {
    axios
        .post(APIURL + "resend-activation", {
          email: this.state.email,
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.data.status === 200) {
            this.setState({
              scsMsg: response.data.message,
              errMsg: '',
              unverified: false,
            });
          }

          if (response.data.status === "failed") {
            this.setState({
              scsMsg: '',
              msg: response.data.message
            });
          }
        });
  }

  getParams() {
    const value = queryString.parse(this.props.location.search);
    const token = value.account_activation;
    const success = this.props.location;

    if (token) {
      axios
          .get(APIURL + "account-activation/" + token)
          .then((response) => {

            this.setState({ isLoading: false });
            if (response.data.status === 200) {
              this.setState({
                scsMsg: response.data.message
              });
            }

            if (response.data.status === "failed") {
              this.setState({
                scsMsg: '',
                msg: response.data.message
              });
            }

            if (response.data == "") {
              this.setState({
                msg: "Der Account wurde bereits aktiviert oder der Aktivierungs-Link ist nicht korrekt."
              });
            }
          });
    }

    if (success.state !== undefined) {
      if (success.state.success === 'registered') {
        this.setState({
          scsMsg: 'Your account is created. Please check your mail for the activation link!'
        });
      }
      if (success.state.success === 'reset') {
        this.setState({
          scsMsg: 'Your account password is changed!'
        });
      }
    }
  }


  getParamsForAccess() {
    const value = queryString.parse(this.props.location.search);
    const token = value.profile_access_token;
    if (token) {
      axios
          .get(APIURL + "user/accept_allow_access/" + token)
          .then((response) => {
            this.setState({
              scsMsg: response.data.message
            });
          })
          .catch((error) => {
            this.setState({
              msg: "Das Recht wurde bereits gesetzt oder der Link ist nicht korrekt."
            });
          });
    }
  }

  lsRememberMe(check) {
    if (check) {
      localStorage.username = this.state.email;
      localStorage.password = this.state.password;
      localStorage.checkbox = true;
      // localStorage.checkbox = rmCheck.value;
    } else {
      localStorage.username = "";
      localStorage.password = "";
      localStorage.checkbox = false;
    }
  }

  onSignInHandler = (event) => {
    event.preventDefault();
    this.setState({ isLoading: true, unverified: false });
    const check = this.state.active;
    this.lsRememberMe(check);
    axios
        .post(APIURL + "user-login", {
          email: this.state.email,
          password: this.state.password,
          platform:"dd",
          uuid:"dd",
          version:"sdsd",
          fcm_token:"sdsds"
        })
        .then((response) => {
          this.setState({ isLoading: false });
          if (response.data.status === 200) {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("userData", JSON.stringify(response.data.data));
            localStorage.setItem("token", JSON.stringify(response.data.token));
            this.setExpired(check);
            // const check = localStorage.getItem("checkbox");
            if (check) {
              localStorage.setItem("username", this.state.email);
              localStorage.setItem("password", this.state.password);
            }
            else {
              localStorage.removeItem("username");
              localStorage.removeItem("password");
            }
            this.setState({
              msg: response.data.message,
              redirect: true,
            });
            document.body.classList.remove('front-end')
          }
          if (
              response.data.status === "failed" &&
              response.data.success === undefined
          ) {
            this.setState({
              scsMsg: '',
              errMsg: '',
              errMsgEmail: response.data.errors.email,
              errMsgPwd: response.data.errors.password,
            });
            setTimeout(() => {
              this.setState({ errMsgEmail: "", errMsgPwd: "" });
            }, 30000);
          } else if (
              response.data.status === "failed" &&
              response.data.success === false
          ) {
            if (response.data.unverified) {
              this.setState({
                unverified: true
              })
            }
            this.setState({
              scsMsg: '',
              errMsg: response.data.message,
              errMsgEmail: '',
              errMsgPwd: '',
            });
            setTimeout(() => {
              this.setState({ errMsg: "" });
            }, 30000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  setExpired(rememberMe)
  {
    const currentDateTime = new Date();
    const updateDateTime = new Date();
    const expireDateTime = new Date(updateDateTime.setMinutes(updateDateTime.getMinutes() + 59));
    const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
    const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);

    const initialState = {
      rememberMe: rememberMe,
      loginTime: currentTimestamp,
      expirationTime: expireTimeStamp,
    };
    localStorage.setItem("session_login", JSON.stringify(initialState));
  }


  onLogoutHandler() {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("switchUser");
    //    const check = localStorage.getItem("checkbox");
    // if (check === 'false' || check === false)
    localStorage.removeItem("checkbox");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("session_login");
    this.setState({
      navigate: true,
    });
  }

  onAlertClose = () => {
      console.log("CLOSE");
    this.setState({
      errMsg: "",
      scsMsg: "",
        msg: "",
    });
  };


  render() {
    const { t } = this.props;
    const login = localStorage.getItem("isLoggedIn");
    const query = queryString.parse(this.props.location.search);
    const token = query.team_member_invitation;
    const tokenTeamOwnerChange = query.team_owner_change;
    const siteid = window.location.pathname;

    if (login && token != undefined) {
      return <Redirect to="/invitaion" />;
    }
    else if (login && tokenTeamOwnerChange != undefined) {
        return <Redirect to="/team_request" />;
    }
    else if (login || this.state.redirect) {
      return <Redirect to="/home" />;
    }

    return (
      <>
        { this.state.scsMsg ?
        <div className="alert alert-success msg-show msg-stripe" role="alert">
          <strong></strong>{this.state.scsMsg}
          <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
        </div>
          : ""
        }
        { this.state.msg ?
            <div className="alert msg-stripe alert-danger msg-show" role="alert">
          <strong></strong>{this.state.msg}
          <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
        </div>
            : ""
        }
        { this.state.errMsg ?
            <div className="alert msg-stripe alert-danger msg-show" role="alert">
              <strong></strong>{this.state.errMsg}
              <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
            </div>
            : ""
        }

            <div class="account-pages mt-5 mb-5">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-8 col-lg-6 col-xl-4">
                    <div class="card">

                      <div class="card-body p-4">

                        <div class="text-center m-auto">
                          <div class="auth-logo">


                            <a href="/" className="logo logo-light text-center">
                                            <span class="logo-lg">
                                                <img src={logoDark} alt="Dark logo" height="35"/>
                                            </span>
                            </a>
                          </div>
                        </div>
                        <p className=" mb-4"></p>
                        <h4>{t("Lets organize - Login")}</h4>
                        <p className=" mb-4"></p>
                        <form action="#"  onSubmit={this.onSignInHandler} >

                          {
                            this.state.errMsg ?
                                <div className={this.state.errMsg || this.state.scsMsg ? "mb-2  text-danger Login_error" : "mb-2  text-danger"} >
                                  {t(this.state.errMsg)}
                                  {this.state.unverified ?
                                      <a className="text-primary font-weight-bold " onClick={this.resendActivationMail} href="javascript:;">Resend Verification Link</a>
                                      : ''
                                  }
                                </div>
                                : ""
                          }
                          {
                            this.state.scsMsg ?
                                <div className={this.state.errMsg || this.state.scsMsg ? "mb-2  text-primary Login_error" : "mb-2  text-danger"} >
                                  {t(this.state.scsMsg)}
                                </div>
                                : ""
                          }
                          <div className="row form-group">
                            <div className="col-md-12">
                              {/* <input
                                                            type="text"
                                                            id="name"
                                                            className="form-control form-control-login"
                                                            placeholder="Benutzername"
                                                            /> */}
                              <Input
                                  className="form-control form-control-login"
                                  required=""
                                  name="email"
                                  autoComplete="new-password"
                                  placeholder={t("Enter Email Address")}
                                  value={this.state.email}
                                  onChange={this.onChangehandler}
                              />
                              <span className="text-danger">{t(this.state.errMsgEmail)}</span>
                            </div>
                          </div>

                          <div className="mb-3">
                            <div className="input-group input-group-merge">
                              <Input
                                  className="form-control form-control-login"
                                  type={this.state.showPassword ? "text" : "password"}
                                  name="password"
                                  autoComplete="new-password"
                                  placeholder={t("Enter password")}
                                  value={this.state.password}
                                  onChange={this.onChangehandler}
                              />
                              <div className={`${this.state.showPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                                <a className="input-group-text form-control-login">
                                  <span className="password-eye" onClick={this.handleClick}></span>
                                </a>
                              </div>
                            </div>
                            <span className="text-danger">{t(this.state.errMsgPwd)}</span>
                          </div>
                          <a href="forgot_password" className=" float-end"><small>{t("Forgot password")}?</small></a>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  value="lsRememberMe"
                                  id="rememberMe"
                                  onChange={this.handleCheck}
                                  checked={this.state.active}
                              />
                              <label htmlFor="flexCheckChecked" className=""> &nbsp; {t("Remain signed in")}</label>
                            </div>
                          </div>

                          <div className="row form-group">
                            <div className="col-md-12">
                              {/*<Button
                                                                className="btn btn-warning btn-block"
                                                                onClick={this.onSignInHandler}
                                                            >*/}

                              {/* {this.state.isLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            ) : (
                                                            <span></span>
                                                            )} */}
                              {/*  </Button>*/}
                              <input type="submit"  className="btn btn-primary btn-block" value={t("Login")} />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </>
    );
  }
}

export default withTranslation()(LogonPage);
