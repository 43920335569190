import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import Sidebar from "../GeneralComponents/Sidebar";
import axios from "axios";
import { APIURL } from '../constants/Common';
import avatar from "../../assets/images/avatar.png"
import NodataImg from "../../assets/images/no.png"
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import dateFormat from 'dateformat';
import Footer from '../GeneralComponents/Footer'
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { withTranslation } from 'react-i18next';
import { Global, css } from "@emotion/core";

const editorConfigurationView = {
    toolbar: [

    ],
    ckfinder: {


    },
};
class News_list extends Component {
    constructor() {
        super()
        this.state = {

            team_name: "",
            NewsData: [],
            navigate: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),
            Show: false,
            NewsModal: false,
            SubscriptionModal: false,
            subscriptionPlan: "No",
            is_owner: "No",
            day: 0,
            ActiveTeam: 0,
            isPermission: 1,
            no_record: false,
            newsContent: "",
            newsName: ""

        };
    }

    NewsModal = (e) => {
        this.setState({
            NewsModal: !this.state.NewsModal,
            newsContent: e
        })
    }

    getNewsData() {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);

        var token = this.state.token
        axios
            .post(APIURL + "news/get-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                this.setState({
                    NewsData: response.data.data,
                    no_record: true
                });
                var Data = response.data.data.filter(i => i.subject == this.props.location.state.NewsInfo.subject).map(item => {
                    this.setState({
                        NewsModal: !this.state.NewsModal,
                        newsContent: item.readmore,
                        newsName: item.subject
                    })
                })

            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false,
                    no_record: true
                })
            );
    }

    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })
            });
    }

    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.getTeamDetails();
            this.getNewsData()
            this.getExpirationPlanMsg()
        }
    }

    readmore = (idx, value) => {
        if (this.state.ActiveTeam === 0) {
            this.CloseModal()
            return false;
        }
        this.setState({
            NewsModal: !this.state.NewsModal,
            newsContent: value.readmore,
            newsName: value.subject
        })

    }

    getExpirationPlanMsg = () => {
        console.log(this.state.user.user_id)
        const formData = new FormData();
        let user_id = this.state.user.user_id;
        if (this.state.switchUser != null) {
            user_id = this.state.switchUser.user_id;
        }
        formData.append('user_id', user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }
                /* else if(response.data.member.length==1)
                 {
                     if(response.data.member[0].is_admin==0 && response.data.member[0].is_teamleader==0)
                     {
                          isPermission=0;
                     }
                 }*/

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission
                    // ActiveTeam:1
                })
            });
    }


    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    render() {
        const { t } = this.props;
        console.log("News pending", this.props.location.visible)
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }

        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }

        return (
            <div id="wrapper">
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "news_page",
                        visible: this.props.location.visible
                    }} />

                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                <h6 className="mt-3">Upgrade Package.</h6>
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}> Cancel </Button>
                            {this.state.is_owner === "Yes" ? <Button className="btn btn-success me-3" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}

                        </div>
                    </ModalFooter>
                </Modal>
                <div className="content-page">
                    <div className="content">
                        <div className="container-fluid">

                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{this.state.team_name} | News</h4>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="">
                                        <form className="form-horizontal" role="form">
                                            {
                                                this.state.NewsData.length > 0 &&
                                                this.state.NewsData.map((item, idx) => (
                                                    <div className="card mt-2 bg-blue">
                                                        <div className="card-body">
                                                            <div className="row">
                                                                <div className="col-lg-12">
                                                                    <div className="d-flex">
                                                                        <div className="">
                                                                            <div style={{ width: "70px" }}>
                                                                                {
                                                                                    item.avatar ?
                                                                                        <div className="avatar-md rounded-circle mr-1">
                                                                                            <img title={item.author} className="avatar-img" src={item.avatar} />
                                                                                        </div>
                                                                                        :
                                                                                        <div className="avatar-md rounded-circle mr-1">
                                                                                            <img title={item.author} className="avatar-img" src={avatar} />
                                                                                        </div>
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className="">
                                                                            <div htmlFor="example-date">
                                                                                <strong>{item.subject}</strong>
                                                                            </div>
                                                                            <div>
                                                                                <span className="mt-1" style={{ fontSize: "12px" }} htmlFor="example-date">
                                                                                    {item.publish_date.split(' ')[0].split('-')[2]}.{item.publish_date.split(' ')[0].split('-')[1]}.{item.publish_date.split(' ')[0].split('-')[0]} | {item.publish_time.split(':')[0]}:{item.publish_time.split(':')[1]}
                                                                                    , {item.author} </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col" >
                                                                    <Global
                                                                        styles={css`
                                                                            :root {
                                                                                p {
                                                                                    margin :  0;
                                                                                    padding : 0;
                                                                                    font-family: sans-serif;
                                                                                    font-size: small;
                                                                                    }
                                                                            }
                                                                            .min_ck .ck-editor__editable{
                                                                                min-height:40px;
                                                                                overflow: hidden;
                                                                                text-overflow: ellipsis;
                                                                                -webkit-box-orient: vertical;
                                                                                display: -webkit-box;
                                                                                position: relative;
                                                                                -webkit-line-clamp: 2;
                                                                            }
                                                                            .min_ck .ck-editor__editable i {
                                                                                vertical-align: baseline !important;
                                                                            }
                                                                            .ck.ck-editor__editable_inline {
                                                                            padding:0;
                                                                            }
                                                                            .ck.ck-editor__editable_inline>:first-child {
                                                                                margin-top:0;
                                                                            }
                                                                           
                                                                `}
                                                                    />
                                                                    {item.readmore ?
                                                                        <div>
                                                                            {item.more === "" ? <div className="" style={{ marginTop: "21px" }}>
                                                                                <span
                                                                                    style={{ color: "#fff" }}
                                                                                    className="ck_view min_ck"
                                                                                    onClick={(e) => this.readmore(idx, item)}>
                                                                                    <CKEditor
                                                                                        editor={Editor}
                                                                                        config={editorConfigurationView}
                                                                                        disabled={true}
                                                                                        data={item.content}
                                                                                        onReady={editor => {
                                                                                            // You can store the "editor" and use when it is needed.
                                                                                            console.log('Editor is ready to use!', editor);
                                                                                        }}

                                                                                        onBlur={(event, editor) => {
                                                                                            console.log('Blur.', editor);
                                                                                        }}
                                                                                        onFocus={(event, editor) => {
                                                                                            console.log('Focus.', editor);
                                                                                        }}
                                                                                    />
                                                                                    {/* <Link><span className="btn btn-link pl-0 fw-bolder" style={{ color: "#f39330" }}>{t("Read more")}</span></Link> */}
                                                                                    <span className="btn btn-link pl-0 fw-bolder" style={{ color: "#f39330" }}>{t("Read more")}</span>

                                                                                </span>
                                                                            </div> : ""}

                                                                        </div>
                                                                        :
                                                                        <div className="mt-3 image1" dangerouslySetInnerHTML={{ __html: item.content }} ></div>
                                                                    }
                                                                    {item.more !== "" ? item.readmore ? <span >

                                                                        <Button className="btn btn-link" onClick={() => this.readmore(idx, "")}>{t("Read less")}</Button>
                                                                    </span> : "" : ""}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}

                                            {this.state.no_record == true && this.state.NewsData.length == 0 &&

                                            <div className="card mt-2">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-12 mt-1 ml-1">
                                                            <div className="fw-medium mt-2" >
                                                                <p className="col-md-4" htmlFor="example-date">{t("No news available at the moment")}</p>
                                                            </div>
                                                            <div className="mb-4 mt-2">
                                                                <img className="" height="40px" src={NodataImg} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            }
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
                <Modal size="lg" isOpen={this.state.NewsModal} toggle={() => this.NewsModal('')} autoFocus={false}>
                    <ModalHeader toggle={() => this.NewsModal('')}>{this.state.newsName}</ModalHeader>
                    {/* <ModalBody className="ck_view" style={{ maxHeight: "65vh", overflow: "auto", }} > */}
                    {/* <span 
                        dangerouslySetInnerHTML={{ __html: this.state.newsContent }}
                        >
                        </span> */}
                    {/* <CKEditor
                            editor={Editor}
                            config={editorConfigurationView}
                            data={this.state.newsContent}
                            disabled={true}
                        /> */}
                    {/* </ModalBody> */}
                    <ModalBody className="ck_view" style={{ maxHeight: "65vh", overflow: "auto", }}>
                        <div className="form-group">
                            <CKEditor
                                editor={Editor}
                                config={editorConfigurationView}
                                disabled={true}
                                data={this.state.newsContent}
                            />
                        </div>
                    </ModalBody>
                    <ModalFooter >
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.NewsModal('')}> {t("Close")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default withTranslation()(News_list);