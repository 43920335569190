import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import '../../assets/css/Custom.css'
import no_img from '../../assets/images/no_data_found.svg'

import check from '../../assets/images/check_white.svg'
import close from '../../assets/images/close_check.svg'
import circle from '../../assets/images/circle_white.svg'
import question from '../../assets/images/question.svg'
import comment from '../../assets/images/comment_white.svg'
import user from '../../assets/images/user_white.svg'

import Sidebar from "../GeneralComponents/Sidebar";
import { APIURL, APIDOMAIN } from '../constants/Common';
import axios from "axios";
import { Input, Button, Modal, ModalBody, ModalFooter, ModalHeader, Label,UncontrolledTooltip } from 'reactstrap';
import dateFormat from 'dateformat';
import IcalExport from '../Regi-deregistration/Ical/IcalExport'
import Details from '../Regi-deregistration/Details'
import TaskDetails from '../Regi-deregistration/TaskDetails'
import Footer from '../GeneralComponents/Footer'
import { Trans, withTranslation } from 'react-i18next';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import { Global, css } from "@emotion/core";
import NodataImg from "../../assets/images/no.png";


const editorConfigurationView = {
    toolbar: [

    ],
    ckfinder: {


    },
    readOnly: true
};

class Index extends Component {
    constructor(props) {
        super(props)
        this.myInput = React.createRef()
        this.myInputHeight = React.createRef()
        this.myInputHeight2 = React.createRef()
        this.myTable = React.createRef()
        this.myScrollCheck = React.createRef()
        this.myNextEvent = React.createRef()
        this.theadRef = React.createRef()

        this.state = {
            sidebarClass: "sidebar-disable",
            navigate: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            switchUser: JSON.parse(localStorage.getItem("switchUser")),
            teamId: this.props.match.params.id,
            SubscriptionModal: false,
            subscriptionPlan: "No",
            is_owner: "No",
            day: 0,
            ActiveTeam: 0,
            isPermission: 1,
            update_Subscription: false,
            NewsData: [],
            latestEvent: [],
            latestEventStatus: 0,
            statusArray: ['bg-blue', 'bg-success', 'bg-danger', 'bg-warning'],
            task_list: [],
            func_list: [],
            event_list: [],
            member_list: [],
            member_is_admin: 0,
            modal: false,
            comment_answer_option: [],
            comment_function_id: 0,
            comment_user_id: 0,
            comment_event_id: 0,
            comment_answer: "",
            comment_guests: "",
            comment_event_name: "",
            comment_event_date: "",
            comment_guest_allowed: "",
            comment: "",
            errMsg: [],
            event_index: 0,
            event_date_task: [],
            commnetviewmodal: false,
            taskviewmodal: false,
            detail_task_id: 0,
            detail_event_id: 0,
            detailsTask: [],
            eventmodal: false,
            eventDetails: "",
            team_name: "",
            takenbyothermodal: false,
            commentModalOkClick: false,
            norecord: false,
            norecordEvent: false,
            fixedHeader: "",
            lefttr: 0,
            leftcss: "auto",
            topcss: "auto",
            widthcss: "100%",
            user_swift_id: 0,
            isLoading:false,
            theadHeight: "auto",
        };

        this.CloseEventModal = this.CloseEventModal.bind(this);

    }

    componentDidMount() {
        if (document.body.classList.contains(this.state.sidebarClass)) {
            this.setState({
                SidebarClosed: true
            })
        }
        if (this.props.match.params.id != "undefined") {
            this.getExpirationPlanMsg();
            this.getNewsData();
            this.getEvent();
            this.getTasks();
            this.getFunctions();
            this.getMemberInfo();
            this.getTeamDetails();
            window.addEventListener('scroll', this.handleScroll);
        }

    }


    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }
    componentWillReceiveProps() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScrollTop = (event) => {
        window.scrollTo(0, 0)
    }

    handleScroll = (event) => {
        let scrollTop = window.pageYOffset;
        let myScrollCheckwidth = this.myScrollCheck.current.offsetLeft
        let nextEventHieght = 115;

        if (window.innerWidth > 575) {
            if ((this.state.latestEvent.length || this.state.NewsData.length) && this.myInputHeight.current) {
                nextEventHieght = this.myInputHeight.current.offsetHeight + 115;
            }
        } else {
            if (this.state.latestEvent.length && this.state.NewsData.length && this.myInputHeight.current) {
                nextEventHieght = this.myInputHeight.current.offsetHeight + this.myInputHeight2.current.offsetHeight + 115;
            } else if (this.state.latestEvent.length) {
                nextEventHieght = this.myInputHeight.current.offsetHeight + 115;
            } else if (this.state.NewsData.length) {
                nextEventHieght = this.myInputHeight2.current.offsetHeight + 115;
            }
        }

        if (scrollTop >= nextEventHieght) {
            if (this.myInputHeight.current && this.myScrollCheck.current.offsetLeft < 150) {
                myScrollCheckwidth = (window.width * 2.5 / 100) + 84;
            }
            this.setState({
                fixedHeader: "fixed-header",
                leftcss: myScrollCheckwidth,
                topcss: 72,
                widthcss: this.myInput.current.offsetWidth - 25
            })

        }
        else {
            this.setState({
                fixedHeader: "",
                leftcss: "auto",
                topcss: "auto",
                widthcss: "100%",
                theadHeight: this.theadRef.current?.offsetHeight,
            })

        }
    }

    handleLeftRightScroll = () => {
        this.setState({
            lefttr: -(this.myScrollCheck.current.scrollLeft)
        })
    }



    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };

    getExpirationPlanMsg = () => {
        const formData = new FormData();
        let user_id = 0;
        if (this.state.user != null) {
            user_id = this.state.user.user_id;
        }
        if (this.state.switchUser != null) {
            user_id = this.state.switchUser.user_id;
        }

        this.setState({
            user_swift_id: user_id
        });

        formData.append('user_id', user_id);

        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                let days = response.data.after_days;
                let Owner = response.data.is_owner;

                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner == "Yes" && days > 0) || (Owner == "No" && days > 14)) {
                    Sub_true = true;
                }
                // for guest
                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission
                    // ActiveTeam:1
                })
            });
    }

    getNewsData() {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "dasboard/get-list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {

                this.setState({
                    NewsData: response.data.data,
                    latestEvent: response.data.event,
                    latestEventStatus: response.data.event_status,
                    norecord: true,
                });


            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    Sub_CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });

    }

    getEvent() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "re-deregistration", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ event_list: response.data.data, norecordEvent: true });
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    getTasks() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "event/get_used_tasks", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ task_list: response.data.data });
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }
    getFunctions() {
        var token = this.state.token
        axios
            .get(APIURL + "get_active_functions/" + this.state.teamId, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.status == 200) {
                    this.setState({
                        func_list: response.data.data
                    })
                }
            });
    }
    getMemberInfo() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "membership/get-active", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.setState({ member_list: response.data.data });

                if (response.data.data.length > 0 && response.data.data.filter(person => person.is_admin == 1 && person.user_id == this.state.user_swift_id).length > 0) {
                    this.setState({ member_is_admin: 1 });

                }
            });
    }
    taskDetails(event_id, task_id, index) {
        this.setState({
            taskviewmodal: !this.state.taskviewmodal,
            detail_task_id: task_id,
            detail_event_id: event_id,
            detailsTask: this.state.event_list[index].comment_tasks

        })
    }
    CloseModal() {
        this.setState({
            modal: !this.state.modal
        })
    }
    CloseCommnetViewModal() {
        this.setState({
            commnetviewmodal: !this.state.commnetviewmodal
        })
    }
    CloseTaskViewModal() {
        this.setState({
            taskviewmodal: !this.state.taskviewmodal
        })
    }
    handle_Comment(e) {
        this.setState({
            comment: e.target.value
        })
    }
    handle_answerComment(e) {
        this.setState({
            comment_answer: e
        })
    }
    handle_taskComment(e, index) {
        let event_date_task = this.state.event_date_task
        event_date_task[index]['task_value'] = e;
        if (e != "") {
            event_date_task[index]['checked'] = 1;
        }
        this.setState({
            event_date_task: event_date_task
        })

    }
    handle_taskCheckedOnComment(e, index) {
        let event_date_task = this.state.event_date_task
        if (e == true) {
            event_date_task[index]['checked'] = 1;
        }
        else {
            event_date_task[index]['task_value'] = "";
            event_date_task[index]['checked'] = 0;
        }

        this.setState({
            event_date_task: event_date_task
        })
    }
    handle_reminderDays(e) {
        this.setState({
            reminder_days: e.target.value
        })
    }
    handle_isReceiveEmail(e) {
        this.setState({
            is_receive_email: e.target.checked
        })
    }
    handle_isInformedEmail(e) {
        this.setState({
            is_informed_email: e.target.checked
        })
    }
    handle_Guest(e) {
        this.setState({
            comment_guests: e.target.value
        })
    }
    commentDetails(comment) {
        this.setState({ comment: comment }, () => {
            this.CloseCommnetViewModal();
        });

    }
    CloseEventModal() {
        this.setState({
            eventmodal: !this.state.eventmodal
        })
    }
    eventDetails(result) {
        this.setState({ eventDetails: result }, () => {

            this.CloseEventModal();
        });
    }

    takenbyothermodal = () => {
        this.setState({
            takenbyothermodal: !this.state.takenbyothermodal
        })
    }


    commentModal(function_id, user_id, e, index) {

        let getAllowd = 0;
        e.event_function.filter(person => person.function_id == function_id).map((result, index) => {
            getAllowd = result.guest_allowed;
        });
        this.setState({
            comment_answer_option: e.answer_option,
            comment_function_id: function_id,
            comment_user_id: user_id,
            comment_event_id: e.event_id,
            comment_event_name: e.name,
            comment_event_date: e.date,
            comment_guest_allowed: getAllowd,
            event_index: index,
            comment_answer: "",
            comment_guests: "",
            comment: "",
            is_receive_email: false,
            reminder_days: "",
            is_informed_email: false,
            errMsg: [],
        })

        const formData = new FormData();
        formData.append('event_id', e.event_id);
        formData.append('function_id', function_id);
        formData.append('user_id', user_id);
        var token = this.state.token
        axios
            .post(APIURL + "comment/edit", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.max == true) {
                    if (response.data.flag) {

                        this.setState({
                            comment_answer: response.data.data.answer_id,
                            comment_guests: response.data.data.guests,
                            comment: response.data.data.comment,
                            is_receive_email: response.data.data.is_receive_email,
                            reminder_days: response.data.data.reminder_days,
                            is_informed_email: response.data.data.is_informed_email,

                        })
                    }
                    this.setState({
                        event_date_task: response.data.task
                    });
                    this.CloseModal();
                }
                else {

                    this.takenbyothermodal();
                }
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }


    commentModalOkClick() {
        this.setState({
            commentModalOkClick: false
        })
        this.onSubmit();
    }
    commentModalCancleClick() {
        this.setState({
            commentModalOkClick: false
        })

    }


    beforOnSubmit() {
        if (this.state.comment_answer != "") {


            this.state.comment_answer_option.filter(person => person.answer_id == this.state.comment_answer).map((m_result, m_index) => {

                if (m_result.answers.state == 2 && this.state.event_date_task.filter(person => person.checked == 1).length > 0) {
                    this.setState({
                        commentModalOkClick: true
                    })

                }
                else {
                    this.onSubmit();
                }
            });
        }
        else {
            this.onSubmit();
        }
    }
    onSubmit() {
        this.setState({
            isLoading: true
        })

        const formData = new FormData();
        formData.append('event_id', this.state.comment_event_id);
        formData.append('function_id', this.state.comment_function_id);
        formData.append('user_id', this.state.comment_user_id);
        formData.append('answer_id', this.state.comment_answer);
        formData.append('guests', this.state.comment_guests);
        formData.append('comment', this.state.comment);
        formData.append('is_receive_email', this.state.is_receive_email);
        formData.append('reminder_days', this.state.reminder_days);
        formData.append('is_informed_email', this.state.is_informed_email);
        formData.append('event_date_task', JSON.stringify(this.state.event_date_task));
        var token = this.state.token

        axios
            .post(APIURL + "comment/update", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let event_list = this.state.event_list; let flag = 0;
                let maplist = event_list[this.state.event_index].event_comment;
                maplist.map((result, index) => {
                    if (result.id == response.data.data[0].id) {
                        flag = 1;
                        event_list[this.state.event_index].event_comment[index] = response.data.data[0];
                    }
                })
                if (flag == 0) {
                    maplist.push(response.data.data[0]);
                    event_list[this.state.event_index].event_comment = maplist;
                }


                event_list[this.state.event_index].comment_tasks = response.data.comment_tasks;
                this.setState({
                    event_list: event_list,
                    isLoading: false
                })
                this.CloseModal();
                this.getNewsData();
            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }
            }
            );
    }
    showTotal(e) {
        let total = 0;
        let member_list = this.state.member_list;
        let func_list = this.state.func_list;
        e.map(function (functions) {
            if (func_list.filter(person => person.function_id == functions.function_id).length) {
                var member_lists = member_list.filter(person => person.function_id == functions.function_id).length;
                if (functions.max_registrations == 0) {
                    total += member_lists;
                }
                else {
                    total += functions.max_registrations;
                }
            }
        });
        return total;
    }

    SubTotalUper(e_result) {
        let total = 0;
        let func_list = this.state.func_list;
        e_result.event_comment.filter(person => person.event_id == e_result.event_id).map((ttl, index) => {

            if (func_list.filter(person => person.function_id == ttl.function_id).length) {
                if (ttl.guests != "null") {
                    total += Number(ttl.guests);
                }
                if (ttl.answer.state != 2 && ttl.answer.state != 3) {
                    total += 1;
                }
            }

        });
        return total;
    }

    showSubTotal(e_result, result) {
        let total = 0;
        e_result.event_comment.filter(person => person.function_id == result.function_id && person.event_id == e_result.event_id).map((ttl, index) => {

            if (ttl.guests != "null") {
                total += Number(ttl.guests);
            }
            if (ttl.answer.state != 2 && ttl.answer.state != 3) {
                total += 1;
            }


        });
        return total;
    }

    urlToOpen(lnk) {
        window.open(lnk, '_blank');
    }
    goToSubscription() {
        this.setState({
            update_Subscription: true
        })
    }
    modalToggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    render() {
        const { t } = this.props;

        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
        if (this.state.update_Subscription) {
            return <Redirect to="/upgrade_package" push={true} />;
        }

        if (this.state.navigate) {
            return <Redirect to="/login" push={true} />;
        }

        return (
            <div id="wrapper">

                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        SidebarClosed: this.state.SidebarClosed,
                        ActivePage: "regi_deregistration_dashboard",
                        handleScrollTop: this.handleScrollTop.bind(this),
                        visible:this.props.location.visible
                    }} />
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>

                            {
                                this.state.subscriptionPlan == "No" && this.state.is_owner == "Yes" &&
                                <h6 className="mt-3">Upgrade Package.</h6>
                            }
                            {
                                this.state.subscriptionPlan == "No" && this.state.is_owner == "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                            }
                            {
                                this.state.is_owner == "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner == "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.Sub_CloseModal()}>{t("Cancel")} </Button>
                            {this.state.is_owner == "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
                        </div>
                    </ModalFooter>
                </Modal>
                <div className="content-page">
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{this.state.team_name} | {t("Dashboard")}</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="row">
                                {this.state.latestEvent.length > 0 &&
                                    <div className="col-lg-5 col-sm-6" ref={this.myInputHeight}>
                                        <div className="card card-event" >
                                            <div className="card-body" >
                                                <h4 className="header-title mb-3 text-capitalize" >{t('Next event')}</h4>
                                                {this.state.latestEvent.length > 0 && this.state.latestEvent.map((item, idx) => (

                                                    <div className={this.state.statusArray[this.state.latestEventStatus]} style={{ borderRadius: ".25rem" }}>
                                                        {/* <div className="card bg-blue"> */}
                                                        <div className="card-body" >
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <h6><span>{dateFormat(item.date, "dd.mm.yyyy")} </span>|<span> {item.time_from.split(':')[0]}:{item.time_from.split(':')[1]}</span></h6>
                                                                    <h6>{item.name}</h6>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <ul className="event_highlight">
                                                                        <li>
                                                                            <i className="mdi mdi-map-marker text-white"></i>
                                                                            <span>{item.place}</span>
                                                                        </li>
                                                                        <li>
                                                                            <i className="mdi mdi-clock text-white"></i>
                                                                            <span className="text-lowercase">{item.time_from} to {item.time_to}</span>
                                                                        </li>
                                                                        {item.info ?
                                                                            <li style={{ width: '100%' }}>
                                                                                <i className="mdi mdi-alert-circle text-white"></i>
                                                                                <span>{item.info}</span>
                                                                            </li>
                                                                            : ""
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            {/* </div> */}
                                                        </div>
                                                    </div>

                                                ))}

                                                {this.state.norecord == true && this.state.latestEvent.length < 1 &&
                                                    <div className="nodata">
                                                        <img src={no_img} />
                                                        <div>{t('No record found')}</div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                                {this.state.NewsData.length > 0 &&
                                    <div className="col-lg-5 col-sm-6" ref={this.myInputHeight2}>
                                        <div className="card card-news">
                                            <div className="card-body">
                                                <h4 className="header-title mb-3 text-capitalize">{t('Latest news')}</h4>
                                                <div>
                                                    {this.state.NewsData.length > 0 && this.state.NewsData.map((item, idx) => (
                                                        <div className="card bg-blue">
                                                            <div className="card-body">
                                                                <div>
                                                                    <h6><span>{item.publish_date.split(' ')[0].split('-')[2]}.{item.publish_date.split(' ')[0].split('-')[1]}.{item.publish_date.split(' ')[0].split('-')[0]} </span>
                                                                        |<span> {item.publish_time.split(':')[0]}:{item.publish_time.split(':')[1]}
                                                                        </span></h6>

                                                                    <h6 className="mb-1">{item.subject}</h6>
                                                                    <p style={{ fontFamily: 'sans-serif' }}><div className="col">
                                                                        {/* <span
                                                                            dangerouslySetInnerHTML={{ __html: item.content }}
                                                                        >
                                                                        </span> */}
                                                                        <Global
                                                                            styles={css`
                                                                            :root {
                                                                                p {
                                                                                    margin :  0;
                                                                                    padding : 0;
                                                                                    }
                                                                            }
                                                                            .ck.ck-editor__editable_inline {
                                                                            padding:0;
                                                                            }
                                                                            .ck.ck-editor__editable_inline>:first-child {
                                                                                margin-top:0;
                                                                            }
                                                                            .ck .ck-link_selected {
                                                                                text-transform:lowercase
                                                                            }
                                                                `}
                                                                        />
                                                                        {item.readmore ?
                                                                            <div>
                                                                                {item.more == "" ? <div className="" >
                                                                                    <span
                                                                                        style={{ color: "#fff" }}
                                                                                        className="ck_view min_ck 1">
                                                                                        <CKEditor
                                                                                            editor={Editor}
                                                                                            config={editorConfigurationView}
                                                                                            disabled={true}
                                                                                            data={item.content}
                                                                                            onReady={editor => {
                                                                                                // You can store the "editor" and use when it is needed.
                                                                                                console.log('Editor is ready to use!', editor);
                                                                                            }}

                                                                                            onBlur={(event, editor) => {
                                                                                                console.log('Blur.', editor);
                                                                                            }}
                                                                                            onFocus={(event, editor) => {
                                                                                                console.log('Focus.', editor);
                                                                                            }}
                                                                                        />



                                                                                    </span>
                                                                                </div> : ""}

                                                                            </div>
                                                                            :
                                                                            <div className="mt-3 ck_view min_ck 2" >
                                                                                <CKEditor
                                                                                    editor={Editor}
                                                                                    config={editorConfigurationView}
                                                                                    disabled={true}
                                                                                    data={item.content}
                                                                                    onReady={editor => {
                                                                                        // You can store the "editor" and use when it is needed.
                                                                                        console.log('Editor is ready to use!', editor);
                                                                                    }}


                                                                                    onBlur={(event, editor) => {
                                                                                        console.log('Blur.', editor);
                                                                                    }}
                                                                                    onFocus={(event, editor) => {
                                                                                        console.log('Focus.', editor);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                        <Link className="text-capitalize" to={{ pathname: `/team_news/${this.props.match.params.id}`, state: { fromDashboard: true, NewsInfo: item } }} stddate="hiii">{t('Go to news')}</Link>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    {this.state.norecord == true && this.state.NewsData.length < 1 &&
                                                        <div className="nodata">
                                                            <img src={no_img} />
                                                            <div>{t('No record found')}</div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>

                            <div className="row">
                                <div className="col-12 scrollhdiebg" ref={this.myInput}>
                                    {/* <h5>Events</h5> */}
                                    {/*<div className="table-horizontal-wrapper  w-auto header-fix">*/}
                                    <div onScroll={this.handleLeftRightScroll} ref={this.myScrollCheck} className="table-horizontal-wrapper scroll_check">
                                        <table ref={this.myTable} id="table" className="table w-auto table-borderless">
                                            {/*<thead className="table-head first-header-fix">*/}
                                            <div style={{height: this.state.theadHeight, display: this.state.fixedHeader ? "block" : "none"}} />
                                            <thead ref={this.theadRef} style={{ left: this.state.leftcss, top: this.state.topcss, width: this.state.widthcss }} className={"sticky-header scroll_check " + this.state.fixedHeader}>
                                                <tr style={{ left: this.state.lefttr }}>
                                                    {this.state.event_list.length > 0 &&
                                                        <th className="sticky-col pad first-col head_strip">
                                                            <div className="anab1 anab-height">
                                                                <span className="top">{t('Events')}</span>
                                                                <span className="bottom">{t('Members')}</span>
                                                            </div>
                                                        </th>
                                                    }
                                                    {this.state.event_list.length > 0 && this.state.event_list.map((result, index) =>
                                                        <th className="" onClick={() => this.eventDetails(result)}>
                                                            <div className="eventclassred1 bar"  ref={(el) => {if (el) {el.style.setProperty('background-color', result.color_code, 'important');}}} style={{ height: "3px" }} id=""></div>
                                                            <div className="anab1">
                                                                <button type="button" className="btn2 anab" data-bs-toggle="modal" data-bs-target="#standard-modal">
                                                                    <p className="termininfo1">{dateFormat(result.date, "dd.mm.yyyy")} | {((result.time_from).split(':')[0]).concat(":" + (result.time_from).split(':')[1])}</p>
                                                                    {/* <p className="termininfo2" ><strong>{result.name}</strong></p>
                                                                    <p className="termininfo3"><i className="mdi mdi-map-marker"></i> {result.place}</p> */}
                                                                    <p className="termininfo2 textoverflow_2">
                                                                        <span
                                                                            href="#"
                                                                            id={`btn-${result.event_id}`}
                                                                        >
                                                                            <strong>{result.name}</strong>
                                                                        </span>
                                                                    </p>
                                                                    <UncontrolledTooltip
                                                                        style={{textAlign:"left", fontSize:"80%",backgroundColor:"#0c2541"}}
                                                                        target={`btn-${result.event_id}`}
                                                                    >
                                                                        {result.name}
                                                                    </UncontrolledTooltip>
                                                                    <p className="termininfo3 textoverflow_1">
                                                                        <span
                                                                            href="#"
                                                                            id={`btn-${index}`}
                                                                        >
                                                                            <i className="mdi mdi-map-marker"></i> {result.place}
                                                                        </span>
                                                                    </p>
                                                                    <UncontrolledTooltip
                                                                        style={{textAlign:"left", fontSize:"80%",backgroundColor:"#0c2541"}}
                                                                        target={`btn-${index}`}
                                                                    >
                                                                        {result.place}
                                                                    </UncontrolledTooltip>
                                                                </button>
                                                            </div>
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                            {this.state.norecordEvent == true && this.state.event_list.length == 0 &&

                                                <div className="card">
                                                    <div className="card-body">
                                                        <p>{t('No Event')}</p>
                                                        <div className="mb-4 mt-2">
                                                            <img className="" height="40px" src={NodataImg} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* other user   */}
                                            {this.state.event_list.length > 0 && this.state.func_list.length > 0 && this.state.func_list.map((result, index) =>
                                                <React.Fragment>
                                                    {this.state.member_list.length > 0 && this.state.member_list.filter(person => person.function_id == result.function_id).length > 0 &&

                                                        <thead
                                                            className="table-heading"
                                                        >
                                                            <tr >
                                                                <th className="sticky-col first-col" ><h5>{result.name}</h5></th>
                                                                <th colSpan="100%"></th>
                                                            </tr>
                                                        </thead>
                                                    }
                                                    <tbody>
                                                        {this.state.member_list.length > 0 && this.state.member_list.filter(person => person.function_id == result.function_id).map((m_result, m_index) =>

                                                            <React.Fragment>
                                                                {m_result.active == 1 ?
                                                                    <tr>
                                                                        <td className="sticky-col anab pad first-col">
                                                                            <div className="anab1 btn anab3 d-flex" style={{
                                                                                textAlign: "start",
                                                                                color:
                                                                                    (this.state.user.user_id == m_result.user_id) ? '#f39330' : 'white'
                                                                            }}>
                                                                                {m_result.full_name.split(' ')[0].charAt(0) + ". " + m_result.full_name.split(' ')[m_result.full_name.split(' ').length - 1]}
                                                                            </div>
                                                                        </td>
                                                                        {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                                            <React.Fragment>
                                                                                {m_result.function_id == result.function_id && e_result.event_function.filter(person => person.function_id == result.function_id).length ?
                                                                                    <td>
                                                                                        <div className="">
                                                                                            {e_result.event_comment.filter(person => person.function_id == result.function_id && person.user_id == m_result.user_id && person.event_id == e_result.event_id).map((color_comment, color_index) =>
                                                                                                <div>
                                                                                                    {color_comment.answer.state == 1 && this.state.member_is_admin == 1 &&

                                                                                                        <button onClick={() => this.commentModal(result.function_id, m_result.user_id, e_result, e_index)} type="button" className="btn btn-block btn-success waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={check} />
                                                                                                             }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={check} />
                                                                                                            }


                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={() => this.commentDetails(color_comment.comment)} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }
                                                                                                        </button>
                                                                                                    }
                                                                                                    {color_comment.answer.state == 1 && this.state.member_is_admin == 0 &&

                                                                                                        <button onClick={ this.state.user.user_id == m_result.user_id ? () => this.commentModal(result.function_id, m_result.user_id, e_result, e_index) : undefined} type="button" className={this.state.user.user_id == m_result.user_id ? "btn btn-block btn-success waves-effect waves-light" : "disable btn btn-block btn-success waves-effect waves-light"} data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={check} />
                                                                                                             }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={check} />
                                                                                                            }


                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={this.state.user.user_id != m_result.user_id ? () => this.commentDetails(color_comment.comment) : undefined} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }
                                                                                                        </button>
                                                                                                    }
                                                                                                    {color_comment.answer.state == 2 && this.state.member_is_admin == 1 &&
                                                                                                        <button onClick={() => this.commentModal(result.function_id, m_result.user_id, e_result, e_index)} type="button" className="btn btn-block btn-danger waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={close} style={{ width: "17px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={close} style={{width:"17px"}} />
                                                                                                            }

                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={() => this.commentDetails(color_comment.comment)} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }

                                                                                                        </button>
                                                                                                    }
                                                                                                    {color_comment.answer.state == 2 && this.state.member_is_admin == 0 &&
                                                                                                        <button onClick={ this.state.user.user_id == m_result.user_id ? () => this.commentModal(result.function_id, m_result.user_id, e_result, e_index) : undefined} type="button" className={this.state.user.user_id == m_result.user_id ? "btn btn-block btn-danger waves-effect waves-light" : "disable btn btn-block btn-danger waves-effect waves-light"} data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={close} style={{ width: "17px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={close} style={{width:"17px"}} />
                                                                                                            }

                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={this.state.user.user_id != m_result.user_id ? () => this.commentDetails(color_comment.comment) : undefined} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }

                                                                                                        </button>
                                                                                                    }
                                                                                                    {color_comment.answer.state == 3 && this.state.member_is_admin == 1 &&
                                                                                                        <button onClick={() => this.commentModal(result.function_id, m_result.user_id, e_result, e_index)} type="button" className="btn btn-block btn-warning waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={circle} style={{ width: "18px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={circle} style={{width:"18px"}} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={() => this.commentDetails(color_comment.comment)} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }

                                                                                                        </button>
                                                                                                    }
                                                                                                    {color_comment.answer.state == 3 && this.state.member_is_admin == 0 &&
                                                                                                        <button onClick={this.state.user.user_id == m_result.user_id ? () => this.commentModal(result.function_id, m_result.user_id, e_result, e_index) : undefined} type="button" className={this.state.user.user_id == m_result.user_id ? "btn btn-block btn-warning waves-effect waves-light" : "disable btn btn-block btn-warning waves-effect waves-light"} data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            {this.state.member_is_admin == 1 &&
                                                                                                                <img className="img-check-svg" src={circle} style={{ width: "18px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin != 1 &&
                                                                                                                <img className="img-check-svg" src={circle} style={{width:"18px"}} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 1 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment} src={comment} style={{ width: "19px" }} />
                                                                                                            }
                                                                                                            {this.state.member_is_admin == 0 && color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} onClick={this.state.user.user_id != m_result.user_id ? () => this.commentDetails(color_comment.comment) : undefined} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }

                                                                                                        </button>
                                                                                                    }
                                                                                                    {this.state.member_is_admin == 1 && color_comment.answer.state != 1 && color_comment.answer.state != 2 && color_comment.answer.state != 3 &&
                                                                                                        <button onClick={() => this.commentModal(result.function_id, m_result.user_id, e_result, e_index)} type="button" className="btn btn-block btn-default waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            <img className="img-check-svg" src={check} />
                                                                                                            {color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }
                                                                                                        </button>
                                                                                                    }
                                                                                                    {this.state.member_is_admin == 0 && color_comment.answer.state != 1 && color_comment.answer.state != 2 && color_comment.answer.state != 3 &&
                                                                                                        <button onClick={() => this.commentDetails(color_comment.comment)} type="button" className="btn btn-block btn-default waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal2">
                                                                                                            <img className="img-check-svg" src={check} />
                                                                                                            {color_comment.comment != null && color_comment.comment != "" &&
                                                                                                                <img className="img-check-svg" title={color_comment.comment}  src={comment} style={{width:"19px"}} />
                                                                                                            }
                                                                                                            {color_comment.guests > 0 &&
                                                                                                                <span><img className="img-check-svg" src={user} style={{width:"18px"}} /><span>+{color_comment.guests}</span></span>
                                                                                                            }
                                                                                                        </button>
                                                                                                    }
                                                                                                </div>
                                                                                            )}
                                                                                            {this.state.member_is_admin == 1 && e_result.event_comment.filter(person => person.function_id == result.function_id && person.user_id == m_result.user_id && person.event_id == e_result.event_id).length == 0 &&
                                                                                                <button type="button" className="btn btn-block btn-default waves-effect waves-light" onClick={() => this.commentModal(result.function_id, m_result.user_id, e_result, e_index)} data-bs-toggle="modal" data-bs-target="#centermodal2"><img className="img-check-svg" src={question} style={{width:"13px"}} /></button>
                                                                                            }
                                                                                           {/*  
                                                                                            {this.state.member_is_admin == 0 && e_result.event_comment.filter(person => person.function_id == result.function_id && person.user_id == m_result.user_id && person.event_id == e_result.event_id).length == 0 &&
                                                                                                <button type="button" className="btn btn-block btn-default waves-effect waves-light disabled" data-bs-toggle="modal" data-bs-target="#centermodal2"><img className="img-check-svg" src={question} style={{width:"13px"}} /></button>
                                                                                            }
                                                                                            */}
                                                                                            {this.state.member_is_admin == 0 && e_result.event_comment.filter(person => person.function_id == result.function_id && person.user_id == m_result.user_id && person.event_id == e_result.event_id).length == 0 &&
                                                                                                <button type="button" onClick={this.state.user.user_id == m_result.user_id ? () => this.commentModal(result.function_id, m_result.user_id, e_result, e_index) : undefined} className={this.state.user.user_id == m_result.user_id ? "btn btn-block btn-default waves-effect waves-light" : "btn btn-block btn-default waves-effect waves-light disabled"} data-bs-toggle="modal" data-bs-target="#centermodal2"><img className="img-check-svg" src={question} style={{width:"13px"}} /></button>
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    :
                                                                                    <td>
                                                                                        <div className="">
                                                                                            <button type="button" className="btn btn-dark waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal4"> </button>
                                                                                        </div>
                                                                                    </td>
                                                                                }
                                                                            </React.Fragment>
                                                                        )}
                                                                    </tr>
                                                                    : m_result.active == 0 ?
                                                                        ""
                                                                        :
                                                                        ""
                                                                }
                                                            </React.Fragment>


                                                        )}
                                                        {/* other user  end */}

                                                        {/* sub total  */}
                                                        {this.state.member_list.length > 0 && this.state.member_list.filter(person => person.user_id != this.state.user_swift_id && person.function_id == result.function_id).length > 0 &&

                                                            <tr className="t-footer" >
                                                                <th className="sticky-col first-col" ><div className="anab1 btn anab3">Total  {result.name}</div> </th>
                                                                {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                                    <React.Fragment>
                                                                        {e_result.event_function.filter(person => person.function_id == result.function_id).length == 0 &&
                                                                            <th className="sub-footer text-center">
                                                                                <div className="anab1 btn anab3">0 | 0</div>
                                                                                <div></div>
                                                                            </th>

                                                                        }
                                                                        {e_result.event_function.filter(person => person.function_id == result.function_id).map((etotal_result, etotal_index) =>
                                                                            <th className="sub-footer text-center">
                                                                                <div className="anab1 btn anab3">
                                                                                    {/* {this.showSubTotal(e_result, result)} | {etotal_result.max_registrations != 0 ?
                                                                                        etotal_result.max_registrations
                                                                                        :
                                                                                        this.state.member_list.filter(person => person.function_id == result.function_id).length
                                                                                    } */}
                                                                                    {this.showSubTotal(e_result, result)} | {etotal_result.max_registrations != 0 ?
                                                                                        etotal_result.max_registrations
                                                                                        :
                                                                                        "∞"
                                                                                    }
                                                                                </div>
                                                                            </th>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </tr>
                                                        }
                                                        {/* sub total end */}

                                                        {/* empty row start */}
                                                        {/*<tr className="blank-row" >
                                                            <td> </td>
                                                            {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                                <React.Fragment>
                                                                       <td >
                                                                            <div >  </div>
                                                                        </td>
                                                                </React.Fragment>
                                                            )}
                                                        </tr>*/}
                                                        {/* empty row finish */}
                                                    </tbody>
                                                </React.Fragment>
                                            )}

                                            {/* grand total  */}
                                            <tbody>
                                                {this.state.event_list.length > 0 &&
                                                    <tr className="t-footer" >
                                                        <th className="sticky-col first-col"><div className="anab1 btn anab3">Total  </div> </th>
                                                        {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                            <React.Fragment>
                                                                <th className="sub-footer text-center">
                                                                    <div className="anab1 btn anab3">
                                                                        {this.SubTotalUper(e_result)} | {this.showTotal(e_result.event_function)} </div>

                                                                </th>
                                                            </React.Fragment>
                                                        )}
                                                    </tr>
                                                }

                                                {/* grand total end  */}

                                                {/* empty rows  */}
                                                <tr className="blanck-row">
                                                    <td > </td>
                                                    {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                        <React.Fragment>
                                                            <td >
                                                                <div >  </div>
                                                            </td>
                                                        </React.Fragment>
                                                    )}
                                                </tr>
                                            </tbody>
                                            {/* empty end */}

                                            {/* task section */}
                                            <thead className="table-heading">
                                                {this.state.event_list.length > 0 &&
                                                    <tr>
                                                        <th className="sticky-col first-col"><h5>{t("Task")}</h5></th>
                                                        <th colSpan="100%"></th>
                                                    </tr>
                                                }
                                            </thead>
                                            {this.state.event_list.length > 0 &&
                                                <tbody style={{ height: "50px" }}>
                                                    {this.state.event_list.length > 0 && this.state.task_list.length > 0 && this.state.task_list.map((result, index) =>
                                                        <React.Fragment>
                                                            <tr>
                                                                <td className="sticky-col anab pad first-col">
                                                                    <div className="anab1 btn anab3 d-flex" style={{textAlign: "start"}}>{result.task_name}</div>
                                                                </td>
                                                                {this.state.event_list.length > 0 && this.state.event_list.map((e_result, e_index) =>
                                                                    <React.Fragment>
                                                                        {e_result.event_task.filter(person => person.task_id == result.task_id).length == 0 ?
                                                                            <td>
                                                                                <div className="">
                                                                                    <button type="button" className="btn btn-dark waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#centermodal4"> </button>
                                                                                </div>
                                                                            </td>
                                                                            :
                                                                            ""
                                                                        }
                                                                        {e_result.event_task.filter(person => person.task_id == result.task_id).map((etask_result, etask_index) =>
                                                                            <td>
                                                                                <div className="">
                                                                                    {e_result.comment_tasks.filter(person => person.task_id == result.task_id).length == 0 ?

                                                                                        <button type="button" className="btn btn-block btn-default waves-effect waves-light disabled" data-bs-toggle="modal" data-bs-target="#centermodal2"><img className="img-check-svg" src={question} style={{width:"13px"}} /></button>

                                                                                        :
                                                                                        <button type="button" onClick={() => this.taskDetails(e_result.event_id, result.task_id, e_index)} className="btn btn-block btn-success waves-effect waves-light disable" data-bs-toggle="modal" data-bs-target="#centermodal2"><img className="img-check-svg" src={check} />
                                                                                            {e_result.comment_tasks.filter(person => person.task_id == result.task_id && person.task_value != null && person.task_value != "").length > 0 &&
                                                                                                <img className="img-check-svg"  src={comment} style={{width:"19px"}} />
                                                                                            }
                                                                                        </button>
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                    </React.Fragment>
                                                                )}
                                                            </tr>
                                                        </React.Fragment>
                                                    )}
                                                </tbody>

                                            }
                                            {/* task section end */}
                                        </table>
                                    </div>
                                </div>
                            </div>


                            {/* <!-- container --> */}
                        </div>
                        {/* <!-- content --> */}
                    </div>
                    {/* <!-- Footer Start --> */}
                    {/* <!-- end Footer --> */}
                </div>

                <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                    <ModalHeader toggle={this.modalToggle} >
                        {this.state.comment_event_date != "" ? dateFormat(this.state.comment_event_date, "dd.mm.yyyy") : ""}: {this.state.comment_event_name}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <h6>{t("Registrations")}</h6>
                                    {this.state.comment_answer_option.length > 0 && this.state.comment_answer_option.map((answer_option, idx) => (
                                        <div className="form-check">
                                            <input checked={this.state.comment_answer == answer_option.answer_id} className="form-check-input" type="radio" onChange={() => this.handle_answerComment(answer_option.answer_id)} name="exampleRadios" id="exampleRadios1" value="option1" />
                                            <label className="form-check-label" >
                                                {t(answer_option.answers.answer_name)}
                                            </label>
                                        </div>
                                    ))}
                                    <span className="text-danger">{t(this.state.errMsg.answer_id)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Guests")}</Label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        placeholder={t("Number of guest")}
                                        required=""
                                        min="0"
                                        disabled={this.state.comment_guest_allowed == 0}
                                        value={this.state.comment_guests}
                                        onChange={(e) => this.handle_Guest(e)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.guests)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Comment")}</Label>
                                    <Input
                                        type="textarea"
                                        className="form-control"
                                        placeholder={t("Comment")}
                                        rows="4"
                                        id="useremail"
                                        name="email"
                                        required=""
                                        value={this.state.comment != null ? this.state.comment : ""}
                                        onChange={(e) => this.handle_Comment(e)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.comment)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    {this.state.event_date_task.length > 0 &&
                                        <h6>{t("Tasks")}</h6>
                                    }
                                    {this.state.event_date_task.length > 0 ?
                                        this.state.event_date_task.map((item, idx) => (
                                            <div className="form-group">
                                                {parseInt(item.admin) == 1 &&
                                                    <React.Fragment>
                                                        <div className="d-flex">
                                                            <Input
                                                                type="checkbox"
                                                                id="example-time"
                                                                className=" col-4"
                                                                min="0"
                                                                placeholder={t("Comment")}
                                                                checked={item.checked == 1}
                                                                disabled={(this.state.member_is_admin == 0) || (item.checked != 1 && item.max_registrations != 0 && item.max_registrations <= item.used_max_registrations)}
                                                                onChange={(e) => this.handle_taskCheckedOnComment(e.target.checked, idx)}
                                                            />
                                                            <label className="form-check-label ml-1">{item.task_name}</label>
                                                        </div>
                                                        <div>
                                                            <Input
                                                                type="text"
                                                                id="example-time"
                                                                className="form-control col-4"
                                                                min="0"
                                                                placeholder={t("Comment")}
                                                                value={item.task_value}
                                                                disabled={(this.state.member_is_admin == 0) || (item.task_value != "" && item.max_registrations != 0 && item.max_registrations <= item.used_max_registrations)}
                                                                onChange={(e) => this.handle_taskComment(e.target.value, idx)}
                                                            />
                                                        </div>
                                                    </React.Fragment>

                                                }
                                                {parseInt(item.admin) == 0 &&
                                                    <React.Fragment>
                                                        <div className="d-flex">
                                                            <Input
                                                                type="checkbox"
                                                                id="example-time"
                                                                className="col-4"
                                                                min="0"
                                                                placeholder={t("Comment")}
                                                                checked={item.checked == 1}
                                                                disabled={item.checked != 1 && item.max_registrations != 0 && item.max_registrations <= item.used_max_registrations}
                                                                onChange={(e) => this.handle_taskCheckedOnComment(e.target.checked, idx)}
                                                            />
                                                            <label className="form-check-label ml-1" >{item.task_name}</label>
                                                        </div>
                                                        <div>
                                                            <Input
                                                                type="text"
                                                                id="example-time"
                                                                className="form-control col-4"
                                                                min="0"
                                                                placeholder={t("Comment")}
                                                                value={item.task_value}
                                                                disabled={item.task_value != "" && item.max_registrations != 0 && item.max_registrations <= item.used_max_registrations}
                                                                onChange={(e) => this.handle_taskComment(e.target.value, idx)}
                                                            />
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            </div>

                                        )) : ""}
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <h6>{t("Notification")}</h6>
                                    <div className="row">
                                        <div className="col-12 ">
                                            <div className="mb-2 row">
                                                {/* <div className="col-md-12">
                                                    <div className="d-flex">
                                                        <div style={{ width: "30px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={this.state.is_receive_email == true || this.state.is_receive_email == "true"} type="checkbox" onChange={(e) => this.handle_isReceiveEmail(e)} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="form-check">
                                                                <label className="form-check-label" >
                                                                    {t("The sytem send an info-mail (and push notification) for all members, who have 'not' registered")}
                                                                    <input type="number" min="0" className="ml-1 mr-1 width-50 form-control" style={{ padding: "5px" }} size="1" value={this.state.reminder_days} onChange={(e) => this.handle_reminderDays(e)} /> {t("day before deadline")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="col-md-12">
                                                    <div className="d-flex">
                                                        <div style={{ width: "30px" }}>
                                                            <div className="form-check">
                                                                <input className="form-check-input" checked={this.state.is_informed_email == true || this.state.is_informed_email == "true"} onChange={(e) => this.handle_isInformedEmail(e)} type="checkbox" id="flexCheckChecked2" /></div>
                                                        </div>
                                                        <div>
                                                            <div className="form-check">
                                                                <label className="form-check-label" >
                                                                    {t("I would like to be informed (email and push notification) when a registration/deregistration is made")}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseModal()}>{t("Cancel")}</Button>
                            { this.state.isLoading == true &&
                                <Button disabled className="btn btn-success fw-bolder" >Save </Button>
                            }
                            { this.state.isLoading == false &&

                                <Button className="btn btn-success fw-bolder" onClick={() => this.beforOnSubmit()}>{t("Save")} </Button>
                            }

                        </div>
                    </ModalFooter>
                </Modal>

                {/* Task info */}
                <TaskDetails data={{ CloseTaskViewModal: this.CloseTaskViewModal.bind(this), taskviewmodal: this.state.taskviewmodal, detailsTask: this.state.detailsTask, detail_task_id: this.state.detail_task_id }} />

                {/* event info */}
                <Details data={{ CloseEventModal: this.CloseEventModal, eventmodal: this.state.eventmodal, eventDetails: this.state.eventDetails }} />

                <Modal size="md" isOpen={this.state.commnetviewmodal} autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t("Comment")}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295" }}>
                        <div className="row ml-1">
                            {this.state.comment} <hr className="mt-2" />
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger" onClick={() => this.CloseCommnetViewModal()}> {t("Cancel")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal size="md" isOpen={this.state.takenbyothermodal}  >
                    <ModalHeader>Information</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295" }}>
                        <div className="row">
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        {t("Sign-in not possible. Maximum number of members reached.")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="text-right w-100">
                            <Button className="btn btn-success" onClick={() => this.takenbyothermodal()}> {t("Ok")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal size="md" isOpen={this.state.commentModalOkClick} autoFocus={false}>
                    <ModalHeader toggle={() => this.commentModalCancleClick()} >{t("Confirm")}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295" }}>
                        <div className="row ml-1">
                            {t("Are you sure to take a task without a registration")}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger" onClick={() => this.commentModalCancleClick()}>{t("Cancel")} </Button>
                            <Button className="btn btn-success" onClick={() => this.commentModalOkClick()}>{t("Yes")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Footer />
            </div>
        );
    }
}
export default withTranslation()(Index);
