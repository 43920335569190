import React, { Component } from 'react'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Form, Input , Spinner ,ModalFooter ,Button ,ModalBody , ModalHeader ,Modal ,Label ,} from "reactstrap";
import Select from 'react-select'
import axios from "axios";
import { APIURL } from '../constants/Common';
import { withTranslation } from 'react-i18next';

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);
      console.log({ data, isDisabled, isFocused, isSelected });
      return {
        ...styles,
        backgroundColor: isFocused ? "#BBE7F6" : null,
        color: "#333333"
      };
    }
  };

class AnswerOptions extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            Stateoptions: [
                { value: 1, label: t('Registered') },
                { value: 2, label: t('Unregistered') },
                { value: 3, label: t('Maybe') }
            ],
            Commentoptions: [
                { value: 0, label: t('No') },
                { value: 1, label: t('Yes')  },
            ],
            AnsOpt_List: [],
            answer_name: "",
            State: [],
            Comment: [{ value: 0, label: t('No') }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            errMsg: "",
            atLeast:"",
            sortRow:[],
            Loader:false,
            modal:false,
            comment_mandatory:false,
            answerId: '',
        }
        this.toggle = this.toggle.bind(this);
    }

    HandleSubjectInput(e) { this.setState({ answer_name: e})}


    handleCheck(){
        this.setState({
            comment_mandatory:!this.state.comment_mandatory
        })
    }
   
    toggle(e) {
            const { t } = this.props;     
        this.setState({
            modal: !this.state.modal,
            errMsg: '',
            answer_name: "",
             State:  [],
            Comment: [{ value: 0, label: t('No') }],
            answerId: '',
        });
    }

    HandleState = (e) => {
        // console.log("State", e);
        this.setState({
            State: [{ value: e.value, label: e.label }]
        })
      
    };
    HandleCommentOpt = (e) => {
       
        this.setState({
            Comment: [{ value: e.value, label: e.label }]
        })
        // console.log("Selected Comment", this.state.Comment)
    };


    handleAddAnsOpt = (e) => {
             const { t } = this.props;     
        this.setState({
            Loader: true
        })

        if (this.state.answerId !== '') {
            const formData = new FormData();


            formData.append('state', this.state.State[0].value);
            formData.append('comment', this.state.Comment[0].value);
            formData.append('team_id', this.props.data.teamId);
            formData.append('active', 1);
            formData.append('answer_name', this.state.answer_name);
    
            var app_url = APIURL + "edit_answer/" + this.state.answerId
            var token = this.state.token
            axios
                .post(app_url, formData,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                .then((response) => {
                    this.getAnswerOption(this.props.data.teamId);
                    this.setState({
                        msg: response.data.message,
                        errMsg: '',
                        redirect: true,
                        Loader:false,
                        isLoading: false
                    });

                    this.setState({
                        answer_name: '', 
                        State:  [],
                        Comment: [{ value: 0, label: t('No') }],
                        errMsgAttr: '',
                        modal: false,
                        answerId: '',
                    });
                })
                .catch(error =>{
                   if(error.response.data.message === "validation_error")
                   {
                        this.setState({
                            errMsg:error.response.data.errors,
                            isLoading: false,
                            Loader:false,
                        })
                   }
                   else
                   {
                        this.setState({
                          error,
                          isLoading: false,
                          Loader:false,
                        })
                   }
                    
                }
              );  
        } else {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('answer_id', '');
        formData.append('answer_name', this.state.answer_name);
        if (Array.isArray(this.state.State) && this.state.State.length) {
            formData.append('state', this.state.State[0].value);
        }
        else{
            formData.append('state', "");
        }
        formData.append('comment', this.state.Comment[0].value);
        var app_url = APIURL + "create_answers";
       
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.getAnswerOption(this.props.data.teamId);
                    this.setState({
                        msg: response.data.message,
                        errMsg: '',
                        redirect: true,
                        Loader:false,
                        isLoading: false
                    });

                    this.setState({
                        answer_name: '', 
                        State:  [],
                        Comment: [{ value: 0, label: t('No') }],
                        errMsgAttr: '',
                        modal: false,
                        answerId: '',
                    });
            })
             .catch(error =>{
               if(error.response.data.message === "validation_error")
               {
                    this.setState({
                        errMsg:error.response.data.errors,
                        isLoading: false,
                        Loader:false,
                    })
               }
               else
               {
                    this.setState({
                      error,
                      isLoading: false,
                      Loader:false,
                    })
               }
                
            }
          );
        }
    }

      handleIsAdmin(e,idx)
    {
        let AnsOpt_List=this.state.AnsOpt_List;
        AnsOpt_List[idx]['comment_mandatory'] = e.target.checked;
        this.setState({
                      AnsOpt_List: AnsOpt_List
         })

         const formData = new FormData();
        formData.append('comment_mandatory',e.target.checked);
        formData.append('answer_id',AnsOpt_List[idx]['answer_id']);
        formData.append('team_id',this.props.data.teamId);
        var token = this.state.token
         axios
            .post(APIURL + "answer/update-comment", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {

            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false
                    })
                }

            }
            );
    }

    handleRemoveAnsopt(e){
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_answer/" + e.answer_id, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                console.log(response.data)
                
                    this.setState({
                        RemScsMsg:response.data.message,
                        redirect: true,
                        toast:true
                    })
                    this.setState({
                        AnsOpt_List: this.state.AnsOpt_List.filter((s, sidx) => e.answer_id != s.answer_id
                        )
                    });
            });
    }

    handleEditAnsopt(e) {
        this.setState({
            modal: true,
            errMsg: '',
            answer_name: e.answer_name,
            State: [this.state.Stateoptions.find((stateItem) => stateItem.value == e.state)],
            Comment: [this.state.Commentoptions.find((stateItem) => stateItem.value == e.comment_mandatory)],
            answerId: e.answer_id,
        });
    }

    onSubmit = (e) => {
        this.setState({
            atLeast:''
        });
      this.props.data.handleCompleteTab(5);
       
    };

    getAnswerOption(id){
        var token = this.state.token
        axios
          .get(APIURL+"get_answers/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
          .then((response) => {
              if (response.data.status === 200) {
              this.setState({ 
                AnsOpt_List: response.data.data
              //  Comment:response.data.data.comment_mandatory,            
                }); 
                this.setState({ selected_type: [{
                    'value': response.data.data.comment_mandatory,
                }] });
            }
          });
      }


    handleStep(type,index){
        const sort_List=this.state.AnsOpt_List;
        let sortRow=[]; let selfsort; let othersort;
     
       if(type === "up")
       {
           
            const self=sort_List[index];
            const other=sort_List[index-1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index-1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['answer_id'],"sort":self['sort']});
            sortRow.push({id:other['answer_id'],"sort":other['sort']});

            this.setState({ 
                AnsOpt_List: sort_List
            })

       }
       else if(type === "down")
       {
            const self=sort_List[index];
            const other=sort_List[index+1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index+1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['answer_id'],"sort":self['sort']});
            sortRow.push({id:other['answer_id'],"sort":other['sort']});

            this.setState({ 
                AnsOpt_List: sort_List
            })
       }
        this.setState({ 
            sortRow: sortRow
        },()=>{this.updateStep()})

    }


    updateStep()
    {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "answer/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                   
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }

      componentDidMount(){
        if(this.props.data.teamId > 0)
        {
            this.getAnswerOption(this.props.data.teamId);
        }
        this.getOption();
    }

    
    componentWillReceiveProps(newProps) {   
       if(this.props.data.teamId > 0)
        {
            this.getAnswerOption(this.props.data.teamId);
        }
        this.getOption();
    }

   getOption(){
        const { t } = this.props;
        let Commentoptions=[];
        Commentoptions.push({ value: 0, label: t('No') })
        Commentoptions.push({ value: 1, label: t('Yes') })

        let Comment=this.state.Comment;
        Comment= [{ value: Comment[0].value, label: Comment[0].label}]

       this.setState({ 
        Commentoptions: Commentoptions,
        Comment:Comment 
       })
   }


    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }}  /> </div> :""}
                <Form id="teaminfoform" method="post" action="#" encType='multipart/form-data' className="form-horizontal was-validated">
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-4">
                                        <h5>{t("Answer options")}</h5>
                                        <span>{t("The answer options define the possibilities that are available for members to choose to subscribe or unsubscribe to an event. At least one subscribes and one unsubscribe option are mandatory and if desired a 'maybe' answer can be added. Also, it can be defined per answer if the members have to give a reason/comment if this answer is selected.")} </span>
                                    </div>


                                    <div className="table-responsive table-wrapper">
                                        <table className="table table-hover tableFixHead">
                                            <thead className="bg-light">
                                                <tr className="fw-medium">
                                                    <th></th>
                                                    <th> {t("Subject")} </th>
                                                    <th> {t("State")} </th>
                                                    <th className="text-center"> {t("Comment mandatory")} </th>
                                                    <th className="text-center">{t("Action")}</th>
                                                </tr>
                                                </thead>
                                                <tbody className="font-14">
                                                {this.state.AnsOpt_List.map((item, idx) => (
                                                    <tr key={idx}>
                                                         <td>
                                                                 {idx != 0 &&
                                                                  <i  onClick={(e) => this.handleStep('up',idx)}   className="mdi mdi-arrow-up"></i>
                                                                 }
                                                                {this.state.AnsOpt_List.length != idx+1 &&
                                                                    <i onClick={(e) => this.handleStep('down',idx)}  className="mdi mdi-arrow-down"></i>
                                                                }
                                                        </td>
                                                        <td>
                                                            {t(item.answer_name)}
                                                        </td>
                                                        <td>
                                                            {
                                                                item.state === 1 ? <span>{t('Registered')}</span> :
                                                                    item.state === 2 ? <span>{t('Unregistered')}</span> :
                                                                        item.state === 3 ? <span>{t('Maybe')}</span> :
                                                                            ""
                                                            }
                                                        </td>
                                                        <td className="text-center">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                onChange={(e) => this.handleIsAdmin(e,idx)}
                                                                checked={this.state.comment_mandatory ? this.state.comment_mandatory : item.comment_mandatory}
                                                                id="flexCheckDefault"    
                                                            />
                                                        </td>
                                                        <td className="text-center">
                                                        {item.is_default === "No" &&
                                                        <>
                                                            <Button size="sm" color="warning" className="mobile-icon ms-2" onClick={(e) => this.handleEditAnsopt(item)}><span>{t("Edit")}</span></Button>
                                                            <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveAnsopt(item)}><span>{t("Remove")}</span></Button>
                                                        </>

                                                        //   <i onClick={(e) => this.handleRemoveAnsopt(item)}  className=" cursor_pointer  far fa-trash-alt "></i>
                                                       }
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <span className="text-danger">{t(this.state.atLeast)}</span>
                                    </div>
                                    <div className="mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                                        > {t("Add answer option")} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <!-- end row --> */}
                    <ul className="pager wizard mb-0 list-inline">
                        <li className="previous list-inline-item">
                            <button onClick={() => this.props.data.handleActiveTab(3)} type="button" className="btn btn-warning mr-2 fw-bolder"><i className="mdi mdi-arrow-left me-1"></i> {t("Back to tasks")}</button>
                        </li>
                        <li className="next list-inline-item disabled">
                            <button onClick={() => this.onSubmit()} type="button" className="btn btn-primary fw-bolder">{t("Go to event types")} <i className="mdi mdi-arrow-right ms-1"></i></button>
                        </li>
                    </ul>
                </Form>

                <Modal size="md" isOpen={this.state.modal}  autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t(this.state.answerId !== '' ? "Edit answer option" : "Add answer option")}</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Subject")}</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="useremail"
                                            placeholder={t("Enter answer option")}
                                            value={this.state.answer_name}
                                            onChange={(e) => this.HandleSubjectInput(e.target.value)}
                                            autoFocus={true}
                                        />
                                        <span className="text-danger">{t(this.state.errMsg.answer_name)}</span>
                                    </div>
                                </div>
                                <div className="col-sm-12 view-element">
                                    <div className="form-group">
                                        <Label>{t("State")}</Label>
                                        <Select
                                            aria-label=".form-select-lg example"
                                            required=""
                                            placeholder={t("Select")}
                                            styles={colourStyles}
                                            options={this.state.Stateoptions}
                                            value={this.state.State}
                                            onChange={this.HandleState}
                                        />
                                        <span className="text-danger">{t(this.state.errMsg.state)}</span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Comment mandatory")}</Label>
                                        <Select
                                            aria-label=".form-select-lg example"
                                            required=""
                                            placeholder="Select Comment Mendatory"
                                            styles={colourStyles}
                                            options={this.state.Commentoptions}
                                            value={this.state.Comment}
                                            onChange={this.HandleCommentOpt}
                                        />
                                        <span className="text-danger">{t(this.state.errMsg.comment)}</span>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                        <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddAnsOpt()}>{t("Save")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(AnswerOptions);