
import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../constants/Common';
import {Spinner,Button, Input, Modal, ModalBody, ModalFooter, Table, ModalHeader, Label} from 'reactstrap';
import {Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '../../assets/css/Custom.css'
import { withTranslation } from 'react-i18next';

 class SettingFunctions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            func_List: [],
            modal: false,
            AddModal:false,
            toast: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            RemScsMsg: "",
            setFunc: "",
            sortRow:[],
            errMsg: {},
            active:false,
            removeFuncId:"",
            msg: "",
            scsMsg: "",
        }
        this.Editclick = this.Editclick.bind(this);
        this.AddModal = this.AddModal.bind(this);
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        this.setState({
            AddModal: !this.state.AddModal,
            errMsgAttr: '' ,
            setFunc: '',
            active:true,
            errMsg:""
        });
    }

    AddModal(e) {
        this.setState({
            AddModal: !this.state.AddModal,
        });
    }

    getFunctions(id) {
        this.setState({Loader:true})
        var token = this.state.token
        axios
            .get(APIURL + "get_functions/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                
                if (response.data.status === 200) {
                    this.setState({
                        func_List: response.data.data
                      
                    });
                }
                 this.setState({
                       
                        Loader:false
                    });
            });
    }

    handle_functions(e) {
        this.setState({
            function_id: e.function_id,
            setFunc: e.name,
            order: e.sort,
            checked: e.active,
            errMsg:""
        })
    }

    handle_function_name(e) {
        this.setState({
            setFunc: e,
            
        })
    }

    Editclick(e) {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.handle_functions(e)
        this.setState({
            modal: !this.state.modal,
            active:e.active==1?true:false
        });
    }
    handleStatus(e)
    {
        this.setState({
            active: e.target.checked
        });
    }


    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    updateInput = (event) => {
        this.setState({ setFunc: event.target.value })
    };

    // activeStatus(){
    //     this.setState({
    //         checked:!this.state.checked
    //     })
      
    // }

    addFunctions = () => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
            this.setState({
                Loader:true
            })
            const formData = new FormData();
            formData.append('team_id',this.props.data.teamId)
            formData.append('name',this.state.setFunc);  
            formData.append('function_id','');  
            formData.append('active',this.state.active);  
            
            var app_url = APIURL + "create_function";
            var token = this.state.token
            axios
                .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {  
                    this.setState({ isLoading: false });
                        this.getFunctions(this.props.data.teamId);
                        this.setState({ 
                            AddModal:false,
                            setFunc: '', 
                            errMsgAttr: '', 
                            errMsg: '',
                            scsMsg: response.data.message,
                            Loader:false
                        });               
                })
                .catch(error =>{
                   if(error.response.data.message=="validation_error")
                   {
                        this.setState({
                            errMsg:error.response.data.errors,
                            Loader: false
                        })
                   }
                   else
                   {
                        this.setState({
                          error,
                          Loader: false
                        })
                   }
                    
                }
              );  
    }

    handleAddFunctions = () => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        if (this.state.setFunc) {
            this.setState({modal:false})
            this.setState({ func_List: this.state.func_List.concat([{ 
                name: this.state.setFunc , 
                function_id: "" ,
            }]),
                 setFunc: '', errMsgAttr: '', errMsg: '' });
        }
        else {
            this.setState({ errMsgFunc: 'Please enter function name.', errMsg: '' });
        }
    }

    handleRemoveTaskModal = (e) =>{
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            removeFuncId:e.function_id,
            removefuncModal:!this.state.removefuncModal
        })
    }
    RemoveModalClose(){
        this.setState({
            removefuncModal:false
        })
    }


    handleRemoveFunc(e) {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.getFunctions(this.props.data.teamId)
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        this.setState({
            Loader: true
        })
        axios.post(APIURL + "delete_function/" + this.state.removeFuncId, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.setState({
                        RemScsMsg: response.data.message,
                        redirect: true,
                        toast: true,
                        removefuncModal:false,
                        scsMsg: response.data.message,
                        Loader: false,
                    })
                    this.setState({
                        func_List: this.state.func_List.filter((s, sidx) => this.state.removeFuncId != s.function_id
                        )
                    });
            }) 
            .catch(error =>{
                this.setState({
                    removefuncModal:false,
                   errMsg: { message: error.response.data.message },
                   Loader: false,
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }

    onSubmit = (e) => {
        const formData = new FormData();
        formData.append('name', this.state.setFunc);
        formData.append('active',this.state.active);  
        formData.append('team_id',this.props.data.teamId);  

        var app_url = APIURL + "edit_function/" + this.state.function_id
        var token = this.state.token
        this.setState({
            Loader: true
        })
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.getFunctions(this.props.data.teamId);
                    this.setState({
                        errMsg: '',
                        redirect: true,
                        modal: false,
                        scsMsg: response.data.message,
                        Loader: true,
                    });
                
            })
            .catch(error =>{
                   if(error.response.data.message=="validation_error")
                   {
                        this.setState({
                            errMsg:error.response.data.errors,
                            Loader: false
                        })
                   }
                   else
                   {
                        this.setState({
                          error,
                          Loader: false
                        })
                   }
                    
                }
            );   
    };

    handleStep(type,index){
        const sort_List=this.state.func_List;
        let sortRow=[]; let selfsort; let othersort;
     
       if(type=="up")
       {
           
            const self=sort_List[index];
            const other=sort_List[index-1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index-1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['function_id'],"sort":self['sort']});
            sortRow.push({id:other['function_id'],"sort":other['sort']});

            this.setState({ 
                func_List: sort_List
            })

       }
       else if(type=="down")
       {
            const self=sort_List[index];
            const other=sort_List[index+1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index+1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['function_id'],"sort":self['sort']});
            sortRow.push({id:other['function_id'],"sort":other['sort']});

            this.setState({ 
                func_List: sort_List
            })
       }
        this.setState({ 
            sortRow: sortRow
        },()=>{this.updateStep()})

    }


    updateStep()
    {
            // this.setState({ func_List: this.state.func_List.filter((s, sidx) => idx !== sidx) });
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "function/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                   
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }


    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getFunctions(this.props.data.teamId);
        }
    }

    onAlertClose = () => {
        this.setState({
            msg: "",
            scsMsg: "",
        });
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                 {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                
           <div className="row">
                <div className="col-sm-8"> 
                    <div className="card">
                        <div className="card-body">
                            <h5>{t("Functions")}</h5>
                             { this.state.errMsg.message &&
                                <span className="text-danger">{this.state.errMsg.message}</span>
                            }
                            <div className="table-responsive">
                                <Table hover className="table table-hover table-centered nowrap">
                                    <thead className="bg-light">
                                        <tr>
                                            <th></th>
                                            {/* <th>Id</th> */}
                                            <th>{t("Functions")}</th>
                                            {/*<th>Order</th>*/}
                                            <th>{t("Active")}</th>
                                            <th className="text-center" style={{width:"170px"}}>{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.func_List.length > 0 &&
                                            this.state.func_List.map((item, idx) => (
                                        <tr key={item.function_id}>
                                            <td>
                                                 {idx!=0 &&
                                                  <i  onClick={(e) => this.handleStep('up',idx)}   className="mdi mdi-arrow-up"></i>
                                                 }
                                                {this.state.func_List.length!=idx+1 &&
                                                    <i onClick={(e) => this.handleStep('down',idx)}  className="mdi mdi-arrow-down"></i>
                                                }
                                            </td>
                                            {/* <th scope="row">#{item.function_id}</th> */}
                                            <td>{item.name}</td>
                                            {/*<td>{item.sort}</td>*/}
                                            <td>
                                                {item.active === 0 ? <span>No</span> 
                                                :
                                                item.active === 1 ? <span>Yes</span>
                                                :
                                                ""
                                                }
                                            </td>
                                            <td>
                                                <Button size="sm" color="warning"  className="mobile-icon" onClick={(e) => this.Editclick(item)} > <i className="ri-pencil-fill"></i> <span> {t("Edit")}</span></Button>
                                                <Button size="sm" color="danger"  className="mobile-icon ms-2" onClick={(e) => this.handleRemoveTaskModal(item)}><i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                            </td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                <div className=" mt-3">
                                    <Button
                                        type="button"
                                        id="add_attrs"
                                        onClick={this.toggle}
                                        className="btn btn-warning waves-effect waves-light mt-2 add_attrs"
                                    >
                                        {t("Add function")}
                                    </Button>
                                </div>
                                <Modal size="md" isOpen={this.state.AddModal}  autoFocus={false}>
                                    <ModalHeader toggle={this.toggle}>{t("Add function")}</ModalHeader>
                                    <ModalBody>
                                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <Label>{t("Functions")}</Label>
                                <Input
                                            type="text"
                                            className="form-control"
                                            id="useremail"
                                            onChange={this.updateInput}
                                            placeholder={t("Please enter function")}
                                            value={this.state.setFunc}
                                            autoFocus={true}
                                        />
                                        <span className="text-danger">{t(this.state.errMsg.name)}</span>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group mb-0">
                                <input className="form-check-input mr-1" type="checkbox"  onChange={(e) => this.handleStatus(e)} checked={this.state.active} id="flexCheckDefault"  />

                                <Label className="mb-0">{t("Active")}</Label>
                            </div>
                        </div>
                    </div>
                                      
                                    </ModalBody>
                                    <ModalFooter>
                                        <div className="d-flex justify-content-between w-100">
                                             <button className="btn btn-danger" onClick={() => this.AddModal()}> {t("Cancel")} </button>
                                             <button className="btn btn-success" onClick={() => this.addFunctions()}> {t("Save")} </button>
                                        </div>
                                    </ModalFooter>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                <ModalHeader toggle={this.Editclick}>{t("Edit function")}</ModalHeader>
                <ModalBody>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <Label>{t("Functions")} </Label>
                                <Input
                                    type="text"
                                    className="form-control "
                                    placeholder={t("Please enter function")}
                                    value={this.state.setFunc}
                                    onChange={(e) => this.handle_function_name(e.target.value)}
                                    autoFocus={true}
                                />
                                 <span className="text-danger">{t(this.state.errMsg.name)}</span>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="form-group mb-0">
                                <input className="form-check-input mr-1" type="checkbox"  onChange={(e) => this.handleStatus(e)} checked={this.state.active} id="flexCheckDefault"  />

                                <Label className="mb-0">{t("Active")}</Label>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-between w-100">
                        <button className="btn btn-danger" onClick={() => this.CloseModal()}> {t("Cancel")}  </button>
                        <Button className="btn btn-success" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                    </div>
                </ModalFooter>
            </Modal>
            <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removefuncModal}  >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">{t("Are you sure want to delete?")}</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.handleRemoveFunc()} >{t("Confirm")} </Button>{' '}
                                <Button className="mx-2" color="default" onClick={()=> this.RemoveModalClose()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog" style={{ width: "300px" }} isOpen={!!this.state.scsMsg}>
                    <div className="row" style={{ backgroundColor: "#1abc9c", padding: "10px", borderRadius: "4px" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group text-center w-100">
                                    {this.state.scsMsg}
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100">
                            <Button className="btn btn-light" onClick={() => this.setState({ scsMsg: "" })}> {t("Ok")} </Button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(SettingFunctions);
