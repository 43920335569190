import React, { Component } from 'react'
import AOS from 'aos';
import axios from "axios";
import Select from 'react-select'
import { APIURL } from '../../constants/Common';
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/icons.css";
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import bg_auth from "../../../assets/images/bg-auth-dark.webp"
import '../../../assets/css/Custom.css'
import logoDark from '../../../assets/images/logo-dark.svg';
import queryString from 'query-string'
import { Redirect } from "react-router-dom";
import Language from "../Language/Language";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Trans, withTranslation } from 'react-i18next';

import { Form, Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

class Impressum extends Component {
    userData;
    constructor() {
        super();
        this.state = {
            signupData: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                redirect: false,
                email: "",
                password: "",
                isLoading: "",
                tnc: "",
                license_type_id: "",
                package_id: "",
            },
            msg: "",
            scsMsg: "",
            startDate: '',
            days: [],
            months: [],
            years: [],
            countries: [],
            countryCode: 0,
            day: 0,
            month: 0,
            year: 0,
            showPassword: false,
            Package_List: [],
            amount: '',
            number_of_teams: '',
            modal: false,
            isLoading: false,
            packageId: '',
            license_type_id: '',
            email: "",
            password: "",
            redirect: false,
            errMsgEmail: "",
            errMsgPwd: "",
            errMsg: "",
            errMsgSignUp: "",

            unverified: false,
            checked: false,
            active: false,
            ScsRegisterModal:false
        }

    }

    handleAddClass() {
        document.body.classList.add('front-end')
    }

    componentDidMount() {
        this.handleAddClass()

    }
    render() {
        const { t } = this.props;

        return (
            <>
                <div >

                    <Header />

                    <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                        <strong></strong> {this.state.scsMsg} {this.state.msg}
                        <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                    </div>
                    <section className="hero">
                        <div className="overlay"></div>
                        <div className="cover text-center" style={{ backgroundImage: `url(${bg_auth})` }} >
                        </div>
                    </section>

                    <section id="maincolumn">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <h1 className="maintitle">Impressum</h1>
                                </div>

                            </div>
                        </div>
                    </section>

                    <section id="services-section">
                        <div className="container">

                            <div className="row"><div className="mar">
                                <h4>Kontaktadresse</h4>
                                <p>Teamorganizer GmbH<br />
                                    Barzloostrasse 2<br />
                                    8330 Pfäffikon ZH</p>
                            </div></div>

                            <div className="row"><div className="mar">
                                <h4>AGB</h4>
                                <p><a href="rechtliches">AGBs der TeamOrganizet GmbH</a></p>
                            </div></div>

                            <div className="row"><div className="mar">
                                <h4>Datenschutz</h4>
                                <p><a href="policies">Datenschutz-Erklärung</a></p>
                            </div></div>


                        </div>
                    </section>

                    <Footer />

                </div>
            </>
        )
    }}

export default withTranslation()(Impressum);
