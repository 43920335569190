import React, { Component } from "react";
import {Link} from 'react-router-dom'

export default class Footer extends Component {
    constructor() {
        super()
        this.state = {
            
        };
    }
    render() {
        return (
            <footer className="footer">
                {/*<div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <Link to="#"><i className="fe-facebook"></i></Link>
                            <Link to="#"><i className="fe-instagram"></i></Link>
                            TeamOrganizer GMbH
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-end footer-links d-none d-sm-block">
                                <Link to="/">imprint</Link>
                                <Link to="/">privacy</Link>
                                <Link to="/">design by stoz</Link>
                            </div>
                        </div>
                    </div>
                </div>*/}           
            </footer>
        );
    }
}