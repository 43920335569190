import React, { Component } from 'react'
import AOS from 'aos';
import axios from "axios";
import Select from 'react-select'
import { APIURL } from '../../constants/Common';
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/icons.css";
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import bg_auth from "../../../assets/images/bg-auth-dark.webp"
import '../../../assets/css/Custom.css'
import logoDark from '../../../assets/images/logo-dark.svg';
import queryString from 'query-string'
import { Redirect } from "react-router-dom";
import Language from "../Language/Language";
import { Trans, withTranslation } from 'react-i18next';

import { Form, Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

 class Rechtliches extends Component {
    userData;
    constructor() {
        super();
        this.state = {
            signupData: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                redirect: false,
                email: "",
                password: "",
                isLoading: "",
                tnc: "",
                license_type_id: "",
                package_id: "",
            },
            msg: "",
            scsMsg: "",
            startDate: '',
            days: [],
            months: [],
            years: [],
            countries: [],
            countryCode: 0,
            day: 0,
            month: 0,
            year: 0,
            showPassword: false,
            Package_List: [],
            amount: '',
            number_of_teams: '',
            modal: false,
            isLoading: false,
            packageId: '',
            license_type_id: '',
            email: "",
            password: "",
            redirect: false,
            errMsgEmail: "",
            errMsgPwd: "",
            errMsg: "",
            errMsgSignUp: "",

            unverified: false,
            checked: false,
            active: false,
            ScsRegisterModal:false,
        }

    }

     handleAddClass() {
        document.body.classList.add('front-end')
    }

    componentDidMount() {
        this.handleAddClass()

    }
    render() {
        const { t } = this.props;

        return (
            <>
            <div >

                <Header />

                <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                    <strong></strong> {this.state.scsMsg} {this.state.msg}
                    <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                </div>
                <section className="hero">
                    <div className="overlay"></div>
                    <div className="cover text-center" style={{ backgroundImage: `url(${bg_auth})` }} >
                    </div>
                </section>

                <section id="maincolumn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <h1 className="maintitle">Allgemeine Geschäftsbedingungen Teamorganizer GmbH
                                </h1>
                            </div>

                        </div>
                    </div>
                </section>

                <section id="services-section">
                    <div className="container">

                        <div className="row"><div className="heading-section"><h3 className="text-center">1. Geltungsbereich</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Diese Allgemeinen Geschäftsbedingungen („AGB“) der Teamorganizer GmbH, Barzloostrasse 2, CH-8330 Pfäffikon ZH (nachfolgend „Teamorganizer“), gelten für sämtliche Geschäftsbeziehungen von Teamorganizer mit ihren Kunden. </p>
                            <p>Teamorganizer ermöglicht über Ihre Webseiten und über die App «Teamorganizer» die Online-Planung von Terminen. </p>
                            <p>Als Kunde wird jede natürliche und juristische Person (Unternehmen und Privatpersonen) bezeichnet, welche sich auf den Webseiten von Teamorganizer oder der App registriert und die Angebote von Teamorganizer nutzt. </p>
                            <p>Diese AGB gelten ausschliesslich. Entgegenstehende, ergänzende oder von diesen AGB abweichende Bedingungen bedürfen zu ihrer Geltung der ausdrücklichen schriftlichen Bestätigung durch Teamorganizer. </p>
                            <p>Der Kunde bestätigt bei der Inanspruchnahme von Dienstleistungen von Teamorganizer respektive bei der Nutzung der Webseiten oder der App, diese AGB umfassend anzuerkennen. </p>
                            <p>Aus Gründen der besseren Lesbarkeit wird in diesen AGB bei Personenbezeichnungen und besonderen Hauptwörtern die männliche Form verwendet. Entsprechende Begriffe gelten im Sinne der Gleichbehandlung grundsätzlich für alle Geschlechter. Die verkürzte Sprachform hat nur redaktionelle Gründe und beinhaltet keine Wertung. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">2. Informationen auf den Webseiten und auf der App</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Die Webseiten und die App von Teamorganizer beinhalten Informationen und Möglichkeiten zur Planung von Terminen. Alle Angaben (Beschreibungen, Abbildungen und sonstige Angaben) sind nur als unverbindliche Angaben zu verstehen und stellen insbesondere keine Zusicherung von Eigenschaften oder Garantien dar, ausser es ist explizit anders vermerkt. Teamorganizer bemüht sich, sämtliche Angaben und Informationen auf ihren Webseiten und in der App korrekt, vollständig, aktuell und übersichtlich bereitzustellen, jedoch kann Teamorganizer weder ausdrücklich noch stillschweigend dafür Gewähr leisten.</p>
                            <p>Sämtliche Angaben und Angebote auf den Webseiten und in der App von Teamorganizer gelten als freibleibend und sind nicht als verbindliche Offerte zu verstehen. </p>
                            <p>Teamorganizer kann keine Garantie abgeben, dass die auf ihren Webseiten und in der App aufgeführten Angaben aktuell sind. Daher sind alle Angaben ohne Gewähr und können sich jederzeit und ohne Ankündigung ändern. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">3. Preise</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Vorbehaltlich anderweitiger Offerten verstehen sich alle Preise brutto in Schweizer Franken (CHF). Teamorganizer ist nicht mehrwertsteuerpflichtig.</p>
                                <p>Teamorganizer behält sich vor, die Preise jederzeit zu ändern. Es gelten die zum Zeitpunkt des Vertragsabschlusses vereinbarten Preise.</p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">4. Vertragsabschluss</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Der Vertragsabschluss kommt durch den Erwerb eines Abonnements durch den Kunden auf einer Webseite von Teamorganizer oder auf der App zustande. Diese AGB sind integraler Bestandteil des Vertrages zwischen Teamorganizer und dem Kunden. </p>
                            <p>Ergibt sich nach Abschluss des Vertrages, dass die bestellte Dienstleistung nicht oder nicht vollständig erbracht werden kann, ist Teamorganizer berechtigt, vom gesamten Vertrag oder von einem Vertragsteil zurückzutreten. Sollte die Zahlung des Kunden bereits bei Teamorganizer eingegangen sein, wird die Zahlung dem Kunden entsprechend zurückerstattet. Ist noch keine Zahlung erfolgt, wird der Kunde von der Zahlungspflicht befreit. Teamorganizer ist im Falle einer Vertragsauflösung zu keinen Ersatzleistungen und auch nicht zu Schadenersatz verpflichtet.</p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">5. Widerrufsrecht</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Dem Kunden wird während 14 Tagen nach Erwerb des Abonnements ein Widerrufsrecht gewährt. Die Frist gilt als eingehalten, wenn der Kunde Teamorganizer den Widerruf fristgerecht per E-Mail anzeigt. Der Widerruf bedarf keiner Begründung. </p>
                            <p>Die Ausübung des Widerrufsrechts führt zu einer Rückabwicklung des Vertrages. Die bereits geleistete Zahlung wird innerhalb von 14 Tagen an den Kunden zurückerstattet. </p>
                            <p>Das Widerrufsrecht entfällt, sobald der Kunde auf den Webseiten oder auf der App von Teamorganizer die erste Terminplanung vorgenommen hat. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">6. Vertragsgegenstand – Pflichten von Teamorganizer</h3></div>
                        <div className="mar">
                            <p>
                                Teamorganizer erbringt insbesondere die folgenden Leistungen:
                                <ul>
                                    <li>Zur Verfügung stellen der auf den Webseiten und auf der App beschriebenen Terminplanungs-funktionen</li>
                                    <li>Eigenes Login zum Dashboard mit Einsicht in geplante Termine, Abonnemente und Preise</li>
                                    <li>Automatische Erinnerungsfunktion für bevorstehende Termine</li>
                                    <li>Web-App und Mobile App zur Verwaltung der Inhalte</li>
                                    <li>Support durch das Teamorganizer Teams bei Fragen oder Problemen</li>
                                </ul>
                            </p>
                            <p>Um die Leistungen von Teamorganizer in Anspruch zu nehmen, muss sich der Kunde auf einer Webseite von Teamorganizer oder auf der App beim Erwerb eines Abonnements registrieren. Teamorganizer ist in keiner Weise an den Antrag des Kunden gebunden und kann diesen ohne Angabe von Gründen jederzeit ablehnen. Teamorganizer ist auch berechtigt, das Profil oder Einträge des Kunden ohne vorhergehende Rücksprache und ohne Angabe von Gründen zu löschen.  </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">7. Dauer und Beendigung des Abonnements</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Der Kunde kann Abonnemente erwerben mit einer Laufzeit von jeweils einem Jahr. Der Kunde hat auf der Webseite von Teamorganizer jederzeit die Möglichkeit einzustellen, ob das Abonnement automatisch verlängert wird oder nicht. Hat der Kunde die Möglichkeit der automatischen Verlängerung gewählt, verlängert es sich automatisch um ein weiteres Jahr. </p>
                            <p>Sollte ein Umstand von ausserhalb des Einflussbereichs von Teamorganizer eintreten, welcher es Teamorganizer dauernd verunmöglicht, ihre Webseiten oder die App weiter zu betreiben (technische Gegebenheiten oder höhere Gewalt), so gilt diese Vereinbarung als per sofort aufgehoben und kann Teamorganizer dafür und für allfällige Folgen und Schäden nicht belangt werden. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">8. Garantien</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Teamorganizer sichert zu, dass sie Inhaberin sämtlicher Rechte an ihren Webseiten und an der App ist und keine Kenntnis von Ansprüchen Dritter hat, welche sich gegen die Rechte an ihren Webseiten und an der App richten.</p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">9. Betrieb, Weiterentwicklungen und Verbesserungen</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Teamorganizer verpflichtet sich, ihre Webseiten und die App während der Vertragsdauer mit dem Kunde zu betreiben, technisch auf einem aktuellen Stand zu halten und, wenn notwendig und sinnvoll, neuen Bedürfnissen anzupassen und/oder zu verbessern. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">10. Immaterialgüterrechte</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Sämtliche Rechte an den Abbildungen, Dienstleistungen, Produkten und allfälligen Marken auf den Webseiten und auf der App stehen Teamorganizer zu oder sie ist zu deren Benutzung vom Inhaber (Kunde) berechtigt.</p>
                            <p>Weder dieser Vertrag noch dazugehörige Individualvereinbarungen haben jedoch die Übertragung von Immaterialgüterrechten zum Inhalt, es sei denn dies werde explizit erwähnt.</p>
                            <p>Für von Dritten auf die Webseiten oder die App von Teamorganizer einestellen Bilder, Filme oder sonstige Inhalte schliesst Teamorganizer jegliche Haftung aus. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">11. Haftung</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Teamorganizer schliesst jede Haftung, unabhängig von ihrem Rechtsgrund, sowie Schadenersatz-ansprüche gegen Teamorganizer und allfällige Hilfspersonen und Erfüllungsgehilfen, aus. Teamorganizer haftet insbesondere nicht für kurzzeitige, temporäre Unterbrüche der Verfügbarkeit der Webseiten oder der App, für indirekte Schäden und Mangelfolgeschäden, entgangenen Gewinn oder sonstige Personen-, Sach- und reine Vermögensschäden des Kunden und von Dritten. Teamorganizer haftet insbesondere auch nicht für Geschäfte zwischen Kunden unter sich und mit Dritten, welche über die Webseiten von Teamorganizer und/oder der App geschlossen werden. Vorbehalten bleibt eine weitergehende zwingende gesetzliche Haftung, beispielsweise für grobe Fahrlässigkeit oder rechtswidrige Absicht. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">12. Geheimhaltung</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Teamorganizer verpflichtet sich, die aufgrund und im Verlauf der Umsetzung des Vertrages zwischen den Parteien erhältlich gemachten Informationen mit vertraulichem Charakter geheim zu halten. Dies gilt nicht für vertrauliche Informationen, welche im Zeitpunkt des Erhalts allgemein bekannt sind, in einem späteren Zeitpunkt allgemein bekannt werden oder dem Empfänger bereits bekannt waren. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">13. Verschiedenes</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Die Rechte und Pflichten aus diesem Vertrag sind ohne vorgängige Zustimmung von Teamorganizer nicht übertragbar. </p>
                            <p>Sollten einzelne Bestimmungen dieser Vereinbarung unwirksam oder unvollständig sein oder sollte die Erfüllung unmöglich werden, so wird hierdurch die Wirksamkeit der übrigen Teile des Vertrages nicht beeinträchtigt. </p>
                            <p>Ergänzungen, Abänderungen oder die Aufhebung des vorliegenden Vertrages sind nur in Schriftform und von beiden Vertragspartnern unterzeichnet rechtsgültig. Dies gilt insbesondere auch für diese Schriftformklausel. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">14. Änderungen</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Diese Allgemeinen Geschäftsbedingungen können von Teamorganizer jederzeit geändert werden. Die neue Version wird auf den Webseiten und auf der App publiziert und tritt im Verhältnis zum Kunde in Kraft, wenn der Kunde nicht innert zwei Wochen seit der entsprechenden Benachrichtigung per E-Mail schriftlich mit eingeschriebener Post widerspricht.   </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">15. Datenschutz</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Der Schutz der personenbezogenen Daten unserer Kunden ist Teamorganizer wichtig.  Teamorganizer nimmt das Thema Datenschutz ernst und achtet auf entsprechende Sicherheit. Teamorganizer verarbeitet und pflegt personenbezogene Daten im Einklang mit den Bestimmungen des Bundes-gesetzes über den Datenschutz (DSG), der Verordnung zum Bundesgesetz über den Datenschutz (VDSG), des Fernmeldegesetzes (FMG) und, soweit anwendbar, anderen datenschutzrechtliche Bestimmungen, insbesondere der Datenschutzgrundverordnung der Europäischen Union (DSGVO). </p>
                            <p>Der Kunde ist damit einverstanden, dass Teamorganizer seine Daten zur Durchführung von Werbe-massnahmen (online oder print) gebraucht, insbesondere um ihm Informationen über Angebote zukommen zu lassen.</p>
                            <p>Der Kunde hat die Möglichkeit, diesen Werbezwecken jederzeit zu widersprechen durch Mitteilung an Teamorganizer.</p>
                            <p>Für von Dritten auf die Webseiten oder die App von Teamorganizer einestellen Bilder, Filme oder sonstige Inhalte schliesst Teamorganizer jegliche Haftung aus.</p>
                            <p>Im Übrigen wird auf die Datenschutzerklärung von Teamorganizer, einsehbar auf ihren Webseiten und auf der App, verwiesen. </p>
                        </div></div>

                        <div className="row"><div className="heading-section"><h3 className="text-center">16. Weitere Bestimmungen</h3></div></div>
                        <div className="row"><div className="mar">
                            <p>Bei höherer Gewalt und unvorhergesehenen Ereignissen, welche von Teamorganizer nicht zu vertreten sind und eine Einschränkung der Dienstleistungen von Teamorganizer erforderlich machen, ist Teamorganizer für die Dauer der Einschränkung von ihren Pflichten befreit und kann Teamorganizer für allfällige Schäden daraus auch nicht haftpflichtig gemacht werden. </p>
                            <p>Sollten einzelne Bestimmungen dieser AGB ganz oder teilweise nichtig und/oder unwirksam sein, bleibt die Gültigkeit und/oder Wirksamkeit der übrigen Bestimmungen oder Teile solcher Bestimmungen unberührt. Die ungültigen und/oder unwirksamen Bestimmungen werden durch solche ersetzt, die dem Sinn und Zweck der ungültigen und/oder unwirksamen Bestimmungen in rechtwirksamer Weise wirtschaftlich am nächsten kommt. Das gleiche gilt bei eventuellen Lücken der Regelung.</p>
                            <p>Im Falle von Streitigkeiten kommt ausschliesslich materielles Schweizer Recht unter Ausschluss von kollisionsrechtlichen Normen zur Anwendung. Das Übereinkommen der Vereinten Nationen über Verträge über den internationalen Produktekauf (SR 0.221.221.1) wird explizit ausgeschlossen.</p>
                            <p>Der Gerichtsstand ist Zürich.</p>
                            <p>Pfäffikon ZH, 25. September 2022</p>
                        </div></div>

                    </div>
                </section>

                <Footer />

            </div>
            </>
        )
    }}

export default withTranslation()(Rechtliches);
