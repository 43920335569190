import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import { Redirect, Link } from "react-router-dom";
import { APIURL } from '../../constants/Common';
import axios from "axios";
import { Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import Sidebar from "../../GeneralComponents/Sidebar";
import Select from 'react-select'
import dateFormat from 'dateformat';
import { withTranslation } from 'react-i18next';

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};

class Document extends Component {
    constructor(props) {
        super(props)
        this.state = {
            sidebarClass: "sidebar-disable",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            activeTab: "",
            Loader: false,
            error: [],
            errMsg: {},
            name: "",
            team_name: "",
            Documents_list: [],
            DocTypes_list: [],
            addModal: false,
            modal: false,
            doc_type: [],
            doc_file: "",
            file_path: "",
            open_window: [{ value: 0, label: 'Yes' }],
            teamId: this.props.match.params.id,
            edit_doc_file: "",
            type: "document",
            status: "published",
            url: "",
            removeDocModal: false,
            index: "",
            DocId: "",
            SubscriptionModal: false,
            subscriptionPlan: "No",
            is_owner: "No",
            day: 0,
            ActiveTeam: 0,
            isPermission: 1,
            no_record: false,
            yesNo: []
        };
        this.toggle = this.toggle.bind(this)
        this.removeDocModal = this.removeDocModal.bind(this)
        this.Editclick = this.Editclick.bind(this)
    }

    toggle() {
        const { t } = this.props;
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        this.setState({
            addModal: !this.state.addModal,
            name: "",
            doc_id: "",
            doc_type: [],
            doc_file: "",
            url: "",
            type: "document",
            status: "published",
            open_window: [{ value: 0, label: t('Yes') }],
            errMsg: {},
        });
    }

    Editclick(e) {
        const { t } = this.props;
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }
        console.log('documrnt', e.name)
        this.setState({
            modal: !this.state.modal,
            name: e.name,
            doc_file: "",
            doc_type: [{ value: e.doc_type_id, label: e.doc_type }],
            open_window: [{ value: e.open_new_window, label: e.open_new_window ? t('Yes') : t('No') }],
            doc_id: e.id,
            edit_doc_file: e.type === "document" ? e.file_name : "",
            url: e.type === "link" ? e.file_name : "",
            type: e.type,
            status: e.status,
            errMsg: {},
        });
    }

    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    SelectDocTypes = (e) => {
        if (e) {
            this.setState({
                doc_type: [{ value: e.value, label: e.label }],
                errMsg: { doc_type_id: [""] },
            })
        }
        else {
            this.setState({
                errMsg: { doc_type_id: ["Please select document type"] }
            });
        }
    };

    handleDocumentFile(e) {
        if (e) {
            this.setState({
                doc_file: e,
                errMsg: { doc_file: [""] },
            })
        }

    }
    handleDocumentUrl(e) {
        if (e) {
            this.setState({
                url: e.target.value
            })
        }

    }



    handleDocumentType(e) {
        if (e) {
            this.setState({
                type: e.target.value
            })
        }

    }

    handleDocumentStatus(e) {
        if (e) {
            this.setState({
                status: e.target.value
            })
        }

    }

    handleChange = (e) => {
        if (e) {
            this.setState({
                open_window: [{ value: e.value, label: e.label }],
                errMsg: { open_window: [""] },
            })

        }
        else {
            this.setState({
                errMsg: { open_window: ["Please select open window"] },
            });
        }
    };



    componentDidMount() {
        if (this.props.match.params.id > 0) {
            this.getDocTypes(this.props.match.params.id);
            this.getDocuments(this.props.match.params.id);
            this.getTeamDetails();
            this.getExpirationPlanMsg()
        }
        this.getOption();
    }

    componentWillReceiveProps() {
        this.getOption();
    }

    removeDocModal(idx, e) {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }

        this.setState({
            DocId: e.id,
            index: idx,
            removeDocModal: !this.state.removeDocModal
        })
    }

    removeModalClose() {
        if (this.state.ActiveTeam === 0) {
            this.Sub_CloseModal()
            return false;
        }

        this.setState({
            removeDocModal: false
        })
    }

    doDelete(index, item) {
        this.setState({ Loader: true });

        const formData = new FormData();
        formData.append('id', this.state.DocId);
        formData.append('team_id', this.state.teamId);
        var token = this.state.token

        /* fetch repos with axios */
        axios
            .post(APIURL + "document/delete", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then(result => {

                const newRep = [...this.state.Documents_list];
                newRep.splice(this.state.index, 1);

                this.setState({
                    Documents_list: newRep,
                    Loader: false,
                    removeDocModal: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false,
                    removeDocModal: false
                })
            );
    }

    getDocTypes(id) {
        const formData = new FormData();
        formData.append('team_id', id);
        var token = this.state.token
        axios
            .post(APIURL + "document_type/list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    let docTypes = response.data.data;
                    for (var c = 0; c < docTypes.length; c++) {
                        this.state.DocTypes_list.push({ value: docTypes[c].id, label: docTypes[c].name })
                    }
                }
            });
    };

    getDocuments(id) {
        const formData = new FormData();
        formData.append('team_id', id);
        var token = this.state.token
        axios
            .post(APIURL + "document/list", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ Documents_list: response.data.data });
                }
                this.setState({ no_record: true });
            });
    };

    onChangehandler = (e) => {
        if (e.target.value) {
            this.setState({
                name: e.target.value,
                errMsg: { name: '' }
            });
        }
        else {
            this.setState({
                name: '',
                errMsg: { name: "Please enter document name" },
            });
        }
    };

    handleAddDocument = () => {
        // this.reloadPage()
        const errMsg = this.state.errMsg;
        if (this.state.name) {
            if (Array.isArray(this.state.doc_type) && this.state.doc_type.length) {
                this.setState({
                    Loader: true
                })
                // console.log("ids", this.state.DocTypes_list)
                const formData = new FormData();

                formData.append('team_id', this.props.match.params.id);
                formData.append('name', this.state.name);
                formData.append('open_new_window', this.state.open_window[0].value);
                formData.append('doc_type_id', this.state.doc_type[0].value);
                if (this.state.type === "document") {
                    formData.append('doc_file', this.state.doc_file);
                }
                else {
                    formData.append('doc_file', '');
                }
                formData.append('status', this.state.status);
                formData.append('type', this.state.type);
                formData.append('url', this.state.url);
                var token = this.state.token

                const config = {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${token}`
                    }
                }

                var app_url = APIURL + "document/create";

                axios
                    .post(app_url, formData, config)
                    .then((response) => {
                        console.log(response.data)
                        this.getDocuments(this.props.match.params.id);
                        this.setState({
                            addModal: false,
                            Loader: false
                        })
                        // this.toggle();
                    })
                    .catch((error) => {
                        if (error.response.data.message === "validation_error") {
                            this.setState({
                                errMsg: error.response.data.errors,
                                Loader: false
                            })
                        }
                        else {
                            this.setState({
                                error,
                                Loader: false
                            })
                        }
                    });

            }
            else {
                errMsg['doc_type_id'] = 'Please select document type';
                errMsg['name'] = '';
                this.setState({ errMsg });
            }
        }
        else {
            errMsg['name'] = 'Please enter document name';
            this.setState({ errMsg });
        }
    }

    onSubmit = (e) => {
        this.setState({
            Loader: true
        });
        const formData = new FormData();

        formData.append('name', this.state.name);
        formData.append('id', this.state.doc_id);
        formData.append('team_id', this.props.match.params.id);
        formData.append('open_new_window', this.state.open_window[0].value);
        formData.append('doc_type_id', this.state.doc_type[0].value);
        if (this.state.type === "document") {
            formData.append('doc_file', this.state.doc_file);
        }
        else {
            formData.append('doc_file', '');
        }
        formData.append('type', this.state.type);
        formData.append('url', this.state.url);
        formData.append('status', this.state.status);
        var token = this.state.token

        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        }
        var app_url = APIURL + "document/update";


        axios
            .post(app_url, formData, config)
            .then((response) => {
                this.getDocuments(this.props.match.params.id);
                this.setState({
                    msg: response.data.message,
                    // errMsg: '',
                    redirect: true,
                    modal: false,
                    Loader: false
                });
            })
            .catch((error) => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }
            });
    };

    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })
            });
    }
    getExpirationPlanMsg = () => {
        console.log(this.state.user.user_id)
        const formData = new FormData();
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan === "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length === 0) {
                    isPermission = 0;
                }
                else if (response.data.member.length === 1) {
                    if (response.data.member[0].is_admin === 0 && response.data.member[0].is_teamleader === 0) {
                        isPermission = 0;
                    }
                }

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission

                })
            });
    }
    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    Sub_CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    getOption() {
        const { t } = this.props;
        let yesNo = [];
        yesNo.push({ value: 0, label: t('No') })
        yesNo.push({ value: 1, label: t('Yes') })

        let open_window = this.state.open_window;
        open_window = [{ value: open_window[0].value, label: open_window[0].label }]


        this.setState({
            yesNo: yesNo,
            open_window: open_window
        },()=>{
            console.log(this.state.yesNo);
        })
    }

    render() {
        const { t } = this.props;
        console.log("Future", this.props)
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
        const { Documents_list } = this.state

        return (
            <React.Fragment>
                <div id="wrapper">
                    {
                        this.state.Loader ?
                            <div className="loader">
                                <Spinner style={{ width: '3rem', height: '3rem' }} />
                            </div>
                            :
                            ""
                    }
                    <Sidebar
                        data={{
                            // teamInfo: this.teamInfo.bind(this),
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "document",
                            visible:this.props.location.visible
                        }} />

                    <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                        <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                        <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                            <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                {
                                    this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                    <h6 className="mt-3">Upgrade Package</h6>
                                }
                                {
                                    this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                    <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner</h6>
                                }
                                {
                                    this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                    <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team</h6>
                                }
                                {
                                    this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                    <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                                }
                                {
                                    this.state.day >= 30 && this.state.day < 61 &&
                                    <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated</h6>
                                }

                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div className="d-flex justify-content-between w-100 text-center">
                                <Button className="btn btn-danger" onClick={() => this.Sub_CloseModal()}>{t("Cancel")} </Button>
                                {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>{t("Upgrade Package")}</Button> : ""}
                            </div>
                        </ModalFooter>
                    </Modal>
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">{this.state.team_name} | {t("Documents")}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <Link to={"/documents/" + this.state.teamId} className="nav-link active">
                                                    <span>{t("Documents")}</span></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/document_types/" + this.state.teamId} className="nav-link">
                                                    <span>{t("Document types")}</span></Link>
                                            </li>
                                        </ul>

                                        <div className="tab-content">
                                            <div className="tab-pane active" id="events1">
                                                <div className="row mb-3">
                                                    <div className="col-12">
                                                        <Button
                                                            id="add_attrs"
                                                            onClick={this.toggle}
                                                            className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                                                        >
                                                            <i className="mdi mdi-plus"></i>{t("Add document")}
                                                        </Button>
                                                    </div>
                                                </div>

                                                <div className="row mt-1">
                                                    <div className="col-12">
                                                        <div className="card">
                                                            <div className="card-body">
                                                                <div className="table-responsive table-wrapper">
                                                                    <React.Fragment>
                                                                        <table className="table  tableFixHead">
                                                                            <thead className="bg-light">
                                                                                <tr className="fw-medium">
                                                                                    {/* <th>ID</th> */}
                                                                                    <th width="130">{t("Author")}</th>
                                                                                    <th>{t("Name")}</th>
                                                                                    <th>{t("Document type")}</th>
                                                                                    <th>{t("Date")}</th>
                                                                                    <th style={{ width: "130px" }}>{t("Open new window")}</th>
                                                                                    <th>{t("Status")}</th>
                                                                                    <th className="text-center" style={{ width: "170px" }}>{t("Action")}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className="font-14">
                                                                                {Documents_list.length > 0 && Documents_list.map((result, index) =>
                                                                                    <tr>
                                                                                        {/* <td><b>#{index + 1}</b></td> */}
                                                                                        <td>{result.author} </td>
                                                                                        <td>{result.name}</td>
                                                                                        <td> {result.doc_type}</td>
                                                                                        <td >{dateFormat(result.created_at, "dd.mm.yyyy")}</td>
                                                                                        <td className="text-center">{result.open_new_window ? <i className="ri-check-line"></i> : <i className="ri-close-line"></i>}</td>
                                                                                        <td> {t(result.status)}</td>
                                                                                        <td>
                                                                                            <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(result)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span> </Button>
                                                                                            <Button size="sm" color="danger" className="ms-2 mobile-icon" onClick={(e) => this.removeDocModal(index, result)}><i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                                                        </td>
                                                                                    </tr>
                                                                                )}
                                                                                {this.state.no_record === true && !Documents_list.length &&
                                                                                    <tr>
                                                                                        <td colSpan="12" className="text-left">{t("No documents available")}</td>
                                                                                    </tr>
                                                                                }

                                                                            </tbody>
                                                                        </table>
                                                                    </React.Fragment>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <Modal size="md" isOpen={this.state.addModal} >
                                                    <ModalHeader toggle={this.toggle}>{t("Add document")}</ModalHeader>
                                                    <ModalBody>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <Label>{t("Name")}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={t("Enter document name")}
                                                                        name="name"
                                                                        required=""
                                                                        value={this.state.name}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.name)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <Label>{t("Document type")}</Label>

                                                                    <Select
                                                                        aria-label=".form-select-lg example"
                                                                        required="required"
                                                                        placeholder={t("Select")}
                                                                        options={this.state.DocTypes_list}
                                                                        value={this.state.doc_type}
                                                                        onChange={this.SelectDocTypes}
                                                                        styles={colourStyles}
                                                                        maxMenuHeight={160}
                                                                        menuPlacement="auto"
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.doc_type_id)}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group">

                                                                    <input type="radio" value="document" checked={this.state.type === "document"} onChange={(e) => this.handleDocumentType(e)} />
                                                                    <Label className="ml-1">{t("Document")}</Label>

                                                                    <input className="ml-2" value="link" type="radio" checked={this.state.type === "link"} onChange={(e) => this.handleDocumentType(e)} />
                                                                    <Label className="ml-1">{t("Link")}</Label>
                                                                </div>
                                                                <span className="text-danger">{t(this.state.errMsg.type)}</span>

                                                            </div>
                                                            {
                                                                this.state.type === "document" ?

                                                                    <div className="col-sm-12" >
                                                                        <div className="form-group">
                                                                            <Label>{t("File")}</Label>
                                                                            <input type="file" onChange={(e) => this.handleDocumentFile(e.target.files[0])} className="form-control" id="example-fileinput" />
                                                                            <span style={{ fontSize: "12px" }}>({t("Only pdf, xls, xlsx, doc, docx, png, jpeg, jpg, gif, ppt, pptx, zip format and size upto 2 MB is allowed")})
                                                                            </span><br />
                                                                            <span className="text-danger">{t(this.state.errMsg.doc_file)}</span>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <Label>{t("Document url")} </Label>
                                                                            <input
                                                                                type="text"
                                                                                onChange={(e) => this.handleDocumentUrl(e)}
                                                                                className="form-control"
                                                                                placeholder=" https://www"
                                                                            />
                                                                            <span style={{ fontSize: "12px" }}>({t("Document Url Format - https://www.  or  http://www.")})
                                                                            </span><br />
                                                                            <span className="text-danger">{t(this.state.errMsg.url)}</span>
                                                                        </div>
                                                                    </div>
                                                            }
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <Label>{t("Open new window")}</Label>
                                                                    <Select
                                                                        placeholder={t("Select")}
                                                                        value={this.state.open_window}
                                                                        onChange={this.handleChange}
                                                                        options={this.state.yesNo}
                                                                        defaultValue={this.state.yesNo[1]}
                                                                        styles={colourStyles}
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.open_new_window)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">

                                                                    <input type="radio" value="not published" checked={this.state.status === "not published"} onChange={(e) => this.handleDocumentStatus(e)} />
                                                                    <Label className="ml-1">{t("Not published")}</Label>

                                                                    <input className="ml-2" value="published" type="radio" checked={this.state.status === "published"} onChange={(e) => this.handleDocumentStatus(e)} />
                                                                    <Label className="ml-1">{t("Published")}</Label>
                                                                </div>
                                                                <span className="text-danger">{t(this.state.errMsg.type)}</span>

                                                            </div>
                                                        </div>
                                                    </ModalBody>

                                                    <ModalFooter>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                                                            <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddDocument()}>{t("Save")} </Button>
                                                        </div>
                                                    </ModalFooter>
                                                </Modal>

                                                <Modal size="md" isOpen={this.state.modal} >
                                                    <ModalHeader toggle={this.Editclick}>{t("Edit document")}</ModalHeader>
                                                    <ModalBody>
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <Label>{t("Name")}</Label>
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={t("Enter document name")}
                                                                        name="name"
                                                                        required=""
                                                                        value={this.state.name}
                                                                        onChange={this.onChangehandler}
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.name)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">
                                                                    <Label>{t("Document type")}</Label>

                                                                    <Select
                                                                        aria-label=".form-select-lg example"
                                                                        required="required"
                                                                        placeholder={t("Select")}
                                                                        options={this.state.DocTypes_list}
                                                                        value={this.state.doc_type}
                                                                        onChange={this.SelectDocTypes}
                                                                        styles={colourStyles}
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.doc_type_id)}</span>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-12">
                                                                <div className="form-group">

                                                                    <input type="radio" value="document" checked={this.state.type === "document"} onChange={(e) => this.handleDocumentType(e)} />
                                                                    <Label className="ml-1">{t("Document")}</Label>

                                                                    <input className="ml-2" value="link" type="radio" checked={this.state.type === "link"} onChange={(e) => this.handleDocumentType(e)} />
                                                                    <Label className="ml-1">{t("Link")}</Label>
                                                                </div>
                                                                <span className="text-danger">{t(this.state.errMsg.type)}</span>
                                                            </div>
                                                            {
                                                                this.state.type === "document" ?
                                                                    <div>
                                                                        <div className="col-12">
                                                                            <input type="file" onChange={(e) => this.handleDocumentFile(e.target.files[0])} className="form-control" id="example-fileinput" />
                                                                            <span style={{ fontSize: "12px" }}>({t("Only pdf, xls, xlsx, doc, docx, png, jpeg, jpg, gif, ppt, pptx, zip format and size upto 2 MB is allowed")})
                                                                            </span>
                                                                            <span className="text-danger">{t(this.state.errMsg.doc_file)}</span>
                                                                        </div>

                                                                        <div className="col-12 mt-2">
                                                                            <a style={{ color: "#0c2541" }} rel="noopener noreferrer" target="_blank" href={this.state.file_path}>
                                                                                {this.state.doc_file === "" &&
                                                                                    <span>{this.state.edit_doc_file}</span>
                                                                                }
                                                                            </a>
                                                                        </div> </div>
                                                                    :
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group">
                                                                            <Label>{t("Document url")}</Label>
                                                                            <input type="text" value={this.state.url} onChange={(e) => this.handleDocumentUrl(e)} className="form-control" id="example-fileinput" />
                                                                            <span style={{ fontSize: "12px" }}>({t("Document Url Format - https://www.  or  http://www.")})
                                                                            </span><br />
                                                                            <span className="text-danger">{t(this.state.errMsg.url)}</span>
                                                                        </div>
                                                                    </div>
                                                            }

                                                            <div className="col-sm-12 mt-2">
                                                                <div className="form-group">
                                                                    <Label>{t("Open new window")}</Label>
                                                                    <Select
                                                                        placeholder={t("Select")}
                                                                        value={this.state.open_window}
                                                                        onChange={this.handleChange}
                                                                        options={this.state.yesNo}
                                                                        styles={colourStyles}
                                                                    />
                                                                    <span className="text-danger">{t(this.state.errMsg.open_new_window)}</span>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group">

                                                                    <input type="radio" value="not published" checked={this.state.status === "not published"} onChange={(e) => this.handleDocumentStatus(e)} />
                                                                    <Label className="ml-1">{t("Not published")}</Label>

                                                                    <input className="ml-2" value="published" type="radio" checked={this.state.status === "published"} onChange={(e) => this.handleDocumentStatus(e)} />
                                                                    <Label className="ml-1">{t("Published")}</Label>
                                                                </div>
                                                                <span className="text-danger">{t(this.state.errMsg.type)}</span>
                                                            </div>
                                                        </div>
                                                    </ModalBody>
                                                    <ModalFooter>
                                                        <div className="d-flex justify-content-between w-100">
                                                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}>{t("Cancel")} </Button>
                                                            <Button className="btn btn-success" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                                                        </div>
                                                    </ModalFooter>
                                                </Modal>
                                                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeDocModal}  >
                                                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                                                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                                                            <h6 className="mt-3">{t("Are you sure want to delete?")}</h6>
                                                            <p className="mt-4 col-12">
                                                                <Button className="mx-2" width="50px" color="light" onClick={() => this.doDelete()} >{t("Confirm")} </Button>{' '}
                                                                <Button className="mx-2" color="default" onClick={() => this.removeModalClose()}>Cancel</Button>
                                                            </p>
                                                        </div>
                                                    </ModalBody>
                                                </Modal>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Document);