import React, { Component } from 'react'
import Select from 'react-select'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Form, Input, Spinner, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";


import axios from "axios";
import { APIURL } from '../constants/Common';
import { withTranslation } from 'react-i18next';

const options = [
    { value: 0, label: 'Nein' },
    { value: 1, label: 'Ja' },
];

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333",
        };
    }
};

class AnswerOptions extends Component {
    constructor() {
        super();

        this.state = {
            Event_Types_List: [],
            Event_type: "",
            Color_code: [],
            default_deadline_days: [],
            default_duration_hours: [],
            default_infomail_uncertain: "",
            default_infomail_uncertain_days: [],
            default_infomail_unregistered: "",
            default_infomail_unregistered_days: [],
            infomail_uncertain: [{ value: 0, label: "Nein" }],
            infomail_unregistered: [{ value: 0, label: "Nein" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            gather_time: "",
            errMsg: "",
            atLeast: "",
            sortRow: [],
            Loader: false,
            modal: false,
            DefaultTask: [],
            Task_list: [],
            TaskSelected: [],
            active: true,
            yesNo: [],
            eventTypeId: '',
        }
        this.toggle = this.toggle.bind(this)
    }
    toggle(e) {
        const { t } = this.props;
        this.setState({
            modal: !this.state.modal,
            errMsg: "",
            Event_type: "",
            Color_code: ["#b2ceeb"],
            default_deadline_days: [],
            default_duration_hours_hrs: "",
            default_duration_hours_min: "",
            default_infomail_uncertain: "",
            gather_time_min: "",
            gather_time_hrs: "",
            default_infomail_uncertain_days: [],
            default_infomail_unregistered: "",
            default_infomail_unregistered_days: [],
            infomail_uncertain: [{ value: 0, label: t("Nein") }],
            infomail_unregistered: [{ value: 0, label: t("Nein") }],
            TaskSelected: [],
            eventTypeId: '',
        });
    }

    uncertainSelect = (e) => {
        this.setState({ infomail_uncertain: [{ value: e.value, label: e.label }] });

    };

    unregisteredSelect = (e) => {
        this.setState({ infomail_unregistered: [{ value: e.value, label: e.label }] });

    };

    getTasks() {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "event/get_tasks", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let Task_listState = [];
                let Task_list = response.data.data; let DefultSec = [];
                for (var c = 0; c < Task_list.length; c++) {
                    Task_listState.push({ value: Task_list[c].task_id, label: Task_list[c].task_name })

                }

                this.setState({

                    TaskSelected: DefultSec,
                    Task_list: Task_listState
                })
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }


    handleAddEvtType = () => {
        const { t } = this.props;
        if (this.state.eventTypeId !== '') {
            if(this.state.default_duration_hours_hrs=="")
            {
                this.state.default_duration_hours_hrs="00";
            }
             if(this.state.default_duration_hours_min=="")
            {
                this.state.default_duration_hours_min="00";
            }
    
              if(this.state.gatherTime_hrs=="")
            {
                this.state.gatherTime_hrs="00";
            }
    
              if(this.state.gatherTime_min=="")
            {
                this.state.gatherTime_min="00";
            }
    
            let default_duration_hours = this.state.default_duration_hours_hrs+":"+this.state.default_duration_hours_min;
            let gather_time = this.state.gatherTime_hrs+":"+this.state.gatherTime_min;
            const formData = new FormData();
            formData.append('event_type', this.state.Event_type);
            formData.append('color_code', this.state.Color_code);
            formData.append('deadline', this.state.default_deadline_days);
            formData.append('duration', default_duration_hours);
            formData.append('gather_time', gather_time);
            formData.append('infomail_uncertain', this.state.infomail_uncertain[0].value);
            formData.append('infomail_uncertain_days', this.state.infomail_uncertain[0].value ? this.state.default_infomail_uncertain_days : null);
            formData.append('infomail_unregistered', this.state.infomail_unregistered[0].value);
            formData.append('infomail_unregistered_days', this.state.infomail_unregistered[0].value ? this.state.default_infomail_unregistered_days : null);
            formData.append('team_id', this.props.data.teamId);
            formData.append('active', Number(this.state.active));
            formData.append('default_task', JSON.stringify(this.state.TaskSelected));
    
            this.setState({
                Loader: true
            })
            var app_url = APIURL + "edit_event_type/" + this.state.eventTypeId
            var token = this.state.token
            axios
                .post(app_url, formData,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                .then((response) => {
                    this.getEventType(this.props.data.teamId);
                    this.setState({
                        errMsg: "",
                        Event_type: '',
                        Color_code: '',
                        gather_time: '',
                        default_duration_hours: '',
                        default_deadline_days: '',
                        default_infomail_uncertain: 0,
                        default_infomail_uncertain_days: '',
                        default_infomail_unregistered: 0,
                        default_infomail_unregistered_days: '',
                        infomail_uncertain: [{ value: 0, label: t("No") }],
                        infomail_unregistered: [{ value: 0, label: t("No") }],
                        errMsgAttr: '',
                        modal: false,
                        Loader: false,
                        eventTypeId: '',
                    });
    
                })
                .catch(error => {
    
                    if (error.response.data.message == "validation_error") {
                        this.setState({
                            errMsg: error.response.data.errors,
                            isLoading: false,
                            Loader: false,
                        })
                    }
                    else {
                        this.setState({
                            error,
                            isLoading: false,
                            Loader: false,
                        })
                    }
    
                }
                );
        } else  {
        if (this.state.default_duration_hours_hrs == "") {
            this.state.default_duration_hours_hrs = "00";
        }
        if (this.state.default_duration_hours_min == "") {
            this.state.default_duration_hours_min = "00";
        }

        if (this.state.gather_time_hrs == "") {
            this.state.gather_time_hrs = "00";
        }

        if (this.state.gather_time_min == "") {
            this.state.gather_time_min = "00";
        }
        let default_duration_hours = this.state.default_duration_hours_hrs + ":" + this.state.default_duration_hours_min
        let gather_time = this.state.gather_time_hrs + ":" + this.state.gather_time_min
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('event_type_id', '');
        formData.append('event_type', this.state.Event_type);
        formData.append('color_code', this.state.Color_code);
        formData.append('deadline', this.state.default_deadline_days);
        formData.append('duration', default_duration_hours);
        formData.append('gather_time', gather_time);
        formData.append('infomail_uncertain', this.state.infomail_uncertain[0].value);
        formData.append('infomail_uncertain_days', this.state.infomail_uncertain[0].value ? this.state.default_infomail_uncertain_days : null);
        formData.append('infomail_unregistered', this.state.infomail_unregistered[0].value);
        formData.append('infomail_unregistered_days', this.state.infomail_unregistered[0].value ? this.state.default_infomail_unregistered_days : null);
        formData.append('default_task', JSON.stringify(this.state.TaskSelected));
        formData.append('active', Number(this.state.active));
        this.setState({
            Loader: true
        })
        var app_url = APIURL + "create_event_types";
        var token = this.state.token
        axios
            .post(app_url, formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                this.getEventType(this.props.data.teamId);
                this.setState({
                    errMsg: "",
                    Event_type: '',
                    Color_code: '',
                    gather_time: '',
                    default_duration_hours: '',
                    default_deadline_days: '',
                    default_infomail_uncertain: 0,
                    default_infomail_uncertain_days: '',
                    default_infomail_unregistered: 0,
                    default_infomail_unregistered_days: '',
                    infomail_uncertain: [{ value: 0, label: t("No") }],
                    infomail_unregistered: [{ value: 0, label: t("No") }],
                    errMsgAttr: '',
                    modal: false,
                    Loader: false,
                    eventTypeId: '',
                });

            })
            .catch(error => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        isLoading: false,
                        Loader: false,
                    })
                }
                else {
                    this.setState({
                        error,
                        isLoading: false,
                        Loader: false,
                    })
                }

            }
            );
        }
    }

    handleRemoveEvtType(e) {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_event_type/" + e.event_type_id, formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {
                this.setState({
                    RemScsMsg: response.data.message,
                    redirect: true,
                    toast: true
                })
                this.setState({
                    Event_Types_List: this.state.Event_Types_List.filter((s, sidx) => e.event_type_id != s.event_type_id
                    )
                });
            });
    }

    handleEditEvtType(e) {
        const { t } = this.props;
        this.setState({
            modal: true,
            errMsg: "",
            Event_type: e.Event_type,
            Color_code: [e.Color_code],
            default_deadline_days: [e.default_deadline_days],
            default_duration_hours_hrs: e.default_duration_hours.split(":")[0],
            default_duration_hours_min: e.default_duration_hours.split(":")[1],
            default_infomail_uncertain: "",
            gather_time_min: e.default_gather_time.split(":")[0],
            gather_time_hrs: e.default_gather_time.split(":")[1],
            default_infomail_uncertain_days: [e.default_infomail_uncertain_days],
            default_infomail_unregistered: "",
            default_infomail_unregistered_days: [e.default_infomail_unregistered_days],
            infomail_uncertain: [e.default_infomail_uncertain == 0 ? { value: 0, label: t("No") } : { value: 1, label: t("Yes") }],
            infomail_unregistered: [e.default_infomail_unregistered == 0 ? { value: 0, label: t("No") } : { value: 1, label: t("Yes") }],
            TaskSelected: this.state.Task_list.filter((taskItem) => e.task_name && e.task_name.includes(taskItem.label)),
            active: e.active == 1 ? true : false,
            eventTypeId: e.event_type_id,
        });
    }

    handleEventType(e) {
        this.setState({
            Event_type: e
        })
    }

    handleColor(e) {

        this.setState({
            Color_code: e
        })
    }
    handleDeadline(e) {
        const re = /^[0-9\b]+$/;
        if (e === '' || re.test(e)) {
            this.setState({
                default_deadline_days: e
            })
        }
        // console.log("DeadLine",e)

    }
    handlestdDuration_hrs(e) {
        const re = /^[0-9\b]+$/;
        if ((e === '') || (re.test(e) && e < 24)) {
            this.setState({
                default_duration_hours_hrs: e
            })
        }
    }
    handlestdDuration_min(e) {
        const re = /^[0-9\b]+$/;
        if ((e === '') || (re.test(e) && e < 60)) {
            this.setState({
                default_duration_hours_min: e
            })
        }
    }
    handleGatherTime_hrs(e) {
        const re = /^[0-9\b]+$/;
        if ((e === '') || (re.test(e) && e < 24)) {
            this.setState({
                gather_time_hrs: e
            })
        }

    }
    handleGatherTime_min(e) {
        const re = /^[0-9\b]+$/;
        if ((e === '') || (re.test(e) && e < 60)) {
            this.setState({
                gather_time_min: e
            })
        }

    }


    handleEntries(e) {
        this.setState({
            default_infomail_uncertain_days: e
        })
    }
    handleRegistred(e) {
        this.setState({
            default_infomail_unregistered_days: e
        })
    }
    handleStatus(e) {

        this.setState({
            active: e.target.checked
        });
        //alert(this.state.active)
    }
    onSubmit = (e) => {
        const { t } = this.props;
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "final_create_team", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {

            })
            .catch(error => {
                this.setState({
                    // errMsg: { message: error.response.data.message }
                })
                // setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );

        if (this.state.Event_Types_List.length < 1) {
            this.setState({
                atLeast: t('Atleast one Event Types must be defined to move on next step')
            });
            setTimeout(() => this.setState({ atLeast: '' }), 2000);
        }
        else {
            this.setState({
                atLeast: ''
            });
            this.props.data.handleCompleteTab(6);
        }
        // this.props.data.handleActiveTab(6);
    };

    getEventType(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_event_types/" + id, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //   console.log("Event types",response.data)
                if (response.data.status === 200) {
                    this.setState({
                        Event_Types_List: response.data.data
                    });
                }
            });
    }

    handleStep(type, index) {
        const sort_List = this.state.Event_Types_List;

        let sortRow = []; let selfsort; let othersort;

        if (type === "up") {

            const self = sort_List[index];
            const other = sort_List[index - 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index - 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['event_type_id'], "sort": self['sort'] });
            sortRow.push({ id: other['event_type_id'], "sort": other['sort'] });

            this.setState({
                Event_Types_List: sort_List
            })

        }
        else if (type === "down") {
            const self = sort_List[index];
            const other = sort_List[index + 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index + 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['event_type_id'], "sort": self['sort'] });
            sortRow.push({ id: other['event_type_id'], "sort": other['sort'] });

            this.setState({
                Event_Types_List: sort_List
            })
        }
        this.setState({
            sortRow: sortRow
        }, () => { this.updateStep() })

    }


    updateStep() {
        // this.setState({ func_List: this.state.func_List.filter((s, sidx) => idx != sidx) });
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "eventtype/updateStep", formData, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then((response) => {

            })
            .catch(error => {

                this.setState({
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

    EventTaskSelect = TaskSelected => {


        this.setState({ TaskSelected });

    };

    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getEventType(this.props.data.teamId);
            this.getTasks();
        }
        this.getOption();
    }



    componentWillReceiveProps() {
        if (this.props.data.teamId > 0) {
            this.getEventType(this.props.data.teamId);
            this.getTasks();
        }
        this.getOption();
    }

    getOption() {
        const { t } = this.props;
        let yesNo = [];
        yesNo.push({ value: 0, label: t('No') })
        yesNo.push({ value: 1, label: t('Yes') })

        let infomail_uncertain = this.state.infomail_uncertain;
        infomail_uncertain = [{ value: infomail_uncertain[0].value, label: infomail_uncertain[0].label }]

        let infomail_unregistered = this.state.infomail_unregistered;
        infomail_unregistered = [{ value: infomail_unregistered[0].value, label: infomail_unregistered[0].label }]

        this.setState({
            yesNo: yesNo,
            infomail_uncertain: infomail_uncertain,
            infomail_unregistered: infomail_unregistered
        })
    }

    render() {
        const { t } = this.props;
        return (
            <>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                <Form id="teaminfoform" method="post" action="#" enctype='multipart/form-data' className="form-horizontal was-validated">

                    <div className="card">
                                <div className="card-body">
                        <div className="mb-2">
                            <h5>{t("Event types")}</h5>
                            <span style={{ }} >{t("With the event types the events can be grouped on the one hand and on the other hand the event types serve as a template. For example, for a sports club, types such as championship match, friendly match, training etc. can be created and a standard duration, a standard meeting time or information for reminders can be defined.")} </span>

                        </div><div className="table-responsive">
                            <table className=" text-center table table-hover table-s w-100 tableFixHead" >
                                <thead style={{ backgroundColor: "#0c3047" }} className="font-14">
                                    <tr className="fw-medium" >
                                        <th></th>
                                        <th>{t("Event type")} </th>
                                        <th>{t("Color")} </th>
                                        <th>{t("Deadline (days)")} </th>
                                        <th>{t("Standard duration (hh:mm)")}</th>
                                        <th>{t("Meeting time (hh:mm)")} </th>
                                        <th>{t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")} </th>
                                        <th>{t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}</th>
                                        <th>{t("Active")}</th>
                                        <th>{t("Action")}</th>

                                    </tr>
                                </thead>
                                <tbody className="font-14">
                                    {this.state.Event_Types_List.map((item, idx) => (
                                        <tr>
                                            <td width="50">
                                                {idx != 0 &&
                                                    <i onClick={(e) => this.handleStep('up', idx)} className="mdi mdi-arrow-up"></i>
                                                }
                                                {this.state.Event_Types_List.length != idx + 1 &&
                                                    <i onClick={(e) => this.handleStep('down', idx)} className="mdi mdi-arrow-down"></i>
                                                }
                                            </td>
                                            <td>
                                                {item.Event_type}
                                            </td>
                                            <td>
                                                <div style={{ width: "30px", height: "25px", backgroundColor: item.color_code, borderRadius: "5px" }}></div>

                                            </td>
                                            <td>
                                                {item.default_deadline_days}
                                            </td>
                                            <td>{item.default_duration_hours != "00:00" ? item.default_duration_hours : "00:00"}</td>
                                            <td>{item.default_gather_time != "00:00" ? item.default_gather_time : "00:00"}</td>
                                            <td>
                                                {item.default_infomail_uncertain ? t('Yes') + ' / ' + item.default_infomail_uncertain_days + ' ' + t('day before') : t('No')}
                                            </td>
                                            <td>
                                                {item.default_infomail_unregistered ? t('Yes') + ' / ' + item.default_infomail_unregistered_days + ' ' + t('day before') : t('No')}
                                            </td>
                                            <td>
                                                {item.active === 1 ? t('Yes') : t('No')}
                                            </td>
                                            <td>
                                            <Button size="sm" color="warning" className="mobile-icon ms-2" onClick={(e) => this.handleEditEvtType(item)}><span>{t("Edit")}</span></Button>
                                            <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveEvtType(item)}><span>{t("Remove")}</span></Button>

                                                {/* <i onClick={(e) => this.handleRemoveEvtType(item)} className=" cursor_pointer far fa-trash-alt"></i> */}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <span className="text-danger">{this.state.atLeast}</span>
                        </div>
                    </div>
                    </div>
                    <div className="row mb-3">
                        <div className="col-sm-4">
                            <Button
                                type="button"
                                id="add_attrs"
                                onClick={this.toggle}
                                className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                            >
                                {t("Add event type")}
                            </Button>
                        </div>
                    </div>
                    <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                        <ModalHeader toggle={this.toggle} > {t(this.state.eventTypeId !== '' ? "Edit event type" : "Add event type")}</ModalHeader>
                        <ModalBody style={{ backgroundColor: "#376295" }}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <Label>{t("Event type")}</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Enter event type")}
                                            value={this.state.Event_type}
                                            onChange={(e) => this.handleEventType(e.target.value)}
                                        // autoFocus={true}
                                        />
                                        <span className="text-danger ms-1">{t(this.state.errMsg.event_type)}</span>
                                    </div>
                                </div>

                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <Label>{t("Color")}</Label>
                                        <Input
                                            className="form-control-color"
                                            type="color"
                                            id="example-color"
                                            name="color"
                                            value={this.state.Color_code}
                                            onChange={(e) => this.handleColor(e.target.value)} />
                                        <span className="text-danger ms-1">{t(this.state.errMsg.color_code)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12 zIndex_4">
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder={t("Select Default Task")}
                                        options={this.state.Task_list}
                                        value={this.state.TaskSelected}
                                        onChange={this.EventTaskSelect}
                                        isMulti={true}
                                        styles={colourStyles}
                                        maxMenuHeight={200}
                                        menuPlacement="auto"
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <Label>{t("Send information e-mail and push notification to all members who have 'not' registered until (number of days before registration deadline)")}</Label>
                                        <Select
                                            aria-label=".form-select-lg example"
                                            required=""
                                            placeholder={t("Select")}
                                            options={this.state.yesNo}
                                            value={this.state.infomail_uncertain}
                                            onChange={this.uncertainSelect}
                                            styles={colourStyles}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <Label>{t("Send information e-mail and push notification to all members who have registered with 'maybe' until (number of days before registration deadline)")}</Label>
                                        <Select
                                            aria-label=".form-select-lg example"
                                            required=""
                                            placeholder={t("Select")}
                                            options={this.state.yesNo}
                                            value={this.state.infomail_unregistered}
                                            onChange={this.unregisteredSelect}
                                            styles={colourStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        {this.state.infomail_uncertain[0].value > 0 &&
                                            <Input
                                                type="number"
                                                min={0}
                                                className="form-control"
                                                placeholder={t("number of days")}
                                                value={this.state.default_infomail_uncertain_days}
                                                onChange={(e) => this.handleEntries(e.target.value)}
                                            />}
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_uncertain_days)}</span>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        {this.state.infomail_unregistered[0].value > 0 &&
                                            <Input
                                                type="number"
                                                min={0}
                                                className="form-control"
                                                placeholder={t("number of days")}
                                                value={this.state.default_infomail_unregistered_days}
                                                onChange={(e) => this.handleRegistred(e.target.value)}
                                            />}
                                        <span className="text-danger ms-1">{t(this.state.errMsg.infomail_unregistered_days)}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <Label>{t("Standard Deadline (days)")}</Label>
                                        <Input
                                            // type="number"
                                            // min={0}
                                            className="form-control"
                                            placeholder={t("Deadline (days)")}
                                            value={this.state.default_deadline_days}
                                            onChange={(e) => this.handleDeadline(e.target.value)} />
                                        <span className="text-danger ms-1">{t(this.state.errMsg.deadline)}</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <Label>{t("Standard duration (hh:mm)")}</Label>
                                        <div className="d-flex">
                                            <Input
                                                // type="number"
                                                // min="0"
                                                // max="23"
                                                className="form-control"
                                                placeholder="00"
                                                value={this.state.default_duration_hours_hrs}
                                                onChange={(e) => this.handlestdDuration_hrs(e.target.value)} />
                                            &nbsp;&nbsp;&nbsp;
                                            <Input
                                                // type="number"
                                                // min="0"
                                                // max="59"
                                                className="form-control"
                                                placeholder="00"
                                                value={this.state.default_duration_hours_min}
                                                onChange={(e) => this.handlestdDuration_min(e.target.value)} />
                                        </div>
                                        <span className="text-danger ms-1">{t(this.state.errMsg.duration)}</span>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <Label>{t("Meeting time (hh:mm)")} </Label>
                                        <div className="d-flex">
                                            <Input
                                                // type="number"
                                                // min="0"
                                                // max="23"
                                                className="form-control"
                                                placeholder="00"
                                                value={this.state.gather_time_hrs}
                                                onChange={(e) => this.handleGatherTime_hrs(e.target.value)} />
                                            &nbsp;&nbsp;&nbsp;
                                            <Input
                                                // type="number"
                                                // min="0"
                                                // max="59"
                                                className="form-control"
                                                placeholder="00"
                                                value={this.state.gather_time_min}
                                                onChange={(e) => this.handleGatherTime_min(e.target.value)} />
                                        </div>
                                        <span className="text-danger ms-1">{t(this.state.errMsg.gather_time)}</span>
                                    </div>
                                </div>


                            </div>
                            <div className="row mt-2">
                                <div className="col-md-12">
                                    <input
                                        className="form-check-input mr-1"
                                        type="checkbox"
                                        onChange={(e) => this.handleStatus(e)}
                                        checked={this.state.active}
                                        id="flexCheckDefault" />
                                    <Label className="mb-0">{t("Active")}</Label>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                          <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")}</button>
                          <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddEvtType()} > {t("Save")} </Button>
                        </div>
                    </ModalFooter>
                    </Modal>
                    <ul className="pager wizard mb-0 list-inline">
                        <li className="previous list-inline-item">
                            <button onClick={() => this.props.data.handleActiveTab(4)} type="button" className="btn btn-warning mr-2 fw-bolder"><i className="mdi mdi-arrow-left me-1"></i> {t("Back to answer options")}</button>
                        </li>
                        <li className="next list-inline-item disabled">
                            <button onClick={() => this.onSubmit()} type="button" className="btn btn-primary fw-bolder">{t("Create Team")} <i className="mdi mdi-arrow-right ms-1"></i></button>
                        </li>
                    </ul>
                </Form>
            </>
        )
    }
}
export default withTranslation()(AnswerOptions);