import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../Admin/GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';
import Footer from '../../GeneralComponents/Footer'



export default class TeamMembers extends Component {

    constructor(props) {
        super(props);
      this.state = {
            User_List: [],
            msg: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Loader: false,
            modal: false,
            activePage: 1,
            limit: 15,
            totalItemsCount:0,
            search:"",
            status:[],
            statusSubscription:"",
            statusArray:[{'value':"","label":"All"},{'value':0,"label":"Inactive"},{'value':1,"label":"Active"}],
            index: "",
            data: [],
            removeTeamModal: false,
            StatusModal: false,
            statusDefault:[{'value':"","label":"All"}],
            subscriptionArray:[{'value':"","label":"All Subscription"},{'value':'deactive',"label":"Inactive"},{'value':'active',"label":"Active"}],
            subscriptionDefault:[{'value':"","label":"All Subscription"}]

        }
    }

    toggle(idx, item) {
        this.setState({
            index: idx,
            data: item,
            StatusModal: !this.state.StatusModal
        })
    }

    getTeamList() {
        this.setState({
            Loader:true
        })
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('status', this.state.status);
        formData.append('statusSubscription', this.state.statusSubscription);
        
        formData.append('limit', this.state.limit);
        var token = this.state.token
        axios
            .post(APIURL + "admin/user/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                
              
                    this.setState({
                        User_List: response.data.data.data,
                        activePage:response.data.data.current_page,
                        totalItemsCount:response.data.data.total,
                        Loader:false
                    })
              
            })
            .catch(error => {
                this.setState({
                    error,
                    Loader: false
                })
            });
    }

    componentDidMount() {
      this.getTeamList();
      // this.getTeamDetails();
    }
      handlePageChange(pageNumber) {
      console.log(`active page is ${pageNumber}`);
        this.setState(
          {activePage: pageNumber}
          ,()=>{this.getTeamList()});
      }
  handleSearch (e){
       this.setState(
          {search: e.target.value, activePage: 1}
          ,()=>{this.getTeamList()});
  }

  handleStatus(e){
    this.setState(
          {
              activePage:1,
              status: e.value,
              statusDefault: [{value:e.value,label:e.label}]
            }
          ,()=>{this.getTeamList()});
  }
  handleSubscription(e){
    this.setState(
          {
              activePage:1,
              statusSubscription: e.value,
              subscriptionDefault: [{value:e.value,label:e.label}]
            }
          ,()=>{this.getTeamList()});
  }
 
statusUpdate(index, item) {
    const {data} = this.state;
        this.setState({ Loader: true });
        const formData = new FormData();
        formData.append('user_id', data.user_id);
        formData.append('status', data.is_active === 1 ? 0 : 1);
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/user/status", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                this.setState({StatusModal:false})
                this.getTeamList();
            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false,
                    StatusModal:false
                })
            );
    }

    doDelete(index, item) {
        this.setState({ Loader: true });
        console.log('delete', item)
        const formData = new FormData();
        formData.append('user_id', item.user_id);
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/user/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {

                const newRep = [...this.state.User_List];
                newRep.splice(index, 1);

                this.setState({
                    User_List: newRep,
                    Loader: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false
                })
            );
    }
    ModalClose() {
        this.setState({
            StatusModal: false
        })
    }

    render() {
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (this.state.user.user_type !== 'Admin') {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                 <Sidebar
                    data={{
                        active: 'user',
                        ActivePage: "user_list"
                    }} />
                   
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">Super Admin | User List </h4>
                                    </div>

                                </div>
                            </div>
                            {/*<div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs ">
                                        <li className="nav-item">
                                            <Link to="/admin/user" className="nav-link active">
                                                <span>Active Users</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/inactive_user" className="nav-link">
                                                <span>Inactive Users</span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br/>*/}
                            <div className="row">
                                <div className="col-6 col-sm-4 col-md-3">
                                  <Input type="text" onChange={(e)=>this.handleSearch(e)} placeholder="Search .." />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                  <Select
                                      aria-label=".form-select-lg example"
                                      required=""
                                      placeholder="Select Status"
                                      className="form-select-control"
                                      options={this.state.statusArray}
                                      value={this.state.statusDefault}
                                      onChange={(e)=>this.handleStatus(e)}
                                  />
                                </div>
                                 <div className="col-6 col-sm-4 col-md-3">
                                  <Select
                                      aria-label=".form-select-lg example"
                                      required=""
                                      placeholder="Select Subscription"
                                      className="form-select-control"
                                      options={this.state.subscriptionArray}
                                      value={this.state.subscriptionDefault}
                                      onChange={(e)=>this.handleSubscription(e)}
                                  /> 

                                </div>
                            </div>
                           
                            <div className="row">
                                <div className="table-responsive">
                                
                                    <Table id="table-to-xls" hover className="table table-hover table-centered nowrap mt-3">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr>
                                                <th className="fw-medium">#No</th>
                                                <th className="fw-medium ">Name</th>
                                                <th className="fw-medium">E-Mail</th>
                                                <th className="fw-medium ">Address</th>
                                               
                                                <th className="fw-medium ">Phone</th>
                                                <th className="fw-medium ">DOB</th>
                                                <th className="fw-medium ">Subscription</th>
                                                <th className="fw-medium">Status</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.User_List.length > 0 ? this.state.User_List.map((item, idx) => (
                                                <tr className="">
                                                    <td className="fw-medium">{(this.state.activePage-1)*this.state.limit+(idx+1)}</td>
                                                    <td >{item.full_name}</td>
                                                    <td>{item.email}</td>
                                                    <td >{item.address}</td>
                                                   
                                                    <td >{item.phone}</td>
                                                    <td >{dateFormat(item.date_of_birth, "dd.mm.yyyy")}</td>
                                                     <td >
                                                     {item.system_subscription=="active" && item.user_subscription=="active" ?
                                                      "Active"
                                                      :
                                                       "Inactive"
                                                     } 
                                                    </td>
                                                    <td >
                                                      {
                                                        item.is_active === 1 ? 
                                                          <Button size="sm" color="success" className="ms-2" onClick={(e) => this.toggle(idx, item)}>Active</Button> : 
                                                          <Button size="sm" color="default" className="ms-2" onClick={(e) => this.toggle(idx, item)}>Inactive</Button>
                                                      }
                                                    </td>
                                                  
                                                </tr>
                                            )) :

                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No User Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {this.state.totalItemsCount>0 && 
                             <div className="pagination-rounded">
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                              />
                            </div>
                          }
                           
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>                

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}

                <div className="rightbar-overlay"></div>
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.StatusModal} >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">Are you sure want to {this.state.data.is_active === 1 ? "inactive" : "active"}  user </h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.statusUpdate()} >Confirm </Button>{' '}
                                <Button color="default" onClick={() => this.ModalClose()}>Cancel</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}