import React, { Component } from 'react'
import { Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL, APIDOMAIN } from '../../../constants/Common';
import { Redirect, Link } from "react-router-dom";
import '@szhsin/react-menu/dist/index.css';
import Sidebar from "../../../GeneralComponents/Sidebar";
import '../../../../assets/css/style.css'
import '../../../../assets/css/bootstrap.min.css'
import '../../../../assets/css/icons.css'
import '../../../../assets/css/Custom.css'
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";

const guestOption = [
    {
        label: "No",
        value: "0",
    },
    {
        label: "Yes",
        value: "1",
    }
];


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamId: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            error: [],
            errorRequireFiled: "",
            ActiveTeam: 0,
            isPermission: 1,
            csvData:[],
            members: [],
            statisticMembers: [],
            responseData: [],
            fromDate: "",
            toDate: "",
            page: 1,
            tasks: [],
            totalRow: []
        };
    }

    componentDidMount() {
        if (this.state.teamId != "undefined") {
            this.getMembers();
            this.getTasks();
            this.getStatisticTask();
            this.getTeamDetails();
        }
    }

    getTeamDetails(){

        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });


    }

    handleChangePage() {
        this.setState({
            page: 2
        }, () => {
            this.taskData();
        });
    }

    handleFromDate(e) {
        this.setState({
            fromDate: e
        }, () => {
            this.getStatisticTask();
        });
    }

    handleToDate(e) {
        this.setState({
            toDate: e
        }, () => {
            this.getStatisticTask();
        });
    }
   
    getMembers() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "membership/get-active", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let members = response.data.data;
                this.setState({
                    members: members,
                })
            })
    }

    getTasks() {
        var token = this.state.token;
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        axios
            .post(APIURL + "event/get_tasks", formData, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.success) {              
                    this.setState({
                        tasks: response.data.data,
                    }, () => {
                        this.getStatisticTask();
                    })
                }
            });
    }

    getStatisticTask() {
        var token = this.state.token;
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);
        formData.append('FROM', this.state.fromDate);
        formData.append('TO', this.state.toDate);

        axios
            .post(APIURL + "statistics/get-task", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === "success") {
                    let responseData = response.data.data;

                    this.setState({
                        responseData: responseData
                    }, () => {
                        this.taskData();
                    })
                }
            });
    }

    taskData() {
        let finalMemberArr = [];
        let csvMemberArr = [];
        var totalCounter  = [];
        var taskCounter = [];
        var membersState = this.state.members;
        
        if(this.state.page === 1) {
            membersState = membersState.slice(0, 10);
        }

        membersState.map((member, index) => {
            var taskArray = [];
            csvMemberArr.push({"Member": member.full_name});
                
            this.state.tasks.map((task) => {
                var counter = this.state.responseData.filter((rows) => 
                    rows.user_id == member.user_id 
                    && rows.task_id == task.task_id
                ).length;

                var taskName = task.task_name;
                csvMemberArr[index][taskName] = counter;
                taskArray.push(counter);
            });

            finalMemberArr.push({member_name: member.full_name, tasks_count: taskArray});
        });

        csvMemberArr.push({"Member": "Total"});
        var csvLength = csvMemberArr.length;

        for(var i = 0; i < this.state.tasks.length; i++) {
            var totalCount = 0;
            for(var j = 0; j < finalMemberArr.length; j++) {
                totalCount += finalMemberArr[j].tasks_count[i];
            }

            taskCounter.push(totalCount);

            var taskName = this.state.tasks[i].task_name;
            csvMemberArr[csvLength-1][taskName] = totalCount;                        
        }

        totalCounter.push({"Total": "Total", total_counter: taskCounter});

        this.setState({
            statisticMembers: finalMemberArr,
            csvData: csvMemberArr,
            totalRow: totalCounter
        })
    }

    render() {
        const { t } = this.props;
        console.log("event props", this.props)
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }

       
        return (
            <React.Fragment>
                <div id="wrapper">
                    <Sidebar
                        data={{
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "events",
                            visible:this.props.location.visible
                        }} />
                    
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt justify-content-start">
                                            <h4 className="page-title">{this.state.team_name} | {t("Statistics")} </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <Link to={"/statistics/" + this.state.teamId} className="nav-link">{t("Member overview")}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/statistics/event-type/" + this.state.teamId} className="nav-link">{t("Event type overview")}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#events1"><span>{t("Task overview")}</span></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="events1">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="header-title mb-3 text-capitalize"></h4>
                                                        <div className="mb-2">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("From")}</label>
                                                                    {/* <input placeholder="TT.MM.JJJJ" type="text" className="form-control form-control-sm" /> */}
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="from_date"
                                                                        id="from_date"
                                                                        value={this.state.fromDate}
                                                                        onChange={(e) => this.handleFromDate(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("To")}</label>
                                                                    {/* <input placeholder="TT.MM.JJJJ" type="text" className="form-control form-control-sm" /> */}
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="to_date"
                                                                        id="to_date"
                                                                        value={this.state.toDate}
                                                                        onChange={(e) => this.handleToDate(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {/* <div className="text-right mb-3"><a href="#" className="btn btn-sm btn-success">Excel Export(CSV)</a></div> */}
                                                {   this.state.csvData.length > 0 &&
                                                    <div className="text-right mb-3"><CSVLink  className="btn btn-sm btn-success" data={this.state.csvData} filename={"my-file.csv"}>{t("Excel Export(CSV)")} </CSVLink ></div>
                                                }
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="table-responsive table-wrapper mb-4">
                                                            <table className="table table-hover tableFixHead tableFixFoot">
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th className="fw-medium">{t("Member")}</th>
                                                                        {
                                                                            this.state.tasks.length > 0 && this.state.tasks.map((item) => (
                                                                                <th className="fw-medium">{item.task_name}</th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="font-14">
                                                                    {
                                                                        this.state.statisticMembers.length > 0 && this.state.statisticMembers.map((item) => (
                                                                            <tr>
                                                                                <td>{item.member_name}</td>
                                                                                {
                                                                                    item.tasks_count.map((taskCount) => (
                                                                                        <td>{taskCount}</td>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                                <tfoot className="font-14">
                                                                    {
                                                                        this.state.totalRow.length > 0 && this.state.totalRow.map((item) => (
                                                                            <tr>
                                                                                <th>{item.Total}</th>
                                                                                {
                                                                                    item.total_counter.map((totalCount) => (
                                                                                        <th>{totalCount}</th>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tfoot>
                                                            </table>  
                                                        </div>
                                                        {
                                                            this.state.page == 1 && this.state.statisticMembers.length > 0 
                                                            ? <div className="text-left mt-3" onClick={() => this.handleChangePage()} ><a href="#" className="btn btn-sm btn-success">{t("List of all members")}</a></div> 
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Index);