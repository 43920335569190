import React, { Component } from 'react'
import AOS from 'aos';
import axios from "axios";
import Select from 'react-select'
import { APIURL } from '../../constants/Common';
// import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../../../assets/css/bootstrap.min.css";
import "../../../assets/css/style.css";
import "../../../assets/css/icons.css";
import logo_light from "../../../assets/images/logo-light.webp"
import google from "../../../assets/images/google.webp"
import play_store from "../../../assets/images/app.webp"
import bg_auth from "../../../assets/images/bg-auth-dark.webp"
import '../../../assets/css/Custom.css'
import logoDark from '../../../assets/images/logo-dark.svg';
import queryString from 'query-string'
import { Redirect } from "react-router-dom";
import Language from "../Language/Language";
import { Trans, withTranslation } from 'react-i18next';

import { Form, Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

 class Home extends Component {
    userData;
    constructor() {
        super();
        this.state = {
            signupData: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                redirect: false,
                email: "",
                password: "",
                isLoading: "",
                tnc: "",
                license_type_id: "",
                package_id: "",
            },
            msg: "",
            scsMsg: "",
            startDate: '',
            days: [],
            months: [],
            years: [],
            countries: [],
            countryCode: 0,
            day: 0,
            month: 0,
            year: 0,
            Package_List: [],
            amount: '',
            number_of_teams: '',
            email: "",
            password: "",
            isLoading: false,
            redirect: false,
            errMsgEmail: "",
            errMsgPwd: "",
            errMsg: "",
            errMsgSignUp: "",
           
            unverified: false,
            showPassword: false,
            modal: false,
            checked: false,
            active: false,
            ScsRegisterModal:false,
            packageId:"",
            license_type_id:"",

            siteid:"",
        }
        this.handleCheck = this.handleCheck.bind(this);
        this.handleCheckSignUp = this.handleCheckSignUp.bind(this);
        this.ScsRegisterModal = this.ScsRegisterModal.bind(this);

        this.RegistrationModal = this.RegistrationModal.bind(this)
    }

    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    handleCheck(e) {
        // console.log(e.target.checked, this.state)
        this.setState({
            active: e.target.checked,
        })
    }

    onAlertClose = () => {
        this.setState({
            errMsg: "",
            scsMsg: "",
        });
    };

    resendActivationMail = () => {
        axios
            .post(APIURL + "resend-activation", {
                email: this.state.email,
            })
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    this.setState({
                        scsMsg: response.data.message,
                        errMsg: '',
                        unverified: false,
                    });
                }

                if (response.data.status === "failed") {
                    this.setState({
                        scsMsg: '',
                        msg: response.data.message
                    });
                }
            });
    }

    onSignInHandler = (event) => {
         event.preventDefault();
        this.setState({ isLoading: true, unverified: false });
        const check = this.state.active;
        this.lsRememberMe(check);
        axios
            .post(APIURL + "user-login", {
                email: this.state.email,
                password: this.state.password,
                platform:"dd",
                uuid:"dd",
                version:"sdsd",
                fcm_token:"sdsds"
            })
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    localStorage.setItem("isLoggedIn", true);
                    localStorage.setItem("userData", JSON.stringify(response.data.data));
                    localStorage.setItem("token", JSON.stringify(response.data.token));
                    this.setExpired(check);
                    // const check = localStorage.getItem("checkbox");
                    if (check) {
                        localStorage.setItem("username", this.state.email);
                        localStorage.setItem("password", this.state.password);
                    }
                    else {
                        localStorage.removeItem("username");
                        localStorage.removeItem("password");
                    }
                    this.setState({
                        msg: response.data.message,
                        redirect: true,
                    });
                    document.body.classList.remove('front-end')
                }
                if (
                    response.data.status === "failed" &&
                    response.data.success === undefined
                ) {
                    this.setState({
                        scsMsg: '',
                        errMsg: '',
                        errMsgEmail: response.data.errors.email,
                        errMsgPwd: response.data.errors.password,
                    });
                    setTimeout(() => {
                        this.setState({ errMsgEmail: "", errMsgPwd: "" });
                    }, 2000);
                } else if (
                    response.data.status === "failed" &&
                    response.data.success === false
                ) {
                    if (response.data.unverified) {
                        this.setState({
                            unverified: true
                        })
                    }
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.message,
                        errMsgEmail: '',
                        errMsgPwd: '',
                    });
                    setTimeout(() => {
                        this.setState({ errMsg: "" });
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };


  setExpired(rememberMe)
  {
        const currentDateTime = new Date();
        const updateDateTime = new Date();
        const expireDateTime = new Date(updateDateTime.setMinutes(updateDateTime.getMinutes() + 59));
        const currentTimestamp = Math.floor(currentDateTime.getTime() / 1000);
        const expireTimeStamp = Math.floor(expireDateTime.getTime() / 1000);

        const initialState = {
            rememberMe: rememberMe,
            loginTime: currentTimestamp,
            expirationTime: expireTimeStamp,
        };
        localStorage.setItem("session_login", JSON.stringify(initialState));
  }

    getParams() {
        const value = queryString.parse(this.props.location.search);
        const token = value.account_activation;
        const success = this.props.location;

        // console.log(this.props.location.state.success);

        if (token) {
            axios
                .get(APIURL + "account-activation/" + token)
                .then((response) => {
                      document.body.classList.add('front-end')
                    this.setState({ isLoading: false });
                    if (response.data.status === 200) {
                        this.setState({
                            scsMsg: response.data.message
                        });
                    }

                    if (response.data.status === "failed") {
                        this.setState({
                            scsMsg: '',
                            msg: response.data.message
                        });
                    }
                });
        }

        if (success.state !== undefined) {
            if (success.state.success === 'registered') {
                this.setState({
                    scsMsg: 'Your account is created. Please check your mail for the activation link!'
                });
            }
            if (success.state.success === 'reset') {
                this.setState({
                    scsMsg: 'Your account password is changed!'
                });
            }
        }
    }


     getParamsForAccess() {
        const value = queryString.parse(this.props.location.search);
        const token = value.profile_access_token;
        if (token) {
            axios
                .get(APIURL + "user/accept_allow_access/" + token)
                .then((response) => {
                        this.setState({
                            scsMsg: response.data.message
                        });
                });
        }

 
    }


    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    lsRememberMe(check) {
        if (check) {
            localStorage.username = this.state.email;
            localStorage.password = this.state.password;
            localStorage.checkbox = true;
            // localStorage.checkbox = rmCheck.value;
        } else {
            localStorage.username = "";
            localStorage.password = "";
            localStorage.checkbox = false;
        }
    }

    RegistrationModal(item) {

        if (item) {
            item.license_type.map((sItem, sIdx) => {

                if (sItem.is_default === "Yes") {
                    this.setState({
                        modal: !this.state.modal,
                        packageId:sItem.package_id,
                        license_type_id : sItem.id
                    })
                  
                }
            })
        }
        this.setState({
            modal: !this.state.modal
        })
      
    }

    CloseModal() {
        this.setState({
            modal: false,
            signupData: {
                firstname: "",
                lastname: "",
                address: "",
                zip: "",
                country: "",
                phone: "",
                countrycode: "",
                dob: "",
                redirect: false,
                email: "",
                password: "",
                isLoading: "",
                tnc: "",
                license_type_id: "",
                package_id: "",
            },
            errMsgSignUp:"",
            errMsg:""

        })
    }
    getPackageRecords() {
        axios
            .get(APIURL + "package/list")
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        Package_List: response.data.data,
                    });
                    console.log(this.state.Package_List)
                }
            })
            .catch(error =>
                this.setState({
                    error,
                    isLoading: false
                })
            );
    }

    HandlePackageDuration(e, idx) {
        console.log(e.target.selectedIndex)
        let Package_List = this.state.Package_List;

        Package_List[idx].license_type.map((item, index) => (
            item.is_default = "No"
        ))
        Package_List[idx].license_type[e.target.selectedIndex].is_default = "Yes"

        this.setState({
            Package_List: Package_List
        })
    }

    countrySelect = (e) => {
        console.log(e);
        const { signupData } = this.state;
        signupData['country'] = e.value;
        this.setState({ signupData, countryCode: e.code });
    };

    daySelect = (e) => {
        this.state.day = e.value;
        console.log(e);
    };

    monthSelect = (e) => {
        this.state.month = e.value;
        console.log(e);
    };

    yearSelect = (e) => {
        this.state.year = e.value;
        console.log(e);
    };

    onChangehandlerSignUp = (e, key) => {
        const { signupData } = this.state;
        signupData[e.target.name] = e.target.value;
        this.setState({ signupData });
    };

    onAlertClose = () => {
        this.setState({
            msg: "",
            scsMsg: "",
        });
    };

    handleClick = () => {
        // alert(this.state.showPassword)
        this.setState({ showPassword: this.state.showPassword ? false : true });
    };


    onSubmitHandler = (e) => {
          document.body.classList.add('front-end')
        // this.setState({
        //     ScsRegisterModal:true,
        // })
        e.preventDefault();
        const { signupData } = this.state;
        if (this.state.year && this.state.month && this.state.day)
            signupData['dob'] = this.state.year + '-' + this.state.month + '-' + this.state.day;

        if (this.state.signupData.phone)
            signupData['countrycode'] = this.state.countryCode;

        signupData['license_type_id'] = this.state.license_type_id;
        signupData['package_id'] = this.state.packageId;
        const value = queryString.parse(this.props.location.search);
        const token = value.team_member_invitation;
        const success = this.props.location;
        if (token) {
            signupData['memberships_token'] = token;
        }

        // console.log(signupData);
        this.setState({ isLoading: true });
        axios
            .post(APIURL + "user-signup", this.state.signupData)
            .then((response) => {
                document.body.classList.add('front-end')
                this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    this.setState({
                        scsMsg: response.data.message,
                        ScsRegisterModal:true,
                        modal:false,
                        signupData: {
                        firstname: "",
                        lastname: "",
                        address: "",
                        zip: "",
                        country: "",
                        phone: "",
                        countrycode: "",
                        dob: "",
                        redirect: false,
                        email: "",
                        password: "",
                        isLoading: "",
                        tnc: "",
                        license_type_id: "",
                        package_id: "",
                    }
                    });
                    // document.body.classList.remove('front-end')
                    setTimeout(()=>{
                        this.setState({
                            ScsRegisterModal:false,
                        })
                    }, 4000);

                    setTimeout(()=>{
                        if(response.data.link!="")
                        {
                             window.location.href = response.data.link;
                        }
                    }, 1000);



                }
                if (response.data.status === "failed") {
                    this.setState({
                        errMsgSignUp: response.data.errors,
                    });
                }
            });
    };
    getDays() {
        const months = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];

        var d = new Date();
        var n = d.getFullYear();
        for (var i = 0; i < 31; i++) {
            this.state.days.push({ value:i+1,label: (i+1).toString()})
        }

        for (var j = 0; j < 12; j++) {
            this.state.months.push({ value: j + 1, label: months[j] })
        }

        for (var k = n; k > 1900; k--) {
            this.state.years.push({ value: k, label: k.toString() })
        }
    }

    getCountries() {
        axios
            .get(APIURL + "get_countries")
            .then((response) => {
                if (response.data.status === 'success') {
                    // console.log(response.data.countrys);
                    let countries = response.data.countrys;
                    for (var c = 0; c < countries.length; c++) {
                        this.state.countries.push({ value: countries[c].id, label: countries[c].country, code: countries[c].country_code_mobile })
                    }
                    //this.state.countries.sort(function (a, b) {
                    //    var textA = a.label;
                    //   var textB = b.label;
                    //    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    //});

                    // console.log(this.state.countries);
                }
            });
        const query = queryString.parse(this.props.location.search);
        console.log('val', query)
        const email = query.email_id;
        const token = query.team_member_invitation;

        const { signupData } = this.state;
        if (email) {
            signupData['email'] = email;
        }
        console.log('state', signupData)
    }

    handleCheckSignUp(e) {

        this.setState({
            checked: e.target.checked,
        })
        console.log(this.state.checked)
        const { signupData } = this.state;
        signupData['tnc'] = e.target.checked ? "tnc" : "";
        this.setState({ signupData });
    }

     handleAddClass() {
        document.body.classList.add('front-end')
    }

    ScsRegisterModal(){
        this.setState({
            ScsRegisterModal:!this.state.ScsRegisterModal
        })
    }

    componentDidMount() {
        this.handleAddClass()
        this.getParams();
        this.getParamsForAccess();
        if (localStorage.checkbox && localStorage.checkbox !== "") {
            this.setState({
                active: true,
                email: localStorage.username,
                password: localStorage.password,
            });
        } else {
            this.setState({
                active: false,
                email: '',
                password: ''
            });
        }

        this.getDays()
        this.getCountries()
        this.getPackageRecords()
        AOS.init({
            // initialise with other settings
            duration: 2000
        });
    }
    render() {
        const { t } = this.props;
        const login = localStorage.getItem("isLoggedIn");
        const query = queryString.parse(this.props.location.search);
        const token = query.team_member_invitation;
        const siteid = window.location.pathname;

        if (login && token!=undefined) {
            return <Redirect to="/invitaion" />;
        }
        else if (login || this.state.redirect) {
            return <Redirect to="/home" />;
        }
        if (this.state.redirect) {
            return <Redirect to={{ pathname: '/login', state: { success: 'registered' } }} />;
        }
        return (
            <>
            <div >

                <Header />

                <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                    <strong></strong> {this.state.scsMsg} {this.state.msg}
                    <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                </div>
                <section className="hero">
                    <div className="overlay"></div>
                    <div className="cover text-center" style={{ backgroundImage: `url(${bg_auth})` }} >
                    </div>
                </section>

                <section id="maincolumn">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-8">
                                <h1 className="maintitle">{t("Hast du eine Frage?")}<br />{t("Gerne helfen wir dir!")}</h1>
                            </div>
                            <div className="col-md-4">
                                <div className="form-wrap">
                                    <div className="tab">
                                        <div className="tab-content">
                                            <div className="tab-content-inner active" data-content="signup">
                                            <form action="#"  onSubmit={this.onSignInHandler} >
                                                    <h4>{t("Lets organize - Login")}</h4>
                                                    {
                                                        this.state.errMsg ?
                                                            <div className={this.state.errMsg || this.state.scsMsg ? "mb-2  text-danger Login_error" : "mb-2  text-danger"} >
                                                                {t(this.state.errMsg)}
                                                                {this.state.unverified ?
                                                                    <a className="text-primary font-weight-bold " onClick={this.resendActivationMail} href="javascript:;">Resend Verification Link</a>
                                                                    : ''
                                                                }
                                                            </div>
                                                            : ""
                                                    }
                                                    {
                                                        this.state.scsMsg ?
                                                            <div className={this.state.errMsg || this.state.scsMsg ? "mb-2  text-primary Login_error" : "mb-2  text-danger"} >
                                                                {t(this.state.scsMsg)}
                                                            </div>
                                                            : ""
                                                    }
                                                    <div className="row form-group">
                                                        <div className="col-md-12">
                                                            {/* <input 
                                                            type="text" 
                                                            id="name" 
                                                            className="form-control form-control-login" 
                                                            placeholder="Benutzername" 
                                                            /> */}
                                                            <Input
                                                                className="form-control form-control-login"
                                                                required=""
                                                                name="email"
                                                                autoComplete="new-password"
                                                                placeholder={t("Enter Email Address")}
                                                                value={this.state.email}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <span className="text-danger">{t(this.state.errMsgEmail)}</span>
                                                        </div>
                                                    </div>

                                                    <div className="mb-0">
                                                        <div className="input-group input-group-merge">
                                                            <Input
                                                                className="form-control form-control-login"
                                                                type={this.state.showPassword ? "text" : "password"}
                                                                name="password"
                                                                autoComplete="new-password"
                                                                placeholder={t("Enter password")}
                                                                value={this.state.password}
                                                                onChange={this.onChangehandler}
                                                            />
                                                            <div className={`${this.state.showPassword ? "show-password" : ""}`} data-password={this.state.showPassword}>
                                                                <a className="input-group-text form-control-login">
                                                                    <span className="password-eye" onClick={this.handleClick}></span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <span className="text-danger">{t(this.state.errMsgPwd)}</span>
                                                    </div>
                                                    <a href="forgot_password" className=" float-end"><small>{t("Forgot password")}?</small></a>
                                                    <div className="row form-group g-0">
                                                        <div className="col-md-12">
                                                            <Input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                value="lsRememberMe"
                                                                id="rememberMe"
                                                                onChange={this.handleCheck}
                                                                checked={this.state.active}
                                                            />
                                                            <label htmlFor="flexCheckChecked"> &nbsp; {t("Remain signed in")}</label>
                                                        </div>
                                                    </div>

                                                    <div className="row form-group">
                                                        <div className="col-md-12">
                                                            {/*<Button
                                                                className="btn btn-warning btn-block"
                                                                onClick={this.onSignInHandler}
                                                            >*/}
                                                               
                                                                {/* {this.state.isLoading ? (
                                                            <span
                                                                className="spinner-border spinner-border-sm ml-5"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>
                                                            ) : (
                                                            <span></span>
                                                            )} */}
                                                          {/*  </Button>*/}
                                                            <input type="submit"  className="btn btn-warning btn-block" value={t("Login")} /> 
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-8">
                                <div className="mar">
                                    <p></p>
                                    <p>{t("Du kannst uns jederzeit kontaktieren wenn du fragen oder Verbesserungsvorschläge hast")}:<br/><a href="mailto:support@teamorganizer.ch">support@teamorganizer.ch</a></p>
                                    <p>{t("Wenn du magst, kannst du auch über unsere Helpdesk Seite ein Ticket erstellen und so immer up-to-date bleiben über den Stand der Anfrage")}:<br/><a href="https://helpdesk.teamorganizer.ch" target="_blank">https://helpdesk.teamorganizer.ch</a></p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="form-wrap white">
                                    <a href="#" onClick={() => this.RegistrationModal()}>
                                        <div className="tab demo"> {t("Create a team now and test it for free")}
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


                <Modal size="md" isOpen={this.state.modal} toggle={() => this.CloseModal()} >
                    <ModalHeader toggle={() => this.CloseModal()} >{t("Create account")}</ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295", maxHeight: "500px", overflowY: "auto" }}>

                        <div className="auth-fluid-form-box">

                            <div className="d-flex">
                                <div className="card-body">
                                    <div className="auth-brand text-center text-lg-start">
                                        <div className="auth-logo">
                                            <a href="index.html" className="text-center">
                                                <img src={logoDark} alt="Dark logo" height="22" />
                                            </a>
                                        </div>
                                    </div>
                                    <h4 className="mt-0 form-heading">{t("Create account")}</h4>
                                    <p className=" mb-4"></p>
                                    
                                    <Form action="#">
                                        <div className="mb-3">
                                            <div className="row">
                                                <div className="col">
                                                    <Label  className="form-label">{t("First name")} <span>*</span></Label>
                                                    <Input
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="firstname"
                                                        placeholder={t("Enter first name")}
                                                        value={this.state.signupData.firstname}
                                                        onChange={this.onChangehandlerSignUp}
                                                        maxLength={20}
                                                    />
                                                    <span className="text-danger">{t(this.state.errMsgSignUp.firstname)}</span>
                                                </div>
                                                <div className="col">
                                                    <Label className="form-label">{t("Last name")} <span>*</span></Label>
                                                    <Input
                                                        className="form-control"
                                                        required=""
                                                        type="text"
                                                        name="lastname"
                                                        placeholder={t("Enter last name")}
                                                        value={this.state.signupData.lastname}
                                                        onChange={this.onChangehandlerSignUp}
                                                        maxLength={20}
                                                    />
                                                    <span className="text-danger">{t(this.state.errMsgSignUp.lastname)}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">{t("Address")} <span>*</span></Label>
                                            <Input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                name="address"
                                                placeholder={t("Enter address")}
                                                value={this.state.signupData.address}
                                                onChange={this.onChangehandlerSignUp}
                                            />
                                            <span className="text-danger">{t(this.state.errMsgSignUp.address)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">{t("ZIP / place")} <span>*</span></Label>
                                            <Input
                                                className="form-control"
                                                required=""
                                                type="text"
                                                name="zip"
                                                placeholder={t("Enter ZIP / place")}
                                                value={this.state.signupData.zip}
                                                onChange={this.onChangehandlerSignUp}
                                            />
                                            <span className="text-danger">{t(this.state.errMsgSignUp.zip)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">{t("Country")} <span>*</span></Label>
                                                <Select
                                                    required=""
                                                    className="form-select-control select select1"
                                                    placeholder={t("Select")}
                                                    options={this.state.countries}
                                                    value={this.state.countries.label}
                                                    onChange={this.countrySelect}
                                                    styles={colourStyles}
                                                />
                                            <span className="text-danger">{t(this.state.errMsgSignUp.country)}</span>
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="phone" className="form-label">{t("Phone number")}</label>
                                                    <Input
                                                        className="form-control input-arrow-hide"
                                                        type="number"
                                                        name="phone"
                                                        min="0"
                                                        onKeyDown={e => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                                        placeholder={t("Enter phone number")}
                                                        value={this.state.signupData.phone}
                                                        onChange={this.onChangehandlerSignUp}
                                                    />
                                            <span className="text-danger">{t(this.state.errMsgSignUp.phone)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">{t("Date of birth")}</Label>
                                            <div className="row">
                                                <div className="col">
                                                    <Select
                                                        placeholder={t("Day")}
                                                        // className="form-select-control"
                                                        options={this.state.days}
                                                        value={this.state.days.label}
                                                        onChange={this.daySelect}
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Select
                                                        placeholder={t("Month")}
                                                        // className="form-select-control"
                                                        options={this.state.months}
                                                        value={this.state.months.label}
                                                        onChange={this.monthSelect}
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                                <div className="col">
                                                    <Select
                                                        placeholder={t("Year")}
                                                        // className="form-select-control"
                                                        options={this.state.years}
                                                        onChange={this.yearSelect}
                                                        styles={colourStyles}
                                                    />
                                                </div>
                                            </div>

                                            <span className="text-danger">{t(this.state.errMsgSignUp.dob)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <Label  className="form-label">{t("E-mail address")}<span>*</span></Label>
                                            <Input
                                                className="form-control"
                                                required=""
                                                type="email"
                                                name="email"
                                                placeholder={t("Enter e-mail address")}
                                                value={this.state.signupData.email}
                                                onChange={this.onChangehandlerSignUp}
                                            />
                                            <span className="text-danger">{t(this.state.errMsgSignUp.email)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <Label className="form-label">{t("Password")} <span>*</span></Label>
                                            <div className="input-group input-group-merge d-flex">
                                                <Input
                                                    className="form-control"
                                                    required=""
                                                    type={this.state.showPassword ? "text" : "password"}
                                                    name="password"
                                                    placeholder={t("Enter password")}
                                                    value={this.state.signupData.password}
                                                    onChange={this.onChangehandlerSignUp}
                                                />
                                                <div className={`${this.state.showPassword ? "show-password d-flex" : "d-flex"}`} data-password={this.state.showPassword}>
                                                    <a className="input-group-text ">
                                                        <span className="password-eye" onClick={this.handleClick}></span>
                                                    </a>
                                                </div>
                                            </div>
                                            <span className="text-danger">{t(this.state.errMsgSignUp.password)}</span>
                                        </div>
                                        <div className="mb-3">
                                            <div className="form-check">
                                                <Input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    required=""
                                                    checked={this.state.checked}
                                                    onChange={this.handleCheckSignUp}
                                                    id="checkbox-signup"
                                                />
                                                <Input
                                                    type="hidden"
                                                    required=""
                                                    name="tnc"
                                                    value={this.state.signupData.tnc}
                                                />
                                                <label className="form-check-label">
                                                    <a href="/rechtliches" target="_blank" className="text-white">{t("I accept the terms and conditions")}</a>
                                                </label>
                                            </div>
                                            <span className="text-danger">{t(this.state.errMsgSignUp.tnc)}</span>
                                        </div>
                                    </Form>
                                    <span className="text-success">{t(this.state.msg)}</span>
                                    
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}> {t("Cancel")} </Button>

                            <Button
                                className="btn btn-primary"
                                color="success"
                                onClick={this.onSubmitHandler}
                                style={{ float: "right" }}
                            >
                                {t("Create account")}
                                {this.state.isLoading ? (
                                    <span
                                        className="spinner-border spinner-border-sm ml-5"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                ) : (
                                    <span></span>
                                )}
                            </Button>

                        </div>
                    </ModalFooter>
                </Modal>

                <Footer />

            </div>
            </>
        )
    }}

export default withTranslation()(Home);
