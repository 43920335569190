import React, { Component } from "react";
import { Button, Form, Label, Input } from "reactstrap";
import axios from "axios";
import Select from 'react-select'
import {APIURL} from './constants/Common';
import { Redirect } from "react-router-dom";
// import "./signup.css";
// import { Link } from "react-router-dom";
import logoDark from '../assets/images/logo-dark.svg';
import queryString from 'query-string'

import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/bootstrap.min.css";
import "../assets/css/style.css";
import "../assets/css/icons.css";
import '../assets/css/Custom.css'

import ErrorPage from "./GeneralComponents/ErrorPage";
import { Trans, withTranslation } from 'react-i18next';

const colourStyles = {

  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isFocused ? "#B8BCBD" : null,
      color: "grey",

    };
  }
};

class RegistrationPage extends Component {
  userData;
  constructor(props) {
    super(props);
    this.state = {
      signupData: {
        firstname: "",
        lastname: "",
        address: "",
        zip: "",
        country: "",
        phone: "",
        countrycode: "",
        dob: "",
        redirect: false,
        email: "",
        password: "",
        password_confirmation: "",
        isLoading: "",
        tnc: "",
        place: "",
      },
      msg: "",
      errMsgSignUp: {},
      scsMsg: "",
      startDate: '',
      days: [],
      months: [],
      years: [],
      countries: [],
      countryCode: 0,
      day: 0,
      month: 0,
      year: 0,
      showPassword: false,
      showPasswordConfirmation: false,
      checked: false,
      tokenIsValid:true,
      // startDate: new Date(),
    };

    this.handleCheckSignUp = this.handleCheckSignUp.bind(this);
  }

  countrySelect = (e) => {
    console.log(e);
    const { signupData } = this.state;
    signupData['country'] = e.value;
    this.setState({ signupData, countryCode: e.code });
  };

  daySelect = (e) => {
    this.state.day = e.value;
    console.log(e);
  };

  monthSelect = (e) => {
    this.state.month = e.value;
    console.log(e);
  };

  yearSelect = (e) => {
    this.state.year = e.value;
    console.log(e);
  };



  onChangehandlerSignUp = (e, key) => {
    const { signupData } = this.state;
    signupData[e.target.name] = e.target.value;
    this.setState({ signupData });
  };

  onAlertClose = () => {
    this.setState({
      msg: "", 
      scsMsg: "", 
    });
  };

  handleClick = () => {
    // alert(this.state.showPassword)
    this.setState({ showPassword: this.state.showPassword ? false : true });
  };

  handleClickPasswordConfirmation = () => {
    this.setState({ showPasswordConfirmation: this.state.showPasswordConfirmation ? false : true });
};

  onLogoutHandler() {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userData");
    localStorage.removeItem("switchUser");
    //    const check = localStorage.getItem("checkbox");
    // if (check === 'false' || check === false)
    localStorage.removeItem("checkbox");
    localStorage.removeItem("username");
    localStorage.removeItem("password");
    localStorage.removeItem("session_login");
    this.setState({
      navigate: true,
    });
  }


  onSubmitHandler = (e) => {
    e.preventDefault();
    const { signupData } = this.state;
    if(this.state.year && this.state.month && this.state.day)
      signupData['dob'] = this.state.year+'-'+this.state.month+'-'+this.state.day;

    if(this.state.signupData.phone)
      signupData['countrycode'] = this.state.countryCode;


    signupData['license_type_id'] = "";
    signupData['package_id'] = "";
    const value=queryString.parse(this.props.location.search);
    const token=value.team_member_invitation;
    const success = this.props.location;
    if(token){
      signupData['memberships_token'] = token;
    }

    // console.log(signupData);
    this.setState({ isLoading: true });
    axios
      .post(APIURL+"user-signup", this.state.signupData)
      .then((response) => {
        this.setState({ isLoading: false });
        if (response.data.status === 200) {
          this.setState({
            // msg: response.data.message+' Please activate your account from an email',
            signupData: {
              firstname: "",
              lastname: "",
              address: "",
              zip: "",
              country: "",
              phone: "",
              countrycode: "",
              dob: "",
              email: "",
              password: "",
              tnc: ""
            },
          });
          this.setState({
            // msg: 'Your account is created. Please check your mail for the activation link',
          });  
          // setTimeout(() => {
            this.setState({
              redirect: true,
            });
          // }, 2000); 
          /*setTimeout(() => {
            this.setState({ msg: "" });
            window.location.href = "/login"; 
          }, 2000);*/
        }

        if (response.data.status === "failed") {
          this.setState({ 
            scsMsg: "",
            errMsgSignUp: response.data.errors,
            // msg: response.data.message
          });
          setTimeout(() => {
            this.setState({ 
              // errMsg: {} 
              // msg: "" 
            });
          }, 2000);
        }
      });
  };

  getDays(){
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var d = new Date();
    var n = d.getFullYear();
    for (var i = 0; i < 31; i++) {
        this.state.days.push({value: i+1, label: (i+1).toString()})
    } 

    for (var j = 0; j < 12; j++) {
        this.state.months.push({value: j+1, label: months[j]})
    }

    for (var k = n; k > 1900; k--) {
        this.state.years.push({value: k, label: k.toString()})
    }
    // console.log(this.state.days);
    // console.log(this.state.months);
    // console.log(this.state.years);
  }

  getCountries(){
    axios
      .get(APIURL+"get_countries")
      .then((response) => {
        if (response.data.status === 'success') {
          // console.log(response.data.countrys);
          let countries = response.data.countrys;
          for (var c = 0; c < countries.length; c++) {
              this.state.countries.push({value: countries[c].id, label: countries[c].country, code: countries[c].country_code_mobile})
          }
          this.state.countries.sort(function(a, b) {
              var textA = a.label;
              var textB = b.label;
              return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
          });

          // console.log(this.state.countries);
        }
      });
      const query=queryString.parse(this.props.location.search);
      console.log('val', query)
      const email=query.email_id;
      const token=query.team_member_invitation;
      const { signupData } = this.state;
      if(email){
        signupData['email'] = email;
      }
      console.log('state', signupData)
  }

  componentDidMount(){
    this.onLogoutHandler()
    this.varifyToken()
    this.getDays()
    this.getCountries()
  }

  handleCheckSignUp(e) {

    this.setState({
      checked: e.target.checked,
    })
    console.log(this.state.checked)
    const { signupData } = this.state;
    signupData['tnc'] = e.target.checked ? "tnc" : "";
    this.setState({ signupData });
  }

  
   varifyToken(){
     const formData = new FormData();
    const value=queryString.parse(this.props.location.search);
    const token=value.team_member_invitation;
     const email_id=value.email_id;
    if(token && email_id){
      const { signupData } = this.state;
      signupData['email'] = email_id;
      this.setState({ signupData });
      formData.append('activation_code', token);
      axios
      .post(APIURL+"user/varify-token",formData
                    )
      .then((response) => {
         if(response.data.data.length==0)
         {
           this.setState({ tokenIsValid:false });
           const { signupData } = this.state;
           signupData['email'] = "";
           this.setState({ signupData });
         }
         
      });
    }
    else
     {
       this.setState({ tokenIsValid:false });
       const { signupData } = this.state;
       signupData['email'] = "";
       this.setState({ signupData });
     }
   }


  render() {

    const { t } = this.props;

    if (this.state.redirect) {
      return <Redirect to={{ pathname: '/login', state: { success: 'registered' } }} />;
    }
    const isLoading = this.state.isLoading;
    return (
      <>

            <div class="account-pages mt-5 mb-5">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-8 col-lg-6 col-xl-6">
                    <div class="card">

                      <div class="card-body p-4">

                        <div class="text-center m-auto">
                          <div class="auth-logo">


                                            <span class="logo-lg">
                                                <img src={logoDark} alt="Dark logo" height="35"/>
                                            </span>

                          </div>

                        </div>
                        <p className=" mb-4"></p>
                        <h4 className="">{t("Create account")}</h4>
                        <p className=" mb-4"></p>
                        <div className={`alert ${this.state.scsMsg ? "alert-success msg-show" : ""} msg-stripe ${this.state.msg ? "alert-danger msg-show" : ""}`} role="alert">
                          <strong></strong> {this.state.scsMsg} {this.state.msg}
                          <button type="button" className="close" onClick={this.onAlertClose} data-dismiss="alert" aria-label="Close"> <span aria-hidden="true">&times;</span> </button>
                        </div>
                        {
                          this.state.tokenIsValid==false?
                              <div className="alert alert-danger msg-show" role="alert">
                                <strong>Die Einladung wurde nicht gefunden oder wurde bereits angenommen.</strong>
                              </div>
                              :
                              ""
                        }

                        <Form action="#">
                          <div className="mb-3">
                            <div className="row">
                              <div className="col">
                                <Label  className="form-label">{t("First name")} <span>*</span></Label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="firstname"
                                    placeholder={t("Enter first name")}
                                    value={this.state.signupData.firstname}
                                    onChange={this.onChangehandlerSignUp}
                                    maxLength={20}
                                />
                                <span className="text-danger">{t(this.state.errMsgSignUp.firstname)}</span>
                              </div>
                              <div className="col">
                                <Label className="form-label">{t("Last name")} <span>*</span></Label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="lastname"
                                    placeholder={t("Enter last name")}
                                    value={this.state.signupData.lastname}
                                    onChange={this.onChangehandlerSignUp}
                                    maxLength={20}
                                />
                                <span className="text-danger">{t(this.state.errMsgSignUp.lastname)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Address")} <span>*</span></Label>
                            <Input
                                className="form-control"
                                required=""
                                type="text"
                                name="address"
                                placeholder={t("Enter address")}
                                value={this.state.signupData.address}
                                onChange={this.onChangehandlerSignUp}
                            />
                            <span className="text-danger">{t(this.state.errMsgSignUp.address)}</span>
                          </div>
                          <div className="mb-3">
                            <div className="row">
                              <div className="col">
                                <Label className="form-label">{t("ZIP")} <span>*</span></Label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="zip"
                                    placeholder={t("Enter ZIP")}
                                    value={this.state.signupData.zip}
                                    onChange={this.onChangehandlerSignUp}
                                />
                                <span className="text-danger">{t(this.state.errMsgSignUp.zip)}</span>
                              </div>
                              <div className="col">
                                <Label className="form-label">{t("Place")} <span>*</span></Label>
                                <Input
                                    className="form-control"
                                    required=""
                                    type="text"
                                    name="place"
                                    placeholder={t("Enter place")}
                                    value={this.state.signupData.place}
                                    onChange={this.onChangehandlerSignUp}
                                />
                                <span className="text-danger">{t(this.state.errMsgSignUp.place)}</span>
                              </div>
                            </div>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Country")} <span>*</span></Label>
                            <Select
                                required=""
                                className="form-select-control select select1"
                                placeholder={t("Select")}
                                options={this.state.countries}
                                value={this.state.countries.label}
                                onChange={this.countrySelect}
                                styles={colourStyles}
                            />
                            <span className="text-danger">{t(this.state.errMsgSignUp.country)}</span>
                          </div>

                          <div className="mb-3">
                            <label htmlFor="phone" className="form-label">{t("Phone number")}</label>
                            <Input
                                className="form-control input-arrow-hide"
                                type="number"
                                name="phone"
                                min="0"
                                onKeyDown={e => (e.keyCode === 38 || e.keyCode === 40 || e.keyCode === 69 || e.keyCode === 190) && e.preventDefault()}
                                placeholder={t("Enter phone number")}
                                value={this.state.signupData.phone}
                                onChange={this.onChangehandlerSignUp}
                            />
                            <span className="text-danger">{t(this.state.errMsgSignUp.phone)}</span>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Date of birth")}</Label>
                            <div className="row">
                              <div className="col">
                                <Select
                                    placeholder={t("Day")}
                                    // className="form-select-control"
                                    options={this.state.days}
                                    value={this.state.days.label}
                                    onChange={this.daySelect}
                                    styles={colourStyles}
                                />
                              </div>
                              <div className="col">
                                <Select
                                    placeholder={t("Month")}
                                    // className="form-select-control"
                                    options={this.state.months}
                                    value={this.state.months.label}
                                    onChange={this.monthSelect}
                                    styles={colourStyles}
                                />
                              </div>
                              <div className="col">
                                <Select
                                    placeholder={t("Year")}
                                    // className="form-select-control"
                                    options={this.state.years}
                                    onChange={this.yearSelect}
                                    styles={colourStyles}
                                />
                              </div>
                            </div>

                            <span className="text-danger">{t(this.state.errMsgSignUp.dob)}</span>
                          </div>
                          <div className="mb-3">
                            <Label  className="form-label">{t("E-mail address")}<span>*</span></Label>
                            <Input
                                className="form-control"
                                required=""
                                type="email"
                                name="email"
                                placeholder={t("Enter e-mail address")}
                                value={this.state.signupData.email}
                                onChange={this.onChangehandlerSignUp}
                            />
                            <span className="text-danger">{t(this.state.errMsgSignUp.email)}</span>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Password")} <span>*</span></Label>
                            <div className="input-group input-group-merge d-flex">
                              <Input
                                  className="form-control"
                                  required=""
                                  type={this.state.showPassword ? "text" : "password"}
                                  name="password"
                                  placeholder={t("Enter password")}
                                  value={this.state.signupData.password}
                                  onChange={this.onChangehandlerSignUp}
                              />
                              <div className={`${this.state.showPassword ? "show-password d-flex" : "d-flex"}`} data-password={this.state.showPassword}>
                                <a className="input-group-text ">
                                  <span className="password-eye" onClick={this.handleClick}></span>
                                </a>
                              </div>
                            </div>
                            <span className="text-danger">{t(this.state.errMsgSignUp.password)}</span>
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">{t("Confirm password")} <span>*</span></Label>
                            <div className="input-group input-group-merge d-flex">
                              <Input
                                  className="form-control"
                                  required=""
                                  type={this.state.showPasswordConfirmation ? "text" : "password"}
                                  name="password_confirmation"
                                  placeholder={t("Enter confirm password")}
                                  value={this.state.signupData.password_confirmation}
                                  onChange={this.onChangehandlerSignUp}
                              />
                              <div className={`${this.state.showPasswordConfirmation ? "show-password d-flex" : "d-flex"}`} data-password={this.state.showPasswordConfirmation}>
                                <a className="input-group-text ">
                                  <span className="password-eye" onClick={this.handleClickPasswordConfirmation}></span>
                                </a>
                              </div>
                            </div>
                            <span className="text-danger">{t(this.state.errMsgSignUp.password_confirmation)}</span>
                          </div>
                          <div className="mb-3">
                            <div className="form-check">
                              <Input
                                  className="form-check-input"
                                  type="checkbox"
                                  required=""
                                  checked={this.state.checked}
                                  onChange={this.handleCheckSignUp}
                                  id="checkbox-signup"
                              />
                              <Input
                                  type="hidden"
                                  required=""
                                  name="tnc"
                                  value={this.state.signupData.tnc}
                              />
                              <label className="form-check-label">
                                <a href="/rechtliches" target="_blank" className="" style={{ textDecoration: "underline" }}>{t("I accept the terms and conditions")}</a>
                              </label>
                            </div>
                            <span className="text-danger">{t(this.state.errMsgSignUp.tnc)}</span>
                          </div>
                        </Form>

                        <Button
                            className="btn btn-primary btn-block"
                            color="success"
                            onClick={this.onSubmitHandler}
                            style={{ float: "right" }}
                        >
                          {t("Create account")}
                          {this.state.isLoading ? (
                              <span
                                  className="spinner-border spinner-border-sm ml-5"
                                  role="status"
                                  aria-hidden="true"
                              ></span>
                          ) : (
                              <span></span>
                          )}
                        </Button>

                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-12 text-center">
                        <p class="text-muted">Already have account? <a href="/login" className="">Sign In</a></p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
      </>
    );
  }
}

export default withTranslation()(RegistrationPage);
