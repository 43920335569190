
import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../constants/Common';
import Select from 'react-select'
import {Spinner, Button, Modal, ModalBody, Table, ModalFooter, ModalHeader, Label, Input } from 'reactstrap';
import '@szhsin/react-menu/dist/index.css';
import '../../assets/css/Custom.css'
import { withTranslation } from 'react-i18next';

const colourStyles = {
   
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {

            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};

class SettingAnswerOpt extends Component {
    constructor(props) {
        super(props);
        const { t } = this.props;
        this.state = {
            Stateoptions: [
                { value: 1, label: t('Registered') },
                { value: 2, label: t('Unregistered') },
                { value: 3, label: t('Maybe') }
            ],
            Commentoptions: [
                { value: 0, label: t('No') },
                { value: 1, label: t('Yes')  },
            ],
            AnsOpt_List: [],
            answer_name: "",
            State: [],
            Comment: [{ value: 0, label: t('No')  }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            active: false,
            errMsg: "",
            addOptModal: false,
            modal: false,
            order: "",
            Answer_id: "",
            RemScsMsg: "",
            sortRow:[],
            toast: false,
            checked: false,
            is_default:"No",
            msg: "",
            scsMsg: "",

        }
        this.Editclick = this.Editclick.bind(this);
        this.toggle = this.toggle.bind(this);
        this.AddModal = this.AddModal.bind(this);
    }

    toggle(e) {
        const { t } = this.props;
        this.setState({
            addOptModal: !this.state.addOptModal,
            errMsg: '',
            answer_name: "",
            State: [{ value: 1, label: t('Registered') }],
            Comment: [{ value: 0, label: t('No') }],
        });
    }

    AddModal(e) {
        this.setState({
            AddModal: !this.state.AddModal,
        });
    }

    HandleSubjectInput(e) { this.setState({ answer_name: e }) }

    HandleState = (e) => {
        this.setState({
            State: [{ value: e.value, label: e.label }]
        })
       
    };
    HandleCommentOpt = (e) => {
        this.setState({
            Comment: [{ value: e.value, label: e.label }]
        })
    };

    getAnswerOption(id) {
        this.setState({
            Loader:true
        })
        var token = this.state.token
        axios
            .get(APIURL + "get_answers/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    this.setState({
                        AnsOpt_List: response.data.data,
                       // Comment: response.data.data.comment_mandatory,
                        Loader:false
                    });
                    this.setState({
                        selected_type: [{
                            'value': response.data.data.comment_mandatory,
                        }]
                    });
                }
            });
    }

    handleModalInputs(e) {
        this.setState({
            answer_id: e.answer_id,
            answer_name: e.answer_name,
            State: e.state,
            Comment: e.comment_mandatory,
            order: e.sort,
            checked: e.active

        })
    }
    handleState = (state) => {
        this.setState({ state }, () =>
            console.log(`Option selected:`, this.state.state)
        );
    };
    handleComment = (Comment) => {
        this.setState({ Comment }, () =>
            console.log(`Option comment:`, this.state.Comment)
        );
    };
    handleStatus(e) {
        this.setState({
            active: e.target.checked
        });
    }
    Editclick(e) {
        const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.handleModalInputs(e)
        let clabel = "No";
        if (e.comment_mandatory === null || e.comment_mandatory === 0) {
            clabel = "No"; e.comment_mandatory = 0;
        }
        else 
        {
            clabel = this.state.Commentoptions[1].label;
            e.comment_mandatory = 1;
        }

        let slabel = "State";

        if (e.state === null || e.state === 0) {
            slabel = "State"; e.state = 0;
        }
        else if (e.state > 0) {
             if(e.state === 1)
            {
              slabel = this.state.Stateoptions[0].label;
            }
            else if(e.state === 2)
            {
              slabel = this.state.Stateoptions[1].label;
            }
            else
            {
              slabel = this.state.Stateoptions[2].label;
            }

           
        }

        this.setState({
            modal: !this.state.modal,
            Answer_id: e.answer_id,
            active: e.active === 1 ? true : false,
            State: [{ value: e.state, label: slabel }],
            Comment: [{ value: e.comment_mandatory, label: t(clabel) }],
            is_default: e.is_default

        });
    }

    activeStatus() {
        this.setState({
            checked: !this.state.checked
        })
    }

    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    addAnsOpt = () => {
          const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('answer_id', '');
        formData.append('answer_name', this.state.answer_name);
        formData.append('active', 1);
        if (Array.isArray(this.state.State) && this.state.State.length) {
            formData.append('state', this.state.State[0].value);
        }
        else{
            formData.append('state', "");
        }
        formData.append('comment', this.state.Comment[0].value);
        var app_url = APIURL + "create_answers";
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.getAnswerOption(this.props.data.teamId);
                    this.setState({
                        errMsg: '',
                        redirect: true,
                        Loader: false,
                        addOptModal: false,
                        answer_name: '',
                        State:  [{ value: 0, label: 'State' }],
                        Comment: [{ value: 0, label: t('No') }],
                        scsMsg: response.data.message,
                        errMsgAttr: '',
                    });
            })
            .catch(error =>{
                   if(error.response.data.message === "validation_error")
                   {
                        this.setState({
                            errMsg:error.response.data.errors,
                            isLoading: false,
                            Loader:false,
                        })
                   }
                   else
                   {
                        this.setState({
                          error,
                          isLoading: false,
                          Loader:false,
                        })
                   }
                    
                }
              );  
                    
              
    }

    handleAddAnsOpt = () => {
          const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        if (this.state.state) {
            this.setState({ AddModal: false })
            this.setState({
                AnsOpt_List: this.state.AnsOpt_List.concat([{

                    State: this.state.state,
                    Comment: this.state.Comment,
                    answer_id: ""
                }]),
                answer_name: '',
                State: '',
                Comment: [{ value: 0, label: t('No')}],
                errMsgAttr: ''
            });
        }
        else {
            this.setState({ errMsgAttr: 'Please enter answer option.' });
        }
    }

    handleRemoveAnsModal = (e) =>{
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            removeAnsId:e.answer_id,
            removeAnsModal:!this.state.removeAnsModal
        })
    }
    RemoveModalClose(){
        this.setState({
            removeAnsModal:false
        })
    }


    handleRemoveAnsopt(e) {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        this.setState({
            Loader: true
        })
        axios.post(APIURL + "delete_answer/" +this.state.removeAnsId, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    RemScsMsg: response.data.message,
                    redirect: true,
                    toast: true,
                    removeAnsModal:false,
                    scsMsg: response.data.message,
                    Loader: false,
                })
                this.setState({
                    AnsOpt_List: this.state.AnsOpt_List.filter((s, sidx) =>this.state.removeAnsId != s.answer_id
                    )
                });
            })
            .catch(error => {
                this.setState({
                    removeAnsModal:false,
                    errMsg: { message: error.response.data.message },
                    Loader: false,
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }


    onSubmit = (e) => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        
        const formData = new FormData();


        formData.append('state', this.state.State[0].value);
        formData.append('comment', this.state.Comment[0].value);
        formData.append('team_id', this.props.data.teamId);
        formData.append('active', 1);
        formData.append('answer_name', this.state.answer_name);

        var app_url = APIURL + "edit_answer/" + this.state.Answer_id
        var token = this.state.token
        this.setState({
            Loader: true
        })
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.getAnswerOption(this.props.data.teamId);
                    this.setState({
                        errMsg: '',
                        redirect: true,
                        modal: false,
                        scsMsg: response.data.message,
                        Loader: false,
                    });
            })
            .catch(error =>{
               if(error.response.data.message === "validation_error")
               {
                    this.setState({
                        errMsg:error.response.data.errors,
                        isLoading: false,
                        Loader:false,
                    })
               }
               else
               {
                    this.setState({
                      error,
                      isLoading: false,
                      Loader:false,
                    })
               }
                
            }
          );  
    };

    handleStep(type,index){
        const sort_List=this.state.AnsOpt_List;
        let sortRow=[]; let selfsort; let othersort;
     
       if(type === "up")
       {
           
            const self=sort_List[index];
            const other=sort_List[index-1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index-1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['answer_id'],"sort":self['sort']});
            sortRow.push({id:other['answer_id'],"sort":other['sort']});

            this.setState({ 
                AnsOpt_List: sort_List
            })

       }
       else if(type === "down")
       {
            const self=sort_List[index];
            const other=sort_List[index+1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index+1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['answer_id'],"sort":self['sort']});
            sortRow.push({id:other['answer_id'],"sort":other['sort']});

            this.setState({ 
                AnsOpt_List: sort_List
            })
       }
        this.setState({ 
            sortRow: sortRow
        },()=>{this.updateStep()})

    }


    updateStep()
    {
            // this.setState({ func_List: this.state.func_List.filter((s, sidx) => idx != sidx) });
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "answer/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                   
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }


    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getAnswerOption(this.props.data.teamId);
        }
        this.getOption();
    }

    componentWillReceiveProps() {   
        this.getOption();
    }

    getOption(){
        const { t } = this.props;
        let Commentoptions=[];
        Commentoptions.push({ value: 0, label: t('No') })
        Commentoptions.push({ value: 1, label: t('Yes') })

        let Comment=this.state.Comment;
     
      Comment= [{ value: Comment[0].value, label: Comment[0].label}]
      this.setState({ 
        Commentoptions: Commentoptions,
        Comment:Comment 
       })

       
   }

   onAlertClose = () => {
        this.setState({
            msg: "",
            scsMsg: "",
        });
    };

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                
                <div className="row">

                    <div className="card">
                        <div className="card-body">
                            <h5>{t("Answer options")}</h5>
                            {this.state.errMsg.message &&
                                <span className="text-danger">{this.state.errMsg.message}</span>
                            }
                            <div className="table-responsive">
                            <Table hover className="table table-hover table-centered nowrap">
                                    <thead className="bg-light">
                                        <tr>
                                            <th></th>
                                            <th>{t("Option")}</th>
                                            <th>{t("Status")}</th>
                                            <th>{t("Comment mandatory")}</th>
                                            <th className="text-center">{t("Action")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.AnsOpt_List.length > 0 && this.state.AnsOpt_List.map((item, idx) => (
                                            <tr key={item.answer_id}>
                                                <td>
                                                         {idx!=0 &&
                                                          <i  onClick={(e) => this.handleStep('up',idx)}   className="mdi mdi-arrow-up"></i>
                                                         }
                                                        {this.state.AnsOpt_List.length != idx+1 &&
                                                            <i onClick={(e) => this.handleStep('down',idx)}  className="mdi mdi-arrow-down"></i>
                                                        }
                                                </td>
                                                <td> {t(item.answer_name)} </td>
                                                <td>
                                                    {
                                                        item.state === 0 ? <span>{t('state')}</span> :
                                                            item.state === 1 ? <span>{t('Registered')}</span> :
                                                                item.state === 2 ? <span>{t('Unregistered')}</span> :
                                                                    item.state === 3 ? <span>{t('Maybe')}</span> :
                                                                        ""
                                                    }

                                                </td>
                                                <td>
                                                    {item.comment_mandatory === 1 ? <span>{t('Yes')}</span> :<span>{t('No')}</span>}
                                                </td>
                                                <td className="text-center">
                                                   
                                                        <div>
                                                            <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(item)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                            {item.is_default === "No" &&
                                                            <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveAnsModal(item)}> <i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                             }
                                                        </div>
                                                    
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </Table>

                                <div className=" mt-3">
                                    <Button
                                        type="button"
                                        id="add_attrs"
                                        onClick={this.toggle}
                                        className="btn btn-warning waves-effect waves-light mt-2 add_attrs"
                                    >
                                        {t("Add answer option")}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal size="md" isOpen={this.state.modal}  autoFocus={false}>
                    <ModalHeader toggle={this.Editclick}>{t("Edit answer option")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                        {this.state.is_default === "No" &&
                         <React.Fragment>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Answer option")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="useremail"
                                        placeholder={t("Enter answer option")}
                                        value={this.state.answer_name}
                                        onChange={(e) => this.HandleSubjectInput(e.target.value)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.answer_name)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12 view-element">
                                <div className="form-group">
                                    <Label>{t("State")}</Label>
                                    <Select
                                        placeholder="State"
                                        value={this.state.State}
                                        onChange={this.HandleState}
                                        options={this.state.Stateoptions}
                                        styles={colourStyles}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.state)}</span>
                                </div>
                            </div>
                            </React.Fragment>
                        }
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Comment mandatory")}</Label>
                                    <Select
                                        placeholder="Only teamadmin"
                                        value={this.state.Comment}
                                        onChange={this.HandleCommentOpt}
                                        options={this.state.Commentoptions}
                                        styles={colourStyles}
                                    />
                                     <span className="text-danger">{t(this.state.errMsg.comment)}</span>
                                </div>
                            </div>
                        {/* {this.state.is_default === "No" &&
                            <div className="col-sm-12">
                                <div className="form-group mb-0">
                                    <input 
                                    className="form-check-input mr-1" 
                                    type="checkbox"
                                     onChange={(e) => this.handleStatus(e)} 
                                     checked={this.state.active} 
                                     id="flexCheckDefault" 
                                     />
                                    <Label className="mb-0">{t("Active")}</Label>

                                </div>
                            </div>
                        } */}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                           <Button className="btn btn-danger" onClick={() => this.CloseModal()}> {t("Cancel")} </Button>
                           <Button className="btn btn-success" onClick={() => this.onSubmit()}>{t("Save")} </Button>
                            </div>
                    </ModalFooter>
                </Modal>
                <Modal size="md" isOpen={this.state.addOptModal}  autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t("Add answer option")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Answer option")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="useremail"
                                        placeholder={t("Enter answer option")}
                                        value={this.state.answer_name}
                                        onChange={(e) => this.HandleSubjectInput(e.target.value)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.answer_name)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12 view-element">
                                <div className="form-group">
                                    <Label>{t("State")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder="Select State"
                                        styles={colourStyles}
                                        options={this.state.Stateoptions}
                                        value={this.state.State}
                                        onChange={this.HandleState}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.state)}</span>
                                </div>
                            </div>
                            
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Comment mandatory")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required=""
                                        placeholder="Select Comment Mendatory"
                                        styles={colourStyles}
                                        options={this.state.Commentoptions}
                                        value={this.state.Comment}
                                        onChange={this.HandleCommentOpt}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.comment)}</span>
                                </div>
                            </div>
                            {/*<div className="col-sm-12">
                                <div className="form-group mb-0">
                                    <input 
                                    className="form-check-input mr-1" 
                                    type="checkbox"
                                     onChange={(e) => this.handleStatus(e)} 
                                     checked={this.state.active} 
                                     id="flexCheckDefault" 
                                     />
                                    <Label className="mb-0">{t("Active")}</Label>

                                </div>
                            </div>*/}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </Button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.addAnsOpt()}>{t("Save")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeAnsModal}  >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">{t("Are you sure want to delete?")}</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.handleRemoveAnsopt()} >{t("Confirm")} </Button>{' '}
                                <Button className="mx-2" color="default" onClick={()=> this.RemoveModalClose()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog" style={{ width: "300px" }} isOpen={!!this.state.scsMsg}>
                    <div className="row" style={{ backgroundColor: "#1abc9c", padding: "10px", borderRadius: "4px" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group text-center w-100">
                                    {this.state.scsMsg}
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100">
                            <Button className="btn btn-light" onClick={() => this.setState({ scsMsg: "" })}> {t("Ok")} </Button>
                        </div>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}
export default withTranslation()(SettingAnswerOpt);