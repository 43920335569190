import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../Admin/GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Pagination from "react-js-pagination";
import dateFormat from 'dateformat';
import Footer from '../../GeneralComponents/Footer'


export default class TeamMembers extends Component {

    constructor(props) {
        super(props);
      this.state = {
            Transaction_List: [],
            msg: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Loader: false,
            modal: false,
            editData:[],
            expiry_date:'',
            transaction_id:0,
            activePage: 1,
            limit: 15,
            totalItemsCount:0,
            search:"",
            status:"",
            errMsg:[],
            statusArray:[{'value':"","label":"All"},{'value':"No","label":"Inactive"},{'value':"Yes","label":"Active"}]

        }
    }

    getTransactionList() {
        this.setState({
            Loader:true
        })
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('status', this.state.status);
        formData.append('limit', this.state.limit);
        var token = this.state.token
        axios
            .post(APIURL + "admin/transaction/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                
              
                    this.setState({
                        Transaction_List: response.data.data.data,
                        activePage:response.data.data.current_page,
                        totalItemsCount:response.data.data.total,
                        Loader:false
                    })
              
            })
            .catch(error => {
                this.setState({
                    error,
                    Loader: false
                })
            });
    }

    componentDidMount() {
      this.getTransactionList();
   
    }

  handlePageChange(pageNumber) {
      console.log(`active page is ${pageNumber}`);
        this.setState(
          {activePage: pageNumber}
          ,()=>{this.getTransactionList()});
      }
  handleSearch (e){
       this.setState(
          {search: e.target.value, activePage: 1}
          ,()=>{this.getTransactionList()});
  }

  handleStatus(e){
    this.setState(
          {status: e.value}
          ,()=>{this.getTransactionList()});
  }
  onChangeExpiryHandler(e){
    this.setState({
        expiry_date: e
    });
  }

  

  Editclick(e)
  {
       this.setState({
            modal: !this.state.modal,
            editData:e,
            expiry_date:e.end_date,
            transaction_id:e.id
            });
  }

  CloseModal() {
    this.setState({
        modal: !this.state.modal,
        editData: []
        });
  }

  onSubmit = (e) => {
        this.setState({
            Loader: true
        });
        const formData = new FormData();

        formData.append('end_date', this.state.expiry_date);
        formData.append('id', this.state.transaction_id);
        var app_url = APIURL + "admin/transaction/update";
        var token = this.state.token

        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    msg: response.data.message,
                    modal: false,
                    Loader: false
               },()=>{this.getTransactionList()});
            })
            .catch((error) => {
                if (error.response.data.message === "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }
            });
    };


 

    render() {
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (this.state.user.user_type !== 'Admin') {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                 <Sidebar
                    data={{
                        active: 'user',
                        ActivePage: "payment"
                    }} />
                   
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">Super Admin | Payment Transaction List </h4>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6 col-sm-4 col-md-3">
                                  <Input type="text" onChange={(e)=>this.handleSearch(e)} placeholder="Search .." />
                                </div>
                                <div className="col-6 col-sm-4 col-md-3">
                                  {/* <Select
                                      aria-label=".form-select-lg example"
                                      required=""
                                      placeholder="Select Status"
                                      className="form-select-control"
                                      options={this.state.statusArray}
                                      onChange={(e)=>this.handleStatus(e)}
                                  /> */}
                                </div>
                            </div>
                           
                            <div className="row">
                                <div className="table-responsive">
                                
                                    <Table id="table-to-xls" hover className="table table-hover table-centered nowrap mt-3">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr>
                                                <th className="fw-medium">#No</th>
                                               
                                                <th className="fw-medium ">E-Mail</th>
                                                 <th className="fw-medium">Payment Type</th>
                                                <th className="fw-medium">Transaction Id</th>
                                                <th className="fw-medium">Transaction UUID</th>
                                                <th className="fw-medium">Package Name</th>
                                                <th className="fw-medium ">Start Date</th>
                                                <th className="fw-medium">Expiry Date</th>
                                                <th className="fw-medium">Number Of Team</th>
                                                <th className="fw-medium">Amount</th>
                                                <th className="fw-medium">Action</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.Transaction_List.length > 0 ? this.state.Transaction_List.map((item, idx) => (
                                                <tr className="">
                                                    <td className="fw-medium">{(this.state.activePage-1)*this.state.limit+(idx+1)}</td>
                                                   
                                                    <td >{item.email}</td>
                                                    <td >{item.payment_type}</td>
                                                    <td >{item.transaction_id}</td>
                                                    <td >{item.transaction_uuid}</td>
                                                    <td >{item.name}</td>
                                                    <td >{dateFormat(item.start_date, "dd.mm.yyyy")}</td>
                                                    <td >{dateFormat(item.end_date, "dd.mm.yyyy")}</td>
                                                    <td >{item.number_of_team}</td>
                                                    <td >{item.amount}</td>
                                                    <td > <Button size="sm" color="warning" onClick={(e) => this.Editclick(item)} >Edit</Button></td>
                                                </tr>
                                            )) :

                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No Record Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {this.state.totalItemsCount>0 && 
                             <div className="pagination-rounded">
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                              />
                            </div>
                          }
                           
                           
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}

                <Modal size="md" isOpen={this.state.modal}   autoFocus={false}>
                    <ModalHeader  toggle={() => this.CloseModal()}  >Edit Transaction</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>Expiry Date</Label>
                                    <Input
                                        type="date"
                                        className="form-control"
                                        required=""
                                        value={this.state.expiry_date}
                                        onChange={(e) => this.onChangeExpiryHandler(e.target.value)}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{this.state.errMsg.end_date}</span>
                                </div>
                            </div>
                           
                           
                            
                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn btn-danger" onClick={() => this.CloseModal()}>Cancel </button>
                            <Button className="btn btn-success" onClick={() => this.onSubmit()}>Save </Button>
                          </div>
                    </ModalFooter>
                </Modal>
                <div className="rightbar-overlay"></div>

            </div>
        );
    }
}