import React, { Component } from 'react'
import { Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL, APIDOMAIN } from '../../../constants/Common';
import { Redirect, Link } from "react-router-dom";
import '@szhsin/react-menu/dist/index.css';
import Sidebar from "../../../GeneralComponents/Sidebar";
import '../../../../assets/css/style.css'
import '../../../../assets/css/bootstrap.min.css'
import '../../../../assets/css/icons.css'
import '../../../../assets/css/Custom.css'
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";

const guestOption = [
    {
        label: "No",
        value: "0",
    },
    {
        label: "Yes",
        value: "1",
    }
];


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamId: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            error: [],
            errorRequireFiled: "",
            ActiveTeam: 0,
            isPermission: 1,
            statisticMember:[],
            csvData:[],
            headers: [
                { label: "Mitglleder", key: "full_name" },
                { label: "Anmeldungen", key: "registered" },
                { label: "Abmeldungen", key: "unregistered" },
                { label: "Vielleicht", key: "maybe" },
                { label: "Kein Elntrag", key: "no_option_selected" },
            ],
            fromDate: "",
            toDate: "",
            page: 1,
            registered: 0,
            unregistered: 0,
            maybe: 0,
            no_option_selected: 0,
            func_List: []
        };
    }

    getTeamDetails(){

        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });


    }

    componentDidMount() {
        if (this.state.teamId != "undefined") {
            this.getExpirationPlanMsg();
            this.getStatisticMember();
            this.getEventTypes();
            this.getFunctions();
            this.getStatisticEvent();
            this.getTeamDetails();
        }
    }

    handleChangePage() {
        this.setState({
            page: 2
        }, () => {
            this.getStatisticMember();
        });
    }

    handleFromDate(e) {
        this.setState({
            fromDate: e
        }, () => {
            this.getStatisticMember();
        });
    }

    handleToDate(e) {
        this.setState({
            toDate: e
        }, () => {
            this.getStatisticMember();
        });
    }
   
    getUnique(arr, index) {
        const unique = arr
            .map(e => e[index])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) == i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
        return unique;
    }


    getStatisticMember = () => {
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);
        formData.append('FROM', this.state.fromDate);
        formData.append('TO', this.state.toDate);
        formData.append('page', this.state.page);
        var token = this.state.token
        axios
            .post(APIURL + "statistics/get-members", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                //console.log( response.data.data);

                var responseData = response.data.data;

                this.setState({
                    statisticMember: response.data.data,
                    csvData: response.data.data
                })

                //console.log(response.data.data);

                if(this.state.csvData.length > 0) {
                    var registered = 0;
                    var unregistered = 0;
                    var maybe = 0;
                    var no_option_selected = 0;
                    var csvdata = responseData

                    responseData.map((item) => {
                        registered += item.registered;
                        unregistered += item.unregistered;
                        maybe += item.maybe;
                        no_option_selected += item.no_option_selected;
                    });

                    csvdata.push({
                        full_name: "Total",
                        registered: registered,
                        unregistered: unregistered,
                        maybe: maybe,
                        no_option_selected: no_option_selected
                    })

                    this.setState({
                        registered: registered,
                        unregistered: unregistered,
                        maybe: maybe,
                        no_option_selected: no_option_selected,
                        csvData: csvdata
                    })
                }
                console.log(this.state.statisticMember);

            });
    }

    getEventTypes() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "eventtype/get_active_event_types", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let EventTypes = response.data.data;
                const arryEvent = [];
                for (var c = 0; c < EventTypes.length; c++) {
                    arryEvent.push({ value: EventTypes[c].event_type_id, label: EventTypes[c].name })
                }

                console.log(arryEvent);
                // this.setState({
                //     EventTypes: arryEvent,
                //     EventTypesList: response.data.data
                // })
            })
            // .catch(error =>
            //     // this.setState({
            //     //     error,
            //     //     isLoading: false
            //     // })
            // );
    }

    getFunctions() {
        var token = this.state.token
        axios
            .get(APIURL + "get_active_functions/" + this.state.teamId,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {

                    let functions = response.data.data;
                    for (var c = 0; c < functions.length; c++) {
                        this.state.func_List.push({ value: functions[c].function_id, label: functions[c].name })
                    }
                }

                console.log(this.state.func_List);
            });
    }

    getStatisticEvent() {
        var token = this.state.token;
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);

        axios
            .post(APIURL + "statistics/get-eventtype", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                // if (response.data.status === 200) {

                //     let functions = response.data.data;
                //     for (var c = 0; c < functions.length; c++) {
                //         this.state.func_List.push({ value: functions[c].function_id, label: functions[c].name })
                //     }
                // }

                console.log(response.data);
            });
    }
   
    getExpirationPlanMsg = () => {
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                console.log("Subscription", response)
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan === "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }

                if (days > 60 || response.data.member.length === 0) {
                    isPermission = 0;
                }
                else if (response.data.member.length === 1) {
                    if (response.data.member[0].is_admin === 0 && response.data.member[0].is_teamleader === 0) {
                        isPermission = 0;
                    }
                }

                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission

                })
            });
    }


    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    Sub_CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    render() {
        const { t } = this.props;
        console.log("event props", this.props)
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }

       
        return (
            <React.Fragment>
                <div id="wrapper">
                    <Sidebar
                        data={{
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "events",
                            visible:this.props.location.visible
                        }} />
                    
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt justify-content-start">
                                            <h4 className="page-title">{this.state.team_name} | {t("Statistics")} </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#events1"><span>{t("Member overview")}</span></a>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/statistics/event-type/" + this.state.teamId} className="nav-link">{t("Event type overview")}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/statistics/task/" + this.state.teamId} className="nav-link">{t("Task overview")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="events1">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="header-title mb-3 text-capitalize"></h4>
                                                        <div className="mb-2">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("From")}</label>
                                                                    {/* <input placeholder="TT.MM.JJJJ" type="text" className="form-control form-control-sm" /> */}
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="from_date"
                                                                        id="from_date"
                                                                        value={this.state.fromDate}
                                                                        onChange={(e) => this.handleFromDate(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("To")}</label>
                                                                    {/* <input placeholder="TT.MM.JJJJ" type="text" className="form-control form-control-sm" /> */}
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="to_date"
                                                                        id="to_date"
                                                                        value={this.state.toDate}
                                                                        onChange={(e) => this.handleToDate(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {/* <div className="text-right mb-3"><a href="#" className="btn btn-sm btn-success">Excel Export (CSV)</a></div> */}
                                                {   this.state.csvData.length > 0 &&
                                                    <div className="text-right mb-3"><CSVLink  className="btn btn-sm btn-success" data={this.state.csvData} headers={this.state.headers} filename={"my-file.csv"}>{t("Excel Export(CSV)")}</CSVLink ></div>
                                                }
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="table-responsive table-wrapper mb-4">
                                                            <table className="table table-hover tableFixHead tableFixFoot">
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th className="fw-medium">{t("Member")}</th>
                                                                        <th className="fw-medium">{t("Registered")}</th>
                                                                        <th className="fw-medium">{t("Unregistered")}</th>
                                                                        <th className="fw-medium">{t("May Be")}</th>
                                                                        <th className="fw-medium">{t("No Option Selected")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="font-14">
                                                                    {
                                                                       this.state.statisticMember.filter((item) =>item.full_name != "Total").map((item) => (
                                                                            <tr>
                                                                                <td>{item.full_name}</td>
                                                                                <td>{item.registered}</td>
                                                                                <td>{item.unregistered}</td>
                                                                                <td>{item.maybe}</td>
                                                                                <td>{item.no_option_selected}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                                <tfoot className="font-14">
                                                                    <tr>
                                                                        <th>Total</th>
                                                                        <th>{this.state.registered}</th>
                                                                        <th>{this.state.unregistered}</th>
                                                                        <th>{this.state.maybe}</th>
                                                                        <th>{this.state.no_option_selected}</th>
                                                                    </tr>
                                                                </tfoot>
                                                            </table>  
                                                        </div>
                                                        {
                                                            this.state.page == 1 && this.state.statisticMember.length > 0 
                                                            ? <div className="text-left mt-3" onClick={() => this.handleChangePage()} ><a href="#" className="btn btn-sm btn-success">{t("List of all members")}</a></div> 
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="events2">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="header-title mb-3 text-capitalize">Next event</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-right mb-3"><a href="#" className="btn btn-sm btn-success">Excel Export(CSV)</a></div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane" id="events3">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="header-title mb-3 text-capitalize">Next event</h4>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="text-right mb-3"><a href="#" className="btn btn-sm btn-success">Excel Export(CSV)</a></div>
                                                <div className="card">
                                                    <div className="card-body">
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Index);