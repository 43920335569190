import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import '../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../GeneralComponents/Sidebar";
import { Spinner, Table, Modal, ModalBody, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import dateFormat from 'dateformat';
import Footer from '../GeneralComponents/Footer'
import { Trans, withTranslation } from 'react-i18next';

const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        // const color = chroma(data.color);
        console.log({ data, isDisabled, isFocused, isSelected });
        return {
            ...styles,
            backgroundColor: isFocused ? "#BBE7F6" : null,
            color: "#333333"
        };
    }
};

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

class TeamMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Member_Team_List: [],
            Team_Request_List: [],
            email: "",
            isOwner: "",
            setMember: "",
            function: "",
            func_List: [],
            msg: "",
            errMsg: {},
            scsMsg: "",
            teamId: '',
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Is_admin: [],
            function_name: [],
            team_name:"",
            Loader: false,
            modal: false,
            acceptReqModal:false,
            cancelReqModal:false,
            Id:'',
            request_from_id:"",
            request_to_id:"",
            index:"",
            no_record:false
        }

    }

    cancelRequest(){
        alert("reject Api")
    }
    acceptReqModal(e ,idx){
        this.setState({
            acceptReqModal:!this.state.acceptReqModal,
            Id:e.id,
            request_from_id:e.request_from_id,
            request_to_id:e.request_to_id,
            teamId:e.team_id,
            index:idx
        })
    }
    acceptModalClose(){
        this.setState({
            acceptReqModal:false
        })
    }
    acceptRequest() {
        this.setState({
            Loader: true,
        })
        const formData = new FormData();
        formData.append('id', this.state.Id)
        formData.append('owner_user_id', this.state.request_to_id)
        formData.append('request_from_id', this.state.request_from_id)
        formData.append('team_id', this.state.teamId)
        // this.handleTeamAtr(e.name)
        var token = this.state.token
        axios.post(APIURL + "update_team_owner", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getTeamOwnerRequestList();
                this.setState({
                    Loader: false,
                    acceptReqModal:false
                })
                this.setState({ Team_Request_List: this.state.Team_Request_List.filter((s, sidx) => this.state.index != sidx) });
            })
            .catch(error =>{
                this.setState({
                    Loader: false,
                    acceptReqModal:false,
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }
    cancelReqModal(e,idx){
        this.setState({
            cancelReqModal:!this.state.cancelReqModal,
            Id:e.id,
            request_from_id:e.request_from_id,
            request_to_id:e.request_to_id,
            teamId:e.team_id,
            index:idx
        })
    }
    cancelModalClose(){
        this.setState({
            cancelReqModal:false
        })
    }

    cancelRequest() {
        this.setState({
            Loader: true,
        })
        const formData = new FormData();
        formData.append('id', this.state.Id)
        formData.append('owner_user_id', this.state.request_to_id)
        formData.append('request_from_id', this.state.request_from_id)
        formData.append('team_id', this.state.teamId)
        var token = this.state.token
        axios.post(APIURL + "reject_team_owner_request", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getTeamOwnerRequestList();
                this.setState({
                    Loader: false,
                    cancelReqModal:false
                })
                this.setState({ Team_Request_List: this.state.Team_Request_List.filter((s, sidx) => this.state.index != sidx) });
            })
            .catch(error =>{
                this.setState({
                    Loader: false,
                    cancelReqModal:false,
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }

    getTeamOwnerRequestList() {
        var token = this.state.token
        axios
            .get(APIURL + "get_team_owner_request/" + this.state.user.user_id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.data && response.data.data.length > 0) {
                    this.setState({ Team_Request_List: response.data.data });
                }
             this.setState({ no_record: true });
                
            });
    }

    componentDidMount() {
        this.getTeamOwnerRequestList();
        if (this.props.match.params.id > 0) {
            // this.getTeamDetails();
        }
    }
    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('user_id',this.state.user.user_id);
        var token = this.state.token
        axios
            .post(APIURL + "get_team_detail",formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                let team_name="";
                if(response.data.data!=null)
                {
                    team_name=response.data.data.team_name;
                }
                
                    this.setState({
                        team_name:team_name
                    })
                
            });
    }

    render() {
        const { t } = this.props;
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage:"team_request",
                        visible:this.props.location.visible
                    }} />
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">{t("Change team owner requests")} </h4>
                                        { this.state.errMsg.message &&
                                            <span className="text-danger">{this.state.errMsg.message}</span>
                                        }
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="table-responsive">
                                    <Table hover className="table table-hover table-centered nowrap">
                                        <thead style={{ backgroundColor: "#0c3047"}}>
                                            <tr>
                                                <th className="fw-medium">{t("Team name")}</th>
                                                <th className="fw-medium">{t("Owner")}</th>
                                                <th className="fw-medium">{t("Date of request")}</th>
                                                <th className="fw-medium text-center" style={{width:"180px"}}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                        {this.state.Team_Request_List.length > 0 && this.state.Team_Request_List.map((item, idx) => (
                                           <React.Fragment>                                                   
                                             <tr>
                                                <td >{item.team_name}</td>
                                                <td >{item.full_name}</td>
                                                <td > {dateFormat(item.created_at, "dd.mm.yyyy")}</td>
                                                <td className="text-center">
                                                    <div>
                                                        <Button size="sm" color="warning" onClick={(e) => this.acceptReqModal(item ,idx)} >{t("Accept")}</Button>
                                                        <Button size="sm" color="danger" className="ml-1" onClick={(e) => this.cancelReqModal(item ,idx)}  >{t("Reject")}</Button>
                                                    </div>
                                                </td>
                                              </tr>
                                            </React.Fragment>
                                         ))}
                                         {this.state.no_record==true && this.state.Team_Request_List.length == 0 &&
                                            <tr>
                                                <td colSpan="4">
                                                    {t("No Request Available")}
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                    
                    </div>
                </div>                

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}

                <div className="rightbar-overlay"></div>
                <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.acceptReqModal}  >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">Are you sure ?</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.acceptRequest()} >Confirm </Button>{' '}
                                <Button color="default" onClick={()=> this.acceptModalClose()}>Cancel</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.cancelReqModal}  >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">Are you sure want to Reject request ?</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.cancelRequest()} >Confirm </Button>{' '}
                                <Button color="default" onClick={()=> this.cancelModalClose()}>Cancel</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation()(TeamMembers);