import React from "react";
import { Input, Button, Modal, ModalBody, Table, ModalFooter, ModalHeader, Label } from 'reactstrap';
import { Trans, withTranslation } from 'react-i18next';

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
           taskviewmodal:this.props.data.taskviewmodal,
           detailsTask:this.props.data.detailsTask,
           detail_task_id:this.props.data.detail_task_id
        };
    }

    CloseTaskViewModal()
    {
       this.props.data.CloseTaskViewModal();
    }
    componentWillReceiveProps(props) {

        this.setState({
           taskviewmodal:props.data.taskviewmodal,
           detailsTask:props.data.detailsTask,
           detail_task_id:props.data.detail_task_id
        })

        
    }

    render() {
          const { t } = this.props;
        return (
                <Modal size="md" isOpen={this.state.taskviewmodal}   >
                    <ModalHeader  >{t("Task")}</ModalHeader>
                    {/*<ModalBody style={{ backgroundColor: "#376295", maxHeight: "400px", overflowY: "auto" }}>*/}
                    <ModalBody style={{ backgroundColor: "#376295"}}>
                        <div className="row">
                            <div className="col-sm-12">
                                {this.state.detailsTask.length > 0 && this.state.detailsTask.filter(person => person.task_id == this.state.detail_task_id).map((task, idx) => (
                                    <React.Fragment>
                                    <div className="modal-tastlist">
                                        <div className="small"><strong>{task.full_name} </strong></div>
                                        <div className="">{task.task_value}</div>                                       
                                    </div>    
                                    </React.Fragment>
                                ))}
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger" onClick={() => this.CloseTaskViewModal()}> {t("Cancel")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>
        );
    }
}
export default withTranslation()(App);
