import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../Admin/GeneralComponents/Sidebar";
import { Input, Spinner, Table,  Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Pagination from "react-js-pagination";
import Footer from '../../GeneralComponents/Footer'


export default class TeamMembers extends Component {

    constructor(props) {
        super(props);
      this.state = {
            User_List: [],
            msg: "",
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Loader: false,
            modal: false,
            activePage: 1,
            limit: 10,
            totalItemsCount:0,
            search:"",
            status:"",
            statusArray:[{'value':"","label":"Status"},{'value':0,"label":"Inactive"},{'value':1,"label":"Active"}]

        }
    }

    getTeamList() {
        this.setState({
            Loader:true
        })
        const formData = new FormData();
        formData.append('page', this.state.activePage);
        formData.append('search', this.state.search);
        formData.append('status', this.state.status);
        formData.append('limit', this.state.limit);
        var token = this.state.token
        axios
            .post(APIURL + "admin/inactive_user/list", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    User_List: response.data.data.data,
                    activePage:response.data.data.current_page,
                    totalItemsCount:response.data.data.total,
                    Loader:false
                })
              
            })
            .catch(error => {
                this.setState({
                    error,
                    Loader: false
                })
            });
    }

    componentDidMount() {
      this.getTeamList();
      // this.getTeamDetails();
    }
      handlePageChange(pageNumber) {
      console.log(`active page is ${pageNumber}`);
        this.setState(
          {activePage: pageNumber}
          ,()=>{this.getTeamList()});
      }
  handleSearch (e){
       this.setState(
          {search: e.target.value}
          ,()=>{this.getTeamList()});
  }

  handleStatus(e){
    this.setState(
          {status: e.value}
          ,()=>{this.getTeamList()});
  }
 
statusUpdate(index, item) {
        this.setState({ Loader: true });
      
        const formData = new FormData();
        formData.append('user_id', item.user_id);
        formData.append('status', item.activation_status === 1 ? 0 : 1);
        var token = this.state.token
        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/user/status", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {
                this.getTeamList();
            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false
                })
            );
    }

    doDelete(index, item) {
        this.setState({ Loader: true });
        console.log('delete', item)
        const formData = new FormData();
        formData.append('user_id', item.user_id);
        var token = this.state.token

        /* fetch repos with axios */
        axios
            .post(APIURL + "admin/user/delete", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(result => {

                const newRep = [...this.state.User_List];
                newRep.splice(index, 1);

                this.setState({
                    User_List: newRep,
                    Loader: false
                });

            })
            .catch(error =>
                this.setState({
                    error,
                    Loader: false
                })
            );
    }

    render() {
        const { visible } = this.state
        const { navigate } = this.state;
        if (navigate || !this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                 <Sidebar
                    data={{
                        active: 'user'
                    }} />
                   
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        <h4 className="page-title">Super Admin | User List </h4>
                                    </div>

                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <ul className="nav nav-tabs ">
                                        <li className="nav-item">
                                            <Link to="/admin/user" className="nav-link">
                                                <span>Active Users</span></Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link to="/admin/inactive_user" className="nav-link active">
                                                <span>Inactive Users</span></Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <br/>
                            <div className="row">
                                <div className="col-6 col-sm-4 col-md-3">
                                  <Input type="text" onChange={(e)=>this.handleSearch(e)} placeholder="Search .." />
                                </div>
                                {/*<div className="col-6 col-sm-4 col-md-3">
                                  <Select
                                      aria-label=".form-select-lg example"
                                      required=""
                                      placeholder="Select Status"
                                      className="form-select-control"
                                      options={this.state.statusArray}
                                      onChange={(e)=>this.handleStatus(e)}
                                  />
                                </div>*/}
                            </div>
                           
                            <div className="row">
                                <div className="table-responsive">
                                
                                    <Table id="table-to-xls" hover className="table table-hover table-centered nowrap mt-3">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr>
                                                <th className="fw-medium">#No</th>
                                                <th className="fw-medium ">Name</th>
                                                <th className="fw-medium">E-Mail</th>
                                                <th className="fw-medium ">Address</th>
                                               
                                                <th className="fw-medium ">Phone</th>
                                                <th className="fw-medium ">DOB</th>
                                                <th className="fw-medium">Status</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody >
                                            {this.state.User_List.length > 0 ? this.state.User_List.map((item, idx) => (
                                                <tr className="">
                                                    <td className="fw-medium">{(this.state.activePage-1)*this.state.limit+(idx+1)}</td>
                                                    <td >{item.full_name}</td>
                                                    <td>{item.email}</td>
                                                    <td >{item.address}</td>
                                                   
                                                    <td >{item.phone}</td>
                                                    <td >{item.date_of_birth}</td>
                                                    
                                                    <td >
                                                      {
                                                        item.activation_status === 1 ? 
                                                          <Button size="sm" color="success" className="ms-2" onClick={(e) => this.statusUpdate(idx, item)}>Active</Button> : 
                                                          <Button size="sm" color="default" className="ms-2" onClick={(e) => this.statusUpdate(idx, item)}>Inactive</Button>
                                                      }
                                                    </td>
                                                  
                                                </tr>
                                            )) :

                                                <tr>
                                                    <td colSpan="7" className="text-center">
                                                        No Teams Available
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            {this.state.totalItemsCount>0 && 
                             <div>
                              <Pagination
                                activePage={this.state.activePage}
                                itemsCountPerPage={this.state.limit}
                                totalItemsCount={this.state.totalItemsCount}
                                pageRangeDisplayed={5}
                                onChange={this.handlePageChange.bind(this)}
                              />
                            </div>
                          }
                           
                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>                

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                <div className="rightbar-overlay"></div>

            </div>
        );
    }
}