import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect } from "react-router-dom";
import { Button } from "reactstrap";

import TeamInfo from "./TeamInfo";
import Functions from "./Functions";
import AnswerOptions from "./AnswerOptions";
import Tasks from "./Tasks";
import Member from "./Member";
import EventTypes from "./EventTypes";
import Finish from "./Finish";
import { APIURL } from '../constants/Common';
import axios from "axios";
import Sidebar from "../GeneralComponents/Sidebar";
import { withTranslation } from 'react-i18next';
import Footer from '../GeneralComponents/Footer'

class AddTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navigate: false,
      activeTab: 0,
      completeTab: 0,
      visible: false,
      Fullvisible: false,
      user: JSON.parse(localStorage.getItem("userData")),
      token: JSON.parse(localStorage.getItem("token")),
      formValue: {},
      teamId: "",
      bookingDetails: [],
      bookingStatus: false
    };
    this.child = React.createRef();
  }

  HandleSideBar = () => {
    this.setState({
      visible: !this.state.visible,
    });

    if (this.state.visible) {
      document.body.classList.remove('sidebar-disable');
    }
    if (!this.state.visible) {
      document.body.classList.add('sidebar-disable');
    }
  };
  HandleSideBarRes = () => {
    this.setState({
      Fullvisible: !this.state.Fullvisible,
    });
  };

  handleActiveTab(index) {
    if (this.state.completeTab === 6 && index !== 6) {

    }
    else if (this.state.completeTab >= index) {
      console.log(this.state.activeTab, ' - ', index);
      if (this.state.activeTab === 1 && index === 2) {
        if (this.state.teamId) {
          // this.child.current.getFunctions();
        }
      }
      this.setState({
        activeTab: index
      });
    }

  }

  handleCompleteTab(index) {
    if (this.state.completeTab <= index) {

      this.setState({
        completeTab: index
      }, () => {
        this.handleActiveTab(index);
      });

    }
    else {
      this.handleActiveTab(index)

    }
  }

  onLogoutHandler = () => {
    localStorage.clear();
    this.setState({
      navigate: true,
    });
  };

  teamInfo(item) {
    if (item) {
      this.setState({
        teamId: item.team_id
      })
      console.log(this.state.teamId)
    }
    console.log('item', item)
  }

  componentDidMount() {
    this.getPackage()
    this.teamInfo()
  }

  onClick = () => {
    //this.child.current.getFunctions();
  };

  getPackage() {
    const formData = new FormData();


    var token = this.state.token
    axios
      .post(APIURL + "booking/details", formData, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
      .then((response) => {

        this.setState({
          bookingDetails: response.data.data,
          bookingStatus: true
        })

      });
  }

  render() {
    const { t } = this.props;
    // console.log('------------', this.props.match.params.id, '-----------');
    const { visible } = this.state
    const { navigate } = this.state;
    if (navigate || !this.state.user) {
      return <Redirect to="/login" push={true} />;
    }
    if (this.state.bookingStatus == true && this.state.bookingDetails.length == 0) {
      return <Redirect to="/upgrade_package" push={true} />;
    }
    return (
      <div id="wrapper">
        <Sidebar
          data={{
            // teamInfo: this.teamInfo.bind(this),
            teamId: this.props.match.params.id,
            team_name: this.state.team_name,
            ActivePage: "add_team",
            visible:this.props.location.visible
          }} />
        <div className={visible ? "content-page content-margin" : "content-page"}>
          <div className="content">
            {/* <!-- Start Content--> */}
            <div className="container-fluid">
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box page-title-box-alt">
                    <h4 className="page-title">{t("Create new team")}</h4>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div id="rootwizard">
                    <ul className="nav nav-pills nav-justified form-wizard-header mb-3">
                      <li className="nav-item" data-target-form="#teaminfoform">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          to=""
                          onClick={() => this.handleActiveTab(0)}
                          className={this.state.activeTab === 0 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">1</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Team information')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 1 ? "" : "desabled")} data-target-form="#functionform">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(1)}
                          className={this.state.activeTab === 1 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">2</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Functions')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 2 ? "" : "desabled")} data-target-form="#mitgliderfrom">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(2)}
                          className={this.state.activeTab === 2 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">3</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Members')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 3 ? "" : "desabled")} data-target-form="#Tasksform">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(3)}
                          className={this.state.activeTab === 3 ? ' nav-link active' : 'nav-link'}
                        >
                          <span><span className="number">4</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Tasks')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 4 ? "" : "desabled")} data-target-form="#antwortform">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(4)}
                          className={this.state.activeTab === 4 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">5</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Answer options')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 5 ? "" : "desabled")} data-target-form="#eventtypenform">
                        <Button
                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(5)}
                          className={this.state.activeTab === 5 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">6</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Event types')}</span></span>
                        </Button>
                      </li>
                      <li className={"nav-item " + (this.state.completeTab >= 6 ? "" : "desabled")} data-target-form="#finsih">
                        <Button

                          data-bs-toggle="tab"
                          data-toggle="tab"
                          onClick={() => this.handleActiveTab(6)}
                          className={this.state.activeTab === 6 ? ' nav-link active' : 'nav-link'}>
                          <span><span className="number">7</span></span> <span className="d-none d-sm-inline"> <span className="ml-1 d-block text-start text-white">{t('Finish')}</span></span>
                        </Button>
                      </li>
                    </ul>
                    {/* Team Informatin */}
                    <div className="tab-content mb-0 b-0">
                      <div
                        className={this.state.activeTab === 0 ? ' tab-pane fade active show' : 'tab-pane fade'} id="first">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "14%" }}></div>
                        </div>

                        <TeamInfo
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamInfo: this.teamInfo.bind(this),
                            teamId: this.state.teamId,
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />
                      </div>

                      <div className={this.state.activeTab === 1 ? ' tab-pane fade active show' : 'tab-pane fade'} id="second">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "28%" }}></div>
                        </div>
                        <Functions
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />
                      </div>

                      <div className={this.state.activeTab === 2 ? ' tab-pane fade active show' : 'tab-pane fade'} id="third">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "42.5%" }}></div>
                        </div>
                        <Member
                          ref={this.child}
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />
                      </div>

                      <div className={this.state.activeTab === 3 ? ' tab-pane fade active show' : 'tab-pane fade'} id="fourth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "57%" }}></div>
                        </div>
                        <Tasks
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />

                      </div>

                      <div className={this.state.activeTab === 4 ? ' tab-pane fade active show' : 'tab-pane fade'} id="fivth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "71%" }}></div>
                        </div>
                        <AnswerOptions
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />

                      </div>

                      <div className={this.state.activeTab === 5 ? ' tab-pane fade active show' : 'tab-pane fade'} id="sixth">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "86%" }}></div>
                        </div>
                        <EventTypes
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }} />
                      </div>
                      <div className={this.state.activeTab === 6 ? ' tab-pane fade active show' : 'tab-pane fade'} id="seventh">
                        <div id="bar" className="progress mb-3" style={{ height: "7px" }}>
                          <div className="bar progress-bar progress-bar-striped progress-bar-animated bg-success" style={{ width: "100%" }}></div>
                        </div>
                        <Finish
                          data={{
                            formValue: this.state.formValue,
                            handleActiveTab: this.handleActiveTab.bind(this),
                            teamId: this.state.teamId,
                            teamInfo: this.teamInfo.bind(this),
                            handleCompleteTab: this.handleCompleteTab.bind(this)
                          }}
                        />
                        <ul className="pager wizard mb-0 list-inline">
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            {/* <!-- container-fluid --> */}
          </div>
        </div>

        <Footer />

        {/*<footer className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                        <Link to="#"><i className="fe-facebook"></i></Link>
                        <Link to="#"><i className="fe-instagram"></i></Link>
                        TeamOrganizer GMbH
                    </div>
                    <div className="col-md-6">
                        <div className="text-md-end footer-links d-none d-sm-block">
                            <Link to="/">imprint</Link>
                            <Link to="/">privacy</Link>
                            <Link to="/">design by stoz</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>*/}
        <div className="rightbar-overlay"></div>
      </div>
    );
  }
}
export default withTranslation()(AddTeam);