import React, { Component } from 'react'
import { Form, Input ,Spinner , Button ,Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import axios from "axios";
import { APIURL } from '../constants/Common';
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { withTranslation } from 'react-i18next';

class Functions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            func_List: [],
            teamInfo: {
                team_name: "",
                logo: "",
                owner: "",
                teamtype: "",
                redirect: false,
                isLoading: "",
            },
            msg: "",
            sortRow:[],
            errMsg: {},
            scsMsg: "",
            errMsgFunc: '',
            atLeast:' ',
            teamTypes: [],
            setFunc: '',
            image: '',
            teamId: '',
            selected_type: [{ value: 1, label: "Ice-Hockey" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            teamAttrs: [],
            Loader:false,
            modal:false,
            functionId: "",
        };
        this.toggle = this.toggle.bind(this)
    }

    toggle(e) {
        this.setState({
            modal: !this.state.modal,
            errMsgAttr: '' ,
            setFunc: '',
            errMsg: {},
            functionId: '',
        });
    }
    
    handleChange = (e) => {
        // console.log('img', e.target.files)
        this.setState({
            image: e.target.files[0]
        })
    }

    updateInput = (event) => {
        this.setState({ setFunc: event.target.value })
    };

    handleAddFunctions = () => {
        if (this.state.functionId !== '') {
            this.setState({
                Loader:true
            })
            const formData = new FormData();
            formData.append('name', this.state.setFunc);
            formData.append('active',this.state.active);  
            formData.append('team_id',this.props.data.teamId);  
    
            var app_url = APIURL + "edit_function/" + this.state.functionId
            var token = this.state.token
            axios
                .post(app_url, formData,{
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                .then((response) => {
                        this.getFunctions(this.props.data.teamId);
                        this.setState({
                            modal:false,
                            setFunc: '', 
                            errMsgAttr: '', 
                            errMsg: '',
                            Loader:false,
                            functionId: '',
                        });
                    
                })
                .catch(error =>{
                       if(error.response.data.message=="validation_error")
                       {
                            this.setState({
                                errMsg:error.response.data.errors,
                                Loader: false
                            })
                       }
                       else
                       {
                            this.setState({
                              error,
                              Loader: false
                            })
                       }
                        
                    }
                );   
        } else {
            this.setState({
                Loader:true
            })
            const formData = new FormData();
            formData.append('team_id',this.props.data.teamId)
            formData.append('name',this.state.setFunc);  
            formData.append('active','true');  
            formData.append('function_id','');  
            var token = this.state.token
            var app_url = APIURL + "create_function";
            
            axios
                .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
                .then((response) => {  
                        this.getFunctions(this.props.data.teamId);
                        this.setState({ 
                            modal:false,
                            setFunc: '', 
                            errMsgAttr: '', 
                            errMsg: '',
                            Loader:false,
                            functionId: '',
                        });               
                })
                .catch(error =>{
                   if(error.response.data.message === "validation_error")
                   {
                        this.setState({
                            errMsg:error.response.data.errors,
                            Loader: false
                        })
                   }
                   else
                   {
                        this.setState({
                          error,
                          Loader: false
                        })
                   }
                    
                }
              );   
            }
       
    }

    handleRemoveFunc(e){
        console.log('rem', this.state.func_List, e)
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        axios.post(APIURL + "delete_function/" + e.function_id, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                    this.setState({
                        RemScsMsg: response.data.message,
                        redirect: true,
                        toast: true
                    })
                    this.setState({
                        func_List: this.state.func_List.filter((s, sidx) => e.function_id != s.function_id
                        )
                    });
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }

    handleEditFunc(e) {
        this.setState({ 
            modal: true,
            setFunc: e.name, 
            errMsgAttr: '', 
            errMsg: '',
            functionId: e.function_id,
        }); 
    }
    

    onSubmit = (e) => {  
       const { t } = this.props;
        if(this.state.func_List.length<1)
        {
             this.setState({
                atLeast:t('Atleast one function must be defined for to move on next step')
            });
             setTimeout(() => this.setState({atLeast:''}), 2000);
                
        }
        else
        {
             this.setState({
                atLeast:''
            });
         this.props.data.handleCompleteTab(2);
        }
        //this.props.data.handleActiveTab(2);
    };
 
    getFunctions(id){
        var token = this.state.token
        axios
          .get( APIURL+"get_functions/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
          .then((response) => {
              console.log("Get functions",response.data)
             if(response.data.status === 200){
                this.setState({ 
                    func_List: response.data.data 
                });
             }
             console.log(this.state.func_List)
          });
      }
    componentDidMount(){
        if(this.props.data.teamId > 0)
        {
            this.getFunctions(this.props.data.teamId);
        }
    }

   handleStep(type,index){
        const sort_List=this.state.func_List;
        let sortRow=[]; let selfsort; let othersort;
     
       if(type === "up")
       {
           
            const self=sort_List[index];
            const other=sort_List[index-1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index-1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['function_id'],"sort":self['sort']});
            sortRow.push({id:other['function_id'],"sort":other['sort']});

            this.setState({ 
                func_List: sort_List
            })

       }
       else if(type === "down")
       {
            const self=sort_List[index];
            const other=sort_List[index+1];

            selfsort=self['sort']; othersort=other['sort'];
            self['sort']=othersort;
            other['sort']=selfsort;
            sort_List[index+1]=self;
            sort_List[index]=other;

            sortRow.push({id:self['function_id'],"sort":self['sort']});
            sortRow.push({id:other['function_id'],"sort":other['sort']});

            this.setState({ 
                func_List: sort_List
            })
       }
        this.setState({ 
            sortRow: sortRow
        },()=>{this.updateStep()})

    }


    updateStep()
    {
            // this.setState({ func_List: this.state.func_List.filter((s, sidx) => idx != sidx) });
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "function/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                   
            })
             .catch(error =>{
                
                this.setState({
                   errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({errMsg: { message: '' }}), 3000);
              }
            );
    }



    
    render() {
        const { t } = this.props;
         console.log("functions",this.props)
        return (
            <>
            {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }}  /> </div> :""}
            <Form id="teaminfoform" method="post" action="#" enctype='multipart/form-data' className="form-horizontal was-validated">
                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-sm-6">
                                
                                <div className="mb-2">
                                <h5>{t("Functions")}</h5> 
                               
                            <span style={{}} >{t("Functions define the different divisions of a team or group such as goalkeeper, defender, forward, ... or exemplarily in an orchestra violin, flute, etc.")}</span>
                            
                            </div>
                                <div className="table-responsive">
                                    <table className=" table table-hover table-s tableFixHead" >
                                        <thead className="bg-light">
                                            <tr>
                                                <td></td>
                                                <td className="fw-medium">
                                                    {t("Functions")}
                                                </td >
                                                <td className="fw-medium text-center">{t("Action")}</td>
                                            </tr>
                                        </thead>
                                        <tbody  className="font-14" >
                                            <tr>
                                                <td>{/*<i className="mdi mdi-arrow-up"></i> <i className="mdi mdi-arrow-down"></i>*/}</td>
                                                <td>
                                                    {t("Team owner & team administrator")}
                                                </td>
                                                <td></td>
                                            </tr>
                                            { this.state.func_List.map((Func, idx) => (
                                                <tr>
                                                    <td>
                                                     {idx != 0 &&
                                                      <i  onClick={(e) => this.handleStep('up',idx)}   className="mdi mdi-arrow-up"></i>
                                                     }
                                                    {this.state.func_List.length != idx+1 &&
                                                        <i onClick={(e) => this.handleStep('down',idx)}  className="mdi mdi-arrow-down"></i>
                                                    }
                                                    </td>
                                                    <td>
                                                        {Func.name}
                                                    </td>
                                                    <td className="text-center">
                                                    <Button size="sm" color="warning" className="mobile-icon ms-2" onClick={(e) => this.handleEditFunc(Func)}><span>{t("Edit")}</span></Button>
                                                    <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveFunc(Func)}><span>{t("Remove")}</span></Button>
                                                   
                                                        {/* <i onClick={(e) => this.handleRemoveFunc(Func)} className="cursor_pointer far fa-trash-alt"></i> */}
                                                        </td>
                                                   
                                                </tr>
                                                ))
                                            }                                    
                                        </tbody>
                                    </table>
                                    <span className="text-danger">{this.state.atLeast}</span>
                                     { this.state.errMsg.message &&
                                          <span className="text-danger">{this.state.errMsg.message}</span>
                                     }
                                </div>
                                <div className=" mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning waves-effect waves-light mt-2 add_attrs fw-bolder"
                                        > {t("Add function")}
                                            </Button>
                                    </div>
                                <Modal size="md" isOpen={this.state.modal} autoFocus={false} style={{ backgroundColor: "#376295" }}>
                                    <ModalHeader  toggle={this.toggle}>{t(this.state.functionId !== '' ? "Edit function" : "Add function")} </ModalHeader>
                                    <ModalBody>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="useremail"
                                            onChange={this.updateInput}
                                            placeholder={t("Please enter function")}
                                            value={this.state.setFunc}
                                            autoFocus={true}
                                        />
                                        <span className="text-danger">{t(this.state.errMsgAttr)}</span> 
                                        <span className="text-danger">{t(this.state.errMsg.name)}</span>
                                    </ModalBody>
                                    <ModalFooter>
                    <div className="d-flex justify-content-between w-100">
                        <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                        <button className="btn btn-success fw-bolder" onClick={() => this.handleAddFunctions()}>{t("Save")} </button>
                    </div>
                </ModalFooter>
                                </Modal>
                            </div>  
                            {/* <!-- end row --> */}
                        </div>
                    </div>
                </div>         
                <ul className="pager wizard mb-0 list-inline">
                    <li className="previous list-inline-item">
                        <button onClick={() => this.props.data.handleActiveTab(0)} type="button" className="btn btn-warning fw-bolder mr-2 "><i className="mdi mdi-arrow-left me-1"></i>{t("Back to team information")}</button>
                    </li>
                    <li className="next list-inline-item disabled">
                        <button 
                        onClick={() => this.onSubmit()} 
                        type="button" className="btn btn-primary fw-bolder">{t("Go to members")} <i className="mdi mdi-arrow-right ms-1"></i></button>
                    </li>
                </ul>
            </Form>   
          
            </>
        )
    }
}
export default withTranslation()(Functions);