import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import logo_svg from '../../../assets/images/logo-sm.svg'
import logo_lg_svg from '../../../assets/images/logo-lg.svg'
import avatar from "../../../assets/images/avatar.png"
import { Link, Redirect } from "react-router-dom";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import '../../../assets/css/Custom.css'

export default class Sidebar extends Component {
    constructor() {
        super()
        this.state = {
             sidebarClass:"sidebar-disable",
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData")),
            AdminShow: true


        };
    }
    componentDidMount() {

    }

    handleAdminShow() {
        this.setState({
            AdminShow: !this.state.AdminShow
        })
    }

    HandleSideBar = () => {
        this.setState({
            visible: !this.state.visible,
        },  );
        
        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
    }; 
    HandleSideBarRes = () => {

        this.setState({
            Fullvisible: !this.state.Fullvisible,
        },  );
        
        if (this.state.Fullvisible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.Fullvisible) {
           
            document.body.classList.add('sidebar-disable');
            document.body.classList.add('sidebar-enable');  
                  }
    }
  

    onLogoutHandler = () => {
        localStorage.removeItem("isLoggedIn");
        localStorage.removeItem("userData");
        const check = localStorage.getItem("checkbox");
        // console.log('check', check)
        if (check === 'false' || check === false)
            localStorage.removeItem("checkbox");

        // localStorage.clear();
        this.setState({
            navigate: true,
        });
    };




    render() {
        const { user, visible, Fullvisible } = this.state
        const { navigate } = this.state;
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        return (
            <div>
                <div className="navbar-custom">
                    <div className="container-fluid">

                        <ul className="list-unstyled topnav-menu float-end mb-0">
                            <li className="dropdown notification-list topbar-dropdown">
                                <UncontrolledDropdown setActiveFromChild>
                                    <DropdownToggle className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" tag="a"  caret>
                                        <Link className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                                            <img src={avatar} alt="user" className="rounded-circle" />
                                            <b className="pro-user-name ml-1">
                                                {user.full_name}
                                                <i className="mdi mdi-chevron-down"></i>
                                            </b>
                                        </Link>
                                    </DropdownToggle>
                                     <DropdownMenu style={{ marginLeft: "30px" }}>
                                        <DropdownItem className="dropdown-item notify-item" onClick={() => this.onLogoutHandler()} >
                                            <i className="fe-log-out me-1"></i>
                                            <span>Logout</span>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </li>
                        </ul>

                        {/* <!-- LOGO --> */}
                        {visible ?
                            <div className={visible ? "logo-box logo-box-width" : "logo-box"}>
                                <Link to="/home" className="logo text-center">
                                    <span  >
                                        <img src={logo_svg} alt="" height="24" />
                                    </span>

                                </Link>
                            </div>
                            :
                            <div className="logo-box">
                                <Link to="/home" className="logo text-center">
                                    <span className="logo-sm" >
                                        <img src={logo_svg} alt="" height="24" />
                                    </span>

                                    <span className="logo-lg">
                                        <img src={logo_lg_svg} alt="" height="24" />
                                    </span>
                                </Link>
                            </div>
                        }
                        <ul className="list-unstyled topnav-menu topnav-menu-left m-0">
                            <li>
                                <button onClick={() => this.HandleSideBar()} className=" MenuIcon button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>
                            <li>
                                <button onClick={() => this.HandleSideBarRes()} className="hidebar button-menu-mobile waves-effect waves-light">
                                    <i className="fe-menu"></i>
                                </button>
                            </li>

                        </ul>
                        <div className="clearfix"></div>
                    </div>
                </div>
                <div className="sidebar-show">
                    {
                        Fullvisible ? ""
                            :
                            <div className={visible ? "left-side-menu hidetxt-Width side-sm" : " left-side-menu "}>
                                {/* <!-- LOGO --> */}
                                <div className="h-100" data-sidebar >
                                    {/* <!-- User box --> */}
                                    {visible ? "" :
                                        <div  className={visible ? "user-box text-center hidetxt" : "user-box text-center "}>
                                            <div className="rounded-circle avatar-xl m-auto bg-profile-pic" style={{ backgroundImage: `url(${avatar})` }}>

                                                {/* <img src={this.state.Logo} alt="user-img" title="Mat Helme" />*/}
                                            </div>
                                            {/* <div className="rounded-circle avatar-xl m-auto">
                                                <img src={avatar} alt="user-img" title="Mat Helme" />
                                            </div> */}
                                            <UncontrolledDropdown setActiveFromChild>
                                                <DropdownToggle tag="a" caret>
                                                    <Link className="text-reset dropdown-toggle h5 mt-2 mb-1 d-block" data-bs-toggle="dropdown" to="#" role="button" aria-haspopup="false" aria-expanded="false">
                                                        <span className="pro-user-name ms-1" style={{ color: "white" }}>
                                                            {/* {user.full_name} */} Super Admin
                                                             <i className="mdi mdi-chevron-down"></i>
                                                        </span>
                                                    </Link>
                                                </DropdownToggle>
                                                <DropdownMenu style={{ marginLeft: "30px" }}>
                                                    {/* <DropdownItem className="dropdown-item notify-item" to="/" >
                                                        <i className="fe-user me-1"></i>
                                                        <span>Simone Kradolfer</span>
                                                    </DropdownItem>
                                                    <DropdownItem className="dropdown-item notify-item" to="/" >
                                                        <i className="fe-user me-1"></i>
                                                        <span>Gaby Kradolfer</span>
                                                    </DropdownItem>
                                                    <DropdownItem className="dropdown-item notify-item" to="/" >
                                                        <i className="fe-user me-1"></i>
                                                        <span>Ivo Niffeler</span>
                                                    </DropdownItem> */}
                                                    <DropdownItem className="dropdown-item notify-item" onClick={() => this.onLogoutHandler()} >
                                                        <i className="fe-log-out me-1"></i>
                                                        <span>Logout</span>
                                                    </DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                            <p className="text-reset">
                                                {/* {user.email} */}
                                                {/* superadmin@mailinator.com */}
                                                </p>

                                        </div>}

                                    {/* <!--- Sidemenu --> */}
                                    <div id="sidebar-menu" className="menu">
                                        <ul id="side-menu">
                                            <li className={visible ? "menu-title hidetxt" : "menu-title"}><h4 className="header-title">Super Admin</h4></li>
                                           
                                            <li >
                                                <div className="team-list">
                                                    <Link  
                                                      className={this.props.data.ActivePage === "team_list" ? "waves-effect active" : "waves-effect"}
                                                    to="/admin/team" >
                                                    <i className="ri-team-line"></i>
                                                        <span className={visible ? " hidetxt" : ""}>Team List</span>
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="team-list">
                                                    <Link  
                                                     className={this.props.data.ActivePage === "user_list" ? "waves-effect active" : "waves-effect"}
                                                   
                                                    to="/admin/user" >
                                                    <i className="ri-group-line"></i>
                                                        <span className={visible ? " hidetxt" : ""}>User List</span>
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="team-list">
                                                    <Link  
                                                     className={this.props.data.ActivePage === "package" ? "waves-effect active" : "waves-effect"}
                                                    
                                                    to="/admin/package" >
                                                    <i className="ri-red-packet-line"></i>
                                                        <span className={visible ? " hidetxt" : ""}>Package List</span>
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="team-list">
                                                    <Link  
                                                     className={this.props.data.ActivePage === "payment" ? "waves-effect active" : "waves-effect"}
                                                   
                                                    to="/admin/transaction" >
                                                    <i className="ri-bank-card-line"></i>
                                                        <span className={visible ? " hidetxt" : ""}>Payment Transaction</span>
                                                    </Link>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="team-list">
                                                    <Link  
                                                     className={this.props.data.ActivePage === "password" ? "waves-effect active" : "waves-effect"}
                                                   
                                                    to="/admin/change_password" >
                                                    <i className="ri-bank-card-line"></i>
                                                        <span className={visible ? " hidetxt" : ""}>Change Password</span>
                                                    </Link>
                                                </div>
                                            </li>
                                           
                                        </ul>
                                    </div>
                                    <div className="clearfix"></div>
                                </div>
                            </div>
                    }
                </div>
            </div>
        )
    }
}
