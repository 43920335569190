import React, { Component } from "react";
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import logo_svg from '../../assets/images/logo-sm.svg'
import logo_lg_svg from '../../assets/images/logo-lg.svg'
import avatar from "../../assets/images/avatar.png"
import { Link, Redirect } from "react-router-dom";
import {Spinner,ModalFooter,Button ,ModalHeader ,ModalBody ,Modal } from 'reactstrap';
import '../../assets/css/Custom.css'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import SettingTeamInfo from "./SettingTeamInfo";
import SettingAttribute from "./SettingAttribute";
import SettingEventTypes from "./SettingEventTypes";
import SettingTasks from "./SettingTasks";
import SettingFunctions from "./SettingFunctions"
import SettingAnswerOpt from './SettingAnswerOpt'
import Sidebar from "../GeneralComponents/Sidebar";
import axios from "axios";
import { APIURL } from '../constants/Common';
import Footer from '../GeneralComponents/Footer'
import { withTranslation } from 'react-i18next';

 class Setting extends Component {
    constructor() {
        super()
        this.state = {
            navigate: false,
            visible: false,
            Fullvisible: false,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            activeTab: "1",
            TeamId: "",
            team_name: "",
            settingInfo: {},
            SubscriptionModal:false,
            subscriptionPlan:"No",
            update_Subscription:false,
            ActiveTeam:0,
            isPermission:1
        };
        this.SubscriptionModal = this.SubscriptionModal.bind(this);
        this.CloseModal = this.CloseModal.bind(this);
    }

   

 

    toggle(tab) {
        if (this.state.activeTab != tab) {
            this.setState({
                activeTab: tab
            })
        }
    }
    componentDidMount() {
        if (this.props.location.pathname.substr(14) != "undefined") {
            this.getExpirationPlanMsg()
        }
        this.teamInfo()
    }

    HandleSideBar = () => {
        this.setState({
            visible: !this.state.visible,
        },  );
        
        if (this.state.visible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.visible) {
            document.body.classList.add('sidebar-disable');
        }
    };

    HandleSideBarRes = () => {
        this.setState({
            Fullvisible: !this.state.Fullvisible,
        });
        if (this.state.Fullvisible) {
            document.body.classList.remove('sidebar-disable');
        }
        if (!this.state.Fullvisible) {
            document.body.classList.add('sidebar-disable');
        }
    };

    onLogoutHandler = () => {
        localStorage.clear();
        this.setState({
            navigate: true,
        });
    };
    
    teamInfo(item) {
        console.log("data", item)
        if (item) {
            this.setState({
                settingInfo: item,
                team_name: item.Team_name
            })
        }
    }

  
    
    getExpirationPlanMsg = () => {
        
        const formData = new FormData();
        if(this.state.user!=null)
        {
            formData.append('user_id', this.state.user.user_id);
        }
        
        formData.append('team_id', this.props.location.pathname.substr(14));
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                console.log("Subscription", response)
                let days =  response.data.after_days ;
                let Owner = response.data.is_owner;
                let Sub_true = false ; let isPermission=1;
                if( (response.data.subscriptionPlan=="No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)){
                    Sub_true = true ;
                }
                
                if(days>60 || response.data.member.length==0)
                {
                   isPermission=0;
                }
                else if(response.data.member.length==1)
                {
                    if(response.data.member[0].is_admin==0 && response.data.member[0].is_teamleader==0)
                    {
                         isPermission=0;
                    }
                }
               
                this.setState({
                    SubscriptionModal:Sub_true,
                    subscriptionPlan:response.data.subscriptionPlan,
                    is_owner:response.data.is_owner,
                    day:response.data.after_days,
                    ActiveTeam:response.data.active,
                    isPermission:isPermission
                   
                })
            });
    }
    
    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModal() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    goToSubscription(){
        this.setState({
            update_Subscription:true
        })
    }
    
    render() {
        const { t } = this.props;
        console.log("user",this.state.user)
        const { navigate } = this.state;
        if(!this.state.user){
            return <Redirect to="/login" push={true} />;
        }
        else if(!this.state.isPermission){
            return <Redirect to="/permission" push={true} />;
        }

        if (navigate ) {
            return <Redirect to="/login" push={true} />;
        }

        if (this.state.update_Subscription) {
            return <Redirect to="/upgrade_package" push={true} />;
        }
        const TeamId = this.props.location.pathname.substr(14);
      
        const { visible, Fullvisible, user, activeTab } = this.state
        return (
            <React.Fragment>
                <div id="wrapper">
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&  
                                <h6 className="mt-3">Upgrade Package.</h6>   
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&  
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>   
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                            }
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            {this.state.is_owner === "Yes" ?<Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> :""}
                            <Button className="btn btn-danger" onClick={() => this.CloseModal()}> Cancel </Button>
                        </div>
                    </ModalFooter>
                </Modal>
                    <Sidebar
                        data={{
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage:"team_setting",
                            visible:this.props.location.visible
                        }} />
                    <div className={visible ? "content-page content-margin" : "content-page"}>
                        <div className="content">

                            {/* <!-- Start Content--> */}
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt">
                                            <h4 className="page-title">{this.state.team_name}</h4>
                                        </div>
                                    </div>
                                </div>

                                <div>
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { this.toggle('1'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-home-variant"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Team infos")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { this.toggle('2'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-account-alert"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Team attributes")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '3' })}
                                                onClick={() => { this.toggle('3'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-svg"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Event types")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '4' })}
                                                onClick={() => { this.toggle('4'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-email-variant"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Tasks")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '5' })}
                                                onClick={() => { this.toggle('5'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-comment-text-outline"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Functions")}</span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({ active: activeTab === '6' })}
                                                onClick={() => { this.toggle('6'); }}
                                            >
                                                <span className="d-inline-block d-sm-none"><i className="mdi mdi-checkbox-multiple-marked-outline"></i></span>
                                                <span className="d-none d-sm-inline-block">{t("Answer options")}</span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <SettingTeamInfo data={{ teamId: TeamId, teamInfo: this.teamInfo.bind(this) , CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam}} />
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <SettingAttribute  data={{ teamId: TeamId, CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam }} />
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <SettingEventTypes data={{ teamId: TeamId , CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam }} />
                                        </TabPane>
                                        <TabPane tabId="4">
                                            <SettingTasks data={{ teamId: TeamId, CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam }} />
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <SettingFunctions data={{ teamId: TeamId, CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam }} />
                                        </TabPane>
                                        <TabPane tabId="6">
                                            <SettingAnswerOpt data={{ teamId: TeamId, CloseModal:this.CloseModal.bind(),ActiveTeam:this.state.ActiveTeam }} />
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                    {/*<footer className="footer">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <Link to="#"><i className="fe-facebook"></i></Link>
                                    <Link to="#"><i className="fe-instagram"></i></Link>
                                    TeamOrganizer GMbH
                                </div>
                                <div className="col-md-6">
                                    <div className="text-md-end footer-links d-none d-sm-block">
                                        <Link to="/">imprint</Link>
                                        <Link to="/">privacy</Link>
                                        <Link to="/">design by stoz</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </footer>*/}
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Setting);