import React, { Component } from 'react'
import '../../assets/css/style.css'
import '../../assets/css/bootstrap.min.css'
import '../../assets/css/icons.css'
import { Redirect } from 'react-router-dom'
import { Button } from 'reactstrap'
import { withTranslation } from 'react-i18next';

class AnswerOptions extends Component {
    constructor() {
        super();
        this.state = {
            navigate: false
        }
    }

    refresh() {
        this.setState({
            navigate: true
        })
    }

    componentDidMount() {
    }
    render() {
        const { t } = this.props;
        const { navigate } = this.state
        if (navigate) {
            return <Redirect to={{ pathname: "/dashboard/"+ this.props.data.teamId  }}  push={true} />;
            // to={{ pathname: "/regi-deregistration/" + this.props.data.teamId,  visible: this.state.visible  }}
            // return <Redirect to="/home"  push={true} />;
        }
        return (
            <>
                <form id="finsih" method="post" action="#" className="form-horizontal">
                    <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <div className="card">
                                <div className="card-body">
                                    <div className="text-center">
                                        <h2 className="mt-0"> <i className="mdi mdi-check-all"></i> </h2>
                                        <h3 className="mt-0">{t("The new team has been created")}.</h3>
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <Button
                                                    type="button"
                                                    id="add_attrs"
                                                    onClick={() => this.refresh()}
                                                    className="btn btn-success waves-effect waves-light mt-2 add_attrs fw-bolder"
                                                > {t("Go to dashboard")} </Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- end col --> */}
                    </div>
                    {/* <!-- end row --> */}
                </form>
            </>
        )
    }
}
export default withTranslation()(AnswerOptions);