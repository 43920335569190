

import React, { Component } from 'react'
import axios from "axios";
import { APIURL } from '../constants/Common';
import {Spinner, Button, Input, Modal, ModalBody, ModalFooter, ModalHeader, Label } from 'reactstrap';
import Select from 'react-select'
import '@szhsin/react-menu/dist/index.css';
import '../../assets/css/Custom.css'
import { Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
// const options = [
//     { value: 0, label: 'No' },
//     { value: 1, label: 'yes' },
// ];

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {

            ...styles,
            backgroundColor: isFocused ? "#B8BCBD" : null,
            color: "grey",

        };
    }
};


 class SettingTasks extends Component {
    constructor(props) {
        super();

        this.state = {
            options: [
                { value: 0, label: 'No', icon: "1234" },
                { value: 1, label: 'yes', icon: "5678" },
            ],
            task_id: "",
            task_name: "",
            rights_user: {},
            active: false,
            order: "",
            Task_list: [],
            sortRow: [],
            change_rights_user: [{ value: 0, label: "No" }],
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            errMsg: "",
            checked: false,
            modal: false,
            AddModal: false,
            addTaskModal: false,
            RemScsMsg: "",
            removeTaskModal:false,
            removeTaskId:"",
            yesNoList:[],
            msg: "",
            scsMsg: "",


        }
        this.Editclick = this.Editclick.bind(this);
        this.AddModal = this.AddModal.bind(this);
        this.toggle = this.toggle.bind(this);
        this.handleRemoveTaskModal = this.handleRemoveTaskModal.bind(this)

    }

    toggle(e) {
         const { t } = this.props;
        this.setState({
            addTaskModal: !this.state.addTaskModal,
            errMsg: '',
            task_name: '',
            active: true,
            change_rights_user: [{ value: 0, label: t("No") }]
        });
    }

    handleTask(e) {
        this.setState({
            task_name: e
        })
    }

    AddModal(e) {
        this.setState({
            AddModal: !this.state.AddModal,
        });
    }


    handleModalInputs(e) {

        this.setState({
            task_name: e
        })
    }

    handleChangeAdmin = (e) => {
        if (e) {
            this.setState({
                Is_Admin: [{ value: e.value, label: e.label }]
            })
        }
        else {
        }
    };
    handleChange = (e) => {
       
        this.setState({ rights_user: [{ value: e.value, label: e.label }] })
    };

    handleChangeRights = (e) => {

        if (e) {
            this.setState({ change_rights_user: [{ value: e.value, label: e.label }] })
        }
        else {
            // this.setState({ errMsg: { function_id: 'Please select is-Admin.'} }); 
        }
    };

    activeStatus() {
        this.setState({
            Active: !this.state.Active
        })
    }

    getTasks(id) {
        this.setState({Loader:true})
        var token = this.state.token
        axios
            .get(APIURL + "get_tasks/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({Loader:false})
                if (response.data.status === 200) {
                    this.setState({ 
                        Task_list: response.data.data
                        
                     });
                }
            })
            // .catch(error => {
            //    this.setState({
            //        Loader:false
            //    })

            // }
            // );
    }
    Editclick(e) {
          const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.handleModalInputs(e.task_name)
        if (e.change_rights_user != 1) {
            e.change_rights_user = 0;
        }
        this.setState({
            modal: !this.state.modal,
            task_id: e.task_id,
            rights_user: [{ value: e.change_rights_user, label: t(e.rights_user_value) }],
            active: e.active == 1 ? true : false
        });
    }

    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }
    handleStatus(e) {
        this.setState({
            active: e.target.checked
        });
    }

    addTasks = () => {
        const { t } = this.props;
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId);
        formData.append('task_id', '');
        formData.append('task_name', this.state.task_name);
        formData.append('rights_user', this.state.change_rights_user[0].value);
        formData.append('active', this.state.active);
        this.setState({
            Loader: true
        })
        var app_url = APIURL + "create_task";
        var token = this.state.token
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getTasks(this.props.data.teamId);
                this.setState({
                    change_rights_user: [{ value: 0, label: t("No") }],
                    task_name: '',
                    errMsg: { task_name: '' },
                    Loader: false,
                    addTaskModal: false,
                    scsMsg: response.data.message,
                    Loader: false,
                });

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );
    }

    handleAddTasks = () => {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        if (this.state.rights_user && this.state.task_name) {
            this.setState({ modal: false })
            this.setState({
                Task_list: this.state.Task_list.concat([{
                    task_name: this.state.task_name,
                    rights_user: this.state.rights_user,
                    task_id: ""
                }]),
                setFunc: '',
                errMsgTask: '',
                rights_user: {},
                task_name: '',
                errMsg: { task_name: '' }
            });
        } else {
            this.setState({
                errMsgTask: "Please enter task name",
                errMsg: ''
            })
        }
    }

    handleRemoveTaskModal = (e) =>{
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.setState({
            removeTaskId:e.task_id,
            removeTaskModal:!this.state.removeTaskModal
        })
    }
    RemoveModalClose(){
        this.setState({
            removeTaskModal:false
        })
    }

    handleRemoveTask(e) {
        if(this.props.data.ActiveTeam === 0){
            this.props.data.CloseModal()
            return false;
           }
        this.getTasks(this.props.data.teamId);
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        var token = this.state.token
        this.setState({
            Loader: true,
        })
        axios.post(APIURL + "delete_task/" + this.state.removeTaskId, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    RemScsMsg: response.data.message,
                    redirect: true,
                    toast: true,
                    removeTaskModal:false,
                    scsMsg: response.data.message,
                    Loader: false,
                })
                this.setState({
                    Task_list: this.state.Task_list.filter((s, sidx) => this.state.removeTaskId != s.task_id
                    )
                });
            })
            .catch(error => {

                this.setState({
                    removeTaskModal:false,
                    errMsg: { message: error.response.data.message },
                    Loader: false,
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }

    onSubmit = (e) => {
        const formData = new FormData();

        formData.append('task_name', this.state.task_name);
        formData.append('team_id', this.props.data.teamId);

        formData.append('rights_user', this.state.rights_user[0].value);
        formData.append('active', this.state.active);

        var app_url = APIURL + "edit_task/" + this.state.task_id

        var token = this.state.token
        this.setState({
            Loader: true,
        })
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getTasks(this.props.data.teamId);
                this.setState({
                    errMsg: '',
                    redirect: true,
                    modal: false,
                    scsMsg: response.data.message,
                    Loader: false,
                });

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );
    };


    handleStep(type, index) {
        const sort_List = this.state.Task_list;
        let sortRow = []; let selfsort; let othersort;

        if (type == "up") {

            const self = sort_List[index];
            const other = sort_List[index - 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index - 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['task_id'], "sort": self['sort'] });
            sortRow.push({ id: other['task_id'], "sort": other['sort'] });

            this.setState({
                Task_list: sort_List
            })

        }
        else if (type == "down") {
            const self = sort_List[index];
            const other = sort_List[index + 1];

            selfsort = self['sort']; othersort = other['sort'];
            self['sort'] = othersort;
            other['sort'] = selfsort;
            sort_List[index + 1] = self;
            sort_List[index] = other;

            sortRow.push({ id: self['task_id'], "sort": self['sort'] });
            sortRow.push({ id: other['task_id'], "sort": other['sort'] });

            this.setState({
                Task_list: sort_List
            })
        }
        this.setState({
            sortRow: sortRow
        }, () => { this.updateStep() })

    }


    updateStep() {
        const formData = new FormData();
        formData.append('team_id', this.props.data.teamId)
        formData.append('sort', JSON.stringify(this.state.sortRow))
        var token = this.state.token
        axios.post(APIURL + "task/updateStep", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

            })
            .catch(error => {

                this.setState({
                    errMsg: { message: error.response.data.message }
                })
                setTimeout(() => this.setState({ errMsg: { message: '' } }), 3000);
            }
            );
    }


    componentDidMount() {
        if (this.props.data.teamId > 0) {
            this.getTasks(this.props.data.teamId);
        }
        this.getOption();

    }

    componentWillReceiveProps() {  
        this.getOption();
    }

    getOption(){
       const { t } = this.props;
        let yesNoList=[];
        yesNoList.push({ value: 0, label: t('No') })
        yesNoList.push({ value: 1, label: t('Yes') })

        let change_rights_user=this.state.change_rights_user;
        change_rights_user= [{ value: change_rights_user[0].value, label: change_rights_user[0].label}]

       this.setState({ 
        yesNoList: yesNoList,
        change_rights_user:change_rights_user 
       })
   }

   onAlertClose = () => {
    this.setState({
        msg: "",
        scsMsg: "",
    });
};

    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                 {this.state.Loader ? <div className="loader"> <Spinner style={{ width: '3rem', height: '3rem' }} /> </div> : ""}
                
                <div className="row">
                    <div className="col-sm-8">
                        <div className="card">
                            <div className="card-body">
                                <h5>{t("Tasks")}</h5>
                                {this.state.errMsg.message &&
                                    <span className="text-danger">{this.state.errMsg.message}</span>
                                }

                                <div className="table-responsive w-100">
                                    <Table hover className="table table-hover table-centered nowrap">
                                        <thead className="bg-light">
                                            <tr>
                                                <th></th>
                                                <th>{t("Task")}</th>
                                                <th>{t("Active")}</th>
                                                <th style={{ maxWidth: "100px" }}>{t("Only admin and team administrators can assign task")}</th>
                                                <th className="text-center" style={{ width: "170px" }}>{t("Action")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.Task_list.length > 0 && this.state.Task_list.map((item, idx) => (
                                                <tr key={item.task_id}>
                                                    <td>
                                                        {idx != 0 &&
                                                            <i onClick={(e) => this.handleStep('up', idx)} className="mdi mdi-arrow-up"></i>
                                                        }
                                                        {this.state.Task_list.length != idx + 1 &&
                                                            <i onClick={(e) => this.handleStep('down', idx)} className="mdi mdi-arrow-down"></i>
                                                        }
                                                    </td>
                                                    <td>{item.task_name}</td>
                                                    <td>
                                                        {item.active === 0 ? <span>No</span>
                                                            :
                                                            item.active === 1 ? <span>Yes</span>
                                                                :
                                                                ""
                                                        }
                                                    </td>
                                                    <td>
                                                        {parseInt(item.change_rights_user) == 1 ? <span>Only Admin</span> : <span>All</span>}

                                                    </td>
                                                    <td>
                                                        <Button size="sm" color="warning" className="mobile-icon" onClick={(e) => this.Editclick(item)} > <i className="ri-pencil-fill"></i> <span>{t("Edit")}</span></Button>
                                                        <Button size="sm" color="danger" className="mobile-icon ms-2" onClick={(e) => this.handleRemoveTaskModal(item)}><i className="ri-delete-bin-line"></i> <span>{t("Remove")}</span></Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>

                                    </Table>

                                    <div className="mt-3">
                                        <Button
                                            type="button"
                                            id="add_attrs"
                                            onClick={this.toggle}
                                            className="btn btn-warning  waves-effect waves-light mt-2 add_attrs"
                                        > {t("Add task")} </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal size="md" isOpen={this.state.addTaskModal}  autoFocus={false}>
                        <ModalHeader toggle={this.toggle}>{t("Add task")}</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Task")}*</Label>
                                        <Input
                                            type="text"
                                            className="form-control"
                                            id="useremail"
                                            placeholder={t("Enter task name")}
                                            value={this.state.task_name}
                                            onChange={(e) => this.handleTask(e.target.value)}
                                            autoFocus={true}
                                        />
                                        <span className="text-danger">{this.state.errMsg.task_name}</span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Only admin and team admin can set task")}</Label>
                                        <Select
                                            placeholder="Select permissions"
                                            value={this.state.change_rights_user}
                                            onChange={this.handleChangeRights}
                                            options={this.state.yesNoList}
                                            styles={colourStyles}
                                        // components={ {Option: this.customSingleValue } }
                                        />
                                        <span className="text-danger">{this.state.errMsg.rights_user}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-0">
                                        <input
                                            className="form-check-input mr-1"
                                            onChange={(e) => this.handleStatus(e)}
                                            checked={this.state.active}
                                            type="checkbox"
                                            id="flexCheckDefault"
                                        />
                                        <Label className="mb-0">{t("Active")}</Label>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                            <div className="d-flex justify-content-between w-100">
                               <button className="btn btn-danger" onClick={() => this.toggle()}> {t("Cancel")} </button>
                               <Button className="btn btn-success" onClick={() => this.addTasks()}> <i className="fas fa-save mr-1"> </i>{t("Save")} </Button>
                                </div>
                        </ModalFooter>
                    </Modal>
                    <Modal size="md" isOpen={this.state.modal} autoFocus={false}>
                        <ModalHeader toggle={this.Editclick}>{t("Edit task")}</ModalHeader>
                        <ModalBody>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Task")}</Label>
                                        <Input
                                            type="text"
                                            className="form-control "
                                            placeholder={t("Enter task name")}
                                            value={this.state.task_name}
                                            onChange={(e) => this.handleModalInputs(e.target.value)}
                                            autoFocus={true}
                                        />
                                        <span className="text-danger">{this.state.errMsg.task_name}</span>
                                    </div>
                                </div>
                                <div className="col-sm-12">
                                    <div className="form-group">
                                        <Label>{t("Only admin and team admin can set task")}</Label>

                                        <Select
                                            placeholder="Only teamadmin can set task"
                                            value={this.state.rights_user}
                                            onChange={this.handleChange}
                                            options={this.state.yesNoList}
                                            styles={colourStyles}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form-group mb-0">
                                        <input
                                            className="form-check-input mr-1"
                                            onChange={(e) => this.handleStatus(e)}
                                            checked={this.state.active}
                                            type="checkbox"
                                            id="flexCheckDefault"
                                        />
                                        <Label className="mb-0">{t("Active")}</Label>

                                    </div>
                                </div>
                            </div>
                        </ModalBody>

                        <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                           <Button className="btn btn-danger" onClick={() => this.CloseModal()}> {t("Cancel")} </Button>
                           <Button className="btn btn-success" onClick={() => this.onSubmit()}> <i className="fas fa-save mr-1"> </i>{t("Save")} </Button>
                        </div>
                          </ModalFooter>
                    </Modal>

                    <Modal className="modal-dialog w-25" style={{  borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.removeTaskModal}  >
                    <ModalBody color="primary w-25" style={{  borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            <h6 className="mt-3">{t("Are you sure want to delete?")}</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={()=> this.handleRemoveTask()} >{t("Confirm")} </Button>{' '}
                                <Button className="mx-2" color="default" onClick={()=> this.RemoveModalClose()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog" style={{ width: "300px" }} isOpen={!!this.state.scsMsg}>
                    <div className="row" style={{ backgroundColor: "#1abc9c", padding: "10px", borderRadius: "4px" }}>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group text-center w-100">
                                    {this.state.scsMsg}
                                </div>
                            </div>
                        </div>
                        <div className="text-center w-100">
                            <Button className="btn btn-light" onClick={() => this.setState({ scsMsg: "" })}> {t("Ok")} </Button>
                        </div>
                    </div>
                </Modal>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(SettingTasks);
