import React, { Component } from "react";
import '../../../assets/css/style.css'
import '../../../assets/css/bootstrap.min.css'
import '../../../assets/css/icons.css'
import '../../../assets/css/Custom.css'
import { Redirect, Link } from "react-router-dom";
import Sidebar from "../../GeneralComponents/Sidebar";
import { Input, Spinner, Table, Modal, ModalBody, ModalFooter, ModalHeader, Label, Button } from "reactstrap";
import axios from "axios";
import { APIURL } from '../../constants/Common';
import '@szhsin/react-menu/dist/index.css';
import Select from 'react-select'
import Footer from '../../GeneralComponents/Footer'
import { withTranslation } from 'react-i18next';
import { changeSort, sortUI } from "../../../utils/sortUI";

const colourStyles = {

    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? "#f1f1f1" : null,
            color: "#000000",
        };
    }
};

const options = [
    { value: 0, label: 'No' },
    { value: 1, label: 'yes' },
];

class TeamMembers extends Component {

    constructor(props) {
        super(props);
        this.state = {
            Member_list: [],
            email: "",
            isOwner: "",
            setMember: "",
            function: "",
            // func_List: [],
            attrValues: [],
            attrIds: [],
            attrList: [],
            func_List: [{ value: "", label: "Keine Funktion: Nur Team-Besitzer / Team-Admin" }],
            msg: "",
            errMsg: {},
            scsMsg: "",
            teamId: '',
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            Is_admin: [{ value: 0, label: 'No' }],
            function_name: [],
            team_name: "",
            Loader: false,
            modal: false,
            addModal: false,
            RemoveModal: false,
            id: "",
            memberId: "",
            RemoveMemberidx: "",
            active: false,
            err: false,
            SubscriptionModal: false,
            subscriptionPlan: "No",
            is_owner: "No",
            day: 0,
            ActiveTeam: 0,
            isPermission:1,
            no_record:false,
            yesNo:[],
            sort: {},
            search: "",
        }
        this.toggle = this.toggle.bind(this)
        this.RemoveModal = this.RemoveModal.bind(this)
        this.RemoveModalClose = this.RemoveModalClose.bind(this)
        this.Editclick = this.Editclick.bind(this)
        this.errModal = this.errModal.bind(this)
        this.onChangeSort = this.onChangeSort.bind(this);
    }

    // handleStatus = () => {
    //     this.setState({
    //         active:!this.state.active
    //     })
    //     alert(this.state.active)
    // }

    handleStatus(e) {
        this.setState({
            active: e.target.checked
        });
    }

    RemoveModal(idx, e) {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        this.setState({
            RemoveModal: !this.state.RemoveModal,
            memberId: e.id,
            RemoveMemberidx: idx
        })
    }
    RemoveModalClose() {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        this.setState({
            RemoveModal: !this.state.RemoveModal,
        })
    }

    toggle(e) {
         const { t } = this.props;
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }

        this.setState({
            addModal: !this.state.addModal,
            email: "",
            errMsg: '',
            Is_admin: [{ value: 0, label: t('No') }],
            function_name: [{ value: "", label: "Keine Funktion: Nur Team-Besitzer / Team-Admin" }],
            RemoveModal: this.state.RemoveModal,
            active: false
        });
    }

    Editclick(e) {
         const { t } = this.props;
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        let adminLable = "No"; let adminValue = 0;
        if (e.is_admin == 1) {
            adminLable = "Yes"; adminValue = 1;
        }
        let function_name = [{ value: e.function_id, label: e.function_name }];
        if (e.function_id == null) {
            function_name = [{ value: "", label: "Keine Funktion: Nur Team-Besitzer / Team-Admin" }];
        }
        this.setState({
            id: e.id,
            function_name: function_name,
            Is_admin: [{ value: adminValue, label: t(adminLable) }],
            modal: !this.state.modal,
            attrList: [],
            active: e.active == 1 ? true : false
        });
        this.getMemberAttr(e);
    }
    CloseModal() {
        this.setState({
            modal: !this.state.modal
        });
    }

    getMemberAttr(e) {
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('member_id', e.id);
        formData.append('team_id', e.team_id);
        var token = this.state.token
        axios
            .post(APIURL + "membership/get-attributes", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    Loader: false,
                    attrList: response.data.data,
                })
            })
            .catch(error => {
                this.setState({
                    error,
                    Loader: false
                })
            });
    }
    resendEmail(e) {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
      
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('member_id', e.id);
        formData.append('user_id', e.user_id);
        formData.append('team_id', e.team_id);
        var token = this.state.token
        axios
            .post(APIURL + "resend_member_invitaion", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({
                    Loader: false,
                    scsMsg: response.data.message,
                })
                setTimeout(() => {
                    this.setState({
                        scsMsg: '',
                    })
                }, 10000);
            });
    }

    HandleEventType(e) {
       
        this.setState({
            Event_id: e.event_type_id,
            Event_type: e.Event_type,
            Color_code: e.Color_code,
            default_deadline_days: e.default_deadline_days,
            default_duration_hours: e.default_duration_hours,
            default_infomail_uncertain: e.default_infomail_uncertain,
            default_infomail_unregistered: e.default_infomail_unregistered,
        })
    }

    onChangeAttr = (e, index) => {
        let attrList = this.state.attrList;
        attrList[index]['value'] = e.target.value;
        this.setState({
            attrList: attrList
        });

    };

    onChangehandler = (e) => {
        if (e) {
            this.setState({
                email: e.target.value,
                errMsg: { errMsgEmail: [""] }
            });
        }
        else {
            this.setState({
                errMsg: { errMsgEmail: ["Please Enter Email"] },
            });
        }
    };

    SelectFunctions = (e) => {
       
        if (e) {
            this.setState({
                function_name: [{ value: e.value, label: e.label }]
            })
        }
       
    };

    handleChange = (e) => {
        if (e) {
            this.setState({
                Is_admin: [{ value: e.value, label: e.label }],
                errMsg: { Is_Admin: [""] },
            })
        }
        else {
            this.setState({
                errMsg: { Is_Admin: ["Please Select Is_admin"] },
            });
        }
    };

    reloadPage() {
        setTimeout(() => {
            window.location.reload();
        }, 2000);
    }


    getTeamDetails = () => {
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }
                this.setState({
                    team_name: team_name
                })
            });
    }
    handleAddMembers = () => {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        this.SelectFunctions()
        this.setState({ modal: false })

        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        formData.append('owner_id', this.state.user.user_id);
        formData.append('member_email', this.state.email);
        formData.append('is_admin', this.state.Is_admin[0].value);
        if (Array.isArray(this.state.function_name) && this.state.function_name.length) {
            formData.append('function_id', this.state.function_name[0].value);
        }
        else {
            formData.append('function_id', '');
        }
        formData.append('member_id', '');
        var token = this.state.token

        var app_url = APIURL + "create_member_function";
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getMemberInfo(this.props.match.params.id);
                this.setState({
                    Is_admin: [{ value: 0, label: 'No' }],
                    function_name: [],
                    email: "",
                    function_id: "",
                    modal: false,
                    addModal: false,
                    Loader: false
                });

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );

    }

    handleEditMembers = () => {
        const { t } = this.props;
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        this.setState({ modal: false })

        this.setState({
            Loader: true
        })
        let falge = 0;
        const formData = new FormData();
        formData.append('id', this.state.id);
        formData.append('team_id', this.props.match.params.id);
        formData.append('is_admin', this.state.Is_admin[0].value);
        formData.append('active', Number(this.state.active));
        if (Array.isArray(this.state.function_name) && this.state.function_name.length) {
            formData.append('function_id', this.state.function_name[0].value);
        }
        else {
            formData.append('function_id', '');
        }

        formData.append('attributess', JSON.stringify(this.state.attrList));
        var token = this.state.token

        var app_url = APIURL + "member-update";
        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.getMemberInfo(this.props.match.params.id);
                this.setState({
                    Is_admin: [{ value: 0, label: t('No') }],
                    function_name: [],
                    function_id: "",
                    modal: false,
                    addModal: false,
                    Loader: false
                });

            })
            .catch(error => {
                if (error.response.data.message == "validation_error") {
                    this.setState({
                        errMsg: error.response.data.errors,
                        Loader: false
                    })
                }
                else {
                    this.setState({
                        error,
                        Loader: false
                    })
                }

            }
            );

    }

    handleRemoveMember = () => {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }

        //  alert(idx)
        this.setState({
            Loader: true
        })
        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        formData.append('id', this.state.memberId);
        var token = this.state.token
        axios
            .post(APIURL + "delete_member", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then(response => {
                this.setState({
                    Loader: false,
                    RemoveModal: false,
                    // errMsg: [{ message: result.data.message }],
                    // errMsg:result.data.message,
                    Member_list: this.state.Member_list.filter((s, sidx) => this.state.RemoveMemberidx != sidx),
                    no_record:true
                });
            })
            .catch(error => {
                this.setState({
                    RemoveModal: false,
                    errModal: true,
                    Loader: false,
                    errMsg: error.response.data.message
                })
                // cons
                setTimeout(() => this.setState({ errModal: false }), 2000);
            }
            );
    }

    getAlert() {
        alert('getAlert from Child');
    }



    getFunctions(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_active_functions/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {

                    let functions = response.data.data;
                    for (var c = 0; c < functions.length; c++) {
                        this.state.func_List.push({ value: functions[c].function_id, label: functions[c].name })
                    }
                }
            });
    }

    errModal = () => {
        this.setState({
            errModal: !this.state.errModal
        })
        this.getMemberInfo()
    }

    onSubmit = (e) => {
        if (this.state.ActiveTeam === 0) {
            this.SubscriptionModal()
            return false;
        }
        const formData = new FormData();

        formData.append('state', this.state.state.value);
        var app_url = APIURL + "edit_answer/" + this.state.Answer_id
        var token = this.state.token

        axios
            .post(app_url, formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                this.setState({ isLoading: false });
                if (response.data.status === 200) {
                    this.getAnswerOption(this.props.data.teamId);
                    this.setState({
                        msg: response.data.message,
                        errMsg: '',
                        redirect: true,
                        modal: false
                    });
                }
                if (
                    response.data.status === "failed" &&
                    response.data.success === undefined
                ) {

                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.errors,
                    });
                    setTimeout(() => {
                        // this.setState({ errMsgEmail: "", errMsgPwd: "" });
                    }, 2000);
                } else if (
                    response.data.status === "failed" &&
                    response.data.success === false
                ) {
                    this.setState({
                        scsMsg: '',
                        errMsg: response.data.message,
                        errMsgEmail: '',
                        errMsgPwd: '',
                    });
                    setTimeout(() => {
                        // this.setState({ errMsg: "" });
                    }, 2000);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    getMemberInfo(id) {
        var token = this.state.token
        axios
            .get(APIURL + "get_member_functions/" + id,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

                if (response.data.data.length > 0) {
                    this.setState({ Member_list: response.data.data });
                }
            });
    }

    componentDidMount() {
        // if(this.state.Loader){
        //     this.reloadPage()
        // }
        if (this.props.match.params.id > 0) {
            this.getFunctions(this.props.match.params.id)
            this.getMemberInfo(this.props.match.params.id);
            this.getTeamDetails();
            this.getExpirationPlanMsg()
        }
          this.getOption();
    }
    componentWillReceiveProps() {
         this.getOption();
    }

     getOption(){
        const { t } = this.props;
        let yesNo=[];
        yesNo.push({ value: 0, label: t('No') })
        yesNo.push({ value: 1, label: t('Yes') })

        let Is_admin=this.state.Is_admin;
        Is_admin= [{ value: Is_admin[0].value, label: Is_admin[0].label}]

    
       this.setState({ 
        yesNo: yesNo,
        Is_admin:Is_admin
       })
   }

    getExpirationPlanMsg = () => {
        const formData = new FormData();
        formData.append('user_id', this.state.user.user_id);
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token
        axios
            .post(APIURL + "license_booking/send_notifications", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                let days = response.data.after_days;
                let Owner = response.data.is_owner;
                let Sub_true = false; let isPermission = 1;
                if ((response.data.subscriptionPlan == "No") || (Owner === "Yes" && days > 0) || (Owner === "No" && days > 14)) {
                    Sub_true = true;
                }
                // for guest
                if (days > 60 || response.data.member.length == 0) {
                    isPermission = 0;
                }
                // for admin
                // if(days>60 || response.data.member.length==0)
                // {
                //    isPermission=0;
                // }
                // else if(response.data.member.length==1)
                // {
                //     if(response.data.member[0].is_admin==0 && response.data.member[0].is_admin==0)
                //     {
                //          isPermission=0;
                //     }
                // }


                this.setState({
                    SubscriptionModal: Sub_true,
                    subscriptionPlan: response.data.subscriptionPlan,
                    is_owner: response.data.is_owner,
                    day: response.data.after_days,
                    ActiveTeam: response.data.active,
                    isPermission: isPermission
                    // ActiveTeam:1
                })
            });
    }
    SubscriptionModal = () => {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    CloseModalForSubscriptio() {
        this.setState({
            SubscriptionModal: !this.state.SubscriptionModal
        })
    }

    onChangeSort(key) {
        let sortTem = {};
        sortTem[`${key}`] = changeSort(this.state.sort[`${key}`]);
        this.setState({
            sort: sortTem,
        })

        let order_by = `${key}.${changeSort(this.state.sort[`${key}`])}`;

        var token = this.state.token
        axios
            .get(APIURL + "get_member_functions/" + this.props.match.params.id + `?order_by=${order_by}&search=${this.state.search}`,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {

                if (response.data.data.length > 0) {
                    this.setState({ Member_list: response.data.data });
                }
            });
    }

    render() {
        const { t } = this.props;
        const { visible } = this.state
        const { navigate } = this.state;
        
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
        if (navigate) {
            return <Redirect to="/login" push={true} />;
        }
        console.log(this.state.Member_list)
        return (
            <div id="wrapper">
                {
                    this.state.Loader ?
                        <div className="loader">
                            <Spinner style={{ width: '3rem', height: '3rem' }} />
                        </div>
                        :
                        ""
                }
                <Sidebar
                    data={{
                        // teamInfo: this.teamInfo.bind(this),
                        teamId: this.props.match.params.id,
                        team_name: this.state.team_name,
                        ActivePage: "members",
                        visible:this.props.location.visible
                    }} />

                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.SubscriptionModal} toggle={() => this.SubscriptionModal()}  >
                    <ModalHeader toggle={() => this.SubscriptionModal()}></ModalHeader>
                    <ModalBody color="primary " style={{ borderRadius: "5px", padding: "10px" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "Yes" &&
                                <h6 className="mt-3">Upgrade Package.</h6>
                            }
                            {
                                this.state.subscriptionPlan === "No" && this.state.is_owner === "No" &&
                                <h6 className="mt-3"> Upgrade Package / Please Contact to Team Owner .</h6>
                            }
                            {
                                this.state.is_owner === "Yes" && this.state.day >= 1 && this.state.day < 31 &&
                                <h6 className="mt-3">Buy more teams / Delete teams <br /><br /> or <br /><br /> Change owner of team.</h6>
                            }
                            {
                                this.state.is_owner === "No" && this.state.day >= 15 && this.state.day < 31 &&
                                <h6 className="mt-3">Pending Payment / Please Contact to Team Owner  {this.state.full_name}</h6>
                            }
                            {
                                this.state.day >= 30 && this.state.day < 61 &&
                                <h6 className="mt-3">The team has been deactivated due to outstanding payments. You should contact Team Organizer if the team is to be reactivated.</h6>
                            }

                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100 text-center">
                            <Button className="btn btn-danger" onClick={() => this.CloseModalForSubscriptio()}>Cancel </Button>
                            {this.state.is_owner === "Yes" ? <Button className="btn btn-success" onClick={() => this.goToSubscription()}>Upgrade Package</Button> : ""}
                          
                        </div>
                    </ModalFooter>
                </Modal>
                <div className={visible ? "content-page content-margin" : "content-page"}>
                    <div className="content">
                        {/* <!-- Start Content--> */}
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div className="page-title-box page-title-box-alt">
                                        {/* <h4 className="page-title">{this.state.teamInfo.team_name}| Member</h4> */}
                                        <h4 className="page-title">{this.state.team_name} | {t("Member")} </h4>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-12">
                                        <h6>
                                            <Button
                                                type="button"
                                                id="add_attrs"
                                                onClick={this.toggle}
                                                className="btn btn-primary waves-effect waves-light mt-0 add_attrs"
                                            >
                                                <i className="mdi mdi-plus"></i> {t("Add New Member")}
                                            </Button>
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">

                                <div className="table-responsive">
                                    {
                                        this.state.scsMsg ?
                                            <div className={this.state.scsMsg ? "mb-2  text-success Login_error" : "mb-2  text-danger"} >
                                                {this.state.scsMsg}
                                            </div> :
                                            ""
                                    }
                                    {/* <span className="text-success">{this.state.scsMsg}</span> */}
                                    <Table hover className="table table-hover mt-2  table-centered dt-responsive nowrap  w-100">
                                        <thead style={{ backgroundColor: "#0c3047" }}>
                                            <tr className="fw-medium" >
                                                {/* <th >ID</th> */}
                                                <th ></th>
                                                <th className="fw-medium " style={{minWidth:"140px"}}>{t('First name')}{sortUI(this.state.sort.first_name, "first_name", this.onChangeSort)}</th>
                                                <th className="fw-medium " style={{minWidth:"140px"}}>{t('Last Name')}{sortUI(this.state.sort.last_name, "last_name", this.onChangeSort)}</th>
                                                <th style={{minWidth:"140px"}}>{t("E-Mail")}{sortUI(this.state.sort.email, "email", this.onChangeSort)}</th>
                                                <th style={{minWidth:"140px"}}>{t("Address")}{sortUI(this.state.sort.address, "address", this.onChangeSort)}</th>
                                                <th style={{minWidth:"140px"}}>{t("Phone")}{sortUI(this.state.sort.phone, "phone", this.onChangeSort)}</th>
                                                <th style={{minWidth:"140px"}}>{t("Function")}{sortUI(this.state.sort.function_name, "function_name", this.onChangeSort)}</th>
                                                <th style={{minWidth:"140px"}}>{t("Administrator")}{sortUI(this.state.sort.is_admin, "is_admin", this.onChangeSort)}</th>
                                                <th style={{minWidth:"50px"}}>{t("State")}</th>
                                                {/* <th >{t("Is owner")}</th> */}
                                                <td style={{minWidth:"140px"}}>{t("Active")}{sortUI(this.state.sort.active, "active", this.onChangeSort)}</td>
                                                <td className="fw-medium text-center" style={{ minWidth: "110px" }}>{t("Action")}</td>
                                            </tr>
                                        </thead>
                                        <tbody className="font-14" >
                                            {this.state.Member_list.length > 0 && this.state.Member_list.map((item, idx) => (
                                                <tr key={item.full_name}>
                                                    <td></td>
                                                    <td >{item.first_name}</td>
                                                    <td >{item.last_name}</td>
                                                    <td> {item.email} </td>
                                                    <td >
                                                        <div>{item.address ? item.address : ""}</div>
                                                         <div>{item.postcode ? item.postcode : ""}</div>
                                                      </td>
                                                    <td >{item.phone ? item.phone : ""}</td>
                                                    <td> {item.function_name ? item.function_name : "Keine Funktion: Nur Team-Besitzer / Team-Admin"} </td>
                                                    <td >
                                                        {item.is_admin === 0 ?
                                                            <span>{t('No')}</span> : <span>{t('Yes')}</span>}
                                                    </td>
                                                     <td >
                                                         <div>
                                                             {
                                                            item.full_name ? t('User has a TeamOrganizer Account') + '.'  : t('User does not have a TeamOrganizer Account') + '.'
                                                             }
                                                         </div>
                                                         <div>
                                                        {
                                                            item.full_name ? '' : (<a href="#" style={{ color: "#f39330" }} onClick={(e) => this.resendEmail(item)}>{t('(send mail again)')}</a>)
                                                        }
                                                         </div>
                                                         <div>
                                                        {
                                                            (item.member_active && item.full_name ? t('User confirmed for this team') + '.' : '')
                                                        }
                                                         </div>
                                                         <div>
                                                        {
                                                            (!item.member_active && item.full_name ? t('User has not yet confirmed membership in this team') + '. ' : '')
                                                        }
                                                         </div>
                                                         <div>
                                                        {
                                                            (!item.member_active && item.full_name ? (<a href="#" style={{ color: "#f39330" }} onClick={(e) => this.resendEmail(item)}>{t('(send mail again)')}</a>) : '')
                                                        }
                                                         </div>
                                                    </td>
                                                   
                                                    <td >
                                                        {item.active === 0 ?
                                                            <span>{t('No')}</span> : <span>{t('Yes')}</span>}
                                                    </td>
                                                    {/* <td>
                                                        {item.active === 0 ?
                                                            <span>No</span> : <span>Yes</span>}
                                                    </td> */}
                                                    <td>
                                                        {
                                                            item.user_id === this.state.user.user_id ?
                                                                <div>
                                                                    <Button size="sm" color="warning"
                                                                        onClick={(e) => this.Editclick(item)} >
                                                                        <i className="ri-pencil-fill"></i>
                                                                    </Button>
                                                                </div>
                                                                : <div></div>


                                                        }
                                                        {
                                                            item.user_id == this.state.user.user_id || item.is_teamleader ?
                                                                <div></div>
                                                                :
                                                                <div>
                                                                    <Button size="sm" color="warning"
                                                                        onClick={(e) => this.Editclick(item)} >
                                                                        <i className="ri-pencil-fill"></i>
                                                                    </Button>
                                                                    <Button size="sm" color="danger" className="ms-2"
                                                                        onClick={(e) => this.RemoveModal(idx, item)}
                                                                    >
                                                                        <i className="ri-delete-bin-line"></i>
                                                                    </Button>
                                                                </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                            {this.state.no_record==true && this.state.Member_list.length==0 &&
                                                <tr>
                                                    <td colSpan="11">
                                                        No Member Added
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>


                        </div>
                        {/* <!-- container-fluid --> */}
                    </div>
                </div>

                <Footer />

                {/*<footer className="footer">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-6">
                                <Link to="#"><i className="fe-facebook"></i></Link>
                                <Link to="#"><i className="fe-instagram"></i></Link>
                                TeamOrganizer GMbH
                            </div>
                            <div className="col-md-6">
                                <div className="text-md-end footer-links d-none d-sm-block">
                                    <Link to="/">imprint</Link>
                                    <Link to="/">privacy</Link>
                                    <Link to="/">design by stoz</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>*/}
                
                <div className="rightbar-overlay"></div>
                <Modal size="md" isOpen={this.state.addModal} autoFocus={false}>
                    <ModalHeader toggle={this.toggle}>{t("Add function")}</ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Member e-mail")}</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        placeholder={t("Enter member e-mail address")}
                                        id="useremail"
                                        name="email"
                                        required=""
                                        value={this.state.email}
                                        onChange={this.onChangehandler}
                                        autoFocus={true}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.member_email)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12 view-element">
                                <div className="form-group">
                                    <Label>{t("Function")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required="required"
                                        placeholder={t("Select")}
                                        options={this.state.func_List}
                                        value={this.state.function_name}
                                        onChange={this.SelectFunctions}
                                        styles={colourStyles}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.function_id)}</span>
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Is team administrator")}</Label>
                                    <Select
                                        placeholder="Select Is-Team Admin"
                                        value={this.state.Is_admin}
                                        onChange={this.handleChange}
                                        options={this.state.yesNo}
                                        styles={colourStyles}
                                    />
                                    <span className="text-danger">{t(this.state.errMsg.Is_Admin)}</span>
                                </div>
                            </div>

                        </div>
                    </ModalBody>

                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <button className="btn btn-danger fw-bolder" onClick={() => this.toggle()}>{t("Cancel")} </button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.handleAddMembers()}>{t("Save")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal size="md" isOpen={this.state.modal}  autoFocus={false}>
                    <ModalHeader toggle={this.Editclick}>{t("Edit")} </ModalHeader>
                    <ModalBody style={{ backgroundColor: "#376295", height: "500px", overflowY: "auto" }}>
                        <div className="row">
                            <div className="col-sm-12 view-element">
                                <div className="form-group">
                                    <Label>{t("Function")}</Label>
                                    <Select
                                        aria-label=".form-select-lg example"
                                        required="required"
                                        placeholder={t("Select")}
                                        options={this.state.func_List}
                                        value={this.state.function_name}
                                        onChange={this.SelectFunctions}
                                        styles={colourStyles}
                                        autoFocus={true}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label>{t("Is team administrator")}</Label>
                                    <Select
                                        placeholder={t("Select Is-Team Admin")}
                                        value={this.state.Is_admin}
                                        onChange={this.handleChange}
                                        options={this.state.yesNo}
                                        styles={colourStyles}
                                    />
                                </div>
                            </div>
                            <div className="col-sm-12">
                                <div className="form-group rounded p-1" style={{backgroundColor:"rgb(12 48 69 / 38%)"}}>
                                    <Label className="mt-1 fw-medium">{t("Attributes")}</Label>
                                </div>
                            </div>
                            {this.state.attrList.length > 0 ? this.state.attrList.map((attrs, idx) => (
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <Label style={{fontSize:"12px"}}>{attrs.name}</Label>
                                    <Input
                                        className="form-control"
                                        required=""
                                        type="text"
                                        name={attrs.team_attrs_id}
                                        placeholder={t("Enter attribute value")}
                                        value={attrs.value}
                                        onChange={(e) => this.onChangeAttr(e, idx)}
                                    />
                                </div>
                            </div>
                            )) : ""}
                            <div className="col-sm-12">
                                <div className="form-group">
                                    <input className="form-check-input mr-1" type="checkbox" onChange={(e) => this.handleStatus(e)} checked={this.state.active} id="flexCheckDefault" />
                                    <Label className="mb-0">{t("Active")}</Label>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className="d-flex justify-content-between w-100">
                            <Button className="btn btn-danger fw-bolder" onClick={() => this.CloseModal()}> {t("Cancel")} </Button>
                            <Button className="btn btn-success fw-bolder" onClick={() => this.handleEditMembers()}>{t("Save")} </Button>
                        </div>
                    </ModalFooter>
                </Modal>

                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.RemoveModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {this.state.errMsg.message &&
                                <span className="text-danger">{this.state.errMsg}</span>
                            }
                            <h6 className="mt-3">{t("Are you sure want to delete?")}</h6>
                            <p className="mt-4 col-12">
                                <Button className="mx-2" width="50px" color="light" onClick={() => this.handleRemoveMember()} >{t("Confirm")} </Button>{' '}
                                <Button color="default" onClick={() => this.RemoveModalClose()}>{t("Cancel")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className="modal-dialog w-25" style={{ borderRadius: "10px", backgroundColor: "#E85368" }} isOpen={this.state.errModal}  >
                    <ModalBody color="primary w-25" style={{ borderRadius: "5px", backgroundColor: "#E85368" }}>
                        <div style={{ color: "#fff", textAlign: "center", alignItems: "center" }}>
                            {this.state.errMsg &&
                                <h6>{this.state.errMsg}</h6>
                            }
                            {/* <h6 className="mt-3">{this.state.errMsg.message}</h6> */}
                            <p className="mt-4 col-12 text-center">
                                <Button width="50px" color="light" onClick={() => this.setState({ errModal: false })}>{t("Close")}</Button>
                            </p>
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}
export default withTranslation()(TeamMembers);