import React, { Component } from 'react'
import { Input, Button, Modal, ModalBody, ModalHeader, Table, ModalFooter } from 'reactstrap';
import axios from "axios";
import { APIURL, APIDOMAIN } from '../../../constants/Common';
import { Redirect, Link } from "react-router-dom";
import '@szhsin/react-menu/dist/index.css';
import Sidebar from "../../../GeneralComponents/Sidebar";
import '../../../../assets/css/style.css'
import '../../../../assets/css/bootstrap.min.css'
import '../../../../assets/css/icons.css'
import '../../../../assets/css/Custom.css'
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { CSVLink } from "react-csv";

const guestOption = [
    {
        label: "No",
        value: "0",
    },
    {
        label: "Yes",
        value: "1",
    }
];


class Index extends Component {
    constructor(props) {
        super(props)
        this.state = {
            teamId: this.props.match.params.id,
            user: JSON.parse(localStorage.getItem("userData")),
            token: JSON.parse(localStorage.getItem("token")),
            error: [],
            errorRequireFiled: "",
            ActiveTeam: 0,
            isPermission: 1,
            csvData:[],
            eventType: [],
            statisticEvents: [],
            responseData: [],
            fromDate: "",
            toDate: "",
            page: 1,
            func_List: [],
            totalRow: []
        };
    }

    componentDidMount() {
        if (this.state.teamId != "undefined") {
            this.getEventTypes();
            this.getFunctions();
            this.getStatisticEvent();
            this.getTeamDetails();
        }
    }

    getTeamDetails(){

        const formData = new FormData();
        formData.append('team_id', this.props.match.params.id);
        var token = this.state.token

        axios
            .post(APIURL + "get_team_detail", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let team_name = "";
                if (response.data.data != null) {
                    team_name = response.data.data.team_name;
                }

                this.setState({
                    team_name: team_name
                })

            });


    }

    handleChangePage() {
        this.setState({
            page: 2
        }, () => {
            this.eventData();
        });
    }

    handleFromDate(e) {
        this.setState({
            fromDate: e
        }, () => {
            this.getStatisticEvent();
        });
    }

    handleToDate(e) {
        this.setState({
            toDate: e
        }, () => {
            this.getStatisticEvent();
        });
    }
   
    getEventTypes() {
        const formData = new FormData();
        formData.append('team_id', this.state.teamId);
        var token = this.state.token
        axios
            .post(APIURL + "eventtype/get_active_event_types", formData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((response) => {
                let EventTypes = response.data.data;
                const arryEvent = [];
                for (var c = 0; c < EventTypes.length; c++) {
                    arryEvent.push({ value: EventTypes[c].event_type_id, label: EventTypes[c].name })
                }

                console.log(arryEvent);
                this.setState({
                    eventType: arryEvent,
                })
            })
    }

    getFunctions() {
        var token = this.state.token
        axios
            .get(APIURL + "get_active_functions/" + this.state.teamId,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === 200) {
                    const functionsArry = [];
                    let functions = response.data.data;
                    for (var c = 0; c < functions.length; c++) {
                        functionsArry.push({ value: functions[c].function_id, label: functions[c].name })
                    }
                    
                    this.setState({
                        func_List: functionsArry,
                    }, () => {
                        this.getStatisticEvent();
                    })
                }
            });
    }

    getStatisticEvent() {
        var token = this.state.token;
        const formData = new FormData();
        if (this.state.user != null) {
            formData.append('user_id', this.state.user.user_id);
        }
        formData.append('team_id', this.state.teamId);
        formData.append('FROM', this.state.fromDate);
        formData.append('TO', this.state.toDate);

        axios
            .post(APIURL + "statistics/get-eventtype", formData,{
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                })
            .then((response) => {
                if (response.data.status === "success") {
                    let responseData = response.data.data;

                    this.setState({
                        responseData: responseData
                    })
                    
                    this.eventData();
                }
            });
    }

    eventData() {
        let finalEventArr = [];
        let csvEventArr = [];
        var totalCounter  = [];
        var funCounter = [];
        var eventTypeState = this.state.eventType;
        
        if(this.state.page === 1) {
            eventTypeState = eventTypeState.slice(0, 10);
        }
        var counter=0;

        eventTypeState.map((eventType, index) => {
            var funcArray = [];
            csvEventArr.push({"Event Type": eventType.label});
                
            this.state.func_List.map((fn) => {
                counter=0;
               this.state.responseData.filter((rows) => 
                    rows.event_type_id == eventType.value 
                    && rows.function_id == fn.value
                ).map((itms)=>{
                    if(itms.state == 1)
                    {
                        counter++;
                    }
                    if(Number(itms.guests) && Number(itms.guests)>0)
                    {
                        counter+=Number(itms.guests);
                    } 

                });


                var funcName = fn.label;
                csvEventArr[index][funcName] = counter;
                funcArray.push(counter);
            });

            finalEventArr.push({event_name: eventType.label, functions_count: funcArray});
        });

        csvEventArr.push({"Event Type": "Total"});
        var csvLength = csvEventArr.length;

        for(var i = 0; i < this.state.func_List.length; i++) {
            var totalCount = 0;
            for(var j = 0; j < finalEventArr.length; j++) {
                totalCount += finalEventArr[j].functions_count[i];
            }

            funCounter.push(totalCount);

            var fnName = this.state.func_List[i].label;
            csvEventArr[csvLength-1][fnName] = totalCount;                        
        }

        totalCounter.push({"Total": "Total", total_counter: funCounter});

        this.setState({
            statisticEvents: finalEventArr,
            csvData: csvEventArr,
            totalRow: totalCounter
        })
    }

    render() {
        const { t } = this.props;
        //console.log("event props", this.props)
        if (!this.state.user) {
            return <Redirect to="/login" push={true} />;
        }
        else if (!this.state.isPermission) {
            return <Redirect to="/permission" push={true} />;
        }
       
        return (
            <React.Fragment>
                <div id="wrapper">
                    <Sidebar
                        data={{
                            teamId: this.props.match.params.id,
                            team_name: this.state.team_name,
                            ActivePage: "events",
                            visible:this.props.location.visible
                        }} />
                    
                    <div className="content-page">
                        <div className="content">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="page-title-box page-title-box-alt justify-content-start">
                                            <h4 className="page-title">{this.state.team_name} | {t("Statistics")} </h4>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <div className="col-12">
                                        <ul className="nav nav-tabs ">
                                            <li className="nav-item">
                                                <Link to={"/statistics/" + this.state.teamId} className="nav-link">{t("Member overview")}</Link>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" href="#events1"><span>{t("Event type overview")}</span></a>
                                            </li>
                                            <li className="nav-item">
                                                <Link to={"/statistics/task/" + this.state.teamId} className="nav-link">{t("Task overview")}</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="tab-content">
                                    <div className="tab-pane active" id="events1">
                                        <div className="row">
                                            <div className="col-md-6 col-sm-8">
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h4 className="header-title mb-3 text-capitalize"></h4>
                                                        <div className="mb-2">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("From")}</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="from_date"
                                                                        id="from_date"
                                                                        value={this.state.fromDate}
                                                                        onChange={(e) => this.handleFromDate(e.target.value)}
                                                                    />
                                                                </div>
                                                                <div className="col-6">
                                                                    <label className="form-label">{t("To")}</label>
                                                                    <input
                                                                        className="form-control form-control-sm"
                                                                        type="date"
                                                                        name="to_date"
                                                                        id="to_date"
                                                                        value={this.state.toDate}
                                                                        onChange={(e) => this.handleToDate(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                {   this.state.csvData.length > 0 &&
                                                    <div className="text-right mb-3"><CSVLink  className="btn btn-sm btn-success" data={this.state.csvData} filename={"my-file.csv"}>{t("Excel Export(CSV)")}</CSVLink ></div>
                                                }
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="table-responsive table-wrapper mb-4">
                                                            <table className="table table-hover tableFixHead tableFixFoot">
                                                                <thead className="bg-light">
                                                                    <tr>
                                                                        <th className="fw-medium">{t("Event Type")}</th>
                                                                        {
                                                                            this.state.func_List.length > 0 && this.state.func_List.map((item) => (
                                                                                <th className="fw-medium">{item.label}</th>
                                                                            ))
                                                                        }
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="font-14">
                                                                    {
                                                                        this.state.statisticEvents.length > 0 && this.state.statisticEvents.map((item) => (
                                                                            <tr>
                                                                                <td>{item.event_name}</td>
                                                                                {
                                                                                    item.functions_count.map((funcCount) => (
                                                                                        <td>{funcCount}</td>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                                <tfoot className="font-14">
                                                                    {
                                                                        this.state.totalRow.length > 0 && this.state.totalRow.map((item) => (
                                                                            <tr>
                                                                                <th>{item.Total}</th>
                                                                                {
                                                                                    item.total_counter.map((totalCount) => (
                                                                                        <th>{totalCount}</th>
                                                                                    ))
                                                                                }
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tfoot>
                                                            </table>  
                                                        </div>
                                                        {
                                                            this.state.page == 1 && this.state.statisticEvents.length > 0 
                                                            ? <div className="text-left mt-3" onClick={() => this.handleChangePage()} ><a href="#" className="btn btn-sm btn-success">{t("List of all events")}</a></div> 
                                                            : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withTranslation()(Index);